import { Tooltip } from '@mui/material'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { Divider } from '@nextui-org/react'
import { format } from '../../utils/date'
import Link from '../common/util/input/Link'
import { formatNumber, formatWAX, getAssetLink } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import { useSharedState } from '../waxplorer/Waxplorer'
import moment from 'moment'

const useNotificationInfo = (type, name) => {
    const { t } = useTranslation('common')
    if (type === 'buy')
        return {
            notificationText: t('navigation.your_asset_was_bought'),
            info: t('navigation.price'),
        }
    if (type === 'sale')
        return {
            notificationText: t('navigation.x_is_for_sale', { x: name }),
            info: t('navigation.price'),
        }
    if (type === 'floor')
        return {
            notificationText: t(
                'navigation.floor_prices_for_items_in_your_inventory_are_up',
            ),
            info: t('navigation.price'),
        }
    if (type === 'bid')
        return {
            notificationText: t('navigation.you_received_a_new_bid'),
            info: t('asset.bid_noun'),
        }
    if (type === 'offer')
        return {
            notificationText: t('navigation.you_received_a_new_trade_offer'),
            info: t('asset.offer_id'),
        }
    if (type === 'drop')
        return {
            notificationText: t('navigation.new_drop', { author: name }),
            info: 'Price',
        }

    return {
        notificationText: '',
        info: '',
    }
}

export const NotificationNav = ({ notification, userName }) => {
    const { t } = useTranslation('common')

    const {
        type,
        assets,
        date,
        price,
        read,
        offerId,
        orderId,
        bid,
        auctionId,
        globalSequence,
        templates,
        currency,
        dropId,
        contract,
        market,
        floorChange,
    } = notification

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const [isLoading, setIsLoading] = useState(false)

    const { image, assetId, universalPreview, thumbnail, name, author } =
        templates && templates.length > 0 ? templates[0] : assets[0]

    const [favored, setFavored] = useState(
        (templates && templates.length > 0 ? templates[0] : assets[0])[
            'isFavorited'
        ],
    )

    const favor = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const res = await activeUser.session.transact(
            {
                actions: [
                    getBoostAction(activeUser),
                    {
                        account: 'nft.hive',
                        name: 'execute',
                        authorization: [
                            {
                                actor: userName,
                                permission: activeUser.requestPermission
                                    ? activeUser.requestPermission
                                    : 'active',
                            },
                        ],
                        data: {
                            signer: userName,
                            action: JSON.stringify({
                                name: assetId
                                    ? 'favorite-asset'
                                    : 'favorite-template',
                                data: {
                                    id: assetId ? assetId : templateId,
                                },
                            }),
                        },
                    },
                ],
            },
            {
                expireSeconds: 300,
                blocksBehind: 0,
            },
        )

        if (res?.response?.transaction_id) {
            setFavored(true)
        }
        setIsLoading(false)
    }

    const unfavor = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const res = await activeUser.session.transact(
            {
                actions: [
                    getBoostAction(activeUser),
                    {
                        account: 'nft.hive',
                        name: 'execute',
                        authorization: [
                            {
                                actor: userName,
                                permission: activeUser.requestPermission
                                    ? activeUser.requestPermission
                                    : 'active',
                            },
                        ],
                        data: {
                            signer: userName,
                            action: JSON.stringify({
                                name: assetId
                                    ? 'unfavorite-asset'
                                    : 'unfavorite-template',
                                data: {
                                    id: assetId ? assetId : templateId,
                                },
                            }),
                        },
                    },
                ],
            },
            {
                expireSeconds: 300,
                blocksBehind: 0,
            },
        )

        if (res?.response?.transaction_id) {
            setFavored(false)
        }
        setIsLoading(false)
    }

    const dateStr = date ? format(date) : ''

    const { info, notificationText } = useNotificationInfo(
        type,
        name ? name : author,
    )

    const [displayImg, setDisplayImg] = useState(
        universalPreview ? universalPreview : thumbnail ? thumbnail : image,
    )

    const replaceImage = () => {
        if (displayImg !== image) {
            setDisplayImg(image)
        }
    }

    const link =
        type === 'offer'
            ? '/trade/received'
            : type === 'floor'
            ? `/user/${userName}?tab=inventory&order_by=floor_change_desc`
            : getAssetLink(
                  assetId,
                  null,
                  null,
                  null,
                  orderId ? price : null,
                  orderId,
                  auctionId,
                  type,
                  globalSequence,
                  dropId,
                  contract,
                  market,
              )

    return (
        <Link href={link}>
            <div
                className={`w-full text-sm cursor-pointer ${
                    type === 'floor' ? 'my-2' : ' my-2'
                }`}
            >
                <div className="flex items-center ">
                    <div className={`${displayImg ? 'pr-4 ' : ' pr-0'}`}>
                        {type !== 'floor' ? (
                            <div className="w-[48px] h-full">
                                {!universalPreview &&
                                displayImg &&
                                displayImg.includes('video:') ? (
                                    <video
                                        loop
                                        controls
                                        autoPlay={true}
                                        muted={true}
                                    >
                                        <source
                                            src={`${
                                                config.ipfs
                                            }${displayImg.replace(
                                                'video:',
                                                '',
                                            )}`}
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                ) : (
                                    <img
                                        className={' w-[48px] h-full '}
                                        src={displayImg}
                                        onError={replaceImage}
                                    />
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div>
                        <div className=" text-start  ">
                            {type !== 'floor' ? null : (
                                <div
                                    className={
                                        'text-green-600 font-semibold text-[12px] md:text-sm  w-full text-start'
                                    }
                                >
                                    {t('navigation.x_in_24h', {
                                        x: formatWAX(floorChange),
                                    })}
                                </div>
                            )}

                            <p className="text-[12px] md:text-sm">
                                {notificationText}
                            </p>
                        </div>
                        {type !== 'floor' && (
                            <div className={'w-full gap-1'}>
                                <div className="">
                                    {type === 'offer' ? (
                                        <div className="flex flex-col gap-1 ">
                                            <div className="font-medium ">
                                                {t('trade.offer_id')}:
                                            </div>
                                            <div className="cursor-pointer text-[#A4A4A4]">
                                                {offerId}
                                            </div>
                                        </div>
                                    ) : type === 'drop' ? (
                                        <div className="flex flex-col gap-1">
                                            <div className="font-medium ">
                                                Drop ID:
                                            </div>
                                            <div className=" text-[#A4A4A4]">
                                                {dropId}
                                            </div>
                                        </div>
                                    ) : null}
                                    {type === 'offer' ? null : (
                                        <div className="flex flex-col gap-1">
                                            <p className="font-medium ">
                                                {info}:{' '}
                                                {!currency || currency === 'WAX'
                                                    ? `${formatNumber(
                                                          price ? price : bid,
                                                      )} WAX`
                                                    : `${formatNumber(
                                                          price,
                                                      )} ${currency}`}
                                            </p>
                                        </div>
                                    )}
                                    <div className="flex gap-1 w-full mt-2">
                                        <p className="text-[#A4A4A4]">
                                            {moment(dateStr).fromNow()}
                                        </p>
                                        <div className="flex items-center justify-between">
                                            <div className="flex justify-end ">
                                                {type !== 'floor' ? (
                                                    <div className="w-1/5 m-auto">
                                                        {type === 'sale' ||
                                                        type === 'drop' ? (
                                                            <Tooltip
                                                                title={
                                                                    favored
                                                                        ? t(
                                                                              'asset.receiving_notifications',
                                                                          )
                                                                        : t(
                                                                              'asset.not_receiving_notifications_anymore',
                                                                          )
                                                                }
                                                            >
                                                                {isLoading ? (
                                                                    <div
                                                                        className={cn(
                                                                            'w-6 h-6',
                                                                        )}
                                                                    >
                                                                        <LoadingIndicator
                                                                            size={
                                                                                'w-6 h-6'
                                                                            }
                                                                            margin={
                                                                                ''
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        onClick={
                                                                            favored
                                                                                ? unfavor
                                                                                : favor
                                                                        }
                                                                        className={cn(
                                                                            'w-5 h-5',
                                                                        )}
                                                                    >
                                                                        <img
                                                                            className={cn(
                                                                                ' w-5 h-5',
                                                                            )}
                                                                            src={
                                                                                favored
                                                                                    ? '/star.svg'
                                                                                    : '/star-outline.svg'
                                                                            }
                                                                            alt={
                                                                                favored
                                                                                    ? t(
                                                                                          'asset.receiving_notifications',
                                                                                      )
                                                                                    : t(
                                                                                          'asset.not_receiving_notifications_anymore',
                                                                                      )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Tooltip>
                                                        ) : read ? null : (
                                                            t('general.new')
                                                        )}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Divider />
        </Link>
    )
}

export default NotificationNav
