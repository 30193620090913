import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAttributeNames } from '../../hooks'
import MainButton from '../common/util/input/MainButton'
import { get, getDelphiMedian } from '../helpers/Api'
import { formatNumber, sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function BuyMissingPopup(props) {
    const asset = props['asset']

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingTxs, setIsLoadingTxs] = useState(false)
    const [error, setError] = useState()
    const includeTubes = props['includeTubes']

    const [searchResults, setSearchResults] = useState([])

    const [currentAction, setCurrentAction] = useState(0)

    const assetsToBuy = []

    searchResults.map((sale) => {
        if (
            assetsToBuy.length === 0 ||
            !assetsToBuy
                .map((asset) => asset.assets[0].summaryId)
                .includes(sale.assets[0].summaryId)
        ) {
            assetsToBuy.push(sale)
        }
    })

    const sum =
        assetsToBuy.length > 1
            ? assetsToBuy.map((a) => a.offer).reduce((a, b) => a + b)
            : 0
    const sum_usd =
        assetsToBuy.length > 1
            ? assetsToBuy.map((a) => a.usd_offer).reduce((a, b) => a + b)
            : 0

    let {
        name,
        author,
        schema,
        rarity,
        variant,
        color,
        type,
        border,
        attr7,
        attr8,
        attr9,
        attr10,
    } = asset

    const [attrNames] = useAttributeNames(author, schema)

    const getSearchResult = (result) => {
        setSearchResults(result)
        setIsLoading(false)

        return result
    }

    const runSearch = async (
        name,
        author,
        schema,
        rarity,
        variant,
        color,
        border,
        type,
        attr7,
        attr8,
        attr9,
        attr10,
    ) => {
        if (!author || author === '*') schema = ''

        setIsLoading(true)

        const searchPath = `search?term=${
            name
                ? name.includes('#') || name.includes('&') || name.includes('?')
                    ? escape(name)
                    : name
                : ''
        }&author=${author ? author : ''}&schema=${
            schema ? schema : ''
        }&rarity=${rarity ? rarity : ''}&variant=${
            variant
                ? variant.includes('#') ||
                  variant.includes('&') ||
                  variant.includes('?')
                    ? escape(variant)
                    : variant
                : ''
        }&order_by=offer&color=${color ? color : ''}&type=${
            type ? type : ''
        }&border=${
            border ? border : ''
        }&order_dir=ASC&limit=100&search_type=cheapest_missing&exact_search=true&offer_type=market&attr7=${
            attr7 ? attr7 : ''
        }&attr8=${attr8 ? attr8 : ''}&attr9=${attr9 ? attr9 : ''}&attr10=${
            attr10 ? attr10 : ''
        }&verified=true&owner=${userName}&include_tubes=${
            includeTubes || includeTubes === false ? includeTubes : ''
        }`

        await get(searchPath).then((result) =>
            getSearchResult(result, searchPath),
        )
    }

    useEffect(() => {
        if (userName) {
            runSearch(
                name,
                author,
                schema,
                rarity,
                variant,
                color,
                border,
                type,
                attr7,
                attr8,
                attr9,
                attr10,
            )
        }
    }, [userName])

    const cancel = () => {
        callBack({ bought: false, error: null })
        closeCallBack()
    }

    const buy = async () => {
        setIsLoadingTxs(true)

        const usd_wax = await getDelphiMedian(state)

        const simpleassetsClaims = []

        let total_amount = 0

        const errors = []
        const boughtAssets = []

        setCurrentAction(0)

        let cnt = 0

        for (const ass of assetsToBuy.sort(
            (a, b) => parseFloat(a.offer) - parseFloat(b.offer),
        )) {
            const actions = [getBoostAction(activeUser)]
            const quantity = parseFloat(ass.offer)
            const orderId = ass.orderId
            const currency = ass.currency

            total_amount += quantity

            const assetId = ass.assetId

            const marketplace = ass.market

            switch (marketplace) {
                case 'atomicmarket':
                    {
                        actions.push({
                            account: 'eosio.token',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: 'atomicmarket',
                                quantity: `${quantity.toFixed(8)} WAX`,
                                memo: 'deposit',
                            },
                        })

                        actions.push({
                            account: 'atomicmarket',
                            name: 'purchasesale',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                buyer: userName,
                                sale_id: orderId,
                                taker_marketplace:
                                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                        ? 'nfthiveboost'
                                        : 'nft.hive',
                                intended_delphi_median:
                                    currency === 'USD'
                                        ? Math.round(usd_wax)
                                        : 0,
                            },
                        })
                    }
                    break
                case 'waxplorercom':
                    {
                        actions.push({
                            account: 'eosio.token',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: 'waxplorercom',
                                quantity: `${quantity.toFixed(8)} WAX`,
                                memo: `purchase:${orderId}`,
                            },
                        })
                    }
                    break
                case 'simplemarket':
                    {
                        actions.push({
                            account: 'eosio.token',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: 'simplemarket',
                                quantity: `${quantity.toFixed(8)} WAX`,
                                memo:
                                    '{"nftid": ' +
                                    assetId +
                                    ', "affiliate_id": 200001}',
                            },
                        })
                    }
                    break
                case 'waxinventory':
                    {
                        actions.push({
                            account: 'eosio.token',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: 'waxinventory',
                                quantity: `${quantity.toFixed(8)} WAX`,
                                memo:
                                    '{"id":"' + assetId + '","type":"market"}',
                            },
                        })
                    }
                    break
                case 'market.myth':
                    {
                        actions.push({
                            account: 'eosio.token',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: 'market.myth',
                                quantity: `${quantity.toFixed(8)} WAX`,
                                memo: '',
                            },
                        })

                        actions.push({
                            account: marketplace,
                            name: 'buy',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                assetid: assetId,
                                from: userName,
                                price: `${quantity.toFixed(8)} WAX`,
                                referral: 'waxplorerref',
                            },
                        })
                    }
                    break
                case 'market.place':
                    {
                        actions.push({
                            account: 'eosio.token',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: 'market.place',
                                quantity: `${quantity.toFixed(8)} WAX`,
                                memo: 'Payment for NFTs | collectables.io',
                            },
                        })
                        actions.push({
                            account: 'market.place',
                            name: 'buy',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                account: userName,
                                listingid: orderId,
                                referral: 'waxplorerref',
                            },
                        })

                        actions.push({
                            account: 'market.place',
                            name: 'withdraw',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                user: userName,
                                assets: [ass.assetId].map((id) => {
                                    return {
                                        contract: ass.standard,
                                        id: id,
                                        quantity:
                                            ass.standard === 'simpleassets'
                                                ? '0.00000000 WAX'
                                                : `1 ${ass.name}`,
                                    }
                                }),
                            },
                        })
                    }
                    break
            }

            try {
                await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                boughtAssets.push(ass)

                if (
                    ass.standard === 'simpleassets' &&
                    [
                        'waxplorercom',
                        'market.place',
                        'waxinventory',
                        'simplemarket',
                        'waxplorercom',
                    ].includes(marketplace)
                ) {
                    simpleassetsClaims.push(assetId)
                }
            } catch (e) {
                setError(assetId + ': ' + e.message)

                errors.push({ assetId: e.message })
            } finally {
                cnt += 1
                setCurrentAction(cnt)
                await sleep(100)
            }
        }

        if (simpleassetsClaims.length > 0) {
            await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'simpleassets',
                            name: 'claim',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                claimer: userName,
                                assetids: simpleassetsClaims,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        }

        //Google Schm00
        //logPurchase(total_amount * usd_wax)
        setIsLoadingTxs(false)
        callBack({ bought: boughtAssets, error: errors })
        closeCallBack()
    }

    const buyField = (
        <MainButton className="w-[80px] max-w-32" onClick={buy}>
            {t('asset.buy')}
        </MainButton>
    )

    return (
        <Popup title={'Buy Missing Assets'} cancel={cancel}>
            <div className="p-2 ">
                <div className="relative flex justify-center w-full p-2 mb-4">
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <b>{t('profile.collection')}</b>
                                </TableCell>
                                <TableCell>{author}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>{t('asset.schema')}</b>
                                </TableCell>
                                <TableCell>{schema}</TableCell>
                            </TableRow>
                            {variant ? (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['variant']}</b>
                                    </TableCell>
                                    <TableCell>{variant}</TableCell>
                                </TableRow>
                            ) : (
                                ''
                            )}
                            {rarity ? (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['rarity']}</b>
                                    </TableCell>
                                    <TableCell>{rarity}</TableCell>
                                </TableRow>
                            ) : (
                                ''
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            {assetsToBuy.length > 0 ? (
                <div className="mx-auto mt-2 mb-6 text-lg font-bold text-center">
                    {t('popups.are_you_sure_you_want_to_buy_asset_for_amount', {
                        asset: `${assetsToBuy.length} Assets`,
                        amount: `${formatNumber(sum)} WAX / $${formatNumber(
                            sum_usd,
                        )}`,
                    })}
                </div>
            ) : isLoading ? (
                <LoadingIndicator
                    text={'Finding Floor Prices for missing Assets'}
                />
            ) : (
                'Could not load valid Result. Please try again later.'
            )}
            {error ? (
                <ErrorMessage error={error} onClick={() => setError(null)} />
            ) : (
                ''
            )}

            <div className="relative flex justify-center gap-2">
                <MainButton onClick={cancel} className="bg-red-500 ">
                    {t('asset.cancel')}
                </MainButton>
                {buyField}
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_sales')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
            {isLoadingTxs ? (
                <PopupLoadingIndicator
                    text={t('popups.signing_multiple_transactions', {
                        x: currentAction,
                        y: assetsToBuy && assetsToBuy.length,
                    })}
                    isLoading={isLoadingTxs}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BuyMissingPopup
