import { Button } from '@mui/material'
import { DropLink } from './DropLink'

export const DropButton = ({
    children,
    className = '',
    size = 'medium',
    variant = 'outlined',
    disabled = false,
    fullWidth = false,
    color = 'primary',
    href,
    external = false,
    onClick,
    startIcon = null,
}) => {
    return (
        <div className={className}>
            {href ? (
                <Button
                    size={size}
                    variant={variant}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    color={color}
                    href={href}
                    external={external}
                    LinkComponent={DropLink}
                    startIcon={startIcon}
                >
                    {children}
                </Button>
            ) : (
                <Button
                    size={size}
                    variant={variant}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    color={color}
                    onClick={onClick}
                    startIcon={startIcon}
                >
                    {children}
                </Button>
            )}
        </div>
    )
}
