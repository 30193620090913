import { Card, CardBody, Checkbox, Input } from '@nextui-org/react'
import React, { useContext, useEffect, useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'

import { AmountSlider } from '../amountslider/AmountSlider'
import MarketPreviewDetailsTable from '../assetpreview/MarketPreviewDetailsTable'
import MainButton from '../common/util/input/MainButton'
import get, { getDelphiMedian } from '../helpers/Api'
import {
    formatMarket,
    formatWAX,
    getAttributeNames,
    getDefaultAttrNames,
    sleep,
} from '../helpers/FormatLinks'
import {
    getBoostAction,
    signAlcorNFTBuy,
    signAtomicMarketBuy,
    signGpkMythBuy,
    signMarketMythBuy,
    signMarketPlaceBuy,
    signMarketPlaceBuy1,
    signMarketPlaceBuy2,
    signMarketPlaceBuy3,
    signNFTHiveBuy,
    signSimpleMarketBuy,
    signWaxInventoryBuy,
    signWaxplorerBuy,
    signWsMythBuy,
} from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import { MarketInfoDetails2 } from '../marketinfo/MarketInfoDetails2'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'

const loadPriceInfo = async (args) => await get('price-info/' + args.assetId)
const loadPriceInfoPacks = async (args) =>
    await get('price-info-fts/' + args.symbol)

const threshold = 20

function BuyPopup(props) {
    const asset = props['asset']

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']
    const [bought, setBought] = useState(false)
    const [currentAction, setCurrentAction] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const [attrNames, setAttrNames] = useState(getDefaultAttrNames())

    const [allAssets, setAllAssets] = useState(null)
    const [buyMultiple, setBuyMultiple] = useState(false)
    const [amountToBuy, setAmountToBuy] = useState(0)
    const [currentDisplayAction, setCurrentDisplayAction] = useState(0)
    const [successCount, setSuccessCount] = useState(0)
    const [errorCount, setErrorCount] = useState(0)
    const [maxActions, setMaxActions] = useState(1)

    const { offer, assets, bundle, currency, price, usd_offer } = asset

    let {
        owner,
        market,
        assetId,
        schema,
        author,
        name,
        image,
        orderId,
        standard,
        symbol,
        seller,
    } = asset

    const assetIds = []

    const marketplace = market
    if (assets) {
        const a = assets[0]
        assetId = a.assetId
        owner = a.owner
        assets.forEach((asset) => assetIds.push(asset.assetId))
        schema = a.schema
        author = a.author
        standard = a.standard
        name =
            assets.length > 1 ? `${a.name} (Bundle of ${assets.length})` : name
        image = a.image
    } else {
        if (assetId) assetIds.push(assetId)
    }

    const getAllForSale = (assetId) => {
        get('get-all-for-sale/' + assetId).then((result) =>
            setAllAssets(result),
        )
    }

    useEffect(() => {
        if (assetId && !symbol) {
            const controller = new AbortController()
            getAttributeNames(author, schema, controller.signal).then(
                (result) => {
                    if (result && !('errors' in result)) setAttrNames(result)
                },
            )
            getAllForSale(assetId)
        }
    }, [assetId])

    const LoadPriceInfo = (assetId) => {
        const { data } = useAsync({
            promiseFn: loadPriceInfo,
            assetId: assetId,
        })
        if (data) {
            data.isLoading = false
            return data
        }
        return { priceInfo: {}, isLoading: true }
    }

    const LoadPriceInfoPacks = (symbol) => {
        const { data } = useAsync({
            promiseFn: loadPriceInfoPacks,
            symbol: symbol,
        })
        if (data) {
            data.isLoading = false
            return data
        }
        return { priceInfo: {}, isLoading: true }
    }

    const multiBuy = async () => {
        setIsLoading(true)

        let currentAction = 0
        let errors = 0
        let successes = 0

        let usd_wax = await getDelphiMedian(state)
        usd_wax /= 10000

        while (currentAction < amountToBuy / maxActions) {
            const start = currentAction * maxActions
            const end = start + maxActions
            const actions = [getBoostAction(activeUser)]

            const simpleassetsClaims = []

            for (const asset of allAssets
                .sort((a, b) => parseFloat(a.offer) - parseFloat(b.offer))
                .slice(start, end)) {
                let quantity = parseFloat(asset.offer)
                const orderId = asset.orderId
                const currency = asset.currency

                const marketplace = asset.market

                if (currency === 'USD') {
                    quantity = parseFloat(asset.usd_offer) / usd_wax
                }

                switch (marketplace) {
                    case 'atomicmarket':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'atomicmarket',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo: 'deposit',
                                },
                            })

                            actions.push({
                                account: 'atomicmarket',
                                name: 'purchasesale',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    buyer: userName,
                                    sale_id: orderId,
                                    taker_marketplace:
                                        process.env.NEXT_PUBLIC_TESTNET ===
                                        'TRUE'
                                            ? 'nfthiveboost'
                                            : 'nft.hive',
                                    intended_delphi_median:
                                        currency === 'USD'
                                            ? Math.round(usd_wax * 10000)
                                            : 0,
                                },
                            })
                        }
                        break
                    case 'waxplorercom':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'waxplorercom',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo: `purchase:${orderId}`,
                                },
                            })
                            simpleassetsClaims.push(assetId)
                        }
                        break
                    case 'simplemarket':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'simplemarket',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo:
                                        '{"nftid": ' +
                                        assetId +
                                        ', "affiliate_id": 200001}',
                                },
                            })

                            simpleassetsClaims.push(assetId)
                        }
                        break
                    case 'waxinventory':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'waxinventory',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo:
                                        '{"id":"' +
                                        assetId +
                                        '","type":"market"}',
                                },
                            })
                            simpleassetsClaims.push(assetId)
                        }
                        break
                    case 'market.place':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'market.place',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo: 'Payment for NFTs | collectables.io',
                                },
                            })
                            actions.push({
                                account: 'market.place',
                                name: 'buy',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    account: userName,
                                    listingid: orderId,
                                    referral: 'waxplorerref',
                                },
                            })

                            actions.push({
                                account: 'market.place',
                                name: 'withdraw',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    user: userName,
                                    assets: [assetId].map((id) => {
                                        return {
                                            contract: asset.standard,
                                            id: id,
                                            quantity:
                                                asset.standard ===
                                                'simpleassets'
                                                    ? '0.00000000 WAX'
                                                    : `1 ${asset.name}`,
                                        }
                                    }),
                                },
                            })
                            if (asset.standard === 'simpleassets') {
                                simpleassetsClaims.push(assetId)
                            }
                        }
                        break
                }
            }

            if (simpleassetsClaims.length > 0) {
                actions.push({
                    account: 'simpleassets',
                    name: 'claim',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        claimer: userName,
                        assetids: simpleassetsClaims,
                    },
                })
            }

            try {
                await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                successes += 1
                setSuccessCount(successes)
            } catch (e) {
                console.error(e)
                setError(e.message)
                errors += 1
                setErrorCount(errors)
            } finally {
                currentAction += 1
                setCurrentDisplayAction(currentAction)
                if (currentAction < amountToBuy) {
                    await sleep(500)
                }
            }
        }
        setIsLoading(false)
        callBack({ bought: true, error: null, asset: asset })
        closeCallBack()
    }

    const buy = async () => {
        let quantity = parseFloat(offer ? offer : price)
        closeCallBack()
        setIsLoading(true)

        let usd_wax = 0

        if (currency === 'USD') {
            usd_wax = await getDelphiMedian(state)
            usd_wax /= 10000
            quantity = parseFloat(asset.usd_offer) / usd_wax
        }
        //Google Shit
        //logPurchase(quantity * usd_wax)

        try {
            let result
            switch (marketplace) {
                case 'atomicmarket':
                    {
                        result = await signAtomicMarketBuy(
                            activeUser,
                            orderId,
                            quantity,
                            currency,
                            usd_wax,
                        )

                        setBought(true)
                        callBack({ bought: true, error: null, asset: asset })
                    }
                    break
                case 'waxplorercom':
                    {
                        result = await signWaxplorerBuy(
                            activeUser,
                            quantity,
                            orderId,
                            assetId,
                        )

                        setBought(true)
                        callBack({ bought: true, error: null, asset: asset })
                    }
                    break
                case 'simplemarket':
                    {
                        result = await signSimpleMarketBuy(
                            activeUser,
                            quantity,
                            assetId,
                        )

                        setBought(true)
                        callBack({ bought: true, error: null, asset: asset })
                    }
                    break
                case 'nft.hive':
                    {
                        result = await signNFTHiveBuy(
                            activeUser,
                            quantity,
                            orderId,
                        )

                        setBought(true)
                        callBack({ bought: true, error: null, asset: asset })
                    }
                    break
                case 'waxinventory':
                    {
                        result = await signWaxInventoryBuy(
                            activeUser,
                            quantity,
                            assetId,
                        )

                        setBought(true)
                        callBack({ bought: true, error: null, asset: asset })
                    }
                    break
                case 'market.myth':
                    {
                        result = await signMarketMythBuy(
                            activeUser,
                            quantity,
                            assetId,
                            owner,
                        )

                        setBought(true)
                        callBack({ bought: true, error: null, asset: asset })
                    }
                    break
                case 'gpk.myth':
                    {
                        result = await signGpkMythBuy(
                            activeUser,
                            quantity,
                            name,
                            owner,
                        )

                        setBought(true)
                        callBack({ bought: true, error: null, asset: asset })
                    }
                    break
                case 'ws.myth':
                    {
                        result = await signWsMythBuy(
                            activeUser,
                            quantity,
                            name,
                            owner,
                        )

                        setBought(true)
                        callBack({ bought: true, error: null, asset: asset })
                    }
                    break
                case 'alcornftswap':
                    {
                        result = await signAlcorNFTBuy(
                            activeUser,
                            orderId,
                            quantity,
                        )

                        setBought(true)
                        callBack({ bought: true, error: null, asset: asset })
                    }
                    break
                case 'market.place':
                    {
                        if (!orderId) {
                            throw Error('Invalid Listing Id')
                        }
                        if (assetIds.length === 1) {
                            result = await signMarketPlaceBuy(
                                activeUser,
                                quantity,
                                orderId,
                                standard,
                                assets,
                            )

                            setBought(true)
                            callBack({
                                bought: true,
                                error: null,
                                asset: asset,
                            })
                        } else {
                            if (currentAction === 1) {
                                result = await signMarketPlaceBuy1(
                                    activeUser,
                                    quantity,
                                    orderId,
                                )
                                setCurrentAction(2)
                            }

                            if (
                                assetIds.length > threshold &&
                                currentAction === 3
                            ) {
                                const transferIds = [...assetIds]
                                transferIds.splice(threshold)
                                result = await signMarketPlaceBuy3(
                                    activeUser,
                                    transferIds,
                                )

                                setBought(true)
                                callBack({
                                    bought: true,
                                    error: null,
                                    asset: asset,
                                })
                            }

                            if (currentAction === 2) {
                                const transferIds = [...assetIds]
                                transferIds.splice(0, threshold)
                                result = await signMarketPlaceBuy2(
                                    activeUser,
                                    transferIds,
                                )
                                if (assetIds.length > threshold) {
                                    setCurrentAction(3)
                                } else {
                                    setBought(true)
                                    callBack({
                                        bought: true,
                                        error: null,
                                        asset: asset,
                                    })
                                }
                            }
                        }
                    }
                    break
            }
        } catch (e) {
            callBack({
                bought: false,
                error: e.message,
                asset: asset,
            })
            setError(e.message)
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    const priceInfo = symbol
        ? LoadPriceInfoPacks(symbol)
        : LoadPriceInfo(assetId)

    const buyField = (
        <div className={'mx-2'}>
            <MainButton
                variant="solid"
                size="md"
                onClick={buyMultiple ? multiBuy : buy}
                disabled={buyMultiple && amountToBuy === 0}
            >
                {market === 'atomicmarket'
                    ? `${t('asset.buy')}`
                    : `${t('asset.buy')} (${formatMarket(
                          market ? market : owner,
                      )})`}
            </MainButton>
        </div>
    )

    const numActions =
        assetIds.length > threshold ? 3 : assetIds.length > 1 ? 2 : 1

    const buyCollectablesField = (
        <div className={'mx-2'}>
            <MainButton
                variant="solid"
                size="md"
                onClick={buy}
                // style={{ 'font-size': '11px' }}
            >{`${
                currentAction === 1 ? t('asset.buy') : t('asset.claim')
            } (${formatMarket(market ? market : owner)}${
                numActions > 1 ? ` ${currentAction}/${numActions}` : ''
            })`}</MainButton>
        </div>
    )

    const cancel = () => {
        callBack({ bought: false, error: null, asset: asset })
        closeCallBack()
    }

    const changeBuyMultiple = (e) => {
        setBuyMultiple(e)
        setAmountToBuy(0)
    }

    const changeAmountToBuy = (e) => {
        setAmountToBuy(e.target.value)
    }

    const increaseAmountToBuy = () => {
        const newAmount = Math.min(amountToBuy + 1, allAssets.length)
        if (newAmount > 0 && maxActions === 0) {
            setMaxActions(1)
        }
        setAmountToBuy(newAmount)
    }

    const decreaseAmountToBuy = () => {
        const newAmount = Math.max(amountToBuy - 1, 0)
        setAmountToBuy(newAmount)
        if (newAmount < maxActions && newAmount > 0) {
            setMaxActions(newAmount)
        }
    }

    const changeMaxActions = (e) => {
        const newValue = parseInt(e.target.value)
        setMaxActions(
            newValue > amountToBuy && amountToBuy > 0
                ? amountToBuy
                : newValue >= 1
                ? newValue
                : 1,
        )
    }

    const lowest = allAssets
        ? Math.min(...allAssets.map((item) => item.offer))
        : 0
    const highest = allAssets
        ? Math.max(...allAssets.map((item) => item.offer))
        : 0

    const totalPrice = allAssets
        ? allAssets
              .map((item) => item.offer)
              .sort((a, b) => a - b)
              .slice(0, amountToBuy)
              .reduce((b, a) => b + a, 0)
        : 0

    return (
        <Popup title={name} cancel={cancel} image={image} asset={asset}>
            {allAssets && allAssets.length > 1 && (
                <div className="flex flex-wrap w-full mb-2 text-sm xl:text-base">
                    <div className={'text-sm xl:text-base my-4'}>
                        <Checkbox
                            isSelected={buyMultiple}
                            onValueChange={changeBuyMultiple}
                            isDisabled={allAssets.length < 2}
                        >
                            {allAssets.length < 100
                                ? t(
                                      'popups.there_are_multiple_assets_for_sale_between_x_and_y',
                                      {
                                          asset: name,
                                          x: formatWAX(lowest),
                                          y: formatWAX(highest),
                                      },
                                  )
                                : t(
                                      'popups.there_are_over_100_assets_for_sale_starting_at_x',
                                      { asset: name, x: formatWAX(lowest) },
                                  )}
                        </Checkbox>
                    </div>

                    {buyMultiple && (
                        <Card className="mb-4">
                            <CardBody>
                                {buyMultiple && (
                                    <div className={'w-full my-4'}>
                                        <AmountSlider
                                            title={t('popups.amount_to_buy')}
                                            disabled={!buyMultiple}
                                            currentAmount={amountToBuy}
                                            changeAmount={changeAmountToBuy}
                                            increaseAmount={increaseAmountToBuy}
                                            decreaseAmount={decreaseAmountToBuy}
                                            max={allAssets.length}
                                        />
                                    </div>
                                )}

                                {buyMultiple && (
                                    <div className="w-full space-y-4 md:my-auto">
                                        <p className="text-sm">
                                            {t(
                                                'popups.items_per_transaction_explained',
                                            )}
                                        </p>
                                        <div className="flex justify-center w-full">
                                            <Input
                                                type="number"
                                                fullWidth
                                                variant="bordered"
                                                label={t(
                                                    'popups.items_per_transaction',
                                                )}
                                                defaultValue="junior@nextui.org"
                                                className="max-w-xs"
                                                onChange={changeMaxActions}
                                                value={maxActions}
                                            />
                                        </div>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    )}
                </div>
            )}
            <div>
                <div className="relative flex flex-wrap justify-center w-full">
                    <MarketPreviewDetailsTable
                        asset={assets ? assets[0] : asset}
                        newOwner={owner}
                        bundleView={false}
                        currentAsset={0}
                        attrNames={attrNames}
                        showData={false}
                        className="w-full"
                    />
                    <MarketInfoDetails2
                        asset={asset}
                        priceInfo={priceInfo}
                        currentAsset={0}
                        assets={assets}
                        bundle={bundle}
                        className="w-full my-1"
                    />
                </div>
            </div>
            <div className={'bottom-0'}>
                <Card className="mb-8">
                    <CardBody>
                        <div className="mx-auto mb-2 text-xl font-bold">
                            {amountToBuy
                                ? t('popups.buying_x_name_for_y_in_total', {
                                      x: amountToBuy,
                                      name: name,
                                      y: formatWAX(totalPrice),
                                  })
                                : t(
                                      'popups.are_you_sure_you_want_to_buy_asset_for_amount',
                                      {
                                          amount: `${
                                              offer ? offer : price
                                          } WAX`,
                                          asset: name,
                                      },
                                  )}
                        </div>
                    </CardBody>
                </Card>

                {error ? <ErrorMessage error={error} /> : ''}
                <div className="relative flex justify-end mt-auto">
                    <div className={'mx-2'}>
                        <MainButton
                            className="font-bold bg-red-500 rounded-md"
                            varient="solid"
                            onClick={cancel}
                            size="md"
                        >
                            {t('asset.cancel')}
                        </MainButton>
                    </div>
                    {userName !== seller &&
                    !bought &&
                    [
                        'waxplorercom',
                        'simplemarket',
                        'waxinventory',
                        'gpk.myth',
                        'market.myth',
                        'alcornftswap',
                        'wax.stash',
                        'nft.hive',
                        'atomicmarket',
                    ].includes(market)
                        ? buyField
                        : ''}
                    {userName !== seller && !bought && owner === 'market.place'
                        ? buyCollectablesField
                        : ''}
                </div>
            </div>
            {isLoading ? (
                buyMultiple ? (
                    <PopupLoadingIndicator
                        text={t('popups.signing_multiple_transactions', {
                            x: currentDisplayAction,
                            y: amountToBuy / maxActions,
                        })}
                        successCount={successCount}
                        errorCount={errorCount}
                        isLoading={isLoading && buyMultiple}
                    />
                ) : (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                )
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BuyPopup
