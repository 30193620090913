import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { useAttributeNames } from '../../hooks'
import Link from '../common/util/input/Link'
import MainButton from '../common/util/input/MainButton'
import { formatNumberS, getAuthorLink } from '../helpers/FormatLinks'
import VideoElement from '../videoelement/VideoElement'
import Popup from './Popup'

const SetItemPopup = ({ asset, closeCallBack, callBack }) => {
    const {
        number,
        total,
        schema,
        variant,
        rarity,
        author,
        name,
        border,
        color,
        attr7,
        attr8,
        attr9,
        attr10,
        type,
        assets,
        image,
        rarity_score,
        numOwned,
    } = asset

    const { t } = useTranslation('common')

    const cancel = () => {
        callBack()
        closeCallBack()
    }
    const [attrNames] = useAttributeNames(author, schema)

    const minMint = assets ? assets.map((asset) => asset.mint).sort()[0] : null

    const getStatus = (asset) => {
        if (asset.status === 'for_sale') return 'active_sales'
        else if (asset.status === 'staked') {
            return 'staked'
        } else if (asset.status === 'choobed') {
            return 'choobed'
        } else {
            return 'assets'
        }
    }

    const getLinkStr = (tab, search) => {
        const query = {
            term: escape(name),
            collection: author,
            order_by: 'date_desc',
            rarity: rarity ? rarity : '',
            variant: variant ? variant : '',
            border: border ? border : '',
            type: type ? type : '',
            attr7: attr7 ? attr7 : '',
            attr8: attr8 ? attr8 : '',
            attr9: attr9 ? attr9 : '',
            attr10: attr10 ? attr10 : '',
            exact_search: 'true',
            tab:
                tab === 'inventory'
                    ? getStatus(asset) === 'active_sales'
                        ? 'sales'
                        : 'inventory'
                    : 'sales',
            search_type: search ? search : getStatus(asset),
        }

        return qs.stringify(query)
    }

    return (
        <Popup title={name} cancel={cancel}>
            <div className="flex flex-wrap justify-around">
                <div className={'max-w-1/2'}>
                    {image && image.includes('video:') ? (
                        <VideoElement
                            video={
                                image.includes('video:')
                                    ? image.replace('video:', config.ipfs)
                                    : image
                            }
                        />
                    ) : (
                        <img src={image} />
                    )}
                </div>
                <div>
                    <Table size="small">
                        <TableBody>
                            {number && (
                                <TableRow>
                                    <TableCell>
                                        <b>{t('asset.number')}</b>
                                    </TableCell>
                                    <TableCell>{number}</TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell>
                                    <b>{t('asset.owned')}</b>
                                </TableCell>
                                <TableCell>{numOwned}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>{t('asset.min_mint')}</b>
                                </TableCell>
                                <TableCell>{minMint}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>{t('profile.collection')}</b>
                                </TableCell>
                                <TableCell>{getAuthorLink(author)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>{t('asset.schema')}</b>
                                </TableCell>
                                <TableCell>{schema}</TableCell>
                            </TableRow>
                            {variant && (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['variant']}</b>
                                    </TableCell>
                                    <TableCell>{variant}</TableCell>
                                </TableRow>
                            )}
                            {rarity && (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['rarity']}</b>
                                    </TableCell>
                                    <TableCell>{rarity}</TableCell>
                                </TableRow>
                            )}
                            {color && (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['color']}</b>
                                    </TableCell>
                                    <TableCell>{color}</TableCell>
                                </TableRow>
                            )}
                            {border && (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['border']}</b>
                                    </TableCell>
                                    <TableCell>{border}</TableCell>
                                </TableRow>
                            )}
                            {type && (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['type']}</b>
                                    </TableCell>
                                    <TableCell>{type}</TableCell>
                                </TableRow>
                            )}
                            {attr7 && (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['attr7']}</b>
                                    </TableCell>
                                    <TableCell>{attr7}</TableCell>
                                </TableRow>
                            )}
                            {attr8 && (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['attr8']}</b>
                                    </TableCell>
                                    <TableCell>{attr8}</TableCell>
                                </TableRow>
                            )}
                            {attr9 && (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['attr9']}</b>
                                    </TableCell>
                                    <TableCell>{attr9}</TableCell>
                                </TableRow>
                            )}
                            {attr10 && (
                                <TableRow>
                                    <TableCell>
                                        <b>{attrNames['attr10']}</b>
                                    </TableCell>
                                    <TableCell>{attr10}</TableCell>
                                </TableRow>
                            )}
                            {(total || total === 0) && (
                                <TableRow>
                                    <TableCell>
                                        <b>{t('profile.total')}</b>
                                    </TableCell>
                                    <TableCell>{total}</TableCell>
                                </TableRow>
                            )}
                            {(rarity_score || rarity_score === 0) && (
                                <TableRow>
                                    <TableCell>
                                        <b>{t('ranking.rarity_score')}</b>
                                    </TableCell>
                                    <TableCell>
                                        {formatNumberS(rarity_score)}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>

            <div className={'flex justify-center mt-10'}>
                <div>
                    <Link href={'/account?' + getLinkStr('inventory')}>
                        <MainButton onClick={cancel}>{'Inventory'}</MainButton>
                    </Link>
                </div>
                <div>
                    <Link href={'/market?' + getLinkStr('sales', 'sales')}>
                        <MainButton onClick={cancel}>{'Market'}</MainButton>
                    </Link>
                </div>
            </div>
        </Popup>
    )
}

export default SetItemPopup
