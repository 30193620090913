import { Table, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatWAX } from '../helpers/FormatLinks'

export const MiniMarketTable = ({ asset }) => {
    const { t } = useTranslation('common')

    const floor = asset && asset.lowest ? asset.lowest : ''
    const median = asset && asset.average ? asset.average : ''
    const last = asset && asset.last_sold ? asset.last_sold : ''

    return (
        <Table size={'small'}>
            <TableRow>
                <TableCell size={'small'}>
                    <div className="text-primary text-1xs">
                        {t('asset.floor')}
                    </div>
                </TableCell>
                <TableCell>
                    <div className="text-black dark:!text-neutral text-1xs">
                        {floor ? formatWAX(floor) : ''}
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <div className="text-primary text-1xs">
                        {t('asset.average_bought')}
                    </div>
                </TableCell>
                <TableCell>
                    <div className="text-black dark:!text-neutral text-1xs">
                        {median ? formatWAX(median) : ''}
                    </div>
                </TableCell>
            </TableRow>
            <TableRow
                sx={{
                    '&:last-child td, &:last-child th relative': { border: 0 },
                }}
            >
                <TableCell>
                    <div className="text-primary text-1xs">
                        {t('asset.last_sold')}
                    </div>
                </TableCell>
                <TableCell>
                    <div className="text-black dark:!text-neutral text-1xs">
                        {last ? formatWAX(last) : ''}
                    </div>
                </TableCell>
            </TableRow>
        </Table>
    )
}
