import React, { useContext } from 'react'
import { CollectionHeaderPopups } from '../popups/CollectionHeaderPopups'
import { PopupImage } from '../popups/PopupImage'
import { Context } from '../waxplorer/Waxplorer'

export const AssetPreviewImage = ({ asset, currentAsset, title }) => {
    const [, dispatch] = useContext(Context)

    const { assets, bundle } = asset
    let { name, image } = assets ? assets[currentAsset] : asset

    const toggleImage = () => {
        dispatch({ type: 'FULL_IMAGE', payload: image })
    }

    return (
        <>
            <div className="px-4 my-3 text-lg text-center 2xl:text-2xl md:my-10">
                <p>{title ? title : name}</p>
            </div>
            {image && (
                <div className="cursor-pointer md:my-5">
                    <PopupImage image={image} toggleImage={toggleImage} />
                </div>
            )}
            {asset && (
                <div className="mt-7 md:my-10">
                    <CollectionHeaderPopups asset={asset} />
                </div>
            )}
        </>
    )
}
