import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import { Input } from '@nextui-org/react'
import { inputStyles } from '../common/styles'
import MainButton from '../common/util/input/MainButton'

function CreatePoolPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const releaseId = props['packReleaseId']

    const [poolName, setPoolName] = useState('')

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            authorized_account: userName,
            collection_name: collectionName,
            release_id: releaseId,
            pool_name: poolName,
        }

        const action = {
            account: 'nfthivepacks',
            name: 'createpool',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: data,
        }

        actions.push(action)

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(!transferError, transferError ? [transferError] : null, {
            poolReleaseId: releaseId,
        })
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Pool" cancel={cancel}>
            <div className="text-xl text-center font-bold mb-4 mx-4">
                {t('packs.do_you_want_to_create_a_pool_in_release_x', {
                    x: releaseId,
                })}
            </div>
            <div>
                <div className="flex justify-center">
                    <Input
                        onChange={(e) =>
                            setPoolName(
                                e.target.value.toLowerCase().replace(' ', ''),
                            )
                        }
                        value={poolName}
                        label={t('packs.pool_name')}
                        variant="outlined"
                        placeholder={t('packs.pool_name')}
                        size="md"
                        classNames={{
                            ...inputStyles,
                        }}
                        isInvalid={poolName.length === 0 ? true : false}
                        isRequired
                        helperText={
                            poolName.length === 0
                                ? t('packs.pool_name_may_not_be_empty')
                                : t('packs.all_lowercase_no_spaces')
                        }
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                create()
                            }
                        }}
                    />
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end gap-4 mt-4">
                <MainButton color="danger" fullWidth={false} onClick={cancel}>
                    Cancel
                </MainButton>

                <MainButton onClick={create} isDisabled={poolName.length === 0}>
                    {t('packs.create_pool')}
                </MainButton>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default CreatePoolPopup
