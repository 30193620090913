import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function HandleTradePopup(props) {
    const offerId = props['offerId']
    const tradeOption = props['tradeOption']

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']

    const [confirmed, setConfirmed] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const confirm = async () => {
        closeCallBack()
        setIsLoading(true)
        try {
            await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'atomicassets',
                            name: `${tradeOption}offer`,
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                offer_id: offerId,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            setConfirmed(true)
            callBack({ confirmed: true, error: null })
        } catch (e) {
            callBack({ confirmed: false, error: e.message })
            setError(e.message)
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    const tradeField = (
        <MainButton onClick={confirm}>{t('trade.confirm')}</MainButton>
    )

    const cancel = () => {
        callBack({ confirmed: false, error: null })
        closeCallBack()
    }

    let message = ''

    switch (tradeOption) {
        case 'decline':
            message = t('popups.are_you_sure_you_want_to_decline_trade')
            break
        case 'accept':
            message = t('popups.are_you_sure_you_want_to_accept_trade')
            break
        case 'cancel':
            message = t('popups.are_you_sure_you_want_to_cancel_trade')
            break
    }

    return (
        <Popup title={t('trade.trade')} cancel={cancel}>
            <div className="p-2">
                <div className="PopupTitle">{}</div>
                <div className="mx-auto mb-4 text-lg font-bold text-center">
                    {message}
                </div>
                {error ? <ErrorMessage error={error} /> : ''}
                <div className="relative flex justify-center gap-2">
                    <MainButton onClick={cancel} className="bg-red-500 ">
                        {t('asset.cancel')}
                    </MainButton>
                    {!confirmed ? tradeField : ''}
                </div>
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default HandleTradePopup
