import { Tooltip } from '@mui/material'
import cn from 'classnames'
import { useLanguage } from '../../hooks'

export const BackedInfo = ({ backedTokens }) => {
    const { t } = useLanguage('common')
    return (
        <div
            className={cn(
                //'absolute flex top-0 m-auto h-8 rounded-3xl bg-dark b-1',
                'absolute flex left-auto bottom-2 right-4',
            )}
        >
            <Tooltip title={`${t('asset.backed_tokens')}: ${backedTokens}`}>
                <div className={cn('h-auto my-auto text-white h-5 w-5')}>
                    <img src={'/wax.png'} alt="wax-logo" />
                </div>
            </Tooltip>
        </div>
    )
}
export default BackedInfo
