import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from '../../utils/date'
import Link from '../common/util/input/Link'
import {
    formatDiff,
    formatDiffFloor,
    formatNumber,
    getOwnerLink,
    openSearchPopup,
} from '../helpers/FormatLinks'
import { Context } from '../waxplorer/Waxplorer'
import { Accordion, AccordionItem } from '@nextui-org/react'

export const MarketInfoDetails2 = ({
    asset,
    priceInfo,
    assets,
    bundle,
    currentAsset,
    cancel,
    className,
}) => {
    const {
        average,
        usd_average,
        last_sold,
        usd_last_sold,
        lowest,
        usd_lowest,
        revenue,
        usd_revenue,
        num_sales,
        craftingCost,
        buyer,
        seller,
        market,
        price,
        usd_price,
        date,
        sender,
        receiver,
        transactionId,
    } = asset

    const { t } = useTranslation('common')

    const dateStr = format(date, null)

    const avgWAX =
        priceInfo && priceInfo['average'] ? priceInfo['average'] : average
    const avgUSD =
        priceInfo && priceInfo['average_usd']
            ? priceInfo['average_usd']
            : usd_average
    const lastSold =
        priceInfo && priceInfo['last_sold'] ? priceInfo['last_sold'] : last_sold
    const lastSoldUSD =
        priceInfo && priceInfo['last_sold_usd']
            ? priceInfo['last_sold_usd']
            : usd_last_sold
    const lowestWAX = priceInfo ? priceInfo['lowest'] : lowest
    const lowestUSD = priceInfo ? priceInfo['lowest_usd'] : usd_lowest
    const revenueWAX = priceInfo ? priceInfo['revenue'] : revenue
    const revenueUSD = priceInfo ? priceInfo['revenue_usd'] : usd_revenue
    const numSales = priceInfo ? priceInfo['num_sales'] : num_sales

    let diffMedian = 0
    if (asset && asset['usd_offer'] && avgUSD)
        diffMedian = asset['usd_offer'] / avgUSD - 1

    let diffLastSold = 0
    if (asset && asset['usd_offer'] && lastSoldUSD)
        diffLastSold = asset['usd_offer'] / lastSoldUSD - 1

    let diffFloor = 0
    if (asset && asset['offer'] && lowestWAX)
        diffFloor = asset['offer'] - lowestWAX

    const initialOpenItems = () => {
        const openItems = new Map()
        openItems.set(t('search.market'), true)
        return openItems
    }

    const [toggleItem, setToggleItem] = useState(initialOpenItems())

    const collapsibleListItem = (name, children) => {
        return (
            <>
                <Accordion className="px-0" defaultExpandedKeys={['2']}>
                    <AccordionItem
                        classNames={{
                            trigger:
                                'px-4 py-2 my-1 rounded-lg dark:bg-zinc-800 bg-zinc-200 font-semibold',
                        }}
                        title={name}
                        toggleItem={toggleItem}
                        setToggleItem={setToggleItem}
                        key="2"
                    >
                        <div className="mb-5">{children}</div>
                    </AccordionItem>
                </Accordion>
            </>
        )
    }

    const [, dispatch] = useContext(Context)
    return buyer && seller && price ? (
        <div
            className={
                className
                    ? className
                    : 'relative w-full mt-6 max-h-100 overflow-y-auto '
            }
        >
            {collapsibleListItem(
                t('search.market'),
                <Table>
                    <TableBody>
                        {buyer && (
                            <TableRow>
                                <TableCell>Buyer</TableCell>
                                <TableCell>{getOwnerLink(buyer)}</TableCell>
                            </TableRow>
                        )}
                        {seller && (
                            <TableRow>
                                <TableCell>Seller</TableCell>
                                <TableCell>{getOwnerLink(seller)}</TableCell>
                            </TableRow>
                        )}
                        {market && (
                            <TableRow>
                                <TableCell>Market</TableCell>
                                <TableCell>{market}</TableCell>
                            </TableRow>
                        )}
                        {price && (
                            <TableRow>
                                <TableCell>Price</TableCell>
                                <TableCell>{`${formatNumber(
                                    price,
                                )} WAX / $${formatNumber(
                                    usd_price,
                                )}`}</TableCell>
                            </TableRow>
                        )}
                        {dateStr && (
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>{dateStr}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>,
            )}
        </div>
    ) : receiver && sender ? (
        <div
            className={
                className
                    ? className
                    : 'relative w-full mt-6 max-h-100 overflow-y-auto InsideScroll'
            }
        >
            {collapsibleListItem(
                t('search.market'),
                <Table>
                    <TableBody>
                        {dateStr && (
                            <TableRow>
                                <TableCell>Sender</TableCell>
                                <TableCell>{sender}</TableCell>
                            </TableRow>
                        )}
                        {receiver && (
                            <TableRow>
                                <TableCell>Receiver</TableCell>
                                <TableCell>{receiver}</TableCell>
                            </TableRow>
                        )}
                        {transactionId && (
                            <TableRow>
                                <TableCell>Tx</TableCell>
                                <TableCell>
                                    <Link
                                        href={
                                            'https://waxblock.io/transaction/' +
                                            transactionId
                                        }
                                        external={true}
                                    >
                                        {transactionId.substr(0, 20) + '...'}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>,
            )}
        </div>
    ) : (
        <div
            className={
                className
                    ? className
                    : 'relative w-full mt-4 max-h-100 overflow-y-auto InsideScroll'
            }
        >
            {collapsibleListItem(
                t('search.market'),
                <Table size="small">
                    <TableBody>
                        {avgWAX && (
                            <TableRow>
                                <TableCell>Median</TableCell>
                                <TableCell>
                                    {
                                        <div className={'flex'}>
                                            {`${formatNumber(
                                                avgWAX,
                                            )} WAX / $${formatNumber(avgUSD)}`}
                                            {formatDiff(
                                                diffMedian,
                                                market,
                                                'Median',
                                            )}
                                        </div>
                                    }
                                </TableCell>
                            </TableRow>
                        )}
                        {lastSold && (
                            <TableRow>
                                <TableCell>{t('asset.last_sold')}</TableCell>
                                <TableCell>
                                    <div
                                        className="flex cursor-pointer"
                                        onClick={() => {
                                            cancel && cancel()
                                            openSearchPopup(
                                                'trades',
                                                bundle
                                                    ? assets[currentAsset]
                                                    : asset,
                                                dispatch,
                                            )
                                        }}
                                    >
                                        {`${formatNumber(
                                            lastSold,
                                        )} WAX / $${formatNumber(lastSoldUSD)}`}
                                        {formatDiff(
                                            diffLastSold,
                                            market,
                                            t('asset.last_sold'),
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                        {!lowestWAX && revenueWAX && (
                            <TableRow>
                                <TableCell>Volume</TableCell>
                                <TableCell>{`${formatNumber(
                                    revenueWAX,
                                )} WAX / $${formatNumber(
                                    revenueUSD,
                                )}`}</TableCell>
                            </TableRow>
                        )}
                        {numSales && (
                            <TableRow>
                                <TableCell>Sales</TableCell>
                                <TableCell>{numSales}</TableCell>
                            </TableRow>
                        )}
                        {lowestWAX && (
                            <TableRow>
                                <TableCell>{t('search.cheapest')}</TableCell>
                                <TableCell>
                                    <div
                                        className="flex cursor-pointer"
                                        onClick={() => {
                                            cancel && cancel()
                                            openSearchPopup(
                                                'sales',
                                                bundle
                                                    ? assets[currentAsset]
                                                    : asset,
                                                dispatch,
                                            )
                                        }}
                                    >
                                        {`${formatNumber(
                                            lowestWAX,
                                        )} WAX / $${formatNumber(lowestUSD)}`}
                                        {formatDiffFloor(
                                            diffFloor,
                                            market,
                                            'Floor',
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                        {craftingCost && (
                            <TableRow>
                                <TableCell>Crafting Cost</TableCell>
                                <TableCell>{`~${formatNumber(
                                    craftingCost,
                                )} WAX`}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>,
            )}
        </div>
    )
}
