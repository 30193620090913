import { Chains, SessionKit } from '@wharfkit/session'
import { WebRenderer } from '@wharfkit/web-renderer'
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor'
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet'
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat'

class WharfKit {
    private static instance: WharfKit
    private sessionKit: SessionKit

    private constructor(api) {
        const wallets =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? [new WalletPluginAnchor()]
                : [
                      new WalletPluginAnchor(),
                      new WalletPluginCloudWallet(),
                      new WalletPluginWombat(),
                  ]

        const args = {
            appName: 'NFTHive.io',
            chains: [
                process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                    ? Chains.WAXTestnet
                    : Chains.WAX,
            ],
            ui: new WebRenderer(),
            walletPlugins: wallets,
        }

        const options = {}

        this.sessionKit = new SessionKit(args, options)
        this.setEndpoint(api)
    }

    public static getInstance(api): WharfKit {
        if (!WharfKit.instance) {
            WharfKit.instance = new WharfKit(api)
        }

        return WharfKit.instance
    }

    async loginWallet(restoreSession = false) {
        if (restoreSession) {
            const activeSessions = await this.sessionKit.getSessions()

            // by default return the first active session in the list
            return activeSessions.length > 0
                ? await this.sessionKit.restore()
                : null
        }

        const login = await this.sessionKit.login()

        return login.session
    }

    async logoutWallet() {
        await this.sessionKit.logout()
    }

    setEndpoint(api) {
        this.sessionKit.setEndpoint(
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? Chains.WAXTestnet.id
                : Chains.WAX.id,
            api,
        )
    }
}

export { WharfKit }
