import { Container, Skeleton } from '@mui/material'
import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import LazyLoad from 'react-lazy-load'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { Context } from '../waxplorer/Waxplorer'

export const ImageElement = ({
    className = 'h-auto m-auto',
    imgClassName = 'm-auto h-auto',
    src,
    useGradient = false,
    fetchPriority = 'low',
    name = 'nfthive',
    useThumbnail = false,
    disableAspectRatio,
    ...props
}) => {
    const [isLoaded, setIsLoaded] = useState(false)

    const [state] = useContext(Context)

    const playAnimations = state.playAnimations

    useEffect(() => {}, [playAnimations])
    return isLoaded ? (
        <LazyLoad>
            <div
                className={cn(
                    'flex',
                    {
                        'bg-gradient-to-b from-page over-page to-transparent':
                            useGradient,
                    },
                    { 'h-full': !className.includes(' h-') },
                    { 'w-full': !className.includes(' w-') },
                    className,
                    disableAspectRatio ? '' : 'aspect-1',
                )}
            >
                <img
                    className={cn('m-auto', imgClassName)}
                    src={
                        src +
                        (playAnimations
                            ? ''
                            : src.includes('preview')
                            ? '&animated=false'
                            : '')
                    }
                    key={src}
                    alt={name}
                    {...props}
                    fetchpriority={fetchPriority}
                    onLoad={() => setIsLoaded(true)}
                />
            </div>
        </LazyLoad>
    ) : (
        <LazyLoad className={cn('w-full h-full')}>
            <Skeleton variant="rounded" width={'100%'} height={'100%'}>
                <Container fixed sx={{ width: '200px' }}>
                    <img
                        className={cn('w-full h-full')}
                        src={
                            src +
                            (playAnimations && useThumbnail
                                ? ''
                                : src.includes('preview')
                                ? '&animated=false'
                                : '')
                        }
                        key={src}
                        {...props}
                        alt={name}
                        fetchpriority={fetchPriority}
                        onLoad={() => setIsLoaded(true)}
                    />
                    <LoadingIndicator />
                </Container>
            </Skeleton>
        </LazyLoad>
    )
}

export default ImageElement
