import { WharfKit } from '../waxplorer/WharfKit'

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ASSET':
            return {
                ...state,
                asset: action.payload,
            }
        case 'SET_SEARCH_ASSET':
            return {
                ...state,
                searchAsset: action.payload,
            }
        case 'SET_NOTIFICATION_TIMER':
            return {
                ...state,
                notificationTimer: action.payload,
            }
        case 'SET_BANNER_COUNTER':
            return {
                ...state,
                bannerCounter: action.payload,
            }
        case 'SET_BANNERS':
            return {
                ...state,
                banners: action.payload,
            }
        case 'SET_ART_COUNTER':
            return {
                ...state,
                artCounter: action.payload,
            }
        case 'SET_ART':
            return {
                ...state,
                art: action.payload,
            }
        case 'SET_COLLECTIBLES_COUNTER':
            return {
                ...state,
                collectibleCounter: action.payload,
            }
        case 'SET_COLLECTIBLES':
            return {
                ...state,
                collectibles: action.payload,
            }
        case 'SET_ASSETS':
            return {
                ...state,
                assets: action.payload,
            }
        case 'SET_SELECTED_ASSETS':
            return {
                ...state,
                selectedAssets: action.payload,
            }
        case 'SET_SCROLL_MANAGER':
            return {
                ...state,
                scrollManager: action.payload,
            }
        case 'SET_ERROR_ASSETS':
            return {
                ...state,
                errorAssets: action.payload,
            }
        case 'SET_TRANSFERRED_ASSETS':
            return {
                ...state,
                transferredAssets: action.payload,
            }
        case 'SET_SKIP_BUY_CONFIRMATION':
            return {
                ...state,
                skipBuyConfirmation: action.payload,
            }
        case 'SET_FILTER':
            return {
                ...state,
                filter: action.payload,
            }
        case 'SET_PLAY_ANIMATIONS':
            return {
                ...state,
                playAnimations: action.payload,
            }
        case 'SET_SOLD_ASSETS':
            return {
                ...state,
                soldAssets: action.payload,
            }
        case 'SET_LINK':
            return {
                ...state,
                link: action.payload,
            }
        case 'SET_BACKGROUND_IMAGE':
            return {
                ...state,
                background_image: action.payload,
            }
        case 'SET_RECEIVER':
            return {
                ...state,
                receiver: action.payload,
            }
        case 'SET_MEMO':
            return {
                ...state,
                memo: action.payload,
            }
        case 'SET_INCLUDE_TUBES':
            return {
                ...state,
                includeTubes: action.payload,
            }
        case 'SIDE_NAVIGATION_VISIBLE':
            return {
                ...state,
                side_navigation_visible: action.payload,
            }
        case 'API':
            return {
                ...state,
                api: action.payload,
            }
        case 'SCROLLDOWN_FUNC':
            return {
                ...state,
                scrolldown_func: action.payload,
            }
        case 'SHOW_SCROLLDOWN':
            return {
                ...state,
                show_scrolldown: action.payload,
            }
        case 'FULL_IMAGE':
            return {
                ...state,
                full_image: action.payload,
            }
        case 'SHOW_BANNER':
            return {
                ...state,
                show_banner: action.payload,
            }
        case 'SET_ACTION':
            return {
                ...state,
                action: action.payload,
            }
        case 'SET_TAGS':
            return {
                ...state,
                tags: action.payload,
            }
        case 'SET_ACTION_SEARCH':
            return {
                ...state,
                actionSearch: action.payload,
            }
        case 'SET_CLAIM_AMOUNT':
            return {
                ...state,
                claimAmount: action.payload,
            }
        case 'SET_EDIT_DESCRIPTION':
            return {
                ...state,
                editDescription: action.payload,
            }
        case 'SET_EDIT_COLLECTION_NAME':
            return {
                ...state,
                editCollectionName: action.payload,
            }
        case 'SET_EDIT_SCHEMA_NAME':
            return {
                ...state,
                editSchemaName: action.payload,
            }
        case 'SET_EDIT_DISPLAY_NAME':
            return {
                ...state,
                editDisplayName: action.payload,
            }
        case 'SET_EDIT_URL':
            return {
                ...state,
                editUrl: action.payload,
            }
        case 'SET_EDIT_MARKET_FEE':
            return {
                ...state,
                editMarketFee: action.payload,
            }
        case 'SET_EDIT_IMAGE':
            return {
                ...state,
                editImage: action.payload,
            }
        case 'SET_EDIT_NEW':
            return {
                ...state,
                newCollection: action.payload,
            }
        case 'SET_EDIT_SCHEMA_ATTRIBUTES':
            return {
                ...state,
                editSchemaAttributes: action.payload,
            }
        case 'SET_EDIT_TEMPLATE_ATTRIBUTES':
            return {
                ...state,
                editTemplateAttributes: action.payload,
            }
        case 'SET_TEMPLATE_ID':
            return {
                ...state,
                templateId: action.payload,
            }
        case 'SET_SEARCH_ID':
            return {
                ...state,
                searchId: action.payload,
            }
        case 'SET_WAX_BALANCE':
            return {
                ...state,
                wax_balance: action.payload,
            }
        case 'SET_CPU':
            return {
                ...state,
                cpu: action.payload,
            }
        case 'SET_NET':
            return {
                ...state,
                net: action.payload,
            }
        case 'SET_OFFER_ID':
            return {
                ...state,
                offerId: action.payload,
            }
        case 'SET_TRADE_OPTION':
            return {
                ...state,
                tradeOption: action.payload,
            }
        case 'SET_SEARCH_TYPE':
            return {
                ...state,
                searchType: action.payload,
            }
        case 'SET_COLLECTION':
            return {
                ...state,
                searchAuthor: action.payload,
            }
        case 'SET_COLLECTION_NAME':
            return {
                ...state,
                collectionName: action.payload,
            }
        case 'SET_SCHEMA_NAME':
            return {
                ...state,
                schemaName: action.payload,
            }
        case 'SET_WAX_RAM':
            return {
                ...state,
                waxRam: action.payload,
            }
        case 'SET_IMAGE':
            return {
                ...state,
                image: action.payload,
            }
        case 'SET_VERIFIED':
            return {
                ...state,
                searchVerified: action.payload,
            }
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            }
        case 'SET_LAST_SHOWN_SET':
            return {
                ...state,
                lastShownSet: action.payload,
            }
        case 'SET_SCROLLED_DOWN':
            return {
                ...state,
                scrolledDown: action.payload,
            }
        case 'SET_SEARCH_TRIGGERED':
            return {
                ...state,
                searchTriggered: action.payload,
            }
        case 'SET_LAST_SEARCH_PATH':
            return {
                ...state,
                last_search_path: action.payload,
            }
        case 'SET_LAST_SEARCH_RESULT':
            return {
                ...state,
                last_search_result: action.payload,
            }
        case 'SET_SWITCHED_TAB':
            return {
                ...state,
                switchedTab: action.payload,
            }
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.payload,
            }
        case 'SET_BALANCE_CHANGED':
            return {
                ...state,
                balanceChanged: action.payload,
            }
        case 'SET_ENABLE_LIVE_AUTOBUY':
            return {
                ...state,
                enableLiveAutoBuy: action.payload,
            }
        case 'SET_HONEY_BALANCE_CHANGED':
            return {
                ...state,
                honeyBalanceChanged: action.payload,
            }
        case 'SET_HAS_NOTIFICATIONS':
            return {
                ...state,
                hasNotifications: action.payload,
            }
        case 'SET_USER_PICTURE_CHANGED':
            return {
                ...state,
                userPictureChanged: action.payload,
            }
        case 'SET_CALLBACK':
            return {
                ...state,
                callBack: action.payload,
            }
        case 'SET_SET_CATEGORIES':
            return {
                ...state,
                setCategories: action.payload,
            }
        case 'SET_DROP':
            return {
                ...state,
                drop: action.payload,
            }
        case 'SET_SELLPRICE':
            return {
                ...state,
                sellPrice: action.payload,
            }
        case 'SET_TUBE_ID':
            return {
                ...state,
                tubeId: action.payload,
            }
        case 'SET_LOWEST_MINTS':
            return {
                ...state,
                lowestMints: action.payload,
            }
        case 'SET_PASSWORD':
            return {
                ...state,
                password: action.payload,
            }
        case 'SET_DROP_ID':
            return {
                ...state,
                dropId: action.payload,
            }
        case 'SET_DROP_NAME':
            return {
                ...state,
                dropName: action.payload,
            }
        case 'SET_DROP_DESCRIPTION':
            return {
                ...state,
                dropDescription: action.payload,
            }
        case 'SET_DROP_PREVIEW_IMG':
            return {
                ...state,
                previewImg: action.payload,
            }
        case 'SET_DROP_PRICE_CURRENCY_LIST':
            return {
                ...state,
                dropPriceCurrencyList: action.payload,
            }
        case 'SET_CREATE_DROP_DATA':
            return {
                ...state,
                createDropData: action.payload,
            }
        case 'SET_DROP_ASSETS_TO_TRANSFER':
            return {
                ...state,
                dropAssetsToTransfer: action.payload,
            }
        case 'SET_DROP_CREATE_POOL':
            return {
                ...state,
                dropCreatePool: action.payload,
            }
        case 'SET_DROP_RAM_BALANCE':
            return {
                ...state,
                dropRamBalance: action.payload,
            }
        case 'SET_PACK_NAME':
            return {
                ...state,
                packName: action.payload,
            }
        case 'SET_PACK_DESCRIPTION':
            return {
                ...state,
                packDescription: action.payload,
            }
        case 'SET_PACK_UNPACK_ANIMATION':
            return {
                ...state,
                packUnpackAnimation: action.payload,
            }
        case 'SET_PACK_ID':
            return {
                ...state,
                packId: action.payload,
            }
        case 'SET_POOL_ID':
            return {
                ...state,
                poolId: action.payload,
            }
        case 'SET_ASSETS_TO_ADD_TO_POOL':
            return {
                ...state,
                assetsToAddToPool: action.payload,
            }
        case 'SET_ASSETS_TO_REMOVE_FROM_POOL':
            return {
                ...state,
                assetsToRemoveFromPool: action.payload,
            }
        case 'SET_PACK_TEMPLATE_ID':
            return {
                ...state,
                packTemplateId: action.payload,
            }
        case 'SET_PACK_RELEASE_ID':
            return {
                ...state,
                packReleaseId: action.payload,
            }
        case 'SET_PACK_TOTAL_PACKS':
            return {
                ...state,
                packTotalPacks: action.payload,
            }
        case 'SET_PACK_SLOTS':
            return {
                ...state,
                packSlots: action.payload,
            }
        case 'SET_PACK_TOKENS':
            return {
                ...state,
                packTokens: action.payload,
            }
        case 'SET_PACK_UNLOCK_TIME':
            return {
                ...state,
                packUnlockTime: action.payload,
            }
        case 'SET_TEMPLATE_DATA_ARRAY':
            return {
                ...state,
                templateDataArray: action.payload,
            }
        case 'SET_TABLE_KEY':
            return {
                ...state,
                tableKey: action.payload,
            }
        case 'SET_MAX_SUPPLY_MAP':
            return {
                ...state,
                maxSupplyMap: action.payload,
            }
        case 'SET_MINT_NUMBERS_MAP':
            return {
                ...state,
                mintNumbersMap: action.payload,
            }
        case 'SET_EXISTING_MINTS':
            return {
                ...state,
                existingMints: action.payload,
            }
        case 'SET_CONTRACT':
            return {
                ...state,
                contract: action.payload,
            }
        case 'setSwitchMode': {
            return {
                ...state,
                useDarkMode: action.payload,
            }
        }
        case 'SET_COLLECTION_NAME_DATA': {
            return {
                ...state,
                collectionNameData: action.payload,
            }
        }
        case 'showMessage':
            return {
                ...state,
                message: {
                    autoHide: true,
                    ...action.payload,
                },
            }
        case 'hideMessage':
            return {
                ...state,
                message: undefined,
            }
        case 'login':
            return {
                ...state,
                activeUser: {
                    accountName: String(action.payload.session?.actor),
                    requestPermission: String(
                        action.payload.session?.permission,
                    ),
                    session: action.payload.session,
                },
                user: action.payload.session,
            }
        case 'logout':
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')

            WharfKit.getInstance(state.api).logoutWallet()

            return {
                ...state,
                activeUser: {
                    accountName: null,
                    requestPermission: null,
                },
                user: null,
            }
        default:
            return state
    }
}

export default Reducer
