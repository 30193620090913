import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import get from '../helpers/Api'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function DeleteDropPopup(props) {
    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const dropId = props['dropId']

    const [purchases, setPurchases] = useState(null)

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const getDropPurchases = async (dropId) => {
        if (process.env.NEXT_PUBLIC_TESTNET === 'TRUE') return
        const dropPurchases = await get(
            `drop-purchases?drop_id=${dropId}&contract=nfthivedrops&limit=200`,
        )
        return dropPurchases
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setIsLoading(true)
            getDropPurchases(dropId).then((res) => setPurchases(res))
            setIsLoading(false)
        }
    }, [])

    return (
        <Popup title="Drop Buyers" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                People who have bought drop {dropId} ({collectionName})
            </div>
            <div></div>
            {isLoading ? (
                <PopupLoadingIndicator isLoading={isLoading} />
            ) : (
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Claimer</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Currency</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Transaction</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {purchases &&
                                purchases.map((purchase) => (
                                    <TableRow>
                                        <TableCell>
                                            <a
                                                className="underline text-primary"
                                                href={`/user/${purchase.claimer}`}
                                                target="_blank"
                                            >
                                                {purchase.claimer}
                                            </a>
                                        </TableCell>
                                        <TableCell>
                                            {purchase.country}
                                        </TableCell>
                                        <TableCell>{purchase.amount}</TableCell>
                                        <TableCell>
                                            {purchase.currency
                                                ? purchase.currency
                                                : 'free'}
                                        </TableCell>
                                        <TableCell>{purchase.date}</TableCell>
                                        <TableCell>
                                            {' '}
                                            <a
                                                className="underline text-primary"
                                                href={
                                                    process.env
                                                        .NEXT_PUBLIC_TESTNET ===
                                                    'TRUE'
                                                        ? `https://testnet.waxblock.io/transaction/${purchase.transactionId}`
                                                        : `https://waxblock.io/transaction/${purchase.transactionId}`
                                                }
                                                target="_blank"
                                            >
                                                {purchase.transactionId.slice(
                                                    0,
                                                    10,
                                                ) + '...'}
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Popup>
    )
}

export default DeleteDropPopup
