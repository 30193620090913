import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import config from '../../config.json'
import { Input } from '@nextui-org/react'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function UserPicturePopup(props) {
    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const asset = props['asset']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']
    const [error, setError] = useState()
    const [changeTimer, setChangeTimer] = useState()

    const [edited, setEdited] = useState(asset)

    const [ipfs, setIpfs] = useState(asset)

    useEffect(() => {}, [ipfs])

    const savePicture = async () => {
        setIsLoading(true)
        try {
            const result = await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'wax.gg',
                            name: 'updatephoto',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                account: userName,
                                photo_hash: edited,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ new: edited })
            closeCallBack()
        } catch (e) {
            console.error(e)
            callBack({ new: null, error: e.message })
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const changeIPFS = (e) => {
        const val = e.target.value
        setEdited(val)
        if (changeTimer) {
            clearInterval(changeTimer)
        }

        setChangeTimer(
            setTimeout(async () => {
                setIpfs(val)
            }, 1000),
        )
    }

    const cancel = () => {
        callBack({ new: null })
        closeCallBack()
    }

    return (
        <Popup
            title={'Update WAX Profile Picture'}
            cancel={cancel}
            image={
                ipfs
                    ? 'https://gateway.pinata.cloud/ipfs/' + ipfs + '?preview=1'
                    : config.ipfs + asset
            }
        >
            <div className="p-4">
                <div className={cn('grid  grid-cols-1')}>
                    <Input
                        variant="faded"
                        type="text"
                        placeholder={'IPFS Hash'}
                        onChange={changeIPFS}
                        value={edited && edited !== asset ? edited : ''}
                    />
                    <div className="grid grid-cols-2 gap-2 mt-4">
                        <MainButton className={'bg-red-500'} onClick={cancel}>
                            Cancel
                        </MainButton>
                        <MainButton onClick={savePicture}>Save</MainButton>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default UserPicturePopup
