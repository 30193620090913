import { Box, CircularProgress, Typography } from '@mui/material'
import config from '../../config.json'
import { getAtomicSchemasForCollection, post } from '../helpers/Api'
import { checkIpfs } from '../helpers/Validators'
import { floatRegEx, unsignedIntegerRegEx } from './EditorComponents'

export const CircularProgressWithLabel = ({ value }) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={value} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

export const hasDuplicateCurrencies = (dropPriceCurrencyList) => {
    const currencies = dropPriceCurrencyList.map((priceCurrency) => {
        return priceCurrency.currency
    })

    const uniqueCurrencies = Array.from(new Set(currencies))

    return currencies.length !== uniqueCurrencies.length
}

export const areCurrenciesInvalid = (dropPriceCurrencyList) => {
    const hasMultipleCurrencies = dropPriceCurrencyList.length > 1
    for (let i = 0; i < dropPriceCurrencyList.length; i++) {
        const dropPrice = dropPriceCurrencyList[i].price
        const dropPriceCurrency = dropPriceCurrencyList[i].currency

        if (!floatRegEx.test(dropPrice)) return 'Drop prices must be numbers'
        if (floatRegEx.test(dropPrice) && dropPrice < 0)
            return "Drop prices can't be negative"
        if (
            hasMultipleCurrencies &&
            floatRegEx.test(dropPrice) &&
            dropPrice === 0
        ) {
            return "Drops can't be free if multiple currencies are being offered"
        }
        if (!dropPriceCurrency) return 'Drop prices must have a currency'
    }

    if (hasDuplicateCurrencies(dropPriceCurrencyList)) {
        return 'No duplicate currencies allowed'
    }

    return null
}

export const isDropPriceInvalid = (dropPriceCurrencyList, payoutRecipients) => {
    const currenciesInvalid = areCurrenciesInvalid(dropPriceCurrencyList)

    if (currenciesInvalid) return currenciesInvalid

    if (hasDuplicateCurrencies(dropPriceCurrencyList)) {
        return 'No duplicate currencies allowed'
    }

    if (!payoutRecipients || payoutRecipients.length === 0)
        return 'Drop must have at least one recipient'
    for (const recipient of payoutRecipients) {
        if (recipient.trim().length === 0)
            return 'Payout Recipient cannot be empty'
    }
    const tempSet = new Set(payoutRecipients)
    if (tempSet.size !== payoutRecipients.length)
        return 'Recipients must be unique'
    return null
}

export const isDropAmountInvalid = ({
    maxClaimable,
    accountLimit,
    accountLimitCoolDown,
    waxRam,
    ramBytes,
    checkedTemplateIds = null,
    mustBeFinite,
    isPremint,
    isPFP,
}) => {
    if (mustBeFinite && maxClaimable === 0) {
        return 'Max Claimable Drops must be larger than 0'
    }
    if (!unsignedIntegerRegEx.test(maxClaimable))
        return 'Max Claimable Drops must be a number'
    if (!unsignedIntegerRegEx.test(accountLimit))
        return 'Account Limit must be a number'
    if (!unsignedIntegerRegEx.test(accountLimitCoolDown))
        return 'Account Limit Cooldown must be a number'
    if (
        parseInt(accountLimit) > parseInt(maxClaimable) &&
        parseInt(maxClaimable) !== 0
    )
        return 'Account Limit must be less than or equal to Max Claimable Drops'
    if (!floatRegEx.test(waxRam)) return 'RAM to buy must be a number'
    if (
        !isPremint &&
        maxClaimable !== 0 &&
        parseInt(Math.floor(ramBytes / (isPFP ? 450 : 151))) <
            (maxClaimable / 10.0) *
                (checkedTemplateIds !== null && checkedTemplateIds.size > 0
                    ? checkedTemplateIds.size
                    : 1)
    )
        return 'You need to buy enough RAM for the drop to cover at least 1/10 of the max claimable amount. You will need to buy more later.'
}

export const dropStartDateValid = (dropStartDateEnabled, dropStartDate) => {
    if (!dropStartDateEnabled) return true
    return dropStartDate > new Date()
}

export const dropEndDateValid = (
    dropEndDateEnabled,
    dropStartDateEnabled,
    dropStartDate,
    dropEndDate,
) => {
    if (!dropEndDateEnabled) return true
    if (dropStartDateEnabled) return dropEndDate > dropStartDate
    else return dropEndDate > new Date()
}

export const getPackCurrencies = async (state) => {
    const body = {
        code: 'nfthivepacks',
        index_position: 1,
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        upper_bound: '',
        reverse: 'true',
        scope: 'nfthivepacks',
        show_payer: 'false',
        table: 'tokenconfig',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (res && res.status === 200 && res.data && res.data.rows) {
        const tokens = [
            { label: 'USD', symbol: '8,WAX', contract: 'eosio.token' },
        ]
        for (const token of res.data.rows[0]['supported_tokens']) {
            const symbol = token['token_symbol']
            const currency = symbol.split(',')[1]
            const contract = token['token_contract']
            if (currency !== 'NULL')
                tokens.push({
                    label: currency,
                    symbol: symbol,
                    contract: contract,
                })
        }

        return tokens
    }

    return []
}

export const getCurrencies = async (state) => {
    const body = {
        code: 'nfthivedrops',
        index_position: 1,
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        upper_bound: '',
        reverse: 'true',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'newconfig',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (res && res.status === 200 && res.data && res.data.rows) {
        const tokens = [
            { label: 'USD', symbol: '8,WAX', contract: 'eosio.token' },
        ]
        for (const token of res.data.rows[0]['supported_tokens']) {
            const symbol = token['token_symbol']
            const currency = symbol.split(',')[1]
            const contract = token['token_contract']
            if (currency !== 'NULL')
                tokens.push({
                    label: currency,
                    symbol: symbol,
                    contract: contract,
                })
        }

        return tokens
    }

    return []
}

export const fetchTemplateAssets = async (
    collectionName,
    schemaName,
    templateId,
    userName,
    userTemplateAssets,
    setUserTemplateAssets,
    dropContractTemplateAssets,
    setDropContractTemplateAssets,
) => {
    if (templateId in userTemplateAssets) {
        return
    }

    const fetchUserAssetsUrl =
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
        `/atomicassets/v1/assets?collection_name=${collectionName}&schema_name=${schemaName}&template_id=${templateId}` +
        `&burned=false&owner=${userName}&is_transferable=true&limit=1000`

    const userAssetsResponse = await fetch(fetchUserAssetsUrl)
    const userAssetsJson = await userAssetsResponse.json()
    const userAssetsData = userAssetsJson.data

    let contractAssetsData = null

    if (
        dropContractTemplateAssets !== null &&
        setDropContractTemplateAssets !== null
    ) {
        const fetchContractAssetsUrl =
            (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.atomictest
                : config.atomic) +
            `/atomicassets/v1/assets?collection_name=${collectionName}&schema_name=${schemaName}&template_id=${templateId}` +
            `&burned=false&owner=nfthivedrops&is_transferable=true&limit=1000`

        const contractAssetsResponse = await fetch(fetchContractAssetsUrl)
        const contractAssetsJson = await contractAssetsResponse.json()
        contractAssetsData = contractAssetsJson.data
    }

    if (
        (!userAssetsData || userAssetsData.length === 0) &&
        (!contractAssetsData || contractAssetsData.length === 0)
    ) {
        return
    }

    const userAssets = []
    for (const dataItem of userAssetsData) {
        userAssets.push({
            id: dataItem.asset_id,
            mint: dataItem.template_mint,
        })
    }

    const tempUserTemplateAssets = {
        ...userTemplateAssets,
        [templateId]: userAssets.reverse(),
    }
    setUserTemplateAssets(tempUserTemplateAssets)

    if (contractAssetsData !== null) {
        const contractAssets = []
        for (const dataItem of contractAssetsData) {
            contractAssets.push({
                id: dataItem.asset_id,
                mint: dataItem.template_mint,
            })
        }

        if (
            dropContractTemplateAssets !== null &&
            setDropContractTemplateAssets !== null
        ) {
            const tempDropContractTemplateAssets = {
                ...dropContractTemplateAssets,
                [templateId]: contractAssets.reverse(),
            }
            setDropContractTemplateAssets(tempDropContractTemplateAssets)
        }
    }
}

export const fetchCollectionSchemas = async (
    userName,
    collectionName,
    setIsUserAuthor,
    setIsUserAuthorized,
    setIsSpecialAccountAuthorized,
    specialAccount,
    setCollectionData,
    setSchemas,
    setIsLoading,
    schemaFilter,
) => {
    if (!userName) {
        return
    }

    const schemas = await getAtomicSchemasForCollection(collectionName)

    if (!schemas || schemas.length === 0) {
        setIsLoading(false)
        return
    }

    // 'collection' will be the same for each schema in the Array, so pick the first one
    const collectionInfo = schemas[0].collection

    setIsUserAuthor(collectionInfo.author === userName)

    setIsUserAuthorized(collectionInfo.authorized_accounts.includes(userName))

    setIsSpecialAccountAuthorized(
        collectionInfo.authorized_accounts.includes(specialAccount),
    )

    setCollectionData({
        uniqueName: collectionInfo.collection_name,
        displayName: collectionInfo.name,
        image: collectionInfo.img,
    })

    const tempSchemas = []

    for (const dataItem of schemas) {
        if (schemaFilter) {
            if (schemaFilter.includes(dataItem.schema_name)) {
                tempSchemas.push(dataItem.schema_name)
            }
        } else {
            tempSchemas.push(dataItem.schema_name)
        }
    }
    setSchemas(tempSchemas.sort())
    setIsLoading(false)
}

export const fetchSchemaTemplates = async (
    collectionName,
    schemaName,
    schemaTemplates,
    setSchemaTemplates,
    templateFilter,
) => {
    if (schemaName in schemaTemplates) {
        return
    }

    const response = await fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/templates?collection_name=${collectionName}&schema_name=${schemaName}&limit=1000`,
    )
    const json = await response.json()
    const data = json.data
    if (!data || data.length === 0) {
        return
    }

    const templates = []
    for (const dataItem of data) {
        if (templateFilter) {
            if (templateFilter.includes(parseInt(dataItem.template_id))) {
                templates.push({
                    id: dataItem.template_id,
                    name: dataItem.name,
                    image: dataItem.immutable_data.img
                        ? dataItem.immutable_data.img
                        : null,
                    rarity: dataItem.immutable_data.rarity
                        ? dataItem.immutable_data.rarity
                        : null,
                    issuedSupply: parseInt(dataItem.issued_supply),
                    maxSupply: parseInt(dataItem.max_supply),
                    schema: dataItem.schema.schema_name,
                })
            }
        } else {
            templates.push({
                id: dataItem.template_id,
                name: dataItem.name,
                image: dataItem.immutable_data.img
                    ? dataItem.immutable_data.img
                    : null,
                rarity: dataItem.immutable_data.rarity
                    ? dataItem.immutable_data.rarity
                    : null,
                issuedSupply: parseInt(dataItem.issued_supply),
                maxSupply: parseInt(dataItem.max_supply),
                schema: dataItem.schema.schema_name,
            })
        }
    }

    const tempSchemaTemplates = {
        ...schemaTemplates,
        [schemaName]: templates.reverse(),
    }

    setSchemaTemplates(tempSchemaTemplates)
}

export const isTemplateAssetSelectionInvalid = (
    t,
    isAssetLevel,
    checkedAssetIds,
    checkedTemplateIds,
    maxClaimable,
    assetsInThisPool,
    templateFilter,
) => {
    const templatesToSelect = templateFilter ? templateFilter.length : 1

    let totalSelected = 0
    if (checkedAssetIds) {
        for (const [, assets] of Object.entries(checkedAssetIds)) {
            totalSelected += assets.size
        }
    }

    if (isAssetLevel && !assetsInThisPool) {
        let sizeComparison
        for (const [, assets] of Object.entries(checkedAssetIds)) {
            if (!sizeComparison) {
                sizeComparison = assets.size
            } else if (sizeComparison !== assets.size) {
                return t('drops.all_templates_must_have_same_number_assets')
            }
        }

        if (!sizeComparison) {
            return t('drops.no_assets_selected')
        }

        if (sizeComparison !== parseInt(maxClaimable)) {
            return t('drops.you_have_selected_x_assets_need_y', {
                x: sizeComparison,
                y: maxClaimable,
            })
        }
    } else {
        if (checkedTemplateIds.size === 0) {
            return t('drops.no_templates_selected')
        }
    }

    if (
        assetsInThisPool &&
        totalSelected + assetsInThisPool.length !==
            maxClaimable * templatesToSelect
    ) {
        return t(
            'drops.your_current_selection_would_bring_pool_size_to_x_needs_y',
            {
                x: totalSelected + assetsInThisPool.length,
                y: maxClaimable * templatesToSelect,
            },
        )
    }

    return null
}

export const isUnpackAnimationInvalid = (
    t,
    useUnpackAnimation,
    unpackAnimation,
) => {
    if (!useUnpackAnimation) return false
    if (!unpackAnimation || !checkIpfs(unpackAnimation)) {
        return t('packs.unpack_animation_invalid')
    }
}
