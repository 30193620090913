import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AttributeChip } from '../attributechip/AttributeChip'
import Link from '../common/util/input/Link'
import get from '../helpers/Api'
import {
    formatNumberS,
    getDefaultAttrNames,
    getOwnerLink,
    parseAtomicImmutableData,
    parseAtomicMutuableData,
} from '../helpers/FormatLinks'

import { Accordion, AccordionItem } from '@nextui-org/react'

export const MarketPreviewDetailsTable = ({
    asset,
    newOwner,
    currentAsset,
    atomicData,
    assetData,
    attrNames = getDefaultAttrNames(),
    visible = true,
    showDetails = true,
    showData = true,
    className,
    defaultExpandedKeys = ['2'],
}) => {
    const { t } = useTranslation('common')

    const [attributeFloors, setAttributeFloors] = useState(null)

    const assetMutableData = atomicData && parseAtomicMutuableData(atomicData)

    const assetImmutableData =
        atomicData && parseAtomicImmutableData(atomicData)

    const attributeData = Object.assign(
        {},
        assetImmutableData,
        assetMutableData,
    )

    const showAttributeAnalytics =
        attributeFloors &&
        attributeFloors['attributes'] &&
        attributeData &&
        attributeFloors['attributes'].filter(
            (x) =>
                Object.keys(attributeData).includes(x.name) &&
                attributeData[x.name] === x.value,
        ).length > 0

    const initialOpenItems = () => {
        const openItems = new Map()
        openItems.set(t('asset.details'), showDetails)
        openItems.set(t('asset.immutable_data'), showData)
        openItems.set(t('asset.mutable_data'), showData)
        openItems.set(t('asset.attribute_analytics'), showAttributeAnalytics)
        return openItems
    }

    const [toggleItem, setToggleItem] = useState(initialOpenItems())

    useEffect(() => {
        if (showAttributeAnalytics) {
            setToggleItem(initialOpenItems())
        }
    }, showAttributeAnalytics)

    const { offer } = asset

    let {
        assetId,
        assets,
        aAssetId,
        schema,
        symbol,
        contract,
        number,
        author,
        standard,
        total,
        burned,
        owner,
        seller,
        rarity_score,
        templateId,
        maxSupply,
        backedTokens,
    } = asset

    if (standard === 'atomicassets') assetId = aAssetId

    useEffect(() => {}, [author, schema, visible, currentAsset])

    useEffect(() => {
        if (templateId) {
            getAttributeFloors()
        }
    }, [templateId])

    const parseAttributeFloors = (res) => {
        setAttributeFloors(res)
    }

    const getAttributeFloors = async () => {
        if (!attributeFloors && templateId) {
            get(
                `pfp-analytics?template_id=${templateId}&asset_id=${assetId}`,
                'v3',
            ).then(parseAttributeFloors)
        }
    }

    const previewData = [
        [
            t('asset.asset_id'),
            assetId ? (
                <Link href={`/asset/${assetId}`}>
                    <div className="text-primary hover:underline">
                        {assetId}
                    </div>
                </Link>
            ) : null,
        ],
        [
            t('analytics.assets'),
            !assetId && assets
                ? assets.map((asset) => (
                      <Link href={`/asset/${asset['assetId']}`}>
                          <div className="flex ml-auto text-primary hover:underline">
                              {asset['assetId']}
                          </div>
                      </Link>
                  ))
                : null,
        ],
        [
            t('asset.template_id'),
            templateId && templateId > 0 ? (
                <Link href={`/template/${templateId}`}>
                    <div className="text-primary hover:underline">
                        {templateId}
                    </div>
                </Link>
            ) : (
                ''
            ),
        ],
        [
            t('asset.max_supply'),
            templateId && templateId > 0 ? (
                <div>{maxSupply ? maxSupply : 0}</div>
            ) : (
                ''
            ),
        ],
        [attrNames['number'], number],
        [
            t('asset.owner'),
            getOwnerLink(newOwner ? newOwner : offer ? seller : owner),
        ],
        [t('profile.total'), total],
        [t('asset.burned'), burned],
        [
            t('asset.schema'),
            schema ? (
                <Link href={`/collection/${author}/${schema}`}>
                    <div className="text-primary hover:underline">{schema}</div>
                </Link>
            ) : null,
        ],
        ['Symbol', symbol],
        ['Contract', contract],
        [
            t('ranking.rarity_score'),
            formatNumberS(rarity_score),
            !(rarity_score >= 0),
        ],
    ]

    if (backedTokens) {
        previewData.push([
            t('asset.backed_tokens'),
            backedTokens.map((token) => (
                <span className={'mr-1'}>{token}</span>
            )),
        ])
    }

    return (
        <div
            className={cn(
                className
                    ? className
                    : 'relative w-full  mt-6 max-h-100 overflow-y-auto InsideScroll',
                {
                    hidden: !visible,
                },
            )}
        >
            {showDetails ? (
                <Accordion
                    className="px-0"
                    defaultExpandedKeys={defaultExpandedKeys}
                >
                    <AccordionItem
                        classNames={{
                            trigger:
                                'px-4 py-2 my-1 rounded-lg dark:bg-zinc-800 bg-zinc-200 font-semibold',
                        }}
                        title={t('asset.details')}
                        toggleItem={toggleItem}
                        setToggleItem={setToggleItem}
                        key="2"
                    >
                        <Table size="small">
                            <TableBody>
                                {previewData.map(
                                    ([term, detail, ignore = false], i) =>
                                        ignore === false &&
                                        detail && (
                                            <TableRow>
                                                <TableCell>{term}</TableCell>
                                                <TableCell>
                                                    <div className="break-words max-w-prose">
                                                        {detail}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ),
                                )}
                            </TableBody>
                        </Table>
                    </AccordionItem>
                </Accordion>
            ) : (
                ''
            )}
            {showAttributeAnalytics ? (
                <Accordion
                    className="px-0"
                    defaultExpandedKeys={defaultExpandedKeys}
                >
                    <AccordionItem
                        classNames={{
                            trigger:
                                'px-4 py-2 my-1 rounded-lg dark:bg-zinc-800 bg-zinc-200 font-semibold',
                        }}
                        title={t('asset.attribute_analytics')}
                        toggleItem={toggleItem}
                        setToggleItem={setToggleItem}
                        key="3"
                    >
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>{t('ranking.rank')}</TableCell>
                                    <TableCell>
                                        <div className="break-all max-w-prose md:break-words">
                                            {
                                                attributeFloors['template'][
                                                    'rank'
                                                ]
                                            }
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {t('ranking.rarity_score')}
                                    </TableCell>
                                    <TableCell>
                                        <div className="break-all max-w-prose md:break-words">
                                            {
                                                attributeFloors['template'][
                                                    'rarityScore'
                                                ]
                                            }
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <div
                            className={
                                'grid grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'
                            }
                        >
                            {attributeFloors && attributeFloors['attributes']
                                ? attributeFloors['attributes'].map((a) => (
                                      <AttributeChip
                                          author={author}
                                          schema={schema}
                                          immutableData={attributeData}
                                          data={a}
                                      />
                                  ))
                                : ''}
                        </div>
                    </AccordionItem>
                </Accordion>
            ) : (
                ''
            )}

            {assetImmutableData ? (
                <Accordion
                    className="px-0"
                    defaultExpandedKeys={defaultExpandedKeys}
                >
                    <AccordionItem
                        classNames={{
                            trigger:
                                'px-4 py-2 my-1 rounded-lg dark:bg-zinc-800 bg-zinc-200 font-semibold',
                        }}
                        title={t('asset.immutable_data')}
                        toggleItem={toggleItem}
                        setToggleItem={setToggleItem}
                        key="4"
                    >
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Attribute</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                                {Object.keys(assetImmutableData).map(
                                    (key, i) => (
                                        <TableRow>
                                            <TableCell>{key}</TableCell>
                                            <TableCell>
                                                <div className="break-all max-w-prose md:break-words">
                                                    {assetImmutableData[key]}
                                                </div>
                                            </TableCell>
                                            {attributeFloors &&
                                            attributeFloors['attributes'] &&
                                            attributeFloors[
                                                'attributes'
                                            ].filter(
                                                (x) =>
                                                    x.name === key &&
                                                    x.value ===
                                                        assetImmutableData[key],
                                            ).length > 0 ? (
                                                <TableCell></TableCell>
                                            ) : (
                                                ''
                                            )}
                                            {attributeFloors &&
                                            attributeFloors['attributes'] &&
                                            attributeFloors[
                                                'attributes'
                                            ].filter(
                                                (x) =>
                                                    x.name === key &&
                                                    x.value ===
                                                        assetImmutableData[key],
                                            ).length > 0 ? (
                                                <TableCell></TableCell>
                                            ) : showAttributeAnalytics ? (
                                                <TableCell></TableCell>
                                            ) : (
                                                ''
                                            )}
                                        </TableRow>
                                    ),
                                )}
                            </TableBody>
                        </Table>{' '}
                    </AccordionItem>
                </Accordion>
            ) : (
                ''
            )}
            {assetMutableData ? (
                <Accordion
                    className="px-0"
                    defaultExpandedKeys={defaultExpandedKeys}
                >
                    <AccordionItem
                        classNames={{
                            trigger:
                                'px-4 py-2 my-1 rounded-lg dark:bg-zinc-800 bg-zinc-200 font-semibold',
                        }}
                        title={t('asset.mutable_data')}
                        toggleItem={toggleItem}
                        setToggleItem={setToggleItem}
                        key="5"
                    >
                        <Table>
                            <TableBody>
                                {Object.keys(assetMutableData).map((key, i) => (
                                    <TableRow>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>
                                            <div className="break-words max-w-prose">
                                                {assetMutableData[key]}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionItem>
                </Accordion>
            ) : (
                ''
            )}
            {!assetMutableData && !assetImmutableData && assetData ? (
                <Accordion
                    className="px-0"
                    defaultExpandedKeys={defaultExpandedKeys}
                >
                    <AccordionItem
                        classNames={{
                            trigger:
                                'px-4 py-2 my-1 rounded-lg dark:bg-zinc-800 bg-zinc-200 font-semibold',
                        }}
                        title={t('asset.data')}
                        toggleItem={toggleItem}
                        setToggleItem={setToggleItem}
                        key="6"
                    >
                        <Table>
                            <TableBody>
                                {Object.keys(assetData).map((key, i) => (
                                    <TableRow>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>
                                            <div className="break-words max-w-prose">
                                                {assetData[key]}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionItem>
                </Accordion>
            ) : (
                ''
            )}
        </div>
    )
}

export default MarketPreviewDetailsTable
