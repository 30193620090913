import axios from 'axios'
import config from '../../config.json'

import { sleep } from './FormatLinks'
import { getBoostAction } from './WaxApi'

const useProdConfig =
    process.env.NODE_ENV === 'production' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'staging'

export const pickEnvOr = (env, defaultValue) => {
    if (useProdConfig) return defaultValue
    if (typeof env === 'string' && env) return env
    return defaultValue
}

const endpoints = {
    v1:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_V1_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_V1,
                  'https://stats.hivebp.io',
              ),
    v2:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_V1_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_V1,
                  'https://stats.hivebp.io',
              ),
    v3:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_V1_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_V3,
                  'https://wax-api.hivebp.io',
              ),
    statistics:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_STATISTICS_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_STATISTICS,
                  'https://stats.hivebp.io',
              ),
    'set-api':
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_SET_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_SET_API,
                  'https://sets.nfthive.io',
              ),
    api:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API,
                  'https://api2.nfthive.io',
              ),
    api1:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API,
                  'https://api.nfthive.io',
              ),
    'wax-api':
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_ASSETS_TEST,
                  'https://test-wax-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_ASSETS,
                  'https://wax-api.hivebp.io',
              ),
    'api-min':
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API,
                  'https://api.nfthive.io',
              ),
    'api-v2':
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_ASSETS,
                  'https://api-v2.nfthive.io',
              ),
    'admin-api':
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API,
                  'https://api.nfthive.io',
              ),
    'verify-api':
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API,
                  'https://api.nfthive.io',
              ),
}

export const get = (path, endpoint = 'api', init = {}) => {
    const server = endpoints[endpoint]
    if (endpoint === 'wax-api') endpoint = 'api'
    const url = `${server}/${endpoint}/${path}`

    try {
        return fetch(url, {
            signal: init.signal ? init.signal : new AbortController().signal,
            headers: init.headers
                ? init.headers
                : { 'Content-Type': 'application/json' },
        })
            .then((res) => {
                if (res.status === 200) return res.json()
                else return { error: res.statusText }
            })
            .catch((ex) => console.error(ex))
    } catch (e) {
        console.error(`Failed to fetch ${url}`)
        console.error(e)
        return Promise.resolve(null)
    }
}

export const getAccountInfo = async (name, state) => {
    const body = {
        account_name: name,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_account'
            : state.api + '/v1/chain/get_account'

    return post(url, body)
}

export const post_admin = (
    path,
    data,
    userName,
    password,
    endpoint = 'api1',
) => {
    const token = Buffer.from(`${userName}:${password}`, 'utf8').toString(
        'base64',
    )
    const server = endpoints[endpoint]
    const url = `${server}/${endpoint}/${path}`
    return axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getDelphiMedian = async (state) => {
    const body = {
        code: 'delphioracle',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        reverse: 'true',
        scope: 'waxpusd',
        show_payer: 'false',
        table: 'datapoints',
        table_key: '',
        upper_bound: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (res && res.status === 200 && res.data && res.data.rows) {
        const row = res.data.rows[0]

        if (row.median) return row.median
    }

    return null
}

export const vote = async ({
    activeUser,
    upvotes,
    downvotes,
    setIsLoading,
    setError,
    callBack,
}) => {
    if (!activeUser) {
        return
    }
    const userName = activeUser.accountName
    setError('')
    try {
        await activeUser.session.transact(
            {
                actions: [
                    getBoostAction(activeUser),
                    {
                        account: 'verifystatus',
                        name: 'vote',
                        authorization: [
                            {
                                actor: userName,
                                permission: activeUser.requestPermission
                                    ? activeUser.requestPermission
                                    : 'active',
                            },
                        ],
                        data: {
                            voter: userName,
                            upvotes: upvotes,
                            downvotes: downvotes,
                        },
                    },
                ],
            },
            {
                expireSeconds: 300,
                blocksBehind: 0,
            },
        )
        callBack({
            voted: true,
        })
    } catch (e) {
        console.error(e)
        setError(e.message)
        callBack({
            voted: false,
            error: e.message,
        })
    } finally {
        setIsLoading(false)
    }
}

export const getMarkets = async (market) => {
    const body = {
        code: 'verifystatus',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: market,
        upper_bound: market,
        scope: 'verifystatus',
        show_payer: 'false',
        table: 'markets',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getAtomicData = async (asset) => {
    const { standard, assetId } = asset
    const controller = new AbortController()
    if (standard === 'atomicassets') return getAtomicAsset(assetId)
    return () => controller.abort()
}

export const getVotes = async (voter) => {
    const body = {
        code: 'verifystatus',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: voter,
        upper_bound: voter,
        scope: 'verifystatus',
        show_payer: 'false',
        table: 'votes',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getAtomicClaimLinkDetails = async (linkKey) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) + `/atomictools/v1/links/${linkKey}`,
    ).then((res) => res.json())
}

export const getCraft = async (craftId, collection, state) => {
    const body = {
        code: 'nfthivecraft',
        index_position: 1,
        json: 'true',
        key_type: 'i64',
        limit: 1000,
        lower_bound: craftId,
        upper_bound: craftId,
        reverse: 'true',
        scope: collection,
        show_payer: 'false',
        table: 'crafts',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data && res.data.rows) {
        for (const row of res.data.rows) {
            if (row.craft_id === craftId) {
                return row
            }
        }
    }

    return null
}

export const getAtomicAsset = async (assetId) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) + `/atomicmarket/v1/assets/${assetId}`,
    ).then((res) => res.json())
}

export const getAtomicTransfers = async (assetId) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/transfers?asset_id=${assetId}&page=1&limit=100&order=desc&sort=created`,
    ).then((res) => res.json())
}

export const getAtomicLogs = async (assetId) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/assets/${assetId}/logs?page=1&limit=100&order=desc`,
    ).then((res) => res.json())
}

export const getAtomicTemplateLogs = async (collection, templateId) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/templates/${collection}/${templateId}/logs?page=1&limit=100&order=desc`,
    ).then((res) => res.json())
}

const getFilterParams = (filters) => {
    let filterStr = ''

    const {
        collections,
        page,
        user,
        schema,
        name,
        limit,
        orderDir,
        bidder,
        winner,
        sortBy,
        asset_id,
        rarity,
        seller,
        ids,
        artist,
        genre,
        template_ids,
        template_id,
    } = filters

    if (collections)
        filterStr += `&collection_whitelist=${collections.join(',')}`

    if (ids) filterStr += `&ids=${ids.join(',')}`

    if (template_ids)
        filterStr += `&template_whitelist=${template_ids.join(',')}`

    if (template_id) filterStr += `&template_id=${template_id}`

    if (page) filterStr += `&page=${page}`

    if (schema) filterStr += `&schema_name=${schema}`

    if (user) filterStr += `&owner=${user}`

    if (seller) filterStr += `&seller=${seller}`

    if (bidder) filterStr += `&bidder=${bidder}`

    if (winner) filterStr += `&participant=${winner}`

    if (name) filterStr += `&match=${escape(name)}`

    if (genre) filterStr += `&template_data.genre=${genre}`

    if (artist) filterStr += `&template_data.song_artist=${artist}`

    if (rarity) filterStr += `&template_data.rarity=${rarity}`

    if (limit) filterStr += `&limit=${limit}`

    if (orderDir) filterStr += `&order=${orderDir}`

    if (sortBy) filterStr += `&sort=${sortBy}`

    if (asset_id) filterStr += `&asset_id=${asset_id}`

    return filterStr
}

export const fetchAssocIds = async (transactionId) => {
    let actions = []
    let tries = 0
    const assocIds = []
    const url =
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.hyperiontest
            : config.hyperion) +
        `/v2/history/get_transaction?id=${transactionId}`
    while (actions.length === 0) {
        await sleep(tries * 1000)
        const response = await fetch(url)
        const json = await response.json()
        actions = json.actions ? json.actions : []
        tries++
    }
    for (const action of actions) {
        if (action.act.name === 'requestrand') {
            assocIds.push(action.act.data.assoc_id)
        }
    }
    return assocIds
}

export const fetchResultsForAssocIds = async (assocIds, contract) => {
    let getTransactionIdActions = []
    const transactionIds = []
    let getTransactionIdTries = 0
    await sleep(3000)
    const getTransactionIdUrl =
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.hyperiontest
            : config.hyperion) +
        `/v2/history/get_actions?account=${contract}&act.name=receiverand&limit=1000`

    while (
        (getTransactionIdActions.length === 0 || transactionIds.length === 0) &&
        getTransactionIdTries < 5 &&
        transactionIds.length < 10
    ) {
        await sleep(getTransactionIdTries * 1000)
        const response = await fetch(getTransactionIdUrl)
        const json = await response.json()
        getTransactionIdActions = json.actions ? json.actions : []

        for (const action of getTransactionIdActions) {
            if (assocIds.includes(action.act.data.assoc_id)) {
                if (!transactionIds.includes(action.trx_id)) {
                    transactionIds.push(action.trx_id)
                }
            }
        }
        getTransactionIdTries++
    }

    const results = []
    for (const resultTransactionId of transactionIds) {
        let actions = []
        let tries = 0
        const url =
            (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.hyperiontest
                : config.hyperion) +
            `/v2/history/get_transaction?id=${resultTransactionId}`
        while (actions.length === 0) {
            await sleep(tries * 1000)
            const response = await fetch(url)
            const json = await response.json()
            actions = json.actions ? json.actions : []
            tries++
        }
        for (const action of actions) {
            if (
                action.act.name === 'addpfpresult' ||
                action.act.name === 'addbigresult' ||
                action.act.name === 'logmresult'
            ) {
                results.push(
                    action.act.name === 'logmresult'
                        ? action.act.data.mirror_result
                        : action.act.data.results,
                )
            }
        }
    }
    return results
}

export const fetchAssocId = async (transactionId) => {
    let actions = []
    let tries = 0
    const url =
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.hyperiontest
            : config.hyperion) +
        `/v2/history/get_transaction?id=${transactionId}`
    while (actions.length === 0) {
        await sleep(tries * 1000)
        const response = await fetch(url)
        const json = await response.json()
        actions = json.actions ? json.actions : []
        tries++
    }
    for (const action of actions) {
        if (action.act.name === 'requestrand') {
            return action.act.data.assoc_id
        }
    }
}

export const fetchResultsForAssocId = async (assocId, contract) => {
    let resultTransactionId = null

    let getTransactionIdActions = []
    let getTransactionIdTries = 0
    const getTransactionIdUrl =
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.hyperiontest
            : config.hyperion) +
        `/v2/history/get_actions?account=${contract}&act.name=receiverand&limit=20`
    while (
        getTransactionIdActions.length === 0 ||
        getTransactionIdActions[0].act.data.assoc_id < assocId
    ) {
        await sleep(getTransactionIdTries * 1000)
        const response = await fetch(getTransactionIdUrl)
        const json = await response.json()
        getTransactionIdActions = json.actions ? json.actions : []
        getTransactionIdTries++
    }
    for (const action of getTransactionIdActions) {
        if (action.act.data.assoc_id === assocId) {
            resultTransactionId = action.trx_id
            break
        }
    }

    let actions = []
    let tries = 0
    const url =
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.hyperiontest
            : config.hyperion) +
        `/v2/history/get_transaction?id=${resultTransactionId}`
    while (actions.length === 0) {
        await sleep(tries * 1000)
        const response = await fetch(url)
        const json = await response.json()
        actions = json.actions ? json.actions : []
        tries++
    }
    const results = []
    for (const action of actions) {
        if (
            action.act.name === 'addpfpresult' ||
            action.act.name === 'addbigresult' ||
            action.act.name === 'logmresult'
        ) {
            results.push(
                action.act.name === 'logmresult'
                    ? action.act.data.mirror_result
                    : action.act.data.results,
            )
        }
    }
    return results
}

export const getAtomicAssets = (filters) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/assets?${getFilterParams(filters)}`,
    ).then((res) => res.json())
}

export const getAtomicAuction = (auctionId) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) + `/atomicmarket/v1/auctions/${auctionId}`,
    ).then((res) => res.json())
}

export const getAtomicTemplateByIdOnly = (templateId) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) + `/atomicassets/v1/templates?ids=${templateId}`,
    ).then((res) => res.json())
}

export const getAtomicTemplate = (templateId, collection) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/templates/${collection}/${templateId}`,
    ).then((res) => res.json())
}

export const getAtomicSchemasForCollection = async (collection) => {
    let num_results = 100
    let schemas = []
    let page = 1
    while (num_results === 100) {
        const response = await fetch(
            (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.atomictest
                : config.atomic) +
                `/atomicassets/v1/schemas?collection_name=${collection}&page=${page}`,
        )
        const json = await response.json()
        num_results = json.data ? json.data.length : 0
        if (json.data && json.data.length > 0) {
            schemas = schemas.concat(json.data)
        }
        page += 1
    }
    schemas.sort((a, b) => {
        return a.schema_name < b.schema_name ? -1 : 1
    })
    return schemas
}

export const getAtomicTemplatesForCollectionSchema = (collection, schema) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/templates?collection_name=${collection}&schema_name=${schema}`,
    ).then((res) => res.json())
}

export const getAtomicSchema = (schema, collection) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/schemas/${collection}/${schema}`,
    ).then((res) => res.json())
}

export const getAtomicSchemaStats = (schema, collection) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/schemas/${collection}/${schema}/stats`,
    ).then((res) => res.json())
}

export const getAtomicCollection = (collection) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) + `/atomicassets/v1/collections/${collection}`,
    ).then((res) => res.json())
}

export const get_ext = (url, init = {}) => {
    try {
        return fetch(url, {
            signal: init.signal ? init.signal : new AbortController().signal,
            headers: init.headers
                ? init.headers
                : { 'Content-Type': 'application/json' },
        })
            .then((res) => {
                if (res.status === 200) return res.json()
                else return { error: res.statusText }
            })
            .catch((ex) => console.error(ex))
    } catch (e) {
        console.error(`Failed to fetch ${url}`)
        console.error(e)
        return Promise.resolve(null)
    }
}

export const post = (url, data) =>
    axios({
        method: 'post',
        url: url,
        data: data,
    }).then((res) => res)

export const logMessage = (message) => {
    const server = endpoints['api']
    const encryptedMsg = window.btoa('nfthive' + message)
    const encryptedUrl = window.btoa('nfthive' + window.location.href)
    fetch(`${server}/api/log-message`, {
        method: 'POST',
        body: JSON.stringify({ message: encryptedMsg, url: encryptedUrl }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const getCollectionThemeData = async (collection, state) => {
    const body = {
        json: true,
        code: 'neftyblocksa',
        scope: 'neftyblocksa',
        table: 'colthemedata',
        lower_bound: collection,
        upper_bound: collection,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getRules = async (ruleId, state) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'rules',
        lower_bound: ruleId,
        upper_bound: ruleId,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getPacksPool = async (poolId, state) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'pools',
        lower_bound: poolId,
        upper_bound: poolId,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getPacksMintOnDemand = async (releaseId, state) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'mintondemand',
        lower_bound: releaseId,
        upper_bound: releaseId,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getAssetsInPackTransferState = async (collection, state) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'transfers',
        lower_bound: collection,
        upper_bound: collection,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getAssetsInUnpackAssetsState = async (state) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'unpackresult',
        table_key: '',
        index_position: 1,
        key_type: '',
        reverse: false,
        limit: 10000,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    const assets = []

    if (res && res.status === 200 && res.data.rows.length > 0) {
        res.data.rows.map((row) => {
            assets.push(row)
        })
    }

    return assets
}

export const getTokensInUnpackTokensState = async (state) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'unpackresult',
        table_key: '',
        index_position: 1,
        key_type: '',
        reverse: false,
        limit: 10000,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    const assets = []

    if (res && res.status === 200 && res.data.rows.length > 0) {
        res.data.rows.map((row) => {
            assets.push(row)
        })
    }

    return assets
}

export const isCollectionWhitelistedForPacks = async (collection, state) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'allconfig',
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return (
            res.data.rows[0].open_mode ||
            res.data.rows[0].col_wl.includes(collection)
        )
    }

    return null
}

export const getPack = async (packId, state) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'packs',
        lower_bound: packId,
        upper_bound: packId,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getAuthorizedCollections = async (userName) => {
    const response = await fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/collections?authorized_account=${userName}&limit=100`,
    )
    const json = await response.json()
    return json.data ? json.data : []
}

export const getReleases = async (collection, state) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: collection,
        table: 'releases',
        table_key: '',
        index_position: 1,
        key_type: '',
        reverse: true,
        limit: 10000,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    const releases = []
    if (res && res.status === 200 && res.data.rows.length > 0) {
        res.data.rows.map((row) => {
            releases.push(row)
        })
    }

    return releases
}

export const getUser = async (name, state) => {
    const body = {
        account_name: name,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_account'
            : state.api + '/v1/chain/get_account'

    let res = null
    try {
        res = await post(url, body)
    } catch (e) {
        return null
    }
    if (res && res.status === 200 && res.data) {
        return res.data
    }
    return null
}

export const getCraftsAccountStats = async (user, craftId, state) => {
    const body = {
        json: true,
        code: 'nfthivecraft',
        scope: user,
        table: 'accounts',
        table_key: '',
        lower_bound: craftId,
        upper_bound: craftId,
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getDropsAccountStats = async (user, dropID, state, isPFP) => {
    const body = {
        json: true,
        code: 'nfthivedrops',
        scope: user,
        table: isPFP ? 'pfpaccounts' : 'accounts',
        table_key: '',
        lower_bound: dropID,
        upper_bound: dropID,
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getDropKeys = async (dropId, state) => {
    return null

    const body = {
        code: 'neftyblocksd',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        upper_bound: '',
        reverse: 'true',
        scope: dropId,
        show_payer: 'false',
        table: 'authkeys',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getPFP = async (dropId, state) => {
    const dropInfoBody = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: dropId,
        upper_bound: dropId,
        reverse: 'true',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'pfps',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const dropInfoRes = await post(url, dropInfoBody)

    if (
        dropInfoRes &&
        dropInfoRes.status === 200 &&
        dropInfoRes.data &&
        dropInfoRes.data.rows &&
        dropInfoRes.data.rows.length > 0
    ) {
        return dropInfoRes.data.rows[0]
    }

    return null
}

export const getPFPFees = async (state) => {
    const dropInfoBody = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        upper_bound: '',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'feeconfig',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const dropInfoRes = await post(url, dropInfoBody)

    if (
        dropInfoRes &&
        dropInfoRes.status === 200 &&
        dropInfoRes.data &&
        dropInfoRes.data.rows &&
        dropInfoRes.data.rows.length > 0
    ) {
        return dropInfoRes.data.rows[0]
    }

    return false
}

export const getCollectionPfpFees = async (collectionName, state) => {
    const dropInfoBody = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: collectionName,
        upper_bound: collectionName,
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'pfpfees',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const dropInfoRes = await post(url, dropInfoBody)

    if (
        dropInfoRes &&
        dropInfoRes.status === 200 &&
        dropInfoRes.data &&
        dropInfoRes.data.rows &&
        dropInfoRes.data.rows.length > 0 &&
        dropInfoRes.data.rows[0]['collection_name'] === collectionName
    ) {
        return dropInfoRes.data.rows[0]
    }

    return false
}

export const isCollectionWhitelistedForPfps = async (collectionName, state) => {
    const dropInfoBody = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: collectionName,
        upper_bound: collectionName,
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'pfpwl',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const dropInfoRes = await post(url, dropInfoBody)

    if (
        dropInfoRes &&
        dropInfoRes.status === 200 &&
        dropInfoRes.data &&
        dropInfoRes.data.rows &&
        dropInfoRes.data.rows.length > 0
    ) {
        return true
    }

    return false
}

export const getDrop = async (dropId, state) => {
    const dropInfoBody = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: dropId,
        upper_bound: dropId,
        reverse: 'true',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'drops',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const dropInfoRes = await post(url, dropInfoBody)

    if (
        dropInfoRes &&
        dropInfoRes.status === 200 &&
        dropInfoRes.data &&
        dropInfoRes.data.rows &&
        dropInfoRes.data.rows.length > 0
    ) {
        return dropInfoRes.data.rows[0]
    }

    return null
}

export const getDropReferrals = async (dropId, state) => {
    const body = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: dropId,
        upper_bound: dropId,
        reverse: 'true',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'droprefs',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getDropPrices = async (dropId, state) => {
    const body = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: dropId,
        upper_bound: dropId,
        reverse: 'true',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'dropprices',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getRamRate = async (state) => {
    const body = {
        code: 'eosio',
        index_position: 1,
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        upper_bound: '',
        reverse: 'true',
        scope: 'eosio',
        show_payer: 'false',
        table: 'rammarket',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (res && res.status === 200 && res.data && res.data.rows) {
        const market = res.data.rows[0]
        const base = market['base']
        const quote = market['quote']

        return {
            base: parseFloat(base['balance'].replace(' RAM', '')),
            quote: parseFloat(quote['balance'].replace(' WAX', '')),
        }
    }
    return null
}

export const getRamBalance = async (
    collection,
    state,
    contract = 'nfthivedrops',
) => {
    const body = {
        code: contract,
        index_position: 1,
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: collection,
        upper_bound: collection,
        reverse: 'true',
        scope: contract,
        show_payer: 'false',
        table: 'rambalances',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        return res.data.rows[0]['byte_balance']
    } else {
        return 0
    }
}

export const getAccountBalance = async (
    account,
    state,
    contract = 'nfthivecraft',
) => {
    const body = {
        code: contract,
        index_position: 1,
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: account,
        upper_bound: account,
        reverse: 'true',
        scope: contract,
        show_payer: 'false',
        table: 'balances',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        return res.data.rows[0]['assets']
    } else {
        return []
    }
}

export const buyRam = async ({
    activeUser,
    collectionName,
    waxToTransfer,
    contract,
    callback,
    setError,
}) => {
    const userName = activeUser ? activeUser['accountName'] : null

    const buyRamActions = [getBoostAction(activeUser)]

    if (waxToTransfer && parseFloat(waxToTransfer) > 0) {
        buyRamActions.push({
            account: 'eosio.token',
            name: 'transfer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                from: userName,
                to: contract,
                quantity: `${parseFloat(waxToTransfer).toFixed(8)} WAX`,
                memo: 'deposit_collection_ram:' + collectionName,
            },
        })
    } else {
        setError(`Cannot transfer amount: ${waxToTransfer}`)
        return
    }

    try {
        await activeUser.session.transact(
            {
                actions: buyRamActions,
            },
            {
                expireSeconds: 300,
                blocksBehind: 0,
            },
        )
    } catch (e) {
        setError(e.message)
    }
    callback()
}

export const getIsOnWhitelist = async (drop, userName, state) => {
    const body = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: userName,
        upper_bound: userName,
        reverse: 'true',
        scope: drop.dropId,
        show_payer: 'false',
        table: 'whitelist',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        for (const row of res.data.rows) {
            if (row.account === userName) {
                return true
            }
        }
    }

    return false
}

export const hasWhitelist = async (drop, state) => {
    const body = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        upper_bound: '',
        reverse: 'true',
        scope: drop.dropId,
        show_payer: 'false',
        table: 'whitelist',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    return (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    )
}

export const getTokenBalance = async (symbol, contract, userName, state) => {
    const body = {
        code: 'eosio.token',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        reverse: 'false',
        scope: userName,
        show_payer: 'false',
        table: 'accounts',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        for (const row of res.data.rows) {
            if (row['balance'].includes(' ' + symbol)) return row['balance']
        }
    }

    return null
}

export const getWhiteList = async (drop, state) => {
    let result = []
    let more = true

    let lower_bound = ''

    while (more) {
        const body = {
            code: 'nfthivedrops',
            index_position: 'primary',
            json: 'true',
            key_type: 'i64',
            limit: 1000,
            lower_bound: lower_bound,
            upper_bound: '',
            reverse: 'false',
            scope: drop.dropId,
            show_payer: 'false',
            table: 'whitelist',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : state.api + '/v1/chain/get_table_rows'
        const res = await post(url, body)

        more = false

        if (
            res &&
            res.status === 200 &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0
        ) {
            for (const row of res.data.rows) {
                result.push(row.account)
            }
            more = res.data.more
            lower_bound = res.data.next_key
        }
    }

    if (result.length === 0) return null

    return result
}

export const getTransaction = async (trxId) => {
    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.hyperiontest + '/v2/history/get_transaction?id=' + trxId
            : config.hyperion + '/v2/history/get_transaction?id=' + trxId

    const res = await fetch(url)

    if (res && res.status === 200) {
        const json = await res.json()

        if (json.actions) {
            return json
        }
    }

    return null
}

export const filterWithAssetLock = async (dropId, assets, claimer, state) => {
    if (!assets || assets.length === 0) {
        return assets
    }
    const body = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1000,
        lower_bound: assets[0],
        upper_bound: assets[assets.length - 1],
        reverse: 'true',
        scope: dropId,
        show_payer: 'false',
        table: 'assetlocks',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    let new_assets = [...assets]

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        for (const row of res.data.rows) {
            if (
                new_assets.includes(row['asset_id'].toString()) &&
                row['claimer'] !== claimer
            ) {
                new_assets = new_assets.filter(
                    (a) => a.toString() !== row['asset_id'].toString(),
                )
            }
        }
    }

    return new_assets
}

export const getProofOwn = async (dropId, state) => {
    const body = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: dropId,
        upper_bound: dropId,
        reverse: 'true',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'dropproof',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getAssetsInDropPools = async (collection, state) => {
    const body = {
        json: true,
        code: 'nfthivedrops',
        scope: collection,
        table: 'droppools',
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 10000,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    let assets = []

    if (res && res.status === 200 && res.data.rows.length > 0) {
        res.data.rows.map((row) => {
            assets = assets.concat(row.assets)
        })
    }
    return assets
}

export const getCrafts = async (collection, state) => {
    const body = {
        json: true,
        code: 'nfthivecraft',
        scope: collection,
        table: 'crafts',
        table_key: '',
        index_position: 1,
        key_type: '',
        reverse: true,
        limit: 10000,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    const crafts = []
    if (res && res.status === 200 && res.data.rows.length > 0) {
        res.data.rows.map((row) => {
            crafts.push(row)
        })
    }

    return crafts
}

export const getCraftCollectionPools = async (collection, state) => {
    const body = {
        json: true,
        code: 'nfthivecraft',
        scope: collection,
        table: 'pools',
        table_key: '',
        index_position: 1,
        key_type: '',
        reverse: true,
        limit: 10000,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    const pools = []
    if (res && res.status === 200 && res.data.rows.length > 0) {
        res.data.rows.map((row) => {
            pools.push(row)
        })
    }

    return pools
}

export const getCraftInTransferAssets = async (collection, state) => {
    const body = {
        code: 'nfthivecraft',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: collection,
        upper_bound: collection,
        reverse: 'true',
        scope: 'nfthivecraft',
        show_payer: 'false',
        table: 'transfers',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getUnclaimedCraftResults = async (userName, state) => {
    const body = {
        json: true,
        code: 'nfthivecraft',
        scope: 'nfthivecraft',
        table: 'claims',
        reverse: false,
        limit: 10000,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    const pools = []
    if (res && res.status === 200 && res.data.rows.length > 0) {
        res.data.rows.map((row) => {
            pools.push(row)
        })
    }

    return pools
}

export const getWithdrawableItems = async (userName, state) => {
    const body = {
        code: 'nfthivecraft',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: userName,
        upper_bound: userName,
        reverse: 'true',
        scope: 'nfthivecraft',
        show_payer: 'false',
        table: 'items',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getMirrorCraft = async (craftId, collectionName, state) => {
    const body = {
        code: 'nfthivecraft',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: craftId,
        upper_bound: craftId,
        reverse: 'true',
        scope: collectionName,
        show_payer: 'false',
        table: 'mirrorcrafts',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export default get
