import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../waxplorer/Waxplorer'
import SearchPopup from './SearchPopup'

function PopupWrapperSearch(props) {
    const [state, dispatch] = useContext(Context)

    const asset = state.actionSearch.asset
    const searchType = state.actionSearch.searchType
    const actionSearch = state.actionSearch.actionSearch
    const searchId = state.actionSearch.searchID
    const callBack = state.callBack
    const includeTubes = state.actionSearch.includeTubes
    const includeDrops = state.actionSearch.includeDrops

    const [element, setElement] = useState(null)

    function OutsideAlerter({ callBack, searchId, children }) {
        const handleClickOutside = (event) => {
            if (
                searchId &&
                event &&
                event.target &&
                event.target.id === 'OutsideAlerter'
            ) {
                dispatch({ type: 'SET_ACTION', payload: '' })
                callBack()
                event.preventDefault()
                event.stopPropagation()
            }
        }

        useEffect(() => {}, [searchId])

        return searchId ? (
            <div
                id={'OutsideAlerter'}
                className={cn(
                    'absolute w-screen h-screen bg-transparent',
                    'z-100 backdrop-filter backdrop-blur-sm',
                )}
                onClick={handleClickOutside}
            >
                {children}
            </div>
        ) : (
            ''
        )
    }

    useEffect(() => {
        if (searchId) {
            setElement(
                <div className="PopupWrapper">
                    <OutsideAlerter
                        callBack={() => {
                            dispatch({
                                type: 'SET_ACTION_SEARCH',
                                payload: {
                                    actionSearch: '',
                                    asset: null,
                                    searchType: '',
                                    searchID: '',
                                    includeDrops: false,
                                },
                            })
                        }}
                        searchId={searchId}
                    >
                        <SearchPopup
                            asset={asset}
                            searchId={searchId}
                            searchType={searchType}
                            includeTubes={includeTubes}
                            includeDrops={includeDrops}
                            callBack={() => {
                                dispatch({
                                    type: 'SET_ACTION_SEARCH',
                                    payload: {
                                        actionSearch: '',
                                        asset: null,
                                        searchType: '',
                                        searchID: '',
                                        includeDrops: false,
                                    },
                                })
                            }}
                            closeCallBack={() => {
                                callBack()
                                dispatch({
                                    type: 'SET_ACTION_SEARCH',
                                    payload: {
                                        actionSearch: '',
                                        asset: null,
                                        searchType: '',
                                        searchID: '',
                                        includeDrops: false,
                                    },
                                })
                            }}
                        />
                    </OutsideAlerter>
                </div>,
            )
        }
    }, [searchId])

    return (
        <div className="PopupWrapper">
            {actionSearch === 'search' ? element : ''}
        </div>
    )
}

export default PopupWrapperSearch
