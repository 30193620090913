import { Input } from '@nextui-org/react'
import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropAlert } from '../../components/drops/DropAlert'
import MainButton from '../common/util/input/MainButton'
import { DropButton } from '../drops/DropButton'
import { formatToken } from '../helpers/FormatLinks'
import { getBoostAction, getHoneyTransitions } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import Popup from './Popup'

function StakeHoneyPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']

    const balance = props['balance']

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [sellPrice, setSellPrice] = useState(balance)
    const token = props['token']
    const digits = props['digits']

    const [transitionBalance, setTransitionBalance] = useState(null)

    const parseTransitionHoneyBalance = (res) => {
        if (
            res &&
            res.status === 200 &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0 &&
            res.data.rows[0].user === userName &&
            res.data.rows[0].amount
        ) {
            setTransitionBalance(res.data.rows[0].amount.replace(' HONEY', ''))
        } else {
            setTransitionBalance(0)
        }
    }

    useEffect(() => {
        getHoneyTransitions(userName, state).then(parseTransitionHoneyBalance)
    }, [userName])

    const transfer = async () => {
        if (!sellPrice) return
        const quantity = parseFloat(sellPrice)

        closeCallBack()
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        if (transitionBalance) {
            actions.push({
                account: 'nfthivevault',
                name: 'stake',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    user: userName,
                },
            })
        }

        actions.push({
            account: 'nfthivehoney',
            name: 'transfer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                from: userName,
                to: 'nfthivevault',
                quantity: `${quantity.toFixed(digits)} ${token}`,
                memo: 'stake',
            },
        })

        actions.push({
            account: 'nfthivevault',
            name: 'stake',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                user: userName,
            },
        })

        try {
            await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({
                transferred: true,
                receiver: 'nfthivevault',
                quantity: quantity,
                error: error,
            })
        } catch (e) {
            console.error(e)
            setError(e.message)
            callBack({
                transferred: false,
                receiver: null,
                quantity: null,
                error: e.message,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const cancel = () => {
        callBack({
            transferred: false,
            receiver: null,
            quantity: null,
            error: error,
        })
        closeCallBack()
    }

    return (
        <Popup
            title={t('asset.stake')}
            cancel={cancel}
            image={'/Logo_HONEY.png'}
            imgSize={'small'}
        >
            <div className="p-2 ">
                <div className="mx-auto mt-2 mb-4 font-bold text-center md:mt-0 text-md">
                    {t('honey.stake_honey_and_earn')}
                </div>
                <div className="flex justify-center mb-4 text-lg font-bold text-center">
                    <div>{`${t('navigation.balance')}`}: </div>
                    <div
                        className={cn({
                            'text-primary cursor-pointer hover:underline':
                                balance !== sellPrice,
                        })}
                        onClick={() => setSellPrice(balance)}
                    >
                        {balance && formatToken(balance, token, digits)}
                    </div>
                </div>
                <div className={cn('flex flex-wrap justify-center')}>
                    <div className="w-2/3 lg:w-1/3">
                        <Input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            label={token}
                            onChange={(e) => {
                                setSellPrice(
                                    Math.min(
                                        parseFloat(e.target.value),
                                        balance,
                                    ),
                                )
                            }}
                            inputProps={{ min: 0 }}
                            value={sellPrice}
                            variant="faded"
                        />
                    </div>
                </div>
                <div className="flex justify-center gap-2 mt-4">
                    <MainButton className="bg-red-500 " onClick={cancel}>
                        {t('asset.cancel')}
                    </MainButton>
                    <MainButton
                        disabled={
                            !sellPrice ||
                            parseFloat(sellPrice) > parseFloat(balance)
                        }
                        onClick={transfer}
                    >
                        {t('asset.stake')}
                    </MainButton>
                </div>
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : null}
                <DropAlert severity="error" condition={error}>
                    {error}
                </DropAlert>
            </div>
        </Popup>
    )
}

export default StakeHoneyPopup
