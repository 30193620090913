import { LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import { EditMintOnDemand } from '../editor/EditMintOnDemand'
import { getPacksMintOnDemand } from '../helpers/Api'
import MainButton from '../common/util/input/MainButton'
import Link from '../common/util/input/Link'
import cn from 'classnames'
import { DropAlert } from '../drops/DropAlert'
import config from '../../config.json'

function CreatePackMintOnDemandPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const [mintOnDemandMap, setMintOnDemandMap] = useState({})

    const [isLoadingMoD, setIsLoadingMoD] = useState(true)

    const [changeCount, setChangeCount] = useState(0)

    const [packsContractAdded, setPacksContractAdded] = useState(false)

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingPacks, setIsLoadingPacks] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const [collectionData, setCollectionData] = useState(null)

    const releaseId = props['packReleaseId']

    const [error, setError] = useState(null)

    const fetchCollectionData = async () => {
        if (!userName) {
            return
        }

        const response = await fetch(
            (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.atomictest
                : config.atomic) +
                `/atomicassets/v1/collections/${collectionName}`,
        )
        const json = await response.json()
        const collectionInfo = json.data
        if (!collectionInfo || collectionInfo.length === 0) {
            return
        }

        return {
            uniqueName: collectionInfo.collection_name,
            displayName: collectionInfo.name,
            image: collectionInfo.img,
            authorizedAccounts: collectionInfo.authorized_accounts,
        }
    }

    const parseMintOnDemand = (res) => {
        if (res && res['release_id'] === releaseId) {
            const newMintOnDemandMap = {}
            for (const asset of res['mint_on_demand_assets']) {
                newMintOnDemandMap[asset['template_id']] =
                    asset['total_to_be_minted']
                setMintOnDemandMap(newMintOnDemandMap)
            }
        }
        setIsLoadingMoD(false)
    }

    useEffect(() => {
        setIsLoadingMoD(true)
        getPacksMintOnDemand(releaseId, state).then(parseMintOnDemand)
    }, [releaseId])

    useEffect(() => {
        fetchCollectionData(collectionName).then(setCollectionData)
    }, [collectionName])

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    const addPacksContract = async () => {
        setIsLoadingPacks(true)

        try {
            const actions = []

            actions.push({
                account: 'atomicassets',
                name: 'addcolauth',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    collection_name: collectionName,
                    account_to_add: 'nfthivepacks',
                },
            })

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )

                if (result?.response?.transaction_id) {
                    setPacksContractAdded(true)
                }
            } catch (e) {
                setError(e.message)
            }
        } catch (e) {
            setError(e.message)
        }

        setIsLoadingPacks(false)
    }

    const addMintOnDemand = async () => {
        setIsLoading(true)

        try {
            const result = await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'nfthivepacks',
                            name: 'setmintondem',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                authorized_account: userName,
                                collection_name: collectionName,
                                release_id: releaseId,
                                mint_on_demand_assets: Object.keys(
                                    mintOnDemandMap,
                                ).map((templateId) => {
                                    return {
                                        template_id: parseInt(templateId),
                                        total_to_be_minted: parseInt(
                                            mintOnDemandMap[templateId],
                                        ),
                                    }
                                }),
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            if (result?.response?.transaction_id) {
                callBack(true, error, {
                    modReleaseId: releaseId,
                })
                closeCallBack()
            }
        } catch (e) {
            setError(e.message)
        }

        setIsLoading(false)
    }

    useEffect(() => {}, [changeCount])

    return (
        <Popup
            title={t('packs.select_template_to_mint_on_demand')}
            cancel={cancel}
        >
            <DropAlert
                severity="warning"
                condition={
                    collectionData &&
                    collectionData['authorizedAccounts'] &&
                    !collectionData['authorizedAccounts'].includes(
                        'nfthivepacks',
                    ) &&
                    !packsContractAdded
                }
                title={t('packs.nfthivepacks_not_authorized')}
            >
                {isLoadingPacks ? (
                    <LinearProgress />
                ) : (
                    <MainButton
                        className={'w-36 mr-4'}
                        onClick={addPacksContract}
                    >
                        {t('editor.authorize_contract')}
                    </MainButton>
                )}
            </DropAlert>
            <div className="w-full flex justify-between h-[5vh]">
                <div className="text-xl my-auto">
                    {t('packs.mint_on_demand')}
                </div>
            </div>
            <div className="w-full md:w-96 mb-5">
                {t('packs.mint_on_demand_explanation')}
            </div>
            {Object.keys(mintOnDemandMap).length > 0 ? (
                <div className="w-full flex justify-between h-[5vh]">
                    <div className="text-xl my-auto">
                        {t('packs.templates_to_mint_on_demand')}
                    </div>
                </div>
            ) : (
                ''
            )}
            <div className={'flex flex-wrap'}>
                {isLoadingMoD ? (
                    <LinearProgress />
                ) : (
                    Object.keys(mintOnDemandMap).map((templateId, index) => (
                        <div className={'flex flex-wrap'}>
                            <Link href={`/template/${templateId}`}>
                                <div className={'text-primary'}>
                                    {templateId}
                                </div>
                            </Link>
                            : {mintOnDemandMap[templateId]}
                            <div
                                className={cn(
                                    'cursor-pointer rounded-xl text-white flex',
                                )}
                            >
                                <img
                                    src="/icons/trash-can.svg"
                                    alt="X"
                                    className={'my-auto ml-2 w-4 h-4 '}
                                    onClick={() => {
                                        delete mintOnDemandMap[templateId]
                                        setMintOnDemandMap(mintOnDemandMap)
                                        setChangeCount(changeCount + 1)
                                    }}
                                />
                            </div>
                            {index < Object.keys(mintOnDemandMap).length - 1 ? (
                                <div className={'mr-2'}>,</div>
                            ) : (
                                ''
                            )}
                        </div>
                    ))
                )}
            </div>
            <div className={'mt-4'}>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <div className={'flex w-full justify-end'}>
                        <MainButton
                            className={'w-32 mr-4'}
                            onClick={addMintOnDemand}
                        >
                            {t('editor.save_changes')}
                        </MainButton>
                        <MainButton className="bg-red-500 " onClick={cancel}>
                            {t('buttons.cancel')}
                        </MainButton>
                    </div>
                )}
            </div>
            {error ? (
                <div className={'mt-4'} onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
            <div className="flex justify-center">
                <EditMintOnDemand
                    collectionName={collectionName}
                    mintOnDemandMap={mintOnDemandMap}
                    setMintOnDemandMap={setMintOnDemandMap}
                    changeCount={changeCount}
                    setChangeCount={setChangeCount}
                />
            </div>
        </Popup>
    )
}

export default CreatePackMintOnDemandPopup
