import DeleteIcon from '@mui/icons-material/Delete'

import { Alert, Collapse } from '@mui/material'
import { Input, Select, Button, SelectItem } from '@nextui-org/react'

import {
    selectStyles,
    inputStyles,
    buttonSearchCommonStyles,
} from '../common/styles'

import React, { useContext, useEffect, useState } from 'react'
import {
    areCurrenciesInvalid,
    CircularProgressWithLabel,
    hasDuplicateCurrencies,
    isDropPriceInvalid,
} from '../editor/DropCreatorUtils'
import { floatRegEx } from '../editor/EditorComponents'
import { formatPercentage, tokenSortFunc } from '../helpers/FormatLinks'
import { getDropFees, getDropSplits } from '../helpers/WaxApi'
import { Context } from '../waxplorer/Waxplorer'
import { DropPaper } from './DropPaper'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import cn from 'classnames'
import { getDrop } from '../helpers/Api'
import Link from '../common/util/input/Link'

export const DropPriceEditor = ({
    dropId,
    dropPriceCurrencyList,
    setDropPriceCurrencyList,
    payoutRecipients,
    setPayoutRecipients,
    currencies,
    recipientSplits,
    setRecipientSplits,
}) => {
    const [dropFees, setDropFees] = useState(0)
    const [state] = useContext(Context)

    const getRecipientSplitPercentage = (recipientSplits, index) => {
        const total = recipientSplits.reduce((acc, curr) => acc + curr, 0)
        if (total === 0) return 0
        const split = recipientSplits[index]
        const splitPercentage = (split / total) * 100
        return splitPercentage
    }

    const parseDrop = (drop) => {
        if (drop) {
            setPayoutRecipients([drop.price_recipient])
            setRecipientSplits([1])
        }
    }

    const parseDropSplits = (splits) => {
        if (splits) {
            setPayoutRecipients(splits.map((split) => split.account))
            setRecipientSplits(splits.map((split) => split.share))
        } else {
            getDrop(dropId, state).then(parseDrop)
        }
    }

    useEffect(() => {
        getDropFees(state).then(setDropFees)
        if (dropId) {
            getDropSplits(state, dropId).then(parseDropSplits)
        }
    }, [dropId])

    const { t } = useTranslation('common')

    return (
        <DropPaper
            expandable
            id={'DropPriceEditor'}
            title={t('drops.price_and_payment_details')}
            error={
                isDropPriceInvalid(dropPriceCurrencyList, payoutRecipients) !==
                null
            }
        >
            {dropPriceCurrencyList.map((priceCurrency, index) => (
                <div key={index + '_price'}>
                    <div className="w-full flex flex-row my-5 md:gap-4 gap-2">
                        <div className="max-w-80">
                            <Input
                                size="sm"
                                classNames={{
                                    ...inputStyles,
                                }}
                                variant="faded"
                                label={t('drops.price_per_drop')}
                                placeholder="0"
                                defaultValue={priceCurrency.price}
                                required={true}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                isInvalid={
                                    priceCurrency.price < 0 ? true : false
                                }
                                onChange={(e) => {
                                    const tempList = [...dropPriceCurrencyList]
                                    tempList[index].price = Math.max(
                                        0,
                                        e.target.value,
                                    )
                                    setDropPriceCurrencyList(tempList)
                                }}
                                helperText={`${t(
                                    'drops.the_amount_user_pays_per_drop',
                                )} ${
                                    dropPriceCurrencyList.length === 1
                                        ? t('drops.0_is_free_to_claim')
                                        : ''
                                }.`}
                            />
                        </div>
                        <div className="w-60">
                            <Select
                                size="sm"
                                scrollShadowProps={{ hideScrollBar: false }}
                                id="DropdownField10"
                                variant="faded"
                                placeholder={t('drops.select_a_currency')}
                                classNames={{
                                    ...selectStyles,
                                }}
                                defaultSelectedKeys={
                                    new Set([
                                        priceCurrency.currency !== '8,USD,USD'
                                            ? priceCurrency.currency
                                            : '8,WAX,USD',
                                    ])
                                }
                                label={t('search.currency')}
                                onChange={(e) => {
                                    const tempList = [...dropPriceCurrencyList]
                                    tempList[index].currency = e.target.value
                                    setDropPriceCurrencyList(tempList)
                                }}
                                isInvalid={
                                    hasDuplicateCurrencies(
                                        dropPriceCurrencyList,
                                    )
                                        ? true
                                        : false
                                }
                            >
                                {currencies
                                    .sort(tokenSortFunc)
                                    .map((currency) =>
                                        dropPriceCurrencyList.length === 1 ||
                                        (dropPriceCurrencyList.length > 1 &&
                                            currency.label !== 'USD') ? (
                                            <SelectItem
                                                key={
                                                    currency.symbol +
                                                    ',' +
                                                    currency.label
                                                }
                                                value={
                                                    currency.symbol +
                                                    ',' +
                                                    currency.label
                                                }
                                            >
                                                {`${currency.label} (${currency.contract})`}
                                            </SelectItem>
                                        ) : null,
                                    )}
                            </Select>
                        </div>
                        <Collapse in={dropPriceCurrencyList.length > 1}>
                            <div className="my-auto">
                                <Button
                                    radius="sm"
                                    size="lg"
                                    isIconOnly
                                    color="danger"
                                    variant="faded"
                                    className={cn(
                                        '!w-[48px] h-[48px] border-2 mr-2',
                                        buttonSearchCommonStyles,
                                    )}
                                    aria-label="delete"
                                    onClick={() => {
                                        const tempDropPriceCurrencyList = [
                                            ...dropPriceCurrencyList,
                                        ]
                                        tempDropPriceCurrencyList.splice(
                                            index,
                                            1,
                                        )
                                        setDropPriceCurrencyList(
                                            tempDropPriceCurrencyList,
                                        )
                                    }}
                                >
                                    <DeleteIcon />
                                </Button>
                            </div>
                        </Collapse>
                    </div>
                    <div className={'mb-4'}>
                        <Alert severity={'info'}>
                            {t('airdrops.token_not_found_visit')}:{' '}
                            <Link
                                href={
                                    'https://discord.com/channels/806257237705621514/905191562990977024'
                                }
                                external={true}
                            >
                                <div
                                    className={
                                        'my-auto text-primary group-hover:underline'
                                    }
                                >
                                    https://discord.com/channels/806257237705621514/905191562990977024
                                </div>
                            </Link>
                        </Alert>
                    </div>
                </div>
            ))}
            <Collapse
                in={
                    dropPriceCurrencyList[0].price > 0 &&
                    isDropPriceInvalid(
                        dropPriceCurrencyList,
                        payoutRecipients,
                    ) === null
                }
            >
                <div className="mb-5">
                    <Alert severity="info">
                        {dropPriceCurrencyList.map((priceCurrency) => {
                            if (
                                ['8,WAX,WAX', '8,WAX,USD'].includes(
                                    priceCurrency.currency,
                                )
                            )
                                return (
                                    <div key={priceCurrency.currency + '_item'}>
                                        {`${t('drops.default_drop_fee_x', {
                                            x: formatPercentage(dropFees),
                                        })}: ${t(
                                            'drops.x_will_go_to_honey_stakers',
                                            {
                                                x: `${(
                                                    priceCurrency.price *
                                                    dropFees *
                                                    (1 / 3)
                                                ).toPrecision(8)} ${
                                                    priceCurrency.currency &&
                                                    priceCurrency.currency.split(
                                                        ',',
                                                    ).length > 2
                                                        ? priceCurrency.currency.split(
                                                              ',',
                                                          )[2]
                                                        : ''
                                                }`,
                                            },
                                        )}`}
                                    </div>
                                )
                        })}
                    </Alert>
                </div>
            </Collapse>
            <Collapse in={dropPriceCurrencyList[0].currency === '8,WAX,USD'}>
                <div className="mb-5">
                    <Alert severity="info">
                        {t('drops.currency_usd_cannot_be_combined')}
                    </Alert>
                </div>
            </Collapse>
            <Collapse
                in={
                    dropPriceCurrencyList.at(-1).price > 0 &&
                    dropPriceCurrencyList[0].currency !== '8,WAX,USD'
                }
            >
                <div className="mb-5">
                    <MainButton
                        fullWidth={false}
                        disabled={
                            areCurrenciesInvalid(
                                dropPriceCurrencyList,
                                payoutRecipients,
                            ) !== null
                        }
                        onClick={() => {
                            const tempList = [...dropPriceCurrencyList]
                            tempList.push({
                                price: 0,
                                currency: '8,WAX,WAX',
                            })
                            setDropPriceCurrencyList(tempList)
                        }}
                    >
                        {t('drops.add_more_currencies')}
                    </MainButton>
                </div>
            </Collapse>
            <div>
                <div className="mb-2">
                    {t('drops.the_recipients_of_the_payment_for_each_drop')}
                </div>
                {payoutRecipients &&
                    payoutRecipients.map((recipient, i) => (
                        <div
                            key={'recipient_' + i}
                            className="flex flex-row md:gap-4 gap-2 items-center"
                        >
                            <div>
                                <Input
                                    size="sm"
                                    classNames={{
                                        ...inputStyles,
                                    }}
                                    variant="faded"
                                    label={t('drops.payout_to')}
                                    value={recipient}
                                    required={true}
                                    isInvalid={
                                        !recipient ||
                                        recipient.length === 0 ||
                                        payoutRecipients.length === 0
                                    }
                                    onChange={(e) => {
                                        const tempPayoutRecipients = [
                                            ...payoutRecipients,
                                        ]
                                        tempPayoutRecipients[i] = e.target.value
                                        setPayoutRecipients(
                                            tempPayoutRecipients,
                                        )
                                    }}
                                />
                            </div>
                            <Collapse in={payoutRecipients.length > 1}>
                                <div className="flex md:flex-wrap py-2 md:gap-4 gap-2 flex-nowrap items-center justify-center">
                                    <div className="w-20">
                                        <Input
                                            size="sm"
                                            classNames={{
                                                ...inputStyles,
                                            }}
                                            variant="faded"
                                            label="Receives"
                                            value={recipientSplits[i]}
                                            required={true}
                                            fullWidth={false}
                                            isInvalid={
                                                !floatRegEx.test(
                                                    recipientSplits[i],
                                                )
                                            }
                                            onChange={(e) => {
                                                const tempRecipientSplits = [
                                                    ...recipientSplits,
                                                ]
                                                tempRecipientSplits[i] = Number(
                                                    e.target.value,
                                                )
                                                setRecipientSplits(
                                                    tempRecipientSplits,
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <CircularProgressWithLabel
                                            value={getRecipientSplitPercentage(
                                                recipientSplits,
                                                i,
                                            )}
                                        />
                                    </div>
                                    <div className="">
                                        <Button
                                            radius="sm"
                                            size="lg"
                                            isIconOnly
                                            color="danger"
                                            variant="faded"
                                            className={cn(
                                                '!w-[48px] h-[48px] border-2',
                                                buttonSearchCommonStyles,
                                            )}
                                            aria-label="delete"
                                            onClick={() => {
                                                const tempPayoutRecipients = [
                                                    ...payoutRecipients,
                                                ]
                                                tempPayoutRecipients.splice(
                                                    i,
                                                    1,
                                                )
                                                setPayoutRecipients(
                                                    tempPayoutRecipients,
                                                )
                                                const tempRecipientSplits = [
                                                    ...recipientSplits,
                                                ]
                                                tempRecipientSplits.splice(i, 1)
                                                setRecipientSplits(
                                                    tempRecipientSplits,
                                                )
                                            }}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    ))}
                <div className="py-2">
                    <MainButton
                        fullWidth={false}
                        onClick={() => {
                            const tempPayoutRecipients = [...payoutRecipients]
                            tempPayoutRecipients.push('')
                            setPayoutRecipients(tempPayoutRecipients)
                            const tempRecipientSplits = [...recipientSplits]
                            tempRecipientSplits.push(1)
                            setRecipientSplits(tempRecipientSplits)
                        }}
                        disabled={
                            isDropPriceInvalid(
                                dropPriceCurrencyList,
                                payoutRecipients,
                            ) !== null
                        }
                    >
                        Add Recipient
                    </MainButton>
                </div>

                <Collapse
                    in={
                        isDropPriceInvalid(
                            dropPriceCurrencyList,
                            payoutRecipients,
                        ) !== null
                    }
                >
                    <div className="my-5">
                        <Alert severity="error">
                            {isDropPriceInvalid(
                                dropPriceCurrencyList,
                                payoutRecipients,
                            )}
                        </Alert>
                    </div>
                </Collapse>
            </div>
        </DropPaper>
    )
}
