import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { Button, Tooltip } from '@nextui-org/react'
import { create } from 'ipfs-http-client'
import { useState } from 'react'

export const ImageUploadButton = ({
    setIpfsHash,
    setError,
    setProgressPercentage = (e) => {
        console.log(e)
    },
    setIsUploading,
    allowVideo = false,
    id = 'upload-button',
    disabled = false,
}) => {
    const ONE_MB = 1048576
    const MAX_FILE_SIZE_IN_MB = 10
    const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MB * ONE_MB // 10MB

    const client = create({ url: 'https://ipfs.hivebp.io/api/v0' })

    const [isLoading, setIsLoading] = useState(false)

    const setProgress = (transferred, fileSize) => {
        setProgressPercentage((transferred / fileSize) * 100)
    }

    const handleUpload = async (e) => {
        const file = e.target.files[0]
        if (!file) {
            // User closed file picker
            return
        }
        setError(null)
        setIpfsHash(null)
        setProgressPercentage(0)
        if (file.size > MAX_FILE_SIZE) {
            setError(
                `The size of the file ${file.name} exceeds the ${MAX_FILE_SIZE_IN_MB}MB limit`,
            )
            return
        }
        try {
            setIsLoading(true)
            setIsUploading(true)
            const added = await client.add(file, {
                progress: (transferred) => setProgress(transferred, file.size),
            })
            const hash = added.path
            setIpfsHash(hash)
            setIsLoading(false)
            setIsUploading(false)
        } catch (error) {
            setError(error.message ? error.message : JSON.stringify(error))
            setIsLoading(false)
            setIsUploading(false)
        }
    }

    return (
        <div>
            <input
                accept={allowVideo ? 'image/*,video/*' : 'image/*'}
                style={{ display: 'none' }}
                id={id}
                onChange={handleUpload}
                type="file"
                disabled={disabled || isLoading}
            />
            <label htmlFor={id}>
                <Button
                    className="w-[45px] h-[45px]"
                    color="primary"
                    as={'span'}
                    isDisabled={disabled || isLoading}
                    isIconOnly
                    size="lg"
                    aria-label="Select file to upload"
                >
                    <Tooltip content="Select file to upload">
                        {isLoading ? (
                            <HourglassEmptyIcon />
                        ) : (
                            <DriveFolderUploadIcon />
                        )}
                    </Tooltip>
                </Button>
            </label>
        </div>
    )
}
