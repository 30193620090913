import CloseIcon from '@mui/icons-material/Close'
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material'
import cn from 'classnames'
import { useState } from 'react'
export const DropAlert = ({
    condition = null,
    severity,
    elementId,
    className,
    children,
    title = null,
    transactionId = null,
    closable = false,
    autoVerticalMargin = true,
}) => {
    const [open, setOpen] = useState(true)

    return (
        <Collapse
            in={(condition && open) || (condition === null && closable && open)}
        >
            <div
                className={cn(
                    'text-left',
                    autoVerticalMargin ? 'my-1' : '',
                    className,
                )}
            >
                <Alert
                    sx={{
                        '.MuiAlert-message': {
                            width: '100%',
                        },
                    }}
                    className={
                        elementId ? 'cursor-pointer font-sans' : 'font-sans'
                    }
                    severity={severity}
                    onClick={() => {
                        if (elementId) {
                            const element = document.getElementById(elementId)
                            element.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                            })
                        }
                    }}
                    action={
                        closable ? (
                            <IconButton
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false)
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        ) : null
                    }
                >
                    {title !== null ? (
                        <AlertTitle className="font-sans">{title}</AlertTitle>
                    ) : null}
                    {children}
                    {transactionId !== null ? (
                        <div>
                            Transaction ID:{' '}
                            <a
                                className="font-mono hover:underline break-all"
                                href={
                                    (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                        ? 'https://testnet.waxblock.io/transaction/'
                                        : 'https://waxblock.io/transaction/') +
                                    transactionId
                                }
                                target="_blank"
                            >
                                {transactionId}
                            </a>
                        </div>
                    ) : null}
                </Alert>
            </div>
        </Collapse>
    )
}
