import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    useCreateBoostAction,
    useCreateBurnAction,
    useTransaction,
} from '../../hooks/waxActions'
import MainButton from '../common/util/input/MainButton'
import Popup from './Popup'
import { Card } from '@nextui-org/react'
import Link from '../common/util/input/Link'
import { useSharedState } from '../waxplorer/Waxplorer'
const BurnPopup = ({ asset, callBack, closeCallBack }) => {
    const { t } = useTranslation('common')

    const { name, backedTokens } = asset

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    // this is needed to ensure the ual is available within the action creators
    // the boost action would not need this, but the burn action does.
    // The litte problem fixed here is when we want to burn multiple assets, then we'd have
    // to call useBurnAction(asset[i]) for each asset. This is not possible with
    // hooks. That is why we generate creator functions, than we'd be able to call
    // `const burnActions = assets.map(createBurnAction)` and we have a valid list of
    // actions to burn all assets.
    const createBoostAction = useCreateBoostAction()
    const createBurnAction = useCreateBurnAction()

    // setup a consistent list of actions to be performed
    const actions =
        typeof window !== undefined
            ? useMemo(
                  () => [createBoostAction(), createBurnAction(asset)],
                  [asset],
              )
            : ''

    // create a transaction callback that signs the actions
    const startBurn = useTransaction(actions, async (error, result) => {
        // now we convert the result into the callBack format
        // the result is in the form of a promise, when we want to wait we
        // could just `await result`.
        closeCallBack()

        const res = await result

        callBack({
            burned: error === undefined,
            asset,
            transactionId: res?.response?.transaction_id,
            tx: res,
            error: error ? error.message : '',
        })
    })

    const cancel = () => {
        callBack({
            burned: false,
            asset,
        })
        closeCallBack()
    }

    return (
        <Popup
            title={t('popups.burn.title', { assetName: name })}
            asset={asset}
            cancel={cancel}
        >
            <div className="mx-auto mb-4 mr-4 text-xl font-bold">
                {t('popups.burn.question', { assetName: name })}
            </div>
            {backedTokens ? (
                <>
                    <div className="mx-auto mb-4 mr-4 text-xl font-bold">
                        {t('popups.burn.backed_info', { x: backedTokens })}
                    </div>
                    <div className="mx-auto mb-4 mr-4 text-xl font-bold">
                        {t('popups.burn.burn_info_backed')}
                    </div>
                    <Link
                        href={`/user/${userName}?view=wallet`}
                        external={true}
                    >
                        <div className="mx-auto mb-4 mr-4 text-xl font-bold text-primary hover:underline">
                            Wallet
                        </div>
                    </Link>
                </>
            ) : (
                ''
            )}
            <Card className="p-4">
                <div className="flex justify-center mx-auto mb-4 text-xl font-bold">
                    <img
                        className={'h-5 mx-2'}
                        src={'/Warning_icn.svg'}
                        alt={'Warning'}
                    />
                    <div className={'text-sm text-primary'}>
                        {t('popups.burn.explanation', { assetName: name })}
                    </div>
                </div>
            </Card>
            <div className="flex justify-start flex-1 gap-2 mt-4">
                <MainButton onClick={cancel} className="bg-red-500 ">
                    {t('asset.cancel')}
                </MainButton>
                <MainButton onClick={startBurn}>{t('asset.burn')}</MainButton>
            </div>
        </Popup>
    )
}

export default BurnPopup
