import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function CollectionCategoryPopup(props) {
    const closeCallBack = props.closeCallBack
    const collectionName = props.collectionName

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const { t } = useTranslation('common')

    const suggest = props.suggest

    const [categoryId, setCategoryId] = useState(null)

    const categoryMappings = {
        1: t('collection_category.1'),
        2: t('collection_category.2'),
        3: t('collection_category.3'),
        4: t('collection_category.4'),
        5: t('collection_category.5'),
        6: t('collection_category.6'),
        7: t('collection_category.7'),
    }

    const cancel = () => {
        closeCallBack()
        callBack({ categorySet: false, error: null })
    }

    const save = async () => {
        const actions = [getBoostAction(activeUser)]

        closeCallBack()

        if (suggest) {
            actions.push({
                account: 'clltncattool',
                name: 'suggestcat',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    collection_name: collectionName,
                    category_id: categoryId,
                },
            })
        } else {
            actions.push({
                account: 'clltncattool',
                name: 'setcatcol',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    collection_name: collectionName,
                    category_id: categoryId,
                },
            })
        }

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({
                categorySet: categoryMappings[categoryId],
                error: null,
            })
        } catch (e) {
            callBack({ categorySet: false, error: e.message })
            console.error(e)
        }
    }

    return (
        <Popup
            cancel={cancel}
            title={
                suggest
                    ? `Suggest a category for the ${collectionName} collection`
                    : `Select your desired category for the ${collectionName} collection`
            }
            className="h-auto"
        >
            <div className={cn('block h-full')}>
                <div className={'w-1/2 mx-auto'}>
                    <FormControl fullWidth size="small">
                        <InputLabel>Categories</InputLabel>
                        <Select
                            label="Categories"
                            value={categoryId}
                            onChange={(e) => setCategoryId(e.target.value)}
                        >
                            {Object.entries(categoryMappings).map(
                                ([key, value]) => (
                                    <MenuItem value={key}>{value}</MenuItem>
                                ),
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className={'flex justify-center mt-5'}>
                    <MainButton onClick={cancel}>
                        {t('asset.cancel')}
                    </MainButton>
                    <MainButton
                        onClick={save}
                        disabled={!categoryId ? 'disabled' : ''}
                    >
                        Save
                    </MainButton>
                </div>
            </div>
        </Popup>
    )
}

export default CollectionCategoryPopup
