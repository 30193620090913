import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, SelectItem } from '@nextui-org/react'
import MarketPreviewDetailsTable from '../assetpreview/MarketPreviewDetailsTable'
import MainButton from '../common/util/input/MainButton'
import { get } from '../helpers/Api'
import { Card } from '@nextui-org/react'
import {
    formatUSD,
    formatWAX,
    getAttributeNames,
    getDefaultAttrNames,
} from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import AssetPreviewDisplay from '../assetpreviewdisplay/AssetPreviewDisplay'
import config from '../../config.json'
import { useSharedState } from '../waxplorer/Waxplorer'

function SellOfferPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const { author, schema, name, image, lowest, offer, usd_wax } = asset
    const usd_offer = offer * usd_wax

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [collectionFee, setCollectionFee] = useState(null)
    const [assetToSell, setAssetToSell] = useState(null)

    const [myAssets, setMyAssets] = useState(asset.myAssets)

    const [attrNames, setAttrNames] = useState(getDefaultAttrNames())

    const getCollectionFee = async (author) => {
        get('collection-fee/' + author).then((result) =>
            setCollectionFee(result['fee']),
        )
    }

    const { orderId } = asset

    const parseAssets = (res) => {
        if (res) setMyAssets(res)
    }

    const getMyAssets = async () => {
        get(
            `buy-offer-assets?seller=${userName}&offer_id=${asset.orderId}`,
        ).then(parseAssets)
    }

    useEffect(() => {
        getCollectionFee(author)
        getMyAssets()
        const controller = new AbortController()
        getAttributeNames(author, schema, controller.signal).then((result) => {
            if (result && !('errors' in result)) setAttrNames(result)
        })
    }, [])

    const create = async () => {
        if (!assetToSell) return
        const quantity = parseFloat(offer)

        closeCallBack()
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        actions.push({
            account: 'atomicassets',
            name: 'createoffer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                sender: userName,
                recipient: 'atomicmarket',
                sender_asset_ids: [assetToSell],
                recipient_asset_ids: [],
                memo: 'tbuyoffer',
            },
        })

        actions.push({
            account: 'atomicmarket',
            name: 'fulfilltbuyo',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                asset_id: assetToSell,
                buyoffer_id: orderId,
                expected_price: `${quantity.toFixed(8)} WAX`,
                seller: userName,
                taker_marketplace: 'nft.hive',
            },
        })

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({
                buyOfferId: orderId,
                sold: true,
                market: 'waxbuyoffers',
                offer: quantity,
            })
        } catch (e) {
            console.error(e)
            callBack({
                buyOfferId: orderId,
                sold: false,
                error: e.message,
            })
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    let cut = asset && offer ? offer - 0.03 * offer : 0
    if (collectionFee && asset && offer) cut = cut - collectionFee * offer

    const cancel = () => {
        callBack({
            buyOfferId: orderId ? orderId : buyOfferId,
            sold: false,
            market: null,
            offer: 0,
        })
        closeCallBack()
    }

    const assetOptions = []

    myAssets &&
        myAssets
            .sort((a, b) => (a.mint && b.mint ? b.mint - a.mint : 0))
            .map((asset) => {
                assetOptions.push({
                    value: asset['assetId'],
                    label: `${asset['assetId']}: Mint #${asset['mint']}`,
                })
            })

    const selectAssetToSell = (e) => {
        setAssetToSell(parseInt(e.target.value))
    }

    return (
        <Popup title={name} image={image} cancel={cancel} asset={asset}>
            <Card className="p-2">
                <div className="relative flex justify-start w-full mb-4">
                    <MarketPreviewDetailsTable
                        asset={asset}
                        newOwner={null}
                        bundleView={false}
                        currentAsset={0}
                        attrNames={attrNames}
                        className="none"
                    />
                </div>
            </Card>
            {(lowest && offer < lowest / 3) ||
            (lowest && offer < lowest / 3) ? (
                <div
                    className={
                        'w-full flex flex-col  flex-wrap border border-red-500 bg-primaryt2 text-start  rounded mx-auto p-2 my-2'
                    }
                >
                    <div>
                        <img
                            className="items-start w-full h-5 md:w-5"
                            src="/Warning_icn.svg"
                            alt="!"
                        />
                    </div>
                    <div>
                        <br />
                        Warning! This Offer is significantly lower than the
                        Median / Floor Price.
                    </div>
                </div>
            ) : (
                ''
            )}
            <div className={cn('text-start  text-base m-4')}>
                <div>
                    {t('asset.accept_this_offer_for_x', {
                        x: formatWAX(offer),
                    })}
                </div>
                <div>{t('asset.select_matching_asset_to_sell')}</div>
            </div>
            <div className={'w-2/3'}>
                <Select
                    variant="faded"
                    onChange={selectAssetToSell}
                    placeholder={t('buttons.select')}
                >
                    {assetOptions.length > 0 ? (
                        assetOptions?.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))
                    ) : (
                        <SelectItem key="no_assets" value="" isDisabled={true}>
                            {t('asset.no_assets_available')}
                        </SelectItem>
                    )}
                </Select>
            </div>
            {assetToSell && (
                <div className={cn('text-center text-base m-4')}>
                    {t('asset.sell_asset_x_for_y', {
                        x: `${assetToSell} (
                        ${
                            myAssets.filter((a) => a.assetId === assetToSell)
                                .length > 0 &&
                            myAssets.filter((a) => a.assetId === assetToSell)[0]
                                .name
                        }
                        )`,
                        y: `${formatWAX(offer)}/${formatUSD(usd_offer)}?`,
                    })}
                    <br />
                </div>
            )}

            {collectionFee || collectionFee === 0 ? (
                <div className={'h-20'}>
                    {collectionFee || collectionFee === 0 ? (
                        <div className="grid w-full gap-2 pt-4 mt-4 grid-col-2 md:grid-cols-4 ">
                            <div className="flex flex-col gap-2">
                                <div className="text-medium">
                                    {t('popups.market_fee')}:{' '}
                                </div>
                                <div className="">2%</div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-medium">
                                    {t('popups.defi_fee')}:
                                </div>
                                <div className="">1%</div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-medium">{`${t(
                                    'popups.collection_fee',
                                )}: `}</div>
                                <div className="">
                                    {`
                            ${collectionFee * 100}%`}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-medium">
                                    {t('popups.your_cut')}:{' '}
                                </div>
                                <div className="">{cut} WAX</div>
                            </div>
                        </div>
                    ) : (
                        <LoadingIndicator />
                    )}
                </div>
            ) : (
                <LoadingIndicator />
            )}
            {error ? <ErrorMessage error={error} /> : ''}
            <div className="my-5 mt-4 text-start">
                <MainButton
                    fullWidth={false}
                    disabled={!assetToSell}
                    onClick={create}
                >
                    {t('trade.accept')}
                </MainButton>
            </div>

            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default SellOfferPopup
