import cn from 'classnames'
import React from 'react'
import ImageElement from '../imageelement/ImageElement'
import VideoElement from '../videoelement/VideoElement'
import config from '../../config.json'

export const AssetPreviewDisplay = ({
    image,
    backImg,
    prevImg,
    video,
    className = cn(''),
    imgClassName = cn('max-h-full'),
    previewDuration = 1,
    useGradient = true,
    grayScale = false,
    fetchPriority = 'low',
    disableAspectRatio,
    name,
    mdata,
}) => {
    if (!image && !prevImg && !video && mdata) {
        try {
            const data = JSON.parse(mdata)
            for (let imgName of ['Image', 'Front', 'Display', 'Cover']) {
                if (data && Object.keys(data).includes(imgName)) {
                    image = data[imgName].includes('http')
                        ? data[imgName]
                        : `${config.ipfs}${data[imgName]}`
                }
            }
        } catch (e) {}
    }

    return (
        <div className={'w-full h-full'}>
            {video && (!prevImg || !prevImg.includes('preview?collection')) ? (
                <VideoElement
                    className={cn(imgClassName, { grayscale: grayScale })}
                    image={prevImg}
                    backimg={backImg}
                    video={prevImg ? prevImg : video}
                    autoPlay={true}
                    timeout={previewDuration * 1000}
                    useGradient={useGradient}
                    fetchPriority={fetchPriority}
                />
            ) : prevImg || image ? (
                <ImageElement
                    name={name}
                    src={prevImg ? prevImg : image}
                    useThumbnail={prevImg !== undefined}
                    className={className}
                    imgClassName={cn('', imgClassName, {
                        grayscale: grayScale,
                    })}
                    useGradient={useGradient}
                    fetchPriority={fetchPriority}
                    disableAspectRatio={disableAspectRatio}
                />
            ) : null}
        </div>
    )
}

export default AssetPreviewDisplay
