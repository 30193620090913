import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../common/util/input/Link'
import { performLogin } from '../helpers/WaxApi'
import NotificationDialog from './NotificationDialog'
import { ProfileBottomMenu } from './ProfileBottomMenu'
import SearchMobileModal from './SearchMobileModal'
import { useSharedState } from '../waxplorer/Waxplorer'
import { useTheme } from 'next-themes'

const BottomNavigationItem = ({ item }) => {
    return (
        <Link href={item.href}>
            <div className="flex items-center justify-center">
                <div className="flex flex-col items-center justify-center rounded-md text-primary">
                    <img
                        src={
                            item.active
                                ? item.activeIcon
                                : item.inactiveIconDark
                        }
                        className={'h-5 w-5 hidden dark:block'}
                        aria-hidden="true"
                        alt={item.text}
                    />
                    <img
                        src={
                            item.active
                                ? item.activeIcon
                                : item.inactiveIconLight
                        }
                        className={'h-5 w-5 block dark:hidden'}
                        aria-hidden="true"
                        alt={item.text}
                    />
                    <p
                        className={cn('text-[12px]', {
                            'text-primary': item.active,
                            'dark:!text-neutral text-zinc-900': !item.active,
                        })}
                    >
                        {item.text}
                    </p>
                </div>
            </div>
        </Link>
    )
}

function BottomNavigation() {
    const { t } = useTranslation('common')
    const { theme } = useTheme()

    const [state, , { login, logout }] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const [profileMenuOpen, setProfileMenuOpen] = useState(false)
    const [notificationMenuOpen, setNotificationMenuOpen] = useState(false)
    const [searchOpen, setSearchOpen] = useState(false)

    const router = useRouter()
    const location = router.asPath.substring(
        0,
        router.asPath.indexOf('?') > 0
            ? router.asPath.indexOf('?')
            : router.asPath.length,
    )

    const items = [
        {
            activeIcon: '/icons/house-primary.svg',
            inactiveIconDark: '/icons/house-neutral.svg',
            inactiveIconLight: '/icons/house-black.svg',
            text: t('navigation.home'),
            active: location === '/',
            href: '/',
        },
        {
            activeIcon: '/icons/shop-primary.svg',
            inactiveIconLight: '/icons/shop-black.svg',
            inactiveIconDark: '/icons/shop-neutral.svg',
            text: t('landing.market_title'),
            active: location.includes('/market'),
            href: '/market',
        },
    ]

    return (
        <div className="fixed bottom-0 flex lg:hidden h-14 w-full bg-white dark:!bg-zinc-900 border border-t border:zinc-200 dark:!border-zinc-800">
            <div className="grid grid-cols-5 gap-2 w-full py-1 px-4 items-end">
                {items.map((item, index) => (
                    <BottomNavigationItem
                        item={item}
                        key={'BottomNavItem' + index}
                    />
                ))}
                <SearchMobileModal
                    searchOpen={searchOpen}
                    setSearchOpen={setSearchOpen}
                />
                {userName ? (
                    <>
                        <div className="flex items-center justify-center">
                            <button
                                className="flex flex-col items-center justify-center rounded-md text-[#FFC30B]"
                                onClick={() => {
                                    setNotificationMenuOpen(
                                        !notificationMenuOpen,
                                    )
                                }}
                            >
                                <img
                                    src={
                                        notificationMenuOpen
                                            ? '/icons/bell-primary.svg'
                                            : '/icons/bell-black.svg'
                                    }
                                    className={'h-5 w-5 block dark:hidden'}
                                    aria-hidden="true"
                                    alt={t('navigation.search')}
                                />
                                <img
                                    src={
                                        notificationMenuOpen
                                            ? '/icons/bell-primary.svg'
                                            : '/icons/bell-neutral.svg'
                                    }
                                    className={'h-5 w-5 hidden dark:block'}
                                    aria-hidden="true"
                                    alt={t('navigation.search')}
                                />
                                <p
                                    className={cn('text-[12px]', {
                                        'text-primary': notificationMenuOpen,
                                        'dark:!text-neutral text-zinc-900':
                                            !notificationMenuOpen,
                                    })}
                                >
                                    {t('navigation.notifications')}
                                </p>
                            </button>
                        </div>
                        <NotificationDialog
                            notificationMenuOpen={notificationMenuOpen}
                            setNotificationMenuOpen={setNotificationMenuOpen}
                        />
                    </>
                ) : (
                    <div />
                )}
                {userName ? (
                    <div className="flex items-center justify-center">
                        <button
                            className="flex flex-col items-center justify-center rounded-md text-[#FFC30B]"
                            onClick={() => {
                                if (!profileMenuOpen) setProfileMenuOpen(true)
                            }}
                        >
                            <img
                                src={
                                    profileMenuOpen
                                        ? '/icons/user-primary.svg'
                                        : theme === 'light'
                                        ? '/icons/user-black.svg'
                                        : '/icons/user-neutral.svg'
                                }
                                className={'h-5 w-5'}
                                aria-hidden="true"
                                alt={t('navigation.search')}
                            />
                            <p
                                className={cn('text-[12px]', {
                                    'text-primary': profileMenuOpen,
                                    'dark:!text-neutral text-zinc-900':
                                        !profileMenuOpen,
                                })}
                            >
                                {t('navigation.profile')}
                            </p>
                        </button>
                    </div>
                ) : (
                    <div className="flex items-center justify-center">
                        <button
                            className="flex flex-col items-center justify-center rounded-md text-[#FFC30B]"
                            onClick={() => login()}
                        >
                            <PersonOutlineIcon className="w-5 h-5" />
                            <p className="text-[12px]">Login</p>
                        </button>
                    </div>
                )}
                <ProfileBottomMenu
                    profileMenuOpen={profileMenuOpen}
                    setProfileMenuOpen={setProfileMenuOpen}
                    userName={userName}
                    logout={logout}
                />
            </div>
        </div>
    )
}

export default BottomNavigation
