import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEventListener } from '../../hooks'
import get, { getAtomicAsset } from '../helpers/Api'
import { getAttributeNames, getDefaultAttrNames } from '../helpers/FormatLinks'
import { MarketInfoDetails2 } from '../marketinfo/MarketInfoDetails2'
import { AssetPreviewImage } from './AssetPreviewImage'
import MarketPreviewDetailsTable from './MarketPreviewDetailsTable'

export const AssetPreviewInfo = ({
    cancel,
    asset,
    newOwner,
    currentAsset,
    bundleView,
    setPriceInfo,
    priceInfo,
    buttons,
}) => {
    const [attrNames, setAttrNames] = useState(getDefaultAttrNames())
    const [atomicData, setAtomicData] = useState(null)
    const [selectedAsset, setSelectedAsset] = useState(0)

    const { t } = useTranslation('common')

    let {
        assetId,
        templateId,
        assets,
        author,
        schema,
        bundle,
        standard,
        symbol,
        buyer,
    } = asset

    const getPriceInfo = async () => {
        if (!priceInfo && (symbol || assetId || templateId)) {
            const pInfo = await get(
                symbol
                    ? 'price-info-fts/' + symbol
                    : assetId
                    ? 'price-info/' + assetId
                    : 'price-info-template/' + templateId,
            )

            setPriceInfo(pInfo)
        }
    }

    const getAtomicData = () => {
        const controller = new AbortController()
        if (standard === 'atomicassets')
            getAtomicAsset(assetId)
                .then((res) => {
                    res && res.success && setAtomicData(res.data)
                })
                .catch((e) => {
                    console.error(e)
                })
        return () => controller.abort()
    }

    const keyHandler = ({ key }) => {
        const ESCAPE_KEYS = ['27', 'Escape']
        if (ESCAPE_KEYS.includes(String(key))) {
            cancel()
        }
    }

    useEventListener('keydown', keyHandler)

    useEffect(() => {
        if (!priceInfo || !atomicData) {
            getAtomicData()
            getPriceInfo()
            const controller = new AbortController()
            getAttributeNames(author, schema, controller.signal).then(
                (result) => {
                    if (result && !('errors' in result)) setAttrNames(result)
                },
            )
        }
    }, [assetId])

    return (
        <div className={cn('absolute flex w-full')}>
            <div
                className={cn(
                    'w-full flex flex-col md:flex-row  justify-center shadow-xl',
                    'backdrop-blur-xl text-xs md:text-sm ',
                    'bg-paperd rounded-sm md:rounded-2xl ',
                )}
            >
                <div className=" grid rounded-l-lg dark:bg-zinc-950 bg-zinc-200 md:w-1/3 place-content-center md:h-full">
                    <AssetPreviewImage
                        asset={assets ? assets[0] : asset}
                        currentAsset={currentAsset}
                    />
                </div>

                <div className="overflow-y-auto md:w-2/3 dark:bg-zinc-900 bg-zinc-100">
                    <div className="mx-5 mt-5 mb-4">
                        <MarketPreviewDetailsTable
                            asset={asset}
                            newOwner={newOwner}
                            bundleView={bundleView}
                            currentAsset={currentAsset}
                            attrNames={attrNames}
                            atomicData={atomicData}
                            showData={false}
                            className="none"
                        />
                        <MarketInfoDetails2
                            asset={asset}
                            priceInfo={priceInfo}
                            currentAsset={currentAsset}
                            assets={assets}
                            bundle={bundle}
                            cancel={cancel}
                            className="my-1 "
                        />
                        {!buyer ? (
                            <div className="h-24 mx-5 mb-4">{buttons}</div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
