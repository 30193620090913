import DeleteIcon from '@mui/icons-material/Delete'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import MainButton from '../common/util/input/MainButton'

function DeletePackReleasePopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const releaseId = props['releaseId']

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            authorized_account: userName,
            collection_name: collectionName,
            release_id: releaseId,
        }

        const action = {
            account: 'nfthivepacks',
            name: 'delrelease',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: data,
        }

        actions.push(action)
        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Delete Release" cancel={cancel}>
            <div className="p-2 ">
                <div className="mt-2 mb-2 text-lg font-bold text-center md:mt-0">
                    Do you want to delete Release {releaseId} ({collectionName}
                    )?
                </div>
                <div>
                    <div className="justify-center my-2 text-lg font-bold text-center lex md:my-4">
                        This Action cannot be undone!
                    </div>
                </div>
                {error ? (
                    <div onClick={dismissError} className="relative h-28">
                        <ErrorMessage layer={5} error={error} />
                    </div>
                ) : (
                    <div className="flex flex-row justify-center">
                        <div className="mx-2">
                            <MainButton
                                className="bg-red-500 "
                                onClick={cancel}
                            >
                                Cancel
                            </MainButton>
                        </div>
                        <div className="mx-2">
                            <MainButton
                                onClick={create}
                                startIcon={<DeleteIcon />}
                            >
                                {t('packs.delete_release')}
                            </MainButton>
                        </div>
                    </div>
                )}

                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default DeletePackReleasePopup
