import { Input } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { TableCell, TableRow, Tooltip } from '@mui/material'
import ImageElement from '../imageelement/ImageElement'
import config from '../../config.json'
import { useTranslation } from 'react-i18next'

export const EditMintOnDemandRow = ({
    template,
    mintOnDemandValue,
    mintOnDemandMap,
    setMintOnDemandMap,
    templateToMaxIssuedSupply,
    changeCount,
    setChangeCount,
}) => {
    const [localMintOnDemandValue, setLocalMintOnDemandValue] =
        useState(mintOnDemandValue)

    const { t } = useTranslation('common')

    useEffect(() => {}, [changeCount])

    return (
        <TableRow>
            <TableCell>
                <Tooltip
                    title={
                        template &&
                        template.img && (
                            <ImageElement
                                src={`${config.ipfs}${template.img.replace(
                                    config.ipfs,
                                    '',
                                )}`}
                            />
                        )
                    }
                    followCursor
                    placement="top"
                >
                    <div>
                        {template.name} (
                        <a
                            className="underline"
                            href={`/template/${template.id}`}
                            target="_blank"
                        >
                            {template.id}
                        </a>
                        )
                    </div>
                </Tooltip>
            </TableCell>
            <TableCell>
                {`${templateToMaxIssuedSupply.get(template.id).issuedSupply}
                                              /
                                              ${
                                                  templateToMaxIssuedSupply.get(
                                                      template.id,
                                                  ).maxSupply === 0
                                                      ? 'Infinite'
                                                      : templateToMaxIssuedSupply.get(
                                                            template.id,
                                                        ).maxSupply
                                              }`}
            </TableCell>
            <TableCell>
                <Input
                    variant="faded"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={parseInt(mintOnDemandMap[template.id])}
                    isInvalid={
                        templateToMaxIssuedSupply.get(template.id).maxSupply >
                            0 &&
                        templateToMaxIssuedSupply.get(template.id).maxSupply -
                            templateToMaxIssuedSupply.get(template.id)
                                .issuedSupply <
                            parseInt(mintOnDemandMap[template.id])
                    }
                    onChange={(e) => {
                        if (e.target.value) {
                            setLocalMintOnDemandValue(parseInt(e.target.value))
                            mintOnDemandMap[template.id] = e.target.value
                            setMintOnDemandMap(mintOnDemandMap)
                        } else {
                            setLocalMintOnDemandValue(null)
                            if (mintOnDemandMap[template.id])
                                delete mintOnDemandMap[template.id]
                            setMintOnDemandMap(mintOnDemandMap)
                        }
                        setChangeCount(changeCount + 1)
                    }}
                    label={t('packs.amount_to_add')}
                />
            </TableCell>
        </TableRow>
    )
}
