import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Select, SelectItem, Button } from '@nextui-org/react'
import MainButton from '../common/util/input/MainButton'

import { getBoostAction } from '../helpers/WaxApi'
import { useSharedState } from '../waxplorer/Waxplorer'
import Popup from './Popup'
import {
    buttonSearchCommonStyles,
    inputStyles,
    selectStyles,
} from '../common/styles'
import {
    getCurrencies,
    hasDuplicateCurrencies,
} from '../editor/DropCreatorUtils'
import { tokenSortFunc } from '../helpers/FormatLinks'
import { Collapse, LinearProgress } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

function BackAssetPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const { assetId, name, image, owner } = asset

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']

    const [dropPriceCurrencyList, setDropPriceCurrencyList] = useState([])
    const [currencies, setCurrencies] = useState(null)

    const parseCurrencies = (res) => {
        setCurrencies(res.filter((curr) => curr.label !== 'USD'))
    }

    useEffect(() => {
        getCurrencies(state).then(parseCurrencies)
    }, [userName])

    const addAssets = async (depositType = 'min') => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const tokens = []

        for (const currency of dropPriceCurrencyList) {
            const split = currency['currency'].split(',')
            const contract = split[3]
            const digits = parseInt(split[0])
            const price = parseFloat(currency.price)
            const symbol = split[1]

            tokens.push({
                quantity: `${price.toFixed(digits)} ${symbol}`,
                token_contract: contract,
            })
        }

        actions.push({
            account: 'waxdaobacker',
            name: 'announcedepo',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                user: userName,
                tokens: tokens,
            },
        })

        for (const token of tokens) {
            actions.push({
                account: token['token_contract'],
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    from: userName,
                    to: 'waxdaobacker',
                    quantity: token['quantity'],
                    memo: 'deposit',
                },
            })
        }

        actions.push({
            account: 'waxdaobacker',
            name: 'backnft',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                asset_id: assetId,
                asset_owner: owner,
                tokens_to_back: tokens,
                user: userName,
            },
        })

        try {
            await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ backed: tokens, error: null })
        } catch (e) {
            callBack({ backed: null, error: e.message })
            console.error(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
            closeCallBack()
        }
    }

    const cancel = () => {
        callBack({ created: false, error: null })
        closeCallBack()
    }

    return (
        <Popup title={name} cancel={cancel} image={image} asset={asset}>
            {dropPriceCurrencyList.map((priceCurrency, index) => (
                <div key={index + '_price'}>
                    <div className="flex flex-row my-5 md:gap-4 gap-2">
                        <div className="w-full">
                            <Input
                                size="sm"
                                classNames={{
                                    ...inputStyles,
                                }}
                                variant="faded"
                                label={t('drops.price_per_drop')}
                                placeholder="0"
                                defaultValue={priceCurrency.price}
                                required={true}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                isInvalid={
                                    priceCurrency.price < 0 ? true : false
                                }
                                onChange={(e) => {
                                    const tempList = [...dropPriceCurrencyList]
                                    tempList[index].price = Math.max(
                                        0,
                                        e.target.value,
                                    )
                                    setDropPriceCurrencyList(tempList)
                                }}
                                helperText={`${t(
                                    'drops.the_amount_user_pays_per_drop',
                                )} ${
                                    dropPriceCurrencyList.length === 1
                                        ? t('drops.0_is_free_to_claim')
                                        : ''
                                }.`}
                            />
                        </div>
                        <div className="w-full">
                            <Select
                                size="sm"
                                scrollShadowProps={{ hideScrollBar: false }}
                                id="DropdownField10"
                                variant="faded"
                                placeholder={t('drops.select_a_currency')}
                                className="w-full"
                                classNames={{
                                    ...selectStyles,
                                }}
                                defaultSelectedKeys={
                                    new Set([
                                        priceCurrency.currency !== '8,USD,USD'
                                            ? priceCurrency.currency
                                            : '8,WAX,USD',
                                    ])
                                }
                                label={t('search.currency')}
                                onChange={(e) => {
                                    const tempList = [...dropPriceCurrencyList]
                                    tempList[index].currency = e.target.value
                                    setDropPriceCurrencyList(tempList)
                                }}
                                isInvalid={
                                    hasDuplicateCurrencies(
                                        dropPriceCurrencyList,
                                    )
                                        ? true
                                        : false
                                }
                            >
                                {currencies
                                    .sort(tokenSortFunc)
                                    .map((currency) =>
                                        dropPriceCurrencyList.length === 1 ||
                                        (dropPriceCurrencyList.length > 1 &&
                                            currency.label !== 'USD') ? (
                                            <SelectItem
                                                key={
                                                    currency.symbol +
                                                    ',' +
                                                    currency.label +
                                                    ',' +
                                                    currency.contract
                                                }
                                                value={
                                                    currency.symbol +
                                                    ',' +
                                                    currency.label +
                                                    ',' +
                                                    currency.contract
                                                }
                                            >
                                                {`${currency.label} (${currency.contract})`}
                                            </SelectItem>
                                        ) : null,
                                    )}
                            </Select>
                        </div>
                        <Collapse in={dropPriceCurrencyList.length > 1}>
                            <div className="my-auto">
                                <Button
                                    radius="sm"
                                    size="lg"
                                    isIconOnly
                                    color="danger"
                                    variant="faded"
                                    className={cn(
                                        '!w-[48px] h-[48px] border-2 mr-2',
                                        buttonSearchCommonStyles,
                                    )}
                                    aria-label="delete"
                                    onClick={() => {
                                        const tempDropPriceCurrencyList = [
                                            ...dropPriceCurrencyList,
                                        ]
                                        tempDropPriceCurrencyList.splice(
                                            index,
                                            1,
                                        )
                                        setDropPriceCurrencyList(
                                            tempDropPriceCurrencyList,
                                        )
                                    }}
                                >
                                    <DeleteIcon />
                                </Button>
                            </div>
                        </Collapse>
                    </div>
                </div>
            ))}
            <Collapse in={true}>
                <div className="mb-5">
                    <MainButton
                        fullWidth={false}
                        disabled={false}
                        onClick={() => {
                            const tempList = [...dropPriceCurrencyList]
                            tempList.push({
                                price: 0,
                                currency: '8,WAX,WAX',
                            })
                            setDropPriceCurrencyList(tempList)
                        }}
                    >
                        {t('asset.add_tokens')}
                    </MainButton>
                </div>
            </Collapse>
            <div
                className={
                    'w-full xl:w-1/2 flex mt-4 xl:mt-0 gap-4 justify-center lg:justify-start'
                }
            >
                {isLoading ? <LinearProgress /> : ''}
                <MainButton
                    size="medium"
                    onClick={cancel}
                    className={
                        'w-32 max-w-32 bg-red-500 lg:w-26 lg:max-w-26 2xl:w-32 2xl:max-w-32'
                    }
                    padding={'pl-0 '}
                    disabled={isLoading}
                >
                    {t('asset.cancel')}
                </MainButton>
                <MainButton
                    size="medium"
                    onClick={addAssets}
                    disabled={dropPriceCurrencyList.length === 0 || isLoading}
                    className={
                        'w-32 max-w-32 lg:w-26 lg:max-w-26 2xl:w-32 2xl:max-w-32'
                    }
                    padding={'pl-0 xl:pl-4'}
                >
                    {t('asset.back_asset_now')}
                </MainButton>
            </div>
        </Popup>
    )
}

export default BackAssetPopup
