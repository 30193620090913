import cn from 'classnames'
import React from 'react'
import { useEventListener } from '../../hooks'
import { AssetPreviewImage } from '../assetpreview/AssetPreviewImage'

export const Popup = ({ children, cancel, title, asset }) => {
    const keyHandler = ({ key }) => {
        const ESCAPE_KEYS = ['27', 'Escape']
        if (ESCAPE_KEYS.includes(String(key))) {
            cancel()
        }
    }

    useEventListener('keydown', keyHandler)

    return (
        <div
            className={cn(
                'w-full flex flex-col md:flex-row justify-center',
                'text-xs md:text-sm ',
                '',
            )}
        >
            {asset ? (
                <div className="flex flex-col dark:bg-zinc-950 bg-zinc-200 md:w-1/3 items-center justify-start">
                    <AssetPreviewImage
                        asset={asset}
                        currentAsset={0}
                        title={title}
                    />
                </div>
            ) : (
                ''
            )}

            <div
                className={cn('h-full overflow-y-auto my-auto ', {
                    'md:w-2/3': asset,
                    'md:w-full': !asset,
                })}
            >
                <div className="mx-2 my-2">
                    <div className="relative p-2 md:p-4 ">{children}</div>
                </div>
            </div>
        </div>
    )
}

export default Popup
