import React, { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import { Card, Input } from '@nextui-org/react'
import MainButton2 from '../common/util/input/MainButton2'
import get from '../helpers/Api'
import { formatNumber } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { PopupImage } from './PopupImage'
import MainButton from '../common/util/input/MainButton'
import { useSharedState } from '../waxplorer/Waxplorer'
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
} from '@nextui-org/react'

const loadPriceInfo = async (args) => await get('price-info/' + args.assetId)

function BidPopup(props) {
    const asset = props['asset']

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null
    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const offer = props['offer']

    const { t } = useTranslation('common')

    const dismissError = () => {
        setError(null)
    }

    const LoadPriceInfo = (assetId) => {
        const { data, error, isLoading } = useAsync({
            promiseFn: loadPriceInfo,
            assetId: assetId,
        })
        if (data) {
            data.isLoading = false
            return data
        }
        return { priceInfo: {}, isLoading: true }
    }

    let { author, orderId, bundle, numBids, market, seller, assets } = asset

    let { assetId, schema, number, rarity, variant, name, image } = assets
        ? assets[0]
        : asset

    const [sellPrice, setSellPrice] = useState(
        !numBids ? offer : offer * (market === 'waxarena3dk1' ? 1.2 : 1.11),
    )

    const validBid = (price) => {
        if (!price) return false
        return (
            price >=
            (!numBids
                ? offer
                : offer * (market === 'waxarena3dk1' ? 1.2 : 1.11))
        )
    }

    const marketplace = market

    const bid = async () => {
        if (!validBid(sellPrice)) {
            setError('Invalid Bid')
            return false
        }
        const quantity = parseFloat(sellPrice)
        const { assetId } = asset
        setIsLoading(true)
        closeCallBack()
        try {
            let result
            switch (marketplace) {
                case 'waxarena3dk1':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'eosio.token',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        to: 'waxarena3dk1',
                                        quantity: `${quantity.toFixed(8)} WAX`,
                                        memo: assetId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'waxinventory':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'eosio.token',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        to: 'waxinventory',
                                        quantity: `${quantity.toFixed(8)} WAX`,
                                        memo:
                                            '{"id":"' +
                                            assetId +
                                            '","type":"bid"}',
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'atomicmarket':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'eosio.token',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        to: 'atomicmarket',
                                        memo: 'deposit',
                                        quantity: `${quantity.toFixed(8)} WAX`,
                                    },
                                },
                                {
                                    account: 'atomicmarket',
                                    name: 'auctionbid',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        auction_id: orderId,
                                        bid: `${quantity.toFixed(8)} WAX`,
                                        bidder: userName,
                                        taker_marketplace:
                                            process.env.NEXT_PUBLIC_TESTNET ===
                                            'TRUE'
                                                ? 'nfthiveboost'
                                                : 'nft.hive',
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'gpk.market':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'eosio.token',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        to: 'gpk.market',
                                        quantity: `${quantity.toFixed(8)} WAX`,
                                        memo: 'Bid for #' + assetId,
                                    },
                                },
                                {
                                    account: 'gpk.market',
                                    name: 'bid',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        quantity: `${quantity.toFixed(8)} WAX`,
                                        assetid: assetId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'market.place':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'eosio.token',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        to: 'market.place',
                                        quantity: `${quantity.toFixed(8)} WAX`,
                                        memo: 'Bid on NFTs | collectables.io',
                                    },
                                },
                                {
                                    account: 'market.place',
                                    name: 'bid',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        account: userName,
                                        listingid: orderId,
                                        price: `${quantity.toFixed(8)} WAX`,
                                        referral: 'waxplorerref',
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
            }
            callBack({ bidPlaced: true, price: quantity })
        } catch (e) {
            setError(e.message)
            callBack({ bidPlaced: false, error: e.message ? e.message : e })
        } finally {
            setIsLoading(false)
        }
    }

    const priceInfo = LoadPriceInfo(assetId)

    const changePrice = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) setSellPrice(val)
    }

    const cancel = () => {
        callBack(false)
    }

    return (
        <Popup title={name} cancel={cancel}>
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                <div className="flex justify-center col-span-1 ">
                    <div className="flex justify-center">
                        <PopupImage image={image} />
                    </div>
                </div>
                <div className="col-span-1 gap-4 ">
                    <div className="flex flex-col justify-center mt-4 lg:justify-start">
                        <div className="relative flex justify-center w-full mb-4 lg:justify-start">
                            <Table
                                hideHeader
                                aria-label="Example static collection table"
                            >
                                <TableHeader>
                                    <TableColumn>test</TableColumn>
                                    <TableColumn>ROLE</TableColumn>
                                </TableHeader>
                                <TableBody className="p-4">
                                    <TableRow key="1">
                                        <TableCell>
                                            <b>{t('asset.schema')}:</b>
                                        </TableCell>
                                        <TableCell>{schema}</TableCell>
                                    </TableRow>
                                    {number && variant ? (
                                        <TableRow key="2">
                                            <TableCell>
                                                {' '}
                                                <b>Card:</b>
                                            </TableCell>
                                            <TableCell>
                                                {number}
                                                {variant}
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        ''
                                    )}

                                    <TableRow key="3">
                                        <TableCell>Jane Fisher</TableCell>
                                        <TableCell>Senior Developer</TableCell>
                                    </TableRow>
                                    <TableRow key="4">
                                        <TableCell>William Howard</TableCell>
                                        <TableCell>Community Manager</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {error ? (
                                <div onClick={dismissError}>
                                    <ErrorMessage error={error} />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <Card className="p-4">
                            <div className="flex justify-center w-full mb-4 font-bold text-md">
                                {t(
                                    'popups.are_you_sure_you_want_to_bid_offer_for_name',
                                    {
                                        offer: `${sellPrice} WAX`,
                                        name: `${name}`,
                                    },
                                )}
                                ?
                            </div>
                            <div className="flex justify-start mb-4">
                                <Input
                                    variant="faded"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    label={t('asset.price')}
                                    onChange={changePrice}
                                    value={sellPrice ? sellPrice : ''}
                                    className="w-full lg:w-2/3"
                                />
                            </div>
                        </Card>

                        <div className="flex justify-start mt-3 mb-4">
                            <div className="mx-2">
                                <MainButton
                                    className="font-bold bg-red-500 rounded-md"
                                    onClick={() => callBack(false)}
                                    varient={'bordered'}
                                >
                                    Cancel
                                </MainButton>
                            </div>
                            {userName !== seller &&
                            [
                                'waxinventory',
                                'gpk.market',
                                'market.place',
                                'atomicmarket',
                                'waxarena3dk1',
                            ].includes(market) ? (
                                <div className="mx-2">
                                    <MainButton
                                        varient={'solid'}
                                        className="font-bold rounded-md text-dark"
                                        onClick={bid}
                                    >
                                        Bid
                                    </MainButton>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        {isLoading ? (
                            <PopupLoadingIndicator
                                text={t('popups.loading_transaction')}
                                isLoading={isLoading}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            <div></div>
        </Popup>
    )
}

export default BidPopup
