import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import config from '../../config.json'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { getAtomicSchemasForCollection } from '../helpers/Api'
import { EditMintOnDemandRow } from './EditMintOnDemandRow'
import { useSharedState } from '../waxplorer/Waxplorer'
import { Accordion, AccordionItem } from '@nextui-org/react'

export const EditMintOnDemand = ({
    collectionName,
    mintOnDemandMap,
    setMintOnDemandMap,
    changeCount,
    setChangeCount,
}) => {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const [schemaTemplates, setSchemaTemplates] = useState(new Map())

    const [isCollectionSchemasLoading, setIsCollectionSchemasLoading] =
        useState(true)

    const [schemaExpanded, setSchemaExpanded] = useState(new Map())

    const [templateToMaxIssuedSupply, setTemplateToMaxIssuedSupply] = useState(
        new Map(),
    )

    const [assetsToBeAddedOrRemoved, setAssetsToBeAddedOrRemoved] = useState(
        new Map(),
    )

    const [amountToBeAddedOrRemoved, setAmountToBeAddedOrRemoved] = useState(
        new Map(),
    )

    const fetchCollectionSchemaTemplates = async (schemaName) => {
        if (!userName || schemaTemplates.get(schemaName).length > 0) {
            return
        }

        const fetchUrl =
            (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.atomictest
                : config.atomic) +
            `/atomicassets/v1/templates?collection_name=${collectionName}&schema_name=${schemaName}&limit=1000&order=asc&sort=created`
        const response = await fetch(fetchUrl)
        const json = await response.json()
        const templateData = json.data
        if (!templateData || templateData.length === 0) {
            return
        }

        const tempTemplateToIssuedMaxSupply = new Map(templateToMaxIssuedSupply)
        for (const template of templateData) {
            const templateId = parseInt(template.template_id)
            const issuedSupply = parseInt(template.issued_supply)
            const maxSupply = parseInt(template.max_supply)
            tempTemplateToIssuedMaxSupply.set(templateId, {
                issuedSupply,
                maxSupply,
            })
        }

        setTemplateToMaxIssuedSupply(tempTemplateToIssuedMaxSupply)

        const tempSchemaTemplates = new Map(schemaTemplates)

        const tempAssetsToBeAddedOrRemoved = new Map(assetsToBeAddedOrRemoved)
        const tempAmountToBeAddedOrRemoved = new Map(amountToBeAddedOrRemoved)

        for (const template of templateData) {
            tempAssetsToBeAddedOrRemoved.set(
                parseInt(template.template_id),
                'add',
            )
            tempAmountToBeAddedOrRemoved.set(parseInt(template.template_id), 0)

            if (tempSchemaTemplates.has(template.schema.schema_name)) {
                tempSchemaTemplates.set(
                    template.schema.schema_name,
                    tempSchemaTemplates
                        .get(template.schema.schema_name)
                        .concat([
                            {
                                id: parseInt(template.template_id),
                                name: template.immutable_data.name,
                                img: template.immutable_data.img,
                                rarity: template.immutable_data.rarity
                                    ? template.immutable_data.rarity
                                    : null,
                                assets: [],
                            },
                        ]),
                )
            } else {
                tempSchemaTemplates.set(template.schema.schema_name, [
                    {
                        id: parseInt(template.template_id),
                        name: template.immutable_data.name,
                        img: template.immutable_data.img,
                        rarity: template.immutable_data.rarity
                            ? template.immutable_data.rarity
                            : null,
                        assets: [],
                    },
                ])
            }
        }

        setAssetsToBeAddedOrRemoved(tempAssetsToBeAddedOrRemoved)
        setAmountToBeAddedOrRemoved(tempAmountToBeAddedOrRemoved)
        setSchemaTemplates(tempSchemaTemplates)
    }

    const fetchCollectionSchemas = async () => {
        if (!userName) {
            return
        }

        const schemaData = await getAtomicSchemasForCollection(collectionName)

        if (!schemaData || schemaData.length === 0) {
            setIsCollectionSchemasLoading(false)
            return
        }

        const tempSchemaTemplates = new Map()
        for (const schema of schemaData) {
            const schemaName = schema.schema_name
            tempSchemaTemplates.set(schemaName, [])
        }
        setSchemaTemplates(tempSchemaTemplates)
        setIsCollectionSchemasLoading(false)
    }

    const toggleCollapseSchema = async (schema) => {
        if (isCollectionSchemasLoading) {
            return
        }
        await fetchCollectionSchemaTemplates(schema)
        const tempSchemaExpanded = new Map(schemaExpanded)
        tempSchemaExpanded.set(schema, !tempSchemaExpanded.get(schema))
        setSchemaExpanded(tempSchemaExpanded)
    }

    useEffect(() => {
        fetchCollectionSchemas()
    }, [collectionName])

    useEffect(() => {}, [changeCount])

    return (
        <div className={'w-full my-3'}>
            <Accordion variant="splitted">
                {Array.from(
                    [...schemaTemplates.entries()].sort(),
                    ([schemaName, templates]) => (
                        <AccordionItem
                            key={schemaName}
                            title={
                                <div className="flex items-center">
                                    <div
                                        className={cn(
                                            'mx-3',
                                            {
                                                'transition transform duration-250 rotate-180':
                                                    schemaExpanded.get(
                                                        schemaName,
                                                    ),
                                            },
                                            {
                                                'transition transform duration-250 rotate-0':
                                                    !schemaExpanded.get(
                                                        schemaName,
                                                    ),
                                            },
                                        )}
                                    >
                                        <img
                                            className="h-full w-full"
                                            src="/collapse.svg"
                                        />
                                    </div>
                                    <div className="text-xl hover:font-bold">
                                        {schemaName}
                                    </div>
                                </div>
                            }
                            //in={schemaExpanded.get(schemaName)}
                            onClick={() => toggleCollapseSchema(schemaName)}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Template</TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title={
                                                    'Global value independent of ownership. ' +
                                                    'Number of assets already minted / maximum possible mints'
                                                }
                                            >
                                                <div>
                                                    issued supply / max suppl
                                                </div>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title={
                                                    'Select how many assets you wish to ' +
                                                    'add to or remove from this pool, per template. ' +
                                                    'Afterwards, scroll to the bottom and select "Update Pool" to confirm your changes'
                                                }
                                            >
                                                <div>
                                                    {t(
                                                        'packs.max_amount_to_mint',
                                                    )}
                                                </div>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {templates.map((template) => (
                                        <EditMintOnDemandRow
                                            template={template}
                                            mintOnDemandValue={
                                                mintOnDemandMap[template.id]
                                                    ? mintOnDemandMap[
                                                          template.id
                                                      ]
                                                    : ''
                                            }
                                            mintOnDemandMap={mintOnDemandMap}
                                            setMintOnDemandMap={
                                                setMintOnDemandMap
                                            }
                                            templateToMaxIssuedSupply={
                                                templateToMaxIssuedSupply
                                            }
                                            changeCount={changeCount}
                                            setChangeCount={setChangeCount}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionItem>
                    ),
                )}
            </Accordion>
        </div>
    )
}
