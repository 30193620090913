import { Modal } from '@mui/material'
import cn from 'classnames'
import React from 'react'
import config from '../../config.json'

const FullImageView = ({ toggleFunc, image }) => {
    if (image.includes('video:')) {
        image = image.replaceAll('video:', '')
        const imgUrl = image.includes('http') ? image : `${config.ipfs}${image}`
        return (
            <Modal
                open={image}
                onClose={toggleFunc}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    className={cn(
                        'absolute w-screen h-screen z-50 m-auto top-0 left-0 backdrop-filter backdrop-blur-xl',
                    )}
                    onClick={toggleFunc}
                >
                    <div className={'flex w-full h-full m-auto'}>
                        <video
                            loop
                            controls
                            autoPlay={true}
                            muted={true}
                            poster={image}
                            className={cn('m-auto w-auto h-auto')}
                        >
                            <source src={imgUrl} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </Modal>
        )
    } else {
        return (
            <Modal
                open={image}
                onClose={toggleFunc}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    className={cn(
                        'absolute w-screen h-screen z-50 m-auto top-0 left-0 backdrop-filter backdrop-blur-xl',
                    )}
                    onClick={toggleFunc}
                >
                    <div className={'flex w-full h-full m-auto'}>
                        <img
                            className={'max-h-full max-w-full m-auto'}
                            src={image}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}

export default FullImageView
