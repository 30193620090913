import i18next from 'i18next'
import qs from 'qs'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { SSRProvider } from 'react-bootstrap'
import { I18nextProvider } from 'react-i18next'
import config from '../../config.json'
import common_de from '../../translations/de/common'
import common_en from '../../translations/en/common'
import common_es from '../../translations/es/common'
import common_jp from '../../translations/jp/common'
import common_ko from '../../translations/ko/common'
import common_zh_hk from '../../translations/zh-hk/common'
import common_zh from '../../translations/zh/common'
import { getCookie, setCookie } from '../helpers/cookies'
import Reducer from '../reducer'
import { WharfKit } from './WharfKit'
import { getAtomicCollection } from '../helpers/Api'

const initialState = {
    asset: null,
    sellPrice: null,
    action: null,
    callBack: null,
    error: null,
    switchedTab: true,
    scrolledDown: false,
    scrollManager: { useManager: false },
    show_banner: true,
    side_navigation_visible: false,
    api: getCookie(
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? 'nfthive-test-api'
            : 'nfthive-api',
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi
            : config.api,
    ),
    selectedAssets: [],
    errorAssets: {},
    transferredAssets: {},
    playAnimations: getCookie('play-animations', 'true') === 'true',
    skipBuyConfirmation: getCookie('skip-buy-confirmation') === 'true',
    actionSearch: {
        actionSearch: '',
        asset: null,
        searchType: '',
        searchID: '',
        includeTubes: true,
        includeDrops: false,
    },
    useDarkMode: false,
    message: undefined,
    isLogout: false,
    user: null,
    activeUser: null,
}

let language = 'en'

if (typeof window !== 'undefined') {
    let values = qs.parse(
        window.location.search.substring(1, window.location.search.length),
    )
    language = values['lang'] ? values['lang'] : null

    if (language) setCookie('language', language)
    else {
        const cookie = getCookie('language')
        if (cookie) language = cookie
    }
}

i18next.init({
    interpolation: { escapeValue: false },
    lng: language ? language : 'en',
    resources: {
        en: {
            common: common_en,
        },
        de: {
            common: common_de,
        },
        es: {
            common: common_es,
        },
        jp: {
            common: common_jp,
        },
        zh: {
            common: common_zh,
        },
        zh_hk: {
            common: common_zh_hk,
        },
        ko: {
            common: common_ko,
        },
    },
})

const Waxplorer = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)

    useEffect(() => {
        const restoreSession = async () => {
            try {
                const session = await WharfKit.getInstance(
                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                        ? 'https://api-test.hivebp.io'
                        : state.api,
                ).loginWallet(true)

                if (!session) return

                dispatch({
                    type: 'login',
                    payload: { session },
                })

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error) {
                console.error(error)
            }
        }

        restoreSession()
    }, [])

    return (
        <SSRProvider>
            <Context.Provider value={[state, dispatch]}>
                <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
            </Context.Provider>
        </SSRProvider>
    )
}

export const useAtomicCollection = (collection) => {
    const [state, dispatch] = useContext(Context)

    const parseCollection = (res) => {
        if (res.success) {
            dispatch({ type: 'SET_COLLECTION_NAME_DATA', payload: res.data })
        }
    }

    useEffect(() => {
        if (
            !state?.collectionNameData ||
            state.collectionNameData.collection_name !== collection
        ) {
            getAtomicCollection(collection).then(parseCollection)
        }
    }, [collection])

    return state?.collectionNameData &&
        state?.collectionNameData.collection_name === collection
        ? state?.collectionNameData
        : null
}

export const useSharedState = () => {
    const [state, dispatch] = useContext(Context)

    const setSwitchMode = (payload) =>
        dispatch({ type: 'setSwitchMode', payload })

    const showMessage = (payload) => dispatch({ type: 'showMessage', payload })

    const hideMessage = () => dispatch({ type: 'hideMessage' })

    const login = async () => {
        try {
            const session = await WharfKit.getInstance(state.api).loginWallet(
                false,
            )

            if (!session) return

            dispatch({
                type: 'login',
                payload: { session },
            })
        } catch (error) {
            console.error('error', error)
        }
    }

    const logout = async () => {
        try {
            dispatch({ type: 'logout' })
        } catch (error) {
            console.error('error', error)
        }
    }

    return [
        state,
        dispatch,
        { setSwitchMode, showMessage, hideMessage, login, logout },
    ]
}

export const Context = createContext([initialState, () => {}])
export default Waxplorer
