import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CheckIndicator from '../checkindicator/CheckIndicator'
import MainButton from '../common/util/input/MainButton'
import { sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function BulkDistributePopup(props) {
    const assets = props['assets']
    const { t } = useTranslation('common')
    const memo = props['memo']

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [errors, setErrors] = useState({})
    const [distributed, setDistributed] = useState([])
    const [transactions, setTransactions] = useState({})

    const maxActions = 10

    const addError = (assetId, e) => {
        errors[assetId] = e
        setErrors(errors)
    }

    const addTransaction = (assetId, tx) => {
        transactions[assetId] = tx
        setTransactions(transactions)
    }

    const [currentDisplayAction, setCurrentDisplayAction] = useState(0)

    const distribute = async () => {
        setIsLoading(true)

        let currentAction = 0

        while (currentAction < Object.keys(assets).length / maxActions) {
            const start = currentAction * maxActions
            const end = start + maxActions
            const actions = [getBoostAction(activeUser)]

            let distributeError = null

            Object.keys(assets)
                .sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
                .slice(start, end)
                .map((assetId) => {
                    const asset = assets[assetId].asset
                    const receiver = assets[assetId].receiver
                        .trim()
                        .toLowerCase()

                    if (asset.standard === 'atomicassets') {
                        actions.push({
                            account: 'atomicassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                memo: memo
                                    ? memo
                                    : 'nfthive.io bulk distribution',
                                asset_ids: [assetId],
                                to: receiver,
                            },
                        })
                    } else {
                        actions.push({
                            account: 'simpleassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: receiver,
                                assetids: [assetId],
                                memo: memo
                                    ? memo
                                    : 'nfthive.io bulk distribution',
                            },
                        })
                    }
                })

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                Object.keys(assets)
                    .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                    .slice(start, end)
                    .map((assetId) =>
                        addTransaction(
                            assetId,
                            result['response']['transaction_id'],
                        ),
                    )
            } catch (e) {
                Object.keys(assets)
                    .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                    .slice(start, end)
                    .map((assetId) => addError(assetId, e.message))
                console.error(e)
                setError(e.message)
                distributeError = e
            } finally {
                currentAction += 1
                setCurrentDisplayAction(currentAction)
                if (!error && !distributeError) {
                    Object.keys(assets)
                        .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                        .slice(start, end)
                        .map((assetId) => distributed.push(assets[assetId]))
                    setDistributed(distributed)
                }
                if (currentAction < Object.keys(assets).length / maxActions) {
                    sleep(1000)
                }
            }
        }
        setIsLoading(false)
        callBack({
            distributed: distributed,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    let distributeInfo = t('search.bulk_distribute')

    const distributeField = (
        <MainButton className="w-[80px] max-w-32" onClick={distribute}>
            {distributeInfo}
        </MainButton>
    )

    const cancel = () => {
        callBack({
            distributed: distributed,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    return (
        <Popup title={t('search.bulk_distribute')} cancel={cancel}>
            <div className="p-2">
                {Object.keys(distributed).length > 0 ? <CheckIndicator /> : ''}
                {Object.keys(distributed).length === 0 ? (
                    <div className="mx-auto mb-4 text-lg font-bold text-center">
                        {memo
                            ? `Are you sure you want to distribute these ${
                                  Object.keys(assets).length
                              } Asset(s) with the Memo '${memo}' ?`
                            : t(
                                  'popups.are_you_sure_you_want_to_distribute_assets',
                                  {
                                      x: Object.keys(assets).length,
                                  },
                              )}
                    </div>
                ) : (
                    ''
                )}
                {error ? <ErrorMessage error={error} /> : ''}
                {Object.keys(distributed).length === 0 ? (
                    <div className="relative flex justify-center gap-2">
                        <MainButton onClick={cancel} className="bg-red-500 ">
                            {t('asset.cancel')}
                        </MainButton>
                        {distributeField}
                    </div>
                ) : (
                    ''
                )}
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.signing_multiple_transactions', {
                            x: currentDisplayAction,
                            y: Math.ceil(
                                Object.keys(assets).length / maxActions,
                            ),
                        })}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default BulkDistributePopup
