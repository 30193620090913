import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import { get } from '../helpers/Api'
import LoadingIndicator from '../loadingindicator'
import DropPreview from './DropPreview'
import { useSharedState } from '../waxplorer/Waxplorer'

const FixedDropsList = (props) => {
    const searchSettings = props['searchSettings']
        ? props['searchSettings']
        : { minimal: false }

    const getValue = (key, def) => {
        if (searchSettings[key]) return searchSettings[key]['value']
        return def
    }

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const offset = getValue('offset', 0)
    const upcoming = getValue('upcoming', false)
    const currency = getValue('currency', 'all')
    const orderBy = getValue('order_by', 'date_desc')
    const market = getValue(
        'market',
        props['market'] ? props['market'] : 'nfthivedrops',
    )
    const searchDropId = getValue('drop_id', null)
    const minPrice = getValue('min_price', 0)
    const maxPrice = getValue('max_price', 0)
    const searchAuthor = getValue('collection', '*')
    const searchSchema = getValue('schema', null)
    const name = getValue('term', null)
    const templateId = getValue('templateId', null)

    const rarity = getValue('rarity', null)
    const type = getValue('type', null)
    const color = getValue('color', null)
    const variant = getValue('variant', null)
    const border = getValue('border', null)

    const searchVerified = getValue(
        'verified',
        searchAuthor && searchAuthor !== '*'
            ? 'all'
            : process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? 'all'
            : 'verified',
    )

    const [drops, setDrops] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const limit = 12

    const parseDrops = (res) => {
        if (offset > 0 && drops) {
            setDrops(drops.concat(res))
        } else {
            setDrops(res)
        }
        setIsLoading(false)
    }

    const getDrops = async () => {
        get(
            `drops?drop_id=${searchDropId ? searchDropId : ''}&offset=${
                offset ? offset : 0
            }&order_by=${orderBy ? orderBy : ''}&collection=${
                searchAuthor ? searchAuthor : ''
            }&schema=${searchSchema ? searchSchema : ''}&rarity=${
                rarity ? rarity : ''
            }&variant=${
                variant
                    ? variant.includes('#') ||
                      variant.includes('&') ||
                      variant.includes('?')
                        ? escape(variant)
                        : variant
                    : ''
            }&color=${color ? color : ''}&type=${type ? type : ''}&border=${
                border ? border : ''
            }&term=${
                name
                    ? name.includes('#') ||
                      name.includes('&') ||
                      name.includes('?')
                        ? escape(name)
                        : name
                    : templateId
                    ? templateId
                    : ''
            }&verified=${searchVerified}&min_price=${
                minPrice ? minPrice : ''
            }&max_price=${maxPrice ? maxPrice : ''}&market=${
                market && market !== 'all' ? market : ''
            }&limit=${limit}${upcoming ? '&upcoming=true' : ''}${
                userName ? '&user=' + userName : ''
            }${currency && currency !== 'all' ? '&currency=' + currency : ''}`,
        ).then(parseDrops)
    }

    useEffect(() => {
        setIsLoading(true)
        if (typeof window !== 'undefined') {
            getDrops()
        }
    }, [searchSettings])

    return (
        <div className={cn('w-full flex flex-col sm:flex-row')}>
            <div className={cn('px-5 w-full mx-auto mt-10 sm:mt-0')}>
                <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5 my-10">
                    {drops &&
                        drops.map((drop, index) => {
                            return (
                                <DropPreview
                                    {...props}
                                    index={index}
                                    drop={drop}
                                />
                            )
                        })}
                </div>
                {isLoading ? <LoadingIndicator /> : ''}
            </div>
        </div>
    )
}

export default FixedDropsList
