import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Card } from '@nextui-org/react'
import MainButton from '../common/util/input/MainButton'

import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import { useSharedState } from '../waxplorer/Waxplorer'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function BuyOfferPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const { author, name, image, templateId } = asset

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [offer, setOffer] = useState(null)

    useEffect(() => {}, [userName])

    const create = async (depositType = 'min') => {
        if (!offer || !offer['price'] || !offer['amount']) return
        const { mdata } = asset

        const data = mdata
            ? JSON.parse(mdata)
            : {
                  video:
                      asset['image'] && asset['image'].includes('video:')
                          ? asset['image']
                                .replace('video:', '')
                                .replace('https://ipfs.hivebp.io/ipfs/', '')
                          : '',
                  img:
                      asset['image'] && !asset['image'].includes('video:')
                          ? asset['image'].replace(
                                'https://ipfs.hivebp.io/ipfs/',
                                '',
                            )
                          : '',
              }

        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        if (offer['missingMin'] > 0) {
            actions.push({
                account: 'eosio.token',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    from: userName,
                    to: 'atomicmarket',
                    quantity: `${parseFloat(offer['missingMin']).toFixed(
                        8,
                    )} WAX`,
                    memo: 'deposit',
                },
            })
        }
        for (let i = 0; i < offer['amount']; ++i) {
            actions.push({
                account: 'atomicmarket',
                name: 'createtbuyo',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    buyer: userName,
                    template_id: templateId,
                    collection_name: author,
                    price: `${parseFloat(offer['price']).toFixed(8)} WAX`,
                    maker_marketplace: 'nft.hive',
                },
            })
        }

        try {
            await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ created: true, error: null })
        } catch (e) {
            callBack(false, e.message)
            console.error(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
            closeCallBack()
        }
    }

    const createWithMinDeposit = async () => {
        create('min')
    }

    const cancel = () => {
        callBack({ created: false, error: null })
        closeCallBack()
    }

    const changePrice = (e) => {
        const inputString = e.target.value
        const amount = offer && offer['amount'] ? offer['amount'] : 1
        if (/^\d*\.?\d*$/.test(inputString)) {
            setOffer({
                price: inputString,
                amount: amount,
                missingMin: amount * inputString,
                missingMax: amount * inputString,
            })
        }
    }

    const changeAmount = (e) => {
        const inputString = e.target.value
        const inputInt = parseFloat(inputString)
        if (/^\d*$/.test(inputString)) {
            setOffer({
                price: offer['price'],
                amount: inputInt,
                missingMin: offer['price'] ? offer['price'] * inputInt : 0,
                missingMax: offer['price'] ? offer['price'] * inputInt : 0,
            })
        }
    }

    return (
        <Popup title={name} cancel={cancel} image={image} asset={asset}>
            <>
                <Card className="p-3">
                    <div
                        className={cn(
                            'text-left flex justify-center flex-col items-center text-base ',
                        )}
                    >
                        <div className="w-full mb-2 ">
                            {t(
                                'buyoffers.do_you_want_to_create_a_buy_offer_for_x',
                                {
                                    x: templateId,
                                },
                            )}
                        </div>
                        <div
                            className={cn(
                                'text-sm dark:text-white text-black   w-full m-auto text-left bg-bluet p-3 rounded',
                            )}
                        >
                            {t(
                                'buyoffers.deposit_the_amount_of_wax_you_are_willing_to_pay',
                            )}
                            <br />
                            {t(
                                'buyoffers.you_can_cancel_your_offer_withdraw_your_funds_at_any_time',
                            )}
                            <br />
                        </div>
                    </div>
                </Card>

                {error && <ErrorMessage error={error} />}
                <div className={cn('w-full')}>
                    <div
                        className={cn(
                            ' w-full justify-center p-3 mx-auto pb-5',
                        )}
                    >
                        <div className="grid items-center grid-cols-1 gap-4 lg:grid-cols-2 place-content-center">
                            <div className="flex mt-2">
                                <div className="">
                                    <Input
                                        variant="faded"
                                        labelPlacement={'inside'}
                                        type="number"
                                        label={t(
                                            'buyoffers.enter_offer_amount',
                                        )}
                                        onWheel={(e) => e.target.blur()}
                                        onChange={changePrice}
                                        value={offer ? offer['price'] : ''}
                                        endContent={'WAX'}
                                    />
                                </div>
                            </div>

                            <div className="flex mt-2">
                                <div className="">
                                    <Input
                                        variant="faded"
                                        type="number"
                                        label={t('buyoffers.number_of_offers')}
                                        onWheel={(e) => e.target.blur()}
                                        onChange={changeAmount}
                                        value={offer ? offer['amount'] : 1}
                                        disabled={!offer || !offer['price']}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={cn(
                            ' w-full justify-center p-3 mx-auto pb-5',
                        )}
                    >
                        <div className={'flex flex-wrap justify-start gap-4'}>
                            <MainButton
                                onClick={cancel}
                                className="bg-red-500 "
                            >
                                {t('asset.cancel')}
                            </MainButton>
                            <MainButton
                                disabled={
                                    (!offer ||
                                        !offer['price'] ||
                                        !offer['amount']) &&
                                    'disabled'
                                }
                                className="w-48 max-w-48"
                                onClick={createWithMinDeposit}
                            >
                                {t('buyoffers.deposit_and_create')}
                            </MainButton>
                        </div>
                    </div>
                </div>
            </>
            {isLoading && (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            )}
        </Popup>
    )
}

export default BuyOfferPopup
