import { Alert, AlertTitle } from '@mui/material'
import Link from '../common/util/input/Link'
import {
    filterWithAssetLock,
    getAtomicAssets,
    getProofOwn,
    hasWhitelist,
} from './Api'

const validateAssets = (assets, amount) => {
    const assetIds = []
    for (let i = 0; i < amount && i < assets.data.length; ++i) {
        assetIds.push(assets.data[i].asset_id)
    }
    return assetIds
}

const validateSchema = async (
    collection_name,
    schema_name,
    amount,
    userName,
) => {
    const assets = await getAtomicAssets({
        collections: [collection_name],
        schema: schema_name,
        limit: amount * 3,
        user: userName,
    })

    return validateAssets(assets, amount)
}

const validateCollection = async (collection_name, amount, userName) => {
    const assets = await getAtomicAssets({
        collections: [collection_name],
        limit: amount * 3,
        user: userName,
    })

    return validateAssets(assets, amount)
}

const validateByTemplate = async (
    collection_name,
    template_id,
    amount,
    userName,
) => {
    const assets = await getAtomicAssets({
        collections: [collection_name],
        template_id: template_id,
        limit: amount * 3,
        user: userName,
    })

    return validateAssets(assets, amount)
}

export const getValidation = async ({
    drop,
    state,
    userName,
    setWhitelist,
    setMissingProof,
    setRequiredAssets,
    setIsLoadingValidation,
}) => {
    if (!userName) return
    setIsLoadingValidation(true)
    const whitelist = await hasWhitelist(drop, state)

    if (whitelist) {
        setWhitelist(whitelist)
        setIsLoadingValidation(false)
        return
    }

    const proofOwn = await getProofOwn(drop.dropId, state)

    let logical_operator = 0
    if (proofOwn && proofOwn.proof && proofOwn.proof[0].op)
        logical_operator = proofOwn.proof[0].op

    const assetIds = []
    const missing = []

    if (proofOwn && proofOwn.proof && proofOwn.proof[0].proof) {
        let foundCounter = 0
        for (let i = 0; i < proofOwn.proof[0].proof.length; ++i) {
            const proof = proofOwn.proof[0].proof[i]
            if (proof) {
                const num = proof.num
                const collection_name = proof.collection_name
                const schema = proof.schema
                const template_id = proof.template_id
                let assets = []

                if (collection_name && !schema && !template_id) {
                    assets = await validateCollection(
                        collection_name,
                        num,
                        userName,
                    )
                } else if (collection_name && schema && !template_id) {
                    assets = await validateSchema(
                        collection_name,
                        schema,
                        num,
                        userName,
                    )
                } else if (collection_name && schema && template_id) {
                    assets = await validateByTemplate(
                        collection_name,
                        template_id,
                        num,
                        userName,
                    )
                }

                assets = await filterWithAssetLock(
                    drop.dropId,
                    assets,
                    userName,
                    state,
                )

                if (assets.length >= num) {
                    ++foundCounter
                    assets.map((assetId) => assetIds.push(assetId))
                } else {
                    missing.push({
                        schema: schema,
                        collection_name: collection_name,
                        template_id: template_id,
                        amount: num - assets.length,
                    })
                }
            }
        }

        if (
            (logical_operator === 'AND' &&
                foundCounter === proofOwn.proof[0].proof.length) ||
            (logical_operator === 'OR' && foundCounter > 0)
        ) {
            setRequiredAssets(assetIds)
        } else {
            setMissingProof(missing)
        }
    }

    setIsLoadingValidation(false)
}

export const MissingDropOwnershipTemplates = ({
    t,
    missingProof,
    closeCallBack,
}) => (
    <div className={'mt-1'}>
        <Alert severity="error">
            <AlertTitle>{t('drops.missing')}</AlertTitle>
            {missingProof &&
                missingProof.map((proof) => (
                    <div className={'flex flex-wrap'}>
                        {/* Collection */}
                        {!proof.template_id &&
                        !proof.schema &&
                        proof.collection_name ? (
                            <div className="mx-1">{proof.amount}x</div>
                        ) : null}
                        {!proof.template_id &&
                        !proof.schema &&
                        proof.collection_name ? (
                            <div className="mx-1">{t('drops.collection')}:</div>
                        ) : null}
                        {!proof.template_id &&
                        !proof.schema &&
                        proof.collection_name ? (
                            <Link
                                href={`/market?collection=${proof.collection_name}`}
                                onClick={closeCallBack}
                            >
                                <div
                                    className={
                                        'text-primary hover:underline mx-1 text-left'
                                    }
                                >
                                    {proof.collection_name}
                                </div>
                            </Link>
                        ) : null}
                        {/* Schema */}
                        {!proof.template_id && proof.schema ? (
                            <div className="mx-1">{proof.amount}x</div>
                        ) : null}
                        {!proof.template_id && proof.schema ? (
                            <div className="mx-1">{t('drops.schema')}:</div>
                        ) : null}
                        {!proof.template_id && proof.schema ? (
                            <Link
                                href={`/market?collection=${proof.collection_name}&schema=${proof.schema}`}
                                onClick={closeCallBack}
                            >
                                <div
                                    className={
                                        'text-primary hover:underline mx-1 text-left'
                                    }
                                >
                                    {proof.collection_name} - {proof.schema}
                                </div>
                            </Link>
                        ) : null}
                        {/* Template */}
                        {proof.template_id ? (
                            <div className="mx-1">{proof.amount}x</div>
                        ) : null}
                        {proof.template_id ? (
                            <div className="mx-1">{t('drops.template')}:</div>
                        ) : null}
                        {proof.template_id ? (
                            <Link
                                href={`/market?collection=${proof.collection_name}&schema=${proof.schema}&template_id=${proof.template_id}`}
                                onClick={closeCallBack}
                            >
                                <div
                                    className={
                                        'text-primary hover:underline mx-1 text-left'
                                    }
                                >
                                    {proof.collection_name} - {proof.schema} -{' '}
                                    {proof.template_id}
                                </div>
                            </Link>
                        ) : null}
                    </div>
                ))}
        </Alert>
    </div>
)
