import { Card, CardContent, InputAdornment, TextField } from '@mui/material'
import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BulkItemBox from '../bulklist/BulkItemBox'
import { floatRegEx } from '../editor/EditorComponents'
import { formatWAX } from '../helpers/FormatLinks'
import ErrorMessage from '../popups/ErrorMessage'
import VideoElement from '../videoelement/VideoElement'
import { MiniMarketTable } from './MiniMarketTable'

export const InputAssetListItem = ({
    asset,
    error,
    dismissError,
    transferType,
    sellPrice,
    changeSellPrice,
    index,
    removeItem,
    listing,
}) => {
    const { image, backimg, video, universalPreview, thumbnail } = asset

    const { t } = useTranslation('common')

    return (
        <Card sx={{ display: 'flex', marginBottom: 1, width: '100%' }}>
            <CardContent sx={{ width: '100%', padding: '4px' }}>
                <div
                    className={cn(
                        'w-full relative grid items-center justify-center',
                        {
                            'grid-cols-3 lg:grid-cols-5':
                                transferType !== 'bulk_sell' &&
                                transferType !== 'buy',
                        },
                        {
                            'grid-cols-1':
                                transferType === 'bulk_sell' ||
                                transferType === 'buy',
                        },
                    )}
                >
                    <img
                        className="absolute top-2 right-2 cursor-pointer z-50 h-4 hidden dark:!block"
                        onClick={removeItem}
                        src="/close_btn.svg"
                        alt={t('popups.wrapper.close-alt')}
                    />
                    <img
                        className="absolute top-2 right-2 cursor-pointer z-50 h-4 block dark:!hidden"
                        onClick={removeItem}
                        src="/close_btn_y.svg"
                        alt={t('popups.wrapper.close-alt')}
                    />
                    <BulkItemBox contentHeight={'h-28'} titleHeight={'h-0'}>
                        <div className={'flex h-28 justify-center'}>
                            {video && !universalPreview ? (
                                <VideoElement
                                    className={cn('flex p-1')}
                                    image={image}
                                    backimg={backimg}
                                    video={video}
                                    autoPlay={true}
                                    timeout={1}
                                >
                                    <source src={video} />
                                    Your browser does not support the video tag.
                                </VideoElement>
                            ) : (
                                <div className={cn('flex p-1')}>
                                    <img
                                        className={'m-auto'}
                                        src={
                                            universalPreview
                                                ? universalPreview
                                                : thumbnail
                                                ? thumbnail
                                                : image
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </BulkItemBox>
                    {error ? (
                        <div
                            onClick={() => dismissError()}
                            className="absolute w-full md:w-1/2 h-full flex"
                        >
                            <div className={'my-auto'}>
                                <ErrorMessage layer={3} error={error} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <BulkItemBox title={t('')}>
                        <div
                            className={cn(
                                {
                                    'text-2xs md:text-1xs':
                                        asset.name && asset.name.length > 50,
                                },
                                {
                                    'text-1xs md:text-xs':
                                        asset.name &&
                                        asset.name.length > 30 &&
                                        asset.name.length < 49,
                                },
                                {
                                    'text-sm md:text-base':
                                        asset.name && asset.name.length < 29,
                                },
                            )}
                        >
                            {asset.name}
                        </div>
                    </BulkItemBox>
                    <BulkItemBox
                    // title={t('asset.mint')}
                    >
                        <div
                            className={cn(
                                {
                                    'text-2xs md:text-1xs':
                                        asset.name && asset.name.length > 50,
                                },
                                {
                                    'text-1xs md:text-xs':
                                        asset.name &&
                                        asset.name.length > 30 &&
                                        asset.name.length < 49,
                                },
                                {
                                    'text-sm md:text-base':
                                        asset.name && asset.name.length < 29,
                                },
                            )}
                        >
                            #{asset.mint}
                        </div>
                    </BulkItemBox>

                    <BulkItemBox
                        // title={t('search.market')}
                        titleHeight={'h-6'}
                        contentHeight={'h-22'}
                        colSpan={''}
                    >
                        <div className={''}>
                            <MiniMarketTable asset={asset} />
                        </div>
                    </BulkItemBox>

                    {transferType === 'bulk_sell' ? (
                        <BulkItemBox
                            title={t('search.price')}
                            colSpan={'col-span-3 lg:col-span-1 mt-4 lg:mt-0'}
                        >
                            <div className={'mx-2'}>
                                <TextField
                                    size="small"
                                    value={sellPrice}
                                    placeholder={sellPrice}
                                    inputProps={{
                                        style: {
                                            height: '1.2rem',
                                            fontSize: '1rem',
                                        },
                                    }}
                                    id={'sell-'}
                                    onChange={changeSellPrice}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                disableTypography
                                                sx={{
                                                    fontSize: '0.7rem',
                                                    marginLeft: 0.2,
                                                }}
                                            >
                                                WAX
                                            </InputAdornment>
                                        ),
                                        tabIndex: index + 1,
                                    }}
                                    error={
                                        !floatRegEx.test(
                                            sellPrice ? sellPrice : '',
                                        )
                                    }
                                />
                            </div>
                        </BulkItemBox>
                    ) : (
                        ''
                    )}

                    {transferType === 'buy' ? (
                        <BulkItemBox
                            title={t('search.price')}
                            colSpan={'col-span-3 lg:col-span-1 mt-4 lg:mt-0'}
                        >
                            <div className={'mx-2'}>
                                {formatWAX(listing.offer)}
                            </div>
                        </BulkItemBox>
                    ) : (
                        ''
                    )}
                </div>
            </CardContent>
        </Card>
    )
}
