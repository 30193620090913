import { Input } from '@nextui-org/react'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainButton from '../common/util/input/MainButton'
import { formatToken } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function TransferTokenPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']

    const balance = props['balance']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [sellPrice, setSellPrice] = useState()
    const [receiver, setReceiver] = useState('')
    const [memo, setMemo] = useState('')
    const token = props['token']
    const digits = props['digits']
    const contract = props['contract']

    useEffect(() => {}, [])

    const transfer = async () => {
        if (!sellPrice || !receiver) return
        const quantity = parseFloat(sellPrice)
        closeCallBack()
        setIsLoading(true)
        try {
            await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: contract,
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: receiver,
                                quantity: `${quantity.toFixed(
                                    digits,
                                )} ${token}`,
                                memo: memo,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({
                transferred: true,
                receiver: receiver,
                quantity: quantity,
                error: error,
            })
        } catch (e) {
            console.error(e)
            setError(e.message)
            callBack({
                transferred: false,
                receiver: null,
                quantity: null,
                error: e.message,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const cancel = () => {
        callBack({
            transferred: false,
            receiver: null,
            quantity: null,
            error: error,
        })
        closeCallBack()
    }

    const changePrice = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) setSellPrice(val)
    }

    const changeReceiver = (e) => {
        const val = e.target.value
        setReceiver(val.trim().toLowerCase())
    }

    const changeMemo = (e) => {
        const val = e.target.value
        setMemo(val)
    }

    return (
        <Popup title={t('asset.transfer')} cancel={cancel}>
            <div className="p-2 mx-auto mb-4 text-lg font-semibold">
                {`${t('navigation.balance')}`}:{' '}
                <div className={''} onClick={() => setSellPrice(balance)}>
                    {' '}
                    {balance && formatToken(balance, token, digits)}
                </div>
            </div>
            {error ? <ErrorMessage error={error} /> : ''}
            <div className={cn('grid grid-cols-1 gap-2')}>
                <div className="px-1 my-auto mb-2 md:mb-auto">
                    <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={token}
                        onChange={changePrice}
                        value={sellPrice ? sellPrice : ''}
                        variant="faded"
                    />
                </div>
                <div className="px-1 my-auto mb-2 md:mb-auto">
                    <Input
                        type="text"
                        label={t('asset.receiver')}
                        onChange={changeReceiver}
                        value={receiver ? receiver : ''}
                        variant="faded"
                    />
                </div>
                <div className="px-1 my-auto mb-2 md:mb-auto">
                    <Input
                        type="text"
                        label={t('asset.memo')}
                        onChange={changeMemo}
                        value={memo ? memo : ''}
                        variant="faded"
                    />
                </div>
            </div>
            <div className="flex justify-between mt-4 ">
                <div>
                    <MainButton
                        onClick={cancel}
                        size="large"
                        fullWidth="true"
                        className="bg-red-500 "
                    >
                        {t('asset.cancel')}
                    </MainButton>
                </div>
                <div>
                    <MainButton
                        disabled={
                            !sellPrice ||
                            !receiver ||
                            parseFloat(sellPrice) > parseFloat(balance)
                                ? 'disabled'
                                : ''
                        }
                        onClick={transfer}
                        size="large"
                        fullWidth="true"
                    >
                        {t('asset.transfer')}
                    </MainButton>
                </div>
            </div>

            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default TransferTokenPopup
