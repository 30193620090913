import config from '../../config.json'
import { post } from './Api'
import { useSharedState } from '../waxplorer/Waxplorer'

export const getBoostAction = (activeUser) => {
    const userName = activeUser.accountName

    return {
        account:
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? 'nfthiveboost'
                : 'nft.hive',
        name: 'boost',
        authorization: [
            {
                actor: userName,
                permission: activeUser.requestPermission
                    ? activeUser.requestPermission
                    : 'active',
            },
        ],
        data: {
            booster: userName,
        },
    }
}

export const swapTransaction = async (activeUser, standard, data) => {
    const userName = activeUser['accountName']

    await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account:
                        standard === 'atomicassets' ? standard : 'simpleassets',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: data,
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const stakeAtomicAssets = async (activeUser, assetIds) => {
    const userName = activeUser.accountName

    await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'atomicassets',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        memo: 'stake',
                        asset_ids: assetIds,
                        to: 's.rplanet',
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const stakeSimpleAssets = async (activeUser, assetIds) => {
    const userName = activeUser.accountName

    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'simpleassets',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 's.rplanet',
                        assetids: assetIds,
                        memo: 'stake',
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signMarketPlaceBuy2 = async (activeUser, assetIds) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'market.place',
                    name: 'withdraw',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        user: userName,
                        assets: assetIds.map((id) => {
                            return {
                                contract: 'simpleassets',
                                id: id,
                                quantity: '0.00000000 WAX',
                            }
                        }),
                    },
                },
                {
                    account: 'simpleassets',
                    name: 'claim',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        claimer: userName,
                        assetids: assetIds,
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signAtomicMarketBuy = async (
    activeUser,
    orderId,
    quantity,
    currency,
    usd_wax,
) => {
    const userName = activeUser ? activeUser['accountName'] : null
    const result = await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'atomicmarket',
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo: 'deposit',
                    },
                },
                {
                    account: 'atomicmarket',
                    name: 'purchasesale',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        buyer: userName,
                        sale_id: orderId,
                        taker_marketplace:
                            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                ? 'nfthiveboost'
                                : 'nft.hive',
                        intended_delphi_median:
                            currency === 'USD'
                                ? Math.round(usd_wax * 10000)
                                : 0,
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )

    return result
}

export const signWaxplorerBuy = async (
    activeUser,
    quantity,
    orderId,
    assetId,
) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'waxplorercom',
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo: `purchase:${orderId}`,
                    },
                },
                {
                    account: 'simpleassets',
                    name: 'claim',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        claimer: userName,
                        assetids: [assetId],
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signSimpleMarketBuy = async (activeUser, quantity, assetId) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'simplemarket',
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo:
                            '{"nftid": ' +
                            assetId +
                            ', "affiliate_id": 200001}',
                    },
                },
                {
                    account: 'simpleassets',
                    name: 'claim',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        claimer: userName,
                        assetids: [assetId],
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signNFTHiveBuy = async (activeUser, quantity, orderId) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'nft.hive',
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo: 'purchase:' + orderId,
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signWaxInventoryBuy = async (activeUser, quantity, assetId) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'waxinventory',
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo: '{"id":"' + assetId + '","type":"market"}',
                    },
                },
                {
                    account: 'simpleassets',
                    name: 'claim',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        claimer: userName,
                        assetids: [assetId],
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signMarketMythBuy = async (
    activeUser,
    quantity,
    assetId,
    owner,
) => {
    const userName = activeUser ? activeUser['accountName'] : null
    await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'market.myth',
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo: '',
                    },
                },
                {
                    account: owner,
                    name: 'buy',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        assetid: assetId,
                        from: userName,
                        price: `${quantity.toFixed(8)} WAX`,
                        referral: 'waxplorerref',
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signGpkMythBuy = async (activeUser, quantity, name, owner) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: owner,
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo: '',
                    },
                },
                {
                    account: owner,
                    name: 'buy',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        quantity: `1 ${name}`,
                        from: userName,
                        price: `${quantity.toFixed(8)} WAX`,
                        referral: 'waxplorerref',
                        instant: true,
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signWsMythBuy = async (activeUser, quantity, name, owner) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: owner,
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo: '',
                    },
                },
                {
                    account: owner,
                    name: 'buy',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        quantity: `1 ${name}`,
                        from: userName,
                        price: `${quantity.toFixed(8)} WAX`,
                        referral: 'waxplorerref',
                        instant: true,
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signAlcorNFTBuy = async (activeUser, orderId, quantity) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'alcornftswap',
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo: 'fill|' + orderId,
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signMarketPlaceBuy = async (
    activeUser,
    quantity,
    orderId,
    standard,
    assetIds,
    name,
) => {
    const userName = activeUser ? activeUser['accountName'] : null
    const actions = [
        getBoostAction(activeUser),
        {
            account: 'eosio.token',
            name: 'transfer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                from: userName,
                to: 'market.place',
                quantity: `${quantity.toFixed(8)} WAX`,
                memo: 'Payment for NFTs | collectables.io',
            },
        },
        {
            account: 'market.place',
            name: 'buy',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                account: userName,
                listingid: orderId,
                referral: 'waxplorerref',
            },
        },
        {
            account: 'market.place',
            name: 'withdraw',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                user: userName,
                assets: assetIds.map((id) => {
                    return {
                        contract: standard,
                        id: id,
                        quantity:
                            standard === 'simpleassets'
                                ? '0.00000000 WAX'
                                : `1 ${name}`,
                    }
                }),
            },
        },
    ]
    if (standard === 'simpleassets') {
        actions.push({
            account: 'simpleassets',
            name: 'claim',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                claimer: userName,
                assetids: assetIds,
            },
        })
    }
    return await activeUser.session.transact(
        {
            actions: actions,
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signMarketPlaceBuy1 = async (activeUser, quantity, orderId) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'market.place',
                        quantity: `${quantity.toFixed(8)} WAX`,
                        memo: 'Payment for NFTs | collectables.io',
                    },
                },
                {
                    account: 'market.place',
                    name: 'buy',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        account: userName,
                        listingid: orderId,
                        referral: 'waxplorerref',
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const signMarketPlaceBuy3 = async (activeUser, assetIds) => {
    const userName = activeUser ? activeUser['accountName'] : null
    return await activeUser.session.transact(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'market.place',
                    name: 'withdraw',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        user: userName,
                        assets: assetIds.map((id) => {
                            return {
                                contract: 'simpleassets',
                                id: id,
                                quantity: '0.00000000 WAX',
                            }
                        }),
                    },
                },
                {
                    account: 'simpleassets',
                    name: 'claim',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        claimer: userName,
                        assetids: assetIds,
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const getStakedHoneyBalance = async (name, state) => {
    const body = {
        code: 'nfthivevault',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 100,
        reverse: 'false',
        lower_bound: name,
        upper_bound: name,
        scope: 'nfthivevault',
        show_payer: 'false',
        table: 'stakes',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getHoneyBalance = async (name, state) => {
    const body = {
        code: 'nfthivehoney',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 100,
        reverse: 'false',
        scope: name,
        show_payer: 'false',
        table: 'accounts',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getHoneyTransitions = async (name, state) => {
    const body = {
        code: 'nfthivevault',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        reverse: 'false',
        lower_bound: name,
        upper_bound: name,
        scope: 'nfthivevault',
        show_payer: 'false',
        table: 'transitions',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getClaimableHoneyBalance = async (name, state) => {
    const body = {
        code: 'nfthivevault',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        reverse: 'false',
        lower_bound: name,
        upper_bound: name,
        scope: 'nfthivevault',
        show_payer: 'false',
        table: 'rewards',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getDropFees = async (state) => {
    const body = {
        json: true,
        code: 'nfthivedrops',
        scope: 'nfthivedrops',
        table: 'newconfig',
        table_key: '',
        lower_bound: '',
        upper_bound: '',
        index_position: 1,
        key_type: 'name',
        limit: 200,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return parseFloat(res.data.rows[0]['default_fee']).toFixed(2)
    }

    return 0
}

export const getDropSplits = async (state, drop_id) => {
    const body = {
        json: true,
        code: 'nfthivedrops',
        scope: 'nfthivedrops',
        table: 'splits',
        table_key: '',
        lower_bound: drop_id,
        upper_bound: drop_id,
        index_position: 1,
        key_type: 'i64',
        limit: 200,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        if (res.data.rows[0].drop_id === drop_id) {
            return res.data.rows[0]['price_recipients']
        }
    }

    return null
}

export const getNFTHiveVaultConfig = async (state) => {
    const body = {
        code: 'nfthivevault',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        reverse: 'false',
        lower_bound: '',
        upper_bound: '',
        scope: 'nfthivevault',
        show_payer: 'false',
        table: 'config',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getNFTHiveDropsConfig = async (state) => {
    const body = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        reverse: 'false',
        lower_bound: '',
        upper_bound: '',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'rewardconfig',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getNFTHiveConfig = async (state) => {
    const body = {
        code: 'nft.hive',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        reverse: 'false',
        lower_bound: '',
        upper_bound: '',
        scope: 'nft.hive',
        show_payer: 'false',
        table: 'honeyconfig',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getAtomicBalance = async (name, state) => {
    const body = {
        code: 'atomicmarket',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: name,
        upper_bound: name,
        reverse: 'false',
        scope: 'atomicmarket',
        show_payer: 'false',
        table: 'balances',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getWaxDaoBackedTokens = async (name, state) => {
    const body = {
        code: 'waxdaobacker',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: name,
        upper_bound: name,
        reverse: 'false',
        scope: 'waxdaobacker',
        show_payer: 'false',
        table: 'balances',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getAetherBalance = async (name, state) => {
    const body = {
        code: 'e.rplanet',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 100,
        reverse: 'false',
        scope: name,
        show_payer: 'false',
        table: 'accounts',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getStakedTrilliumBalance = async (name, state) => {
    const body = {
        code: 'token.worlds',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 100,
        lower_bound: '',
        upper_bound: '',
        reverse: 'false',
        scope: name,
        show_payer: 'false',
        table: 'accounts',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getTrilliumBalance = async (name, state) => {
    const body = {
        code: 'alien.worlds',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 100,
        reverse: 'false',
        scope: name,
        show_payer: 'false',
        table: 'accounts',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    return post(url, body)
}

export const getBrawler = async (user, brawler, state) => {
    const body = {
        code: 'bcbrawlers',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: brawler,
        upper_bound: brawler,
        reverse: 'true',
        scope: user,
        show_payer: 'false',
        table: 'brawlers',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows[0] &&
        res.data.rows[0]['available']
    ) {
        const row = res.data.rows[0]
        return {
            available: row['available'] ? row['available'] : 0,
            health: row['health'] ? row['health'] : 0,
            slotId: row['slot_id'] ? row['slot_id'] : 0,
        }
    } else {
        return 0
    }
}
