import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function EditSchemaPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const editSchemaAttributes = props['editSchemaAttributes']
    const collectionUniqueName = props['collectionUniqueName']
    const editSchemaName = props['editSchemaName']
    const newSchema = props['newSchema']

    const minimumAttributes = [
        { name: 'name', type: 'string' },
        { name: 'img', type: 'image' },
    ]

    const [error, setError] = useState(null)

    const edit = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        if (newSchema) {
            let data = {
                authorized_creator: userName,
                collection_name: collectionUniqueName,
                schema_name: editSchemaName,
            }

            if (editSchemaAttributes && editSchemaAttributes.length > 0) {
                const completeAttributes =
                    minimumAttributes.concat(editSchemaAttributes)
                const attributes = { schema_format: completeAttributes }
                data = { ...data, ...attributes }
            } else {
                const attributes = { schema_format: minimumAttributes }
                data = { ...data, ...attributes }
            }

            const action = {
                account: 'atomicassets',
                name: 'createschema',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: data,
            }
            actions.push(action)
        } else {
            if (editSchemaAttributes && editSchemaAttributes.length > 0) {
                const data = {
                    authorized_editor: userName,
                    collection_name: collectionUniqueName,
                    schema_name: editSchemaName,
                    schema_format_extension: editSchemaAttributes,
                }
                const action = {
                    account: 'atomicassets',
                    name: 'extendschema',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: data,
                }
                actions.push(action)
            }
        }

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            newSchema,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, newSchema, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title={t('creator.edit_schema')} cancel={cancel}>
            <div className="p-2">
                <div className="mx-auto mb-4 text-lg font-bold text-center">
                    {newSchema
                        ? t(
                              'creator.do_you_want_to_create_a_schema_with_these_attributes',
                          )
                        : t(
                              'creator.do_you_want_to_add_these_attributes_to_this_schema',
                          )}
                </div>
                <div>
                    <div className="relative flex flex-wrap w-full justify-center mb-4">
                        <div className="flex w-full justify-center text-xl font-bold mb-2">
                            Schema Name: {editSchemaName}
                        </div>
                        <div className="w-full grid grid-cols-2 text-xl my-10">
                            <div className="m-auto font-bold mb-2">
                                Attribute Name
                            </div>
                            <div className="m-auto font-bold mb-2">Type</div>
                            {newSchema &&
                                minimumAttributes.map((attribute) => (
                                    <>
                                        <div className="m-auto">
                                            {attribute.name}
                                        </div>
                                        <div className="m-auto">
                                            {attribute.type}
                                        </div>
                                    </>
                                ))}
                            {editSchemaAttributes.map((attribute) => (
                                <>
                                    <div className="m-auto">
                                        {attribute.name}
                                    </div>
                                    <div className="m-auto">
                                        {attribute.type}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
                {error ? (
                    <div onClick={dismissError}>
                        <ErrorMessage layer={5} error={error} />
                    </div>
                ) : (
                    ''
                )}
                <div className="relative flex justify-center gap-2">
                    <MainButton className="bg-red-500 " onClick={cancel}>
                        {t('asset.cancel')}
                    </MainButton>
                    <MainButton className="PopupEditButton" onClick={edit}>
                        {t('collection.save_collection')}
                    </MainButton>
                </div>
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default EditSchemaPopup
