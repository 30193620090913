import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../common/util/input/Link'
import { formatPercentageFull, formatWAX } from '../helpers/FormatLinks'

export const AttributeChip = ({ author, schema, immutableData, data }) => {
    const { total, name, waxFloor, percentage, value } = data

    const { t } = useTranslation('common')

    return (
        <div
            className={
                'border border-primary bg-paget2 text-primary m-2 rounded-lg flex flex-wrap py-2 px-4 '
            }
        >
            <div className={'w-full text-xs text-center break-all'}>{name}</div>
            <div
                className={
                    'w-full text-xl font-bold text-center mt-1 mb-4 break-all'
                }
            >
                {value}
            </div>
            <Link
                href={`/pfps?collection=${author}&search=pfps&order_by=rarity_score_desc&schema=${schema}&attributes=${escape(
                    name,
                )}:${escape(value)}`}
                className={'w-full text-center'}
            >
                <div className="text-black dark:text-white opacity-70 hover:opacity-90 text-xs hover:underline">
                    {`${t('profile.total')}: ${total} (${formatPercentageFull(
                        percentage,
                    )}`}
                    )
                </div>
            </Link>
            {waxFloor ? (
                <Link
                    href={`/pfps?collection=${author}&schema=${schema}&order_by=offer_asc&attributes=${escape(
                        name,
                    )}:${escape(value)}&search_type=sales`}
                    className={'w-full text-center'}
                >
                    <div className="text-black dark:text-white opacity-70 hover:opacity-90 text-xs hover:underline mt-4">
                        {`${t('asset.floor')}: ${formatWAX(waxFloor)}`}
                    </div>
                </Link>
            ) : (
                <div className={'w-full text-center'}>
                    <div className="text-black dark:text-white opacity-70 text-xs mt-4">
                        {t('asset.not_listed')}
                    </div>
                </div>
            )}
        </div>
    )
}
