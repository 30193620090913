import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Collapse, IconButton, LinearProgress } from '@mui/material'
import cn from 'classnames'
import qs from 'qs'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { BuyDropPopupBuyConfirmation } from '../drops/BuyDropPopupBuyConfirmation'
import { DropAlert } from '../drops/DropAlert'
import {
    fetchAssocIds,
    fetchResultsForAssocIds,
    get,
    getDelphiMedian,
    getDropPrices,
    getIsOnWhitelist,
    post,
} from '../helpers/Api'
import { getValidation } from '../helpers/DropProofs'
import { getBoostAction } from '../helpers/WaxApi'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import Popup from './Popup'

function BuyDropPopup(props) {
    const IMAGE_HEIGHT = 'md:max-h-72 lg:max-h-80 xl:max-h-96'
    const IMAGE_WIDTH = 'md:max-w-72 lg:max-w-80 xl:max-w-96'
    const BOX_HEIGHT = 'md:h-72 lg:h-80 xl:h-96'
    const BOX_WIDTH = 'md:w-72 lg:w-80 xl:w-96'

    const drop = props['drop']

    const action = props['action']

    const values = qs.parse(
        window.location.search.substring(1, window.location.search.length),
    )

    const referrer = values['referral'] ? values['referral'] : 'nft.hive'

    const [error, setError] = useState(null)
    const [claimTransactionId, setClaimTransactionId] = useState(null)

    const [pfpResults, setPfpResults] = useState(null)
    const [curPfpPreviewIdx, setCurPfpPreviewIdx] = useState(0)

    const [infoText, setInfoText] = useState(null)

    const { templatesToMint } = drop
    const { idata } = templatesToMint[0]

    const [country, setCountry] = useState(null)

    const data = JSON.parse(idata)

    const image = data['img']
        ? config.ipfs + data['img']
        : data['video']
        ? 'video:' + data['video']
        : null

    const amount = props['amount']

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']
    const [isPurchasing, setIsPurchasing] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [delphiMedian, setDelphiMedian] = useState(0)
    const [supportedTokens, setSupportedTokens] = useState(null)

    const [requiredAssets, setRequiredAssets] = useState(null)
    const [whitelist, setWhitelist] = useState(null)
    const [isLoadingValidation, setIsLoadingValidation] = useState(false)
    const [missingProof, setMissingProof] = useState(null)
    const [dropPrices, setDropPrices] = useState(null)
    const [isOnWhitelist, setIsOnWhitelist] = useState(false)

    const { name } = drop

    const [listingPrice, setListingPrice] = useState(drop.listingPrice)

    const parseUSDListingPrice = (median, amount, usd) => {
        if (median) {
            setQuantity(
                ((amount * usd) / (median / 10000.0)).toFixed(8) + ' WAX',
            )
            setDelphiMedian(median)
        }
    }

    const getSupportedTokens = async () => {
        const body = {
            code: 'nfthivedrops',
            index_position: 1,
            json: 'true',
            key_type: 'i64',
            limit: 1,
            lower_bound: '',
            upper_bound: '',
            reverse: 'true',
            scope: 'nfthivedrops',
            show_payer: 'false',
            table: 'newconfig',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : state.api + '/v1/chain/get_table_rows'
        const res = await post(url, body)

        if (res && res.status === 200 && res.data && res.data.rows) {
            const tokens = []
            for (const token of res.data.rows[0]['supported_tokens']) {
                const symbol = token['token_symbol']
                const decimals = symbol.split(',')[0]
                const currency = symbol.split(',')[1]
                const contract = token['token_contract']
                tokens.push({
                    symbol: symbol,
                    decimals: decimals,
                    currency: currency,
                    contract: contract,
                })
            }
            if (!listingPrice.includes(' USD') && !free) {
                const tokenCurrency = listingPrice.split(' ')[1]
                let tokenContract = 'eosio.token'
                let symbol = '8,WAX'
                for (const token of tokens) {
                    if (token['currency'] === tokenCurrency) {
                        tokenContract = token['contract']
                        symbol = token['symbol']
                    }
                }

                const quantity = parseFloat(listingPrice.split(' ')[0]) * amount
                const precision = parseInt(symbol.split(',')[0])

                const formattedListingPrice = `${quantity.toFixed(
                    precision,
                )} ${tokenCurrency}`

                setQuantity(formattedListingPrice)
            } else if (listingPrice.includes(' USD') && !free) {
                getDelphiMedian(state).then((res) =>
                    parseUSDListingPrice(
                        res,
                        amount,
                        parseFloat(listingPrice.replace(' USD', '')),
                    ),
                )
            }
            setSupportedTokens(tokens)
        }
    }

    const parseDropPrices = (res) => {
        if (res && Object.keys(res).includes('listing_prices')) {
            setDropPrices(res['listing_prices'])
        }
    }

    const parseUserCountry = (res) => {
        setCountry(res['country'])
    }

    useEffect(() => {
        getDropPrices(drop.dropId, state).then(parseDropPrices)
        if (drop.authRequired && userName) {
            getValidation({
                drop: drop,
                state: state,
                userName: userName,
                setWhitelist: setWhitelist,
                setMissingProof: setMissingProof,
                setRequiredAssets: setRequiredAssets,
                setIsLoadingValidation: setIsLoadingValidation,
            })
        }
        if (userName) {
            get('user-country/' + userName).then(parseUserCountry)
        }
    }, [userName])

    useEffect(() => {
        if (userName && whitelist) {
            getIsOnWhitelist(drop, userName, state).then(setIsOnWhitelist)
        }
    }, [whitelist])

    useEffect(() => {
        getSupportedTokens()
    }, [listingPrice])

    const free = listingPrice === '0 NULL' || listingPrice === '0.00000000 WAX'

    const purchase = async () => {
        setIsPurchasing(true)
        setError(null)
        setClaimTransactionId(null)
        setInfoText('Purchasing Drop...')
        const actions = [getBoostAction(activeUser)]
        const tokenCurrency = listingPrice.split(' ')[1]

        let tokenContract = 'eosio.token'
        let symbol = '8,WAX'
        for (const token of supportedTokens) {
            if (token['currency'] === tokenCurrency) {
                tokenContract = token['contract']
                symbol = token['symbol']
            }
        }

        if (!free) {
            actions.push({
                account: tokenContract,
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    from: userName,
                    to: 'nfthivedrops',
                    quantity: quantity,
                    memo: 'deposit',
                },
            })
        }

        const data = {
            referrer: referrer,
            drop_id: drop.dropId,
            country: country,
            intended_delphi_median: delphiMedian ? delphiMedian : 0,
            amount: amount,
            claimer: userName,
            currency: symbol,
        }

        if (requiredAssets) {
            data['asset_ids'] = requiredAssets
        }

        actions.push({
            account: 'nfthivedrops',
            name: drop.authRequired
                ? whitelist
                    ? 'claimdropwl'
                    : 'claimwproof'
                : 'claimdrop',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: data,
        })

        let claimError = null
        let claimTransactionId = null
        try {
            const claimResult = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            claimTransactionId = claimResult?.response?.transaction_id
            setClaimTransactionId(claimTransactionId)
            callBack({ bought: true, error: null })
        } catch (e) {
            claimError = e
        }
        if (claimError !== null) {
            setInfoText(null)
            setError(claimError.message)
            callBack({ bought: false, error: claimError.message })
            setIsPurchasing(false)
        } else if (action === 'buy_drop') {
            setInfoText(null)
            setIsPurchasing(false)
        } else {
            // This is a PFP drop
            setInfoText(
                amount > 10
                    ? t('drops.generating_preview_max_10')
                    : t('drops.generating_preview'),
            )
            const assocIds = await fetchAssocIds(claimTransactionId)
            const results = await fetchResultsForAssocIds(
                assocIds,
                'nfthivedrops',
            )
            if (results) {
                setPfpResults(results)
            }
            setInfoText(null)
            setIsPurchasing(false)
        }
    }

    const cancel = () => {
        if (claimTransactionId) {
            callBack({ bought: true, error: error })
        } else {
            callBack({ bought: false, error: error })
        }
        closeCallBack()
    }

    const fulfilled =
        (!missingProof && requiredAssets) || (whitelist && isOnWhitelist)

    const onSelectCountry = (e) => {
        if (e) {
            setCountry(e.value)
        } else {
            setCountry(null)
        }
    }

    const { t } = useTranslation('common')

    const buildLayersForCurrentSelection = () => {
        if (pfpResults === null || pfpResults.length === 0) {
            return []
        }
        const selectedPfp = pfpResults[curPfpPreviewIdx]
        const layers = []
        for (const attribute of selectedPfp) {
            const name = attribute.attribute_name
            const value = attribute.value
            const dropAttribute = drop.attributes.find(
                (element) => element.attribute_name === name,
            )
            const dropValue = dropAttribute.possible_values.find(
                (element) => element.value === value,
            )
            for (const layer of dropValue.layers) {
                if (layer.ipfs) {
                    layers.push(layer)
                }
            }
        }
        layers.sort((a, b) => {
            return a.layer - b.layer
        })
        return layers
    }

    const DisplayPfpResults = () => {
        return (
            <div>
                <div className="flex justify-center mt-10">
                    {pfpResults && pfpResults.length > 1 ? (
                        <div className="my-auto">
                            <IconButton
                                color="primary"
                                size="large"
                                disabled={curPfpPreviewIdx === 0}
                                onClick={() =>
                                    setCurPfpPreviewIdx(curPfpPreviewIdx - 1)
                                }
                            >
                                <ArrowBackIosNewIcon />
                            </IconButton>
                        </div>
                    ) : null}
                    <div
                        className={cn(
                            BOX_HEIGHT,
                            BOX_WIDTH,
                            'bg-transparent rounded-md relative',
                        )}
                    >
                        {buildLayersForCurrentSelection().map(
                            (layer, index) => (
                                <div className="flex justify-center">
                                    <img
                                        className={cn(
                                            IMAGE_HEIGHT,
                                            IMAGE_WIDTH,
                                            'absolute top-auto bottom-auto',
                                        )}
                                        src={config.ipfs + layer.ipfs}
                                        key={'layer_' + index}
                                    />
                                </div>
                            ),
                        )}
                    </div>
                    {pfpResults && pfpResults.length > 1 ? (
                        <div className="my-auto">
                            <IconButton
                                color="primary"
                                size="large"
                                disabled={
                                    curPfpPreviewIdx >= pfpResults.length - 1
                                }
                                onClick={() =>
                                    setCurPfpPreviewIdx(curPfpPreviewIdx + 1)
                                }
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </div>
                    ) : null}
                </div>
                <div className="text-center italic mt-2">
                    {t('drops.will_be_minted_when_irreversible')}
                </div>
            </div>
        )
    }

    return (
        <Popup
            title={''}
            image={image}
            cancel={cancel}
            asset={templatesToMint[0]}
        >
            <div className="text-3xl mt-4 lg:mt-0 text-center">{name}</div>
            <Collapse in={infoText !== null}>
                <div className="text-xl italic mt-5 mb-2">{infoText}</div>
            </Collapse>
            <Collapse in={isPurchasing || supportedTokens === null}>
                <LinearProgress />
            </Collapse>
            <Collapse in={claimTransactionId === null}>
                <BuyDropPopupBuyConfirmation
                    closeCallBack={closeCallBack}
                    purchase={purchase}
                    drop={drop}
                    listingPrice={listingPrice}
                    setListingPrice={setListingPrice}
                    dropPrices={dropPrices}
                    free={free}
                    requiredAssets={requiredAssets}
                    amount={amount}
                    isLoadingValidation={isLoadingValidation}
                    missingProof={missingProof}
                    fulfilled={fulfilled}
                    country={country}
                    onSelectCountry={onSelectCountry}
                    cancel={cancel}
                    supportedTokens={supportedTokens}
                />
            </Collapse>
            <Collapse in={pfpResults}>
                <DisplayPfpResults />
            </Collapse>
            <div className="my-5">
                <DropAlert
                    condition={claimTransactionId !== null}
                    title={t('asset.purchase_successful')}
                    transactionId={claimTransactionId}
                    closable
                    severity="success"
                />
                <DropAlert
                    condition={error !== null}
                    title="An error occured!"
                    severity="error"
                >
                    {error}
                </DropAlert>
            </div>
        </Popup>
    )
}

export default BuyDropPopup
