import { FormControlLabel, TextField } from '@mui/material'
import { Input } from '@nextui-org/react'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function UnstakeCpuNetPopup(props) {
    const cpu = props['cpu']
    const net = props['net']

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [unstakeNet, setUnstakeNet] = useState(0)
    const [unstakeCpu, setUnstakeCpu] = useState(0)
    const closeCallBack = props['closeCallBack']
    const [error, setError] = useState()

    const { t } = useTranslation('common')

    const unstake = async () => {
        if ((!unstakeNet && !unstakeCpu) || unstakeNet < 0 || unstakeCpu < 0) {
            setError('Must at least unstake a positive amount of CPU or NET')
            return false
        }
        const quantity_net = unstakeNet ? unstakeNet : 0
        const quantity_cpu = unstakeCpu ? unstakeCpu : 0
        setIsLoading(true)
        try {
            const result = await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'eosio',
                            name: 'undelegatebw',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                receiver: userName,
                                unstake_net_quantity: `${quantity_net.toFixed(
                                    8,
                                )} WAX`,
                                unstake_cpu_quantity: `${quantity_cpu.toFixed(
                                    8,
                                )} WAX`,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ wasStaked: true })
            closeCallBack()
        } catch (e) {
            console.error(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const changeNet = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (val === '') {
                setUnstakeNet(0)
            } else {
                setUnstakeNet(Math.min(parseFloat(val), net))
            }
        }
    }

    const changeCpu = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (val === '') {
                setUnstakeCpu(0)
            } else {
                setUnstakeCpu(Math.min(parseFloat(val), cpu))
            }
        }
    }

    const dismissError = () => {
        setError(null)
    }

    const cancel = () => {
        callBack({ wasStaked: false })
        closeCallBack()
    }

    return (
        <Popup title={'Unstake CPU / NET'} cancel={cancel}>
            <div className="p-2 ">
                <div className="mx-auto mb-4 text-lg font-bold text-center">
                    Unstaking {unstakeCpu} WAX from CPU and {unstakeNet} WAX
                    from NET
                </div>
                <div className={cn('flex mb-2')}>
                    <div className="items-start justify-start ">
                        <FormControlLabel
                            control={
                                <Input
                                    variant="faded"
                                    type="text"
                                    placeholder={'CPU'}
                                    onChange={changeCpu}
                                    value={unstakeCpu ? unstakeCpu : ''}
                                />
                            }
                            label={
                                <div
                                    className={
                                        'm-auto my-2  font-semibold  hover:underline cursor-pointer'
                                    }
                                    onClick={() => setUnstakeCpu(cpu)}
                                >
                                    Available: <br></br> {cpu ? cpu : 0}
                                </div>
                            }
                            labelPlacement="top"
                        />
                    </div>
                    <div className="flex items-start justify-start ">
                        <FormControlLabel
                            control={
                                <Input
                                    variant="faded"
                                    type="text"
                                    placeholder={'NET'}
                                    onChange={changeNet}
                                    value={unstakeNet ? unstakeNet : ''}
                                />
                            }
                            label={
                                <div
                                    className={
                                        'm-auto my-2  font-semibold hover:underline cursor-pointer'
                                    }
                                    onClick={() => setUnstakeNet(net)}
                                >
                                    Available: <br></br> {net ? net : 0}
                                </div>
                            }
                            labelPlacement="top"
                        />
                    </div>
                </div>
                <div className="flex justify-center gap-4 mt-4">
                    <MainButton
                        className={'mt-0 bg-red-500  text-black h-12 mt-auto'}
                        onClick={cancel}
                    >
                        Cancel
                    </MainButton>
                    <MainButton
                        className={'mt-0 h-12 mt-auto'}
                        onClick={unstake}
                        disabled={!unstakeNet && !unstakeCpu}
                    >
                        Unstake
                    </MainButton>
                </div>

                {error ? (
                    <div onClick={dismissError}>
                        <ErrorMessage error={error} />
                    </div>
                ) : (
                    ''
                )}
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default UnstakeCpuNetPopup
