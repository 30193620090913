export const checkIpfs = (ipfs) => {
    const isIPFS = require('is-ipfs')

    if (ipfs && ipfs.indexOf('/') === 46) {
        const hash = ipfs.substr(0, 46)

        return isIPFS.multihash(hash)
    } else {
        return isIPFS.multihash(ipfs)
    }
}

export const checkCID = (ipfs) => {
    const isIPFS = require('is-ipfs')

    if (ipfs && ipfs.indexOf('/') === 59) {
        const hash = ipfs.substr(0, 46)

        return isIPFS.cid(hash)
    } else {
        return isIPFS.cid(ipfs)
    }
}

export const ipfsToCID = (ipfs) => {
    const CID = require('cid-tool')

    return CID.format(ipfs, { format: '%M', base: 'base58btc' })
}
