import DeleteIcon from '@mui/icons-material/Delete'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function DeletePackPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const packId = props['packId']
    const releaseId = props['releaseId']

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            authorized_account: userName,
            collection_name: collectionName,
            pack_id: packId,
        }

        const action = {
            account: 'nfthivepacks',
            name: 'delpack',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: data,
        }

        actions.push(action)
        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(!transferError, transferError ? [transferError] : null, {
            packReleaseId: releaseId,
            transactionId: transactionId,
        })
    }

    const cancel = () => {
        callBack(false, null, {
            packReleaseId: releaseId,
        })
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Delete Pack" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to delete Pack {packId} ({collectionName})?
            </div>
            <div>
                <div className="flex justify-center text-xl font-bold my-10">
                    This Action cannot be undone!
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={create}
                        startIcon={<DeleteIcon />}
                    >
                        Delete Pack
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default DeletePackPopup
