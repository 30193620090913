import { useCallback, useState } from 'react'

// simple storage interface to read / write to a memory store. This can only store strings, as localStorage can only store strings
export const Storage = () => {
    const storage = {}

    const add = (key, value) => {
        if (typeof key !== 'string') throw new TypeError('key must be a string')
        if (value !== undefined && typeof value !== 'string')
            throw new TypeError('value must be a string')
        storage[key] = value
    }
    const get = (key) => storage[key]
    const remove = (key) => delete storage[key]
    const clear = () => Object.keys(storage).forEach((key) => remove(key))

    return {
        add,
        get,
        remove,
        clear,
    }
}

export const setWithExpiry = (key, value, ttl) => {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    if (JSON.stringify(item).length < 289821) {
        localStorage.setItem(key, JSON.stringify(item))
    }
}

const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export const LocalStorage = () => {
    return {
        add: (key, value) => setWithExpiry(key, value, 300000),
        get: (key) => getWithExpiry(key),
        remove: (key) => localStorage.removeItem(key),
        clear: () => localStorage.clear(),
    }
}

export const storage = LocalStorage() // create a global store

export const useStorage = (
    key,
    { store = storage, guard = (_) => true, defaultValue = undefined } = {},
) => {
    // create a simple read function that ensures the value is defined by us or undefined
    const read = useCallback(() => {
        try {
            const value = JSON.parse(store.get(key))
            if (!guard(value)) return defaultValue
            return value
        } catch (e) {
            return defaultValue
        }
    }, [key])

    // create a simple write function that correctly writes to the store
    const write = useCallback(
        (value) => {
            if (!guard(value))
                return console.error('useStorage: value is not allowed')
            store.add(key, JSON.stringify(value))
        },
        [key],
    )

    const [value, setValue] = useState(read())

    // create a change function that will be user facing, it will update the state and write to the store
    const change = useCallback(
        (value) => {
            setValue(value)
            write(value)
        },
        [setValue, write],
    )

    return [value, change]
}
