import { TextField } from '@mui/material'
import { Input } from '@nextui-org/react'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { DropAlert } from '../drops/DropAlert'
import { DropButton } from '../drops/DropButton'
import { formatToken } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function UnstakeHoneyPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']

    const balance = props['balance']

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [sellPrice, setSellPrice] = useState(balance)
    const token = props['token']
    const digits = props['digits']

    const transfer = async () => {
        if (!sellPrice) return
        const quantity = parseFloat(sellPrice)
        closeCallBack()
        setIsLoading(true)
        try {
            await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'nfthivevault',
                            name: 'unstake',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                staker: userName,
                                amount: `${quantity.toFixed(digits)} ${token}`,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({
                transferred: true,
                receiver: 'nfthivevault',
                quantity: quantity,
                error: error,
            })
        } catch (e) {
            console.error(e)
            setError(e.message)
            callBack({
                transferred: false,
                receiver: null,
                quantity: null,
                error: e.message,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const cancel = () => {
        callBack({
            transferred: false,
            receiver: null,
            quantity: null,
            error: error,
        })
        closeCallBack()
    }

    return (
        <Popup
            title={t('asset.unstake')}
            cancel={cancel}
            image={'/Logo_HONEY.png'}
            imgSize={'small'}
        >
            <div className="p-2">
                <div className="mx-auto mt-2 mb-4 text-lg font-bold text-center lg:mt-0">
                    Choose the amount of HONEY you want to unstake.
                </div>
                <div className="flex justify-center mb-4 text-lg font-bold text-center">
                    <div>{`${t('navigation.balance')}`}: </div>
                    <div
                        className={cn({
                            'text-primary cursor-pointer hover:underline':
                                balance !== sellPrice,
                        })}
                        onClick={() => setSellPrice(balance)}
                    >
                        {balance && formatToken(balance, token, digits)}
                    </div>
                </div>
                <div className={cn('flex flex-wrap justify-center')}>
                    <div className="w-2/3 md:w-1/3">
                        <Input
                            variant="faded"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            label={token}
                            onChange={(e) => {
                                setSellPrice(
                                    Math.min(
                                        parseFloat(e.target.value),
                                        balance,
                                    ),
                                )
                            }}
                            inputProps={{ min: 0 }}
                            value={sellPrice}
                        />
                    </div>
                </div>

                <div className="flex justify-center gap-2 mt-4 ">
                    <MainButton className="bg-red-500 " onClick={cancel}>
                        {t('asset.cancel')}
                    </MainButton>
                    <MainButton
                        disabled={
                            !sellPrice ||
                            parseFloat(sellPrice) > parseFloat(balance)
                        }
                        onClick={transfer}
                    >
                        {t('asset.unstake')}
                    </MainButton>
                </div>

                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : null}
                <DropAlert severity="error" condition={error}>
                    {error}
                </DropAlert>
            </div>
        </Popup>
    )
}

export default UnstakeHoneyPopup
