import SendIcon from '@mui/icons-material/Send'
import {
    Alert,
    AlertTitle,
    Button,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainButton from '../common/util/input/MainButton'
import { floatRegEx } from '../editor/EditorComponents'
import { get, getUser } from '../helpers/Api'
import { formatWAX, getSellingElement } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import { InputAssetListItem } from './InputAssetListItem'

export const TransferInput = () => {
    const [receiver, setReceiver] = useState(null)
    const [memo, setMemo] = useState(null)
    const [isValidUser, setIsValidUser] = useState(undefined)
    const [transferType, setTransferType] = useState('transfer')
    const [sellPrice, setSellPrice] = useState(0)
    const [days, setDays] = useState(1)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [collectionFee, setCollectionFee] = useState(null)
    const [collectionFees, setCollectionFees] = useState(null)
    const [bulkSellPrices, setBulkSellPrices] = useState({})
    const [changed, setChanged] = useState(0)
    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const { standard } =
        state.selectedAssets && state.selectedAssets.length > 0
            ? state.selectedAssets[0]
            : { standard: 'atomicassets' }

    const marketplace =
        standard === 'atomicassets' ? 'atomicmarket' : 'waxplorercom'

    let cut = sellPrice - 0.03 * sellPrice
    if (collectionFee) cut = cut - collectionFee * sellPrice

    let collectionCut = 0
    if (collectionFees && Object.keys(collectionFees).length > 0) {
        cut = 0
        for (const asset of state.selectedAssets) {
            const collection = asset.author
            const assetPrice = parseFloat(
                bulkSellPrices[asset.assetId]
                    ? bulkSellPrices[asset.assetId]
                    : 0,
            )
            const cCut = assetPrice * collectionFees[collection]
            cut += assetPrice ? assetPrice - 0.04 * assetPrice - cCut : 0
            collectionCut += cCut
        }
    }

    const [transferActions, setTransferActions] = useState({
        transfer: 'Transfer',
    })

    const changeReceiver = (e) => {
        const val = e.target.value.trim().toLowerCase()
        setIsValidUser(undefined)
        setReceiver(val)
    }

    const changeMemo = (e) => {
        const val = e.target.value
        setMemo(val)
    }

    const handleTransferred = (transferred) => {
        const errors = transferred['errors']
        const transferItems = state.selectedAssets
        const transferredItems = {}
        const newSelected = []

        if (transferred['transferred']) {
            transferItems.map((item) => {
                if (
                    !errors ||
                    (!Object.keys(errors).includes(
                        item['assetId'].toString(),
                    ) &&
                        !Object.keys(errors).includes(
                            item['aAssetId'].toString(),
                        ))
                ) {
                    const localId =
                        item['standard'] === 'atomicassets'
                            ? item['aAssetId']
                            : item['assetId']
                    transferredItems[localId] = item
                    transferredItems[localId]['newOwner'] = receiver
                    transferredItems[localId]['transferred'] = true
                } else {
                    newSelected.push(item)
                }
            })
            dispatch({
                type: 'SET_TRANSFERRED_ASSETS',
                payload: transferredItems,
            })
            dispatch({
                type: 'SET_SELECTED_ASSETS',
                payload: newSelected,
            })
        }

        dispatch({
            type: 'SET_ERROR_ASSETS',
            payload: errors,
        })
    }

    const { t } = useTranslation('common')

    const removeItem = async (asset) => {
        const newSelected = []
        state.selectedAssets.map((item) => {
            if (item.assetId !== asset.assetId) {
                newSelected.push(item)
            }
        })
        dispatch({
            type: 'SET_SELECTED_ASSETS',
            payload: newSelected,
        })
    }

    const transfer = async () => {
        const user = await getUser(receiver, state)
        setIsValidUser(user !== null)

        if (user !== null) {
            if (state.selectedAssets.length > 0) {
                const transferItems = {}
                for (const asset of state.selectedAssets) {
                    const localId =
                        asset['standard'] === 'atomicassets'
                            ? asset['aAssetId']
                            : asset['assetId']
                    transferItems[localId] = asset
                }
                dispatch({
                    type: 'SET_ASSETS',
                    payload: transferItems,
                })
                dispatch({ type: 'SET_RECEIVER', payload: receiver })
                dispatch({ type: 'SET_MEMO', payload: memo })
                dispatch({ type: 'SET_ACTION', payload: 'bulk_transfer' })
                dispatch({
                    type: 'SET_CALLBACK',
                    payload: (transferred) => handleTransferred(transferred),
                })
            }
        }
    }

    const changePrice = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) setSellPrice(val)
    }

    const changeHours = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setHours(val)
    }

    const changeMinutes = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setMinutes(val)
    }

    const changeDays = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setDays(val)
    }

    const getCollectionFee = async (author) => {
        get('collection-fee/' + author).then((result) =>
            setCollectionFee(result['fee']),
        )
    }

    const bulksell = async () => {
        setIsLoading(true)

        let transferError = null

        const actions = [getBoostAction(activeUser)]

        for (const asset of state.selectedAssets) {
            if (asset.standard === 'atomicassets') {
                actions.push({
                    account: 'atomicmarket',
                    name: 'announcesale',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        seller: userName,
                        maker_marketplace:
                            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                ? 'nfthiveboost'
                                : 'nft.hive',
                        settlement_symbol: '8,WAX',
                        asset_ids: [asset.aAssetId],
                        listing_price:
                            parseFloat(bulkSellPrices[asset.assetId]).toFixed(
                                8,
                            ) + ' WAX',
                    },
                })
                actions.push({
                    account: 'atomicassets',
                    name: 'createoffer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        sender: userName,
                        recipient: 'atomicmarket',
                        sender_asset_ids: [asset.aAssetId],
                        recipient_asset_ids: [],
                        memo: 'sale',
                    },
                })
            } else {
                actions.push({
                    account: 'waxplorercom',
                    name: 'announcesale',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        seller: userName,
                        asset_ids: [asset.assetId],
                        listing_price:
                            parseFloat(bulkSellPrices[asset.assetId]).toFixed(
                                8,
                            ) + ' WAX',
                    },
                })
                actions.push({
                    account: 'simpleassets',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'waxplorercom',
                        assetids: [asset.assetId],
                        memo: 'sale',
                    },
                })
            }
        }

        try {
            await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        } catch (e) {
            console.error(e)
            setError(e.message)
            transferError = e
        } finally {
            setIsLoading(false)
            handleTransferred({
                transferred: !transferError,
                market: marketplace,
            })
        }
    }

    const sell = async () => {
        setIsLoading(true)

        let transferError = null

        const atomicAssetIds = []
        const simpleAssetIds = []
        const actions = [getBoostAction(activeUser)]

        const assets = state.selectedAssets

        Object.keys(assets).map((assetId) => {
            const asset = assets[assetId]

            if (asset.standard === 'atomicassets')
                atomicAssetIds.push(asset.aAssetId)
            else if (asset.standard === 'simpleassets')
                simpleAssetIds.push(asset.assetId)
        })

        if (atomicAssetIds.length > 0) {
            actions.push({
                account: 'atomicmarket',
                name: 'announcesale',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    seller: userName,
                    maker_marketplace:
                        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                            ? 'nfthiveboost'
                            : 'nft.hive',
                    settlement_symbol: '8,WAX',
                    asset_ids: atomicAssetIds,
                    listing_price: parseFloat(sellPrice).toFixed(8) + ' WAX',
                },
            })
            actions.push({
                account: 'atomicassets',
                name: 'createoffer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    sender: userName,
                    recipient: 'atomicmarket',
                    sender_asset_ids: atomicAssetIds,
                    recipient_asset_ids: [],
                    memo: 'sale',
                },
            })
        }

        if (simpleAssetIds.length > 0) {
            actions.push({
                account: 'waxplorercom',
                name: 'announcesale',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    seller: userName,
                    asset_ids: simpleAssetIds,
                    listing_price: parseFloat(sellPrice).toFixed(8) + ' WAX',
                },
            })
            actions.push({
                account: 'simpleassets',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    from: userName,
                    to: 'waxplorercom',
                    assetids: simpleAssetIds,
                    memo: 'sale',
                },
            })
        }

        try {
            await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        } catch (e) {
            console.error(e)
            setError(e.message)
            transferError = e
        } finally {
            setIsLoading(false)
            handleTransferred({
                transferred: !transferError,
                market: marketplace,
            })
        }
    }

    const auction = async () => {
        if (!sellPrice) return
        const quantity = parseFloat(sellPrice)
        const assets = state.selectedAssets.map((asset) => asset.aAssetId)
        setIsLoading(true)
        try {
            await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'atomicmarket',
                            name: 'announceauct',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                duration:
                                    (days ? parseInt(days) * 24 * 60 * 60 : 0) +
                                    (hours ? parseInt(hours) * 60 * 60 : 0) +
                                    (minutes ? parseInt(minutes) * 60 : 0),
                                starting_bid: quantity.toFixed(8) + ' WAX',
                                seller: userName,
                                maker_marketplace:
                                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                        ? 'nfthiveboost'
                                        : 'nft.hive',
                                asset_ids: assets,
                            },
                        },
                        {
                            account: 'atomicassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                memo: 'auction',
                                asset_ids: assets,
                                to: 'atomicmarket',
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            handleTransferred({
                transferred: true,
                market: marketplace,
                offer: quantity,
            })
        } catch (e) {
            handleTransferred({ transferred: false })
            console.error(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const parseCollectionFees = (res) => {
        const fees = {}
        for (const fee of res) {
            fees[fee['collection']] = fee['fee']
        }
        setCollectionFees(fees)
    }

    useEffect(() => {
        const transferActions = {
            transfer: t('asset.transfer'),
            bulk_sell: t('asset.bulk_sell'),
        }
        let sameCollection = state.selectedAssets.length > 0
        let standard = 'atomicassets'

        if (state.selectedAssets && state.selectedAssets.length > 0) {
            let collection = state.selectedAssets[0].author
            for (const asset of state.selectedAssets) {
                if (
                    collection !== asset.author ||
                    standard !== asset.standard
                ) {
                    sameCollection = false
                }
            }
        }

        if (sameCollection) {
            transferActions['sell'] = t('asset.bundle_sell')
            transferActions['auction'] = t('asset.bundle_auction')
        }

        setTransferActions(transferActions)

        if (!sameCollection && transferType !== 'transfer') {
            setTransferType('transfer')
        }

        if (sameCollection) {
            getCollectionFee(state.selectedAssets[0].author)
        } else {
            const collections = []
            for (const asset of state.selectedAssets) {
                if (!collections.includes(asset.author)) {
                    collections.push(asset.author)
                }
            }
            Promise.all(
                collections.map((collection) =>
                    get('collection-fee/' + collection),
                ),
            ).then(parseCollectionFees)
        }
    }, [state.selectedAssets && state.selectedAssets.length])

    useEffect(() => {}, [changed])

    const changeSellPrice = (e, asset) => {
        const localId = (asset.assets ? asset.assets[0] : asset).assetId
        let sells = bulkSellPrices
        sells[localId] = e.target.value
        if (!e.target.value) delete sells[localId]
        setBulkSellPrices(sells)
        setChanged(changed + 1)
    }

    const priceList = Object.keys(bulkSellPrices).map((key) =>
        bulkSellPrices[key] ? bulkSellPrices[key] : 0,
    )

    let dropDownOptions = [
        { value: 'reduce', label: '1% Below Floor Price' },
        {
            value: 'reduce1Wax',
            label: '1 WAX Below Floor Price',
        },
        {
            value: 'floor',
            label: 'Floor Price',
        },
        {
            value: 'reduceMedian',
            label: 'Set to Median',
        },
    ]

    const reducePrice = (by) => {
        state.selectedAssets.map((asset) => {
            const { lowest, assetId, average } = asset.assets
                ? asset.assets[0]
                : asset

            if (by === 'reduceone' && asset.offer && asset.offer > 1) {
                bulkSellPrices[assetId] = asset.offer - 1
            }
            if (by === '1waxfloor' && lowest && lowest > 1) {
                bulkSellPrices[assetId] = lowest - 1
            }
            if (by === '1percentfloor' && lowest) {
                bulkSellPrices[assetId] = lowest * 0.99
            }
            if (by === 'floor' && lowest) {
                bulkSellPrices[assetId] = lowest
            }
            if (by === 'median' && average) {
                bulkSellPrices[assetId] = average
            }
        })

        setBulkSellPrices(bulkSellPrices)

        setChanged(changed + 1)
    }

    const onSelect = (e) => {
        switch (e.target.value) {
            case 'equalizeImageName':
                reducePrice('equalizename')
                return
            case 'equalizeRarity':
                reducePrice('equalizerarity')
                return
            case 'reduceOne':
                reducePrice('reduceone')
                return
            case 'reduce':
                reducePrice('1percentfloor')
                return
            case 'floor':
                reducePrice('floor')
                return
            case 'reduce1Wax':
                reducePrice('1waxfloor')
                return
            case 'reduceMedian':
                reducePrice('median')
                return
        }
    }

    return (
        <div className={'flex flex-wrap h-1/2 lg:h-full w-full mx-auto p-4'}>
            <div className={'w-full flex justify-center my-2'}>
                <FormControl size="small">
                    <InputLabel>{t('asset.actions')}</InputLabel>
                    <Select
                        label={t('asset.actions')}
                        value={transferType}
                        onChange={(e) => setTransferType(e.target.value)}
                    >
                        {Object.entries(transferActions).map(([key, value]) => (
                            <MenuItem value={key}>{value}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {transferType === 'bulk_sell' ? (
                    <div className="ml-4 w-48">
                        <FormControl fullWidth size="small">
                            <InputLabel>{'AutoFill Options'}</InputLabel>
                            <Select
                                label={'AutoFill Options'}
                                onChange={onSelect}
                                disabled={isLoading}
                            >
                                {dropDownOptions.map((option) => (
                                    <MenuItem value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className={'w-full'}>
                {state.selectedAssets.map((asset) => (
                    <InputAssetListItem
                        asset={asset}
                        transferType={transferType}
                        sellPrice={
                            bulkSellPrices
                                ? bulkSellPrices[
                                      (asset.assets ? asset.assets[0] : asset)
                                          .assetId
                                  ]
                                : ''
                        }
                        changeSellPrice={(e) => changeSellPrice(e, asset)}
                        removeItem={() => removeItem(asset)}
                    />
                ))}
            </div>
            {isLoading ? (
                <div className={'w-full my-4'}>
                    <LinearProgress />
                </div>
            ) : (
                ''
            )}
            {transferType === 'transfer' ? (
                <div className={'w-full mx-2 pb-2'}>
                    <div className={'w-full mb-4 font-bold text-lg'}>
                        {t('search.transfer_x_items', {
                            x: state.selectedAssets.length,
                        })}
                    </div>
                    <div
                        className={
                            'w-full flex flex-col lg:flex-row lg:flex-wrap justify-between mb-4'
                        }
                    >
                        <div className="my-auto xl:w-48">
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label={t('asset.receiver')}
                                variant="outlined"
                                value={receiver}
                                onChange={changeReceiver}
                                spellCheck={false}
                                error={!receiver || isValidUser === false}
                                helperText={
                                    receiver && isValidUser === false
                                        ? 'This user does not exist'
                                        : ' '
                                }
                            />
                        </div>
                        <div className="my-auto xl:w-96">
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label={t('asset.memo')}
                                variant="outlined"
                                value={memo}
                                onChange={changeMemo}
                                spellCheck={false}
                                helperText=" "
                            />
                        </div>
                        <div className="xl:mt-2">
                            <Button
                                fullWidth
                                disabled={!receiver || isValidUser === false}
                                variant="outlined"
                                size="large"
                                onClick={transfer}
                                endIcon={<SendIcon />}
                            >
                                {t('asset.send')}
                            </Button>
                        </div>
                    </div>
                </div>
            ) : transferType === 'auction' ? (
                <div className={'w-full mx-2 pb-2'}>
                    <div className={'w-full mb-4 font-bold text-lg'}>
                        {t('search.auction_x_items_in_a_bundle', {
                            x: state.selectedAssets.length,
                        })}
                    </div>
                    <div
                        className={
                            'w-full flex flex-col lg:flex-row lg:flex-wrap justify-between'
                        }
                    >
                        <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
                            <div>
                                <TextField
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    label={t('search.start_bid')}
                                    onChange={changePrice}
                                    value={sellPrice ? sellPrice : ''}
                                    size="small"
                                    disabled={isLoading ? 'disabled' : ''}
                                />
                            </div>
                            <div>
                                <TextField
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    label={t('popups.days')}
                                    onChange={changeDays}
                                    value={days ? days : ''}
                                    size="small"
                                    disabled={isLoading ? 'disabled' : ''}
                                />
                            </div>
                            <div>
                                <TextField
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    label={t('popups.hours')}
                                    onChange={changeHours}
                                    value={hours ? hours : ''}
                                    size="small"
                                    disabled={isLoading ? 'disabled' : ''}
                                />
                            </div>
                            <div>
                                <TextField
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    label={t('popups.minutes')}
                                    onChange={changeMinutes}
                                    value={minutes ? minutes : ''}
                                    size="small"
                                    disabled={isLoading ? 'disabled' : ''}
                                />
                            </div>
                            <div className={'flex justify-end'}>
                                <MainButton
                                    disabled={
                                        isLoading ||
                                        !sellPrice ||
                                        ((!days || days === '0') &&
                                            (!hours || hours === '0') &&
                                            (!minutes || minutes === '0'))
                                            ? 'disabled'
                                            : ''
                                    }
                                    className={'w-32 max-w-32'}
                                    padding={'pl-4'}
                                    onClick={auction}
                                >
                                    {t('popups.auction')}
                                </MainButton>
                            </div>
                        </div>
                        {error !== null ? (
                            <div className={'w-full my-4'}>
                                <Alert severity="error">
                                    <AlertTitle>An error occured</AlertTitle>
                                    {error}
                                </Alert>
                            </div>
                        ) : null}
                        {collectionFee || collectionFee === 0 ? (
                            <div className="text-1xs leading-3 text-right mt-4 border-t border- w-68 ml-auto border-neutralt pt-4">
                                <div>{t('popups.market_fee')}: 2%</div>
                                <div>{t('popups.defi_fee')}: 1%</div>
                                <div>{`${t('popups.collection_fee')}: ${
                                    collectionFee * 100
                                }%`}</div>
                                <div>
                                    {t('popups.your_cut')}: {cut} WAX
                                </div>
                            </div>
                        ) : (
                            <LoadingIndicator />
                        )}
                    </div>
                </div>
            ) : transferType === 'sell' ? (
                <div className={'w-full mx-2 pb-2'}>
                    <div className={'w-full mb-4 font-bold text-lg'}>
                        {t('search.selling_x_items_in_a_bundle', {
                            x: state.selectedAssets.length,
                        })}
                    </div>
                    <div
                        className={
                            'w-full flex flex-col lg:flex-row lg:flex-wrap justify-between'
                        }
                    >
                        <div className="w-full grid grid-cols-2 md:grid-cols-2 gap-2">
                            <div>
                                <TextField
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    label={t('search.price')}
                                    onChange={changePrice}
                                    value={sellPrice ? sellPrice : ''}
                                    size="small"
                                    disabled={isLoading ? 'disabled' : ''}
                                />
                            </div>
                            <div className={'flex justify-end'}>
                                <MainButton
                                    disabled={isLoading || !sellPrice}
                                    className={'w-32 max-w-32'}
                                    padding={'pl-4'}
                                    onClick={sell}
                                >
                                    {t('asset.sell')}
                                </MainButton>
                            </div>
                        </div>
                        {error !== null ? (
                            <div className={'w-full my-4'}>
                                <Alert severity="error">
                                    <AlertTitle>An error occured</AlertTitle>
                                    {error}
                                </Alert>
                            </div>
                        ) : null}
                        {collectionFee || collectionFee === 0 ? (
                            <div className="text-1xs leading-3 text-right mt-4 border-t border- w-68 ml-auto border-neutralt pt-4">
                                <div>{t('popups.market_fee')}: 2%</div>
                                <div>{t('popups.defi_fee')}: 1%</div>
                                <div>{`${t('popups.collection_fee')}: ${
                                    collectionFee * 100
                                }%`}</div>
                                <div>
                                    {t('popups.your_cut')}: {cut} WAX
                                </div>
                            </div>
                        ) : (
                            <LoadingIndicator />
                        )}
                    </div>
                </div>
            ) : transferType === 'bulk_sell' ? (
                <div className={'w-full mx-2 pb-2'}>
                    <div className={'w-full mb-4 font-bold text-lg'}>
                        {getSellingElement(
                            t('search.selling_x_items_for_y', {
                                x: state.selectedAssets.length,
                                y:
                                    bulkSellPrices !== {}
                                        ? formatWAX(
                                              priceList && priceList.length > 0
                                                  ? priceList.reduce(
                                                        (a, b) =>
                                                            parseFloat(a) +
                                                            parseFloat(b),
                                                    )
                                                  : 0,
                                          )
                                        : 0,
                            }),
                        )}
                    </div>
                    <div
                        className={
                            'w-full flex flex-col lg:flex-row lg:flex-wrap justify-between'
                        }
                    >
                        <div className="w-full">
                            <div className={'flex justify-end'}>
                                <MainButton
                                    disabled={
                                        isLoading ||
                                        !bulkSellPrices ||
                                        Object.keys(bulkSellPrices).length !==
                                            state.selectedAssets.length ||
                                        !floatRegEx.test(
                                            priceList && priceList.length > 0
                                                ? priceList.reduce(
                                                      (a, b) =>
                                                          parseFloat(a) +
                                                          parseFloat(b),
                                                  )
                                                : 0,
                                        )
                                    }
                                    className={'w-32 max-w-32'}
                                    padding={'pl-4'}
                                    onClick={bulksell}
                                >
                                    {t('asset.sell')}
                                </MainButton>
                            </div>
                        </div>
                        {error !== null ? (
                            <div className={'w-full my-4'}>
                                <Alert severity="error">
                                    <AlertTitle>An error occured</AlertTitle>
                                    {error}
                                </Alert>
                            </div>
                        ) : null}
                        {collectionCut || collectionCut === 0 ? (
                            <div className="text-1xs leading-3 text-right mt-4 border-t border- w-68 ml-auto border-neutralt pt-4">
                                <div>{t('popups.market_fee')}: 2%</div>
                                <div>{t('popups.defi_fee')}: 1%</div>
                                <div>{`${t(
                                    'popups.collection_fee',
                                )}: ${formatWAX(collectionCut)}`}</div>
                                <div>
                                    {t('popups.your_cut')}: {formatWAX(cut)}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    )
}
