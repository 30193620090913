import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckIndicator from '../checkindicator/CheckIndicator'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function TubePopup(props) {
    const assets = props['assets']
    const tubeId = props['tubeId']
    const lowestMints = props['lowestMints']

    const receiver = 'tubes.kogs'

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [tubed, setTubed] = useState(false)
    const [error, setError] = useState(null)
    const closeCallBack = props['closeCallBack']
    const [errors, setErrors] = useState({})
    const [transferred, setTransferred] = useState([])
    const [transactions, setTransactions] = useState({})

    const addError = (assetId, e) => {
        errors[assetId] = e
        setErrors(errors)
    }

    const addTransaction = (assetId, tx) => {
        transactions[assetId] = tx
        setTransactions(transactions)
    }

    const transfer = async () => {
        setIsLoading(true)

        let transferError = null

        const atomicAssetIds = []
        const actions = [getBoostAction(activeUser)]

        Object.keys(assets).map((assetId) => {
            const asset = assets[assetId]

            if (asset.assets.length > 1) {
                if (lowestMints) {
                    atomicAssetIds.push(
                        asset.assets
                            .filter(
                                (asset) =>
                                    asset.status === 'inventory' ||
                                    asset.status === 'for_sale',
                            )
                            .sort((a, b) => a.mint - b.mint)[0].aasset_id,
                    )
                } else {
                    atomicAssetIds.push(
                        asset.assets
                            .filter(
                                (asset) =>
                                    asset.status === 'inventory' ||
                                    asset.status === 'for_sale',
                            )
                            .sort((a, b) => b.mint - a.mint)[0].aasset_id,
                    )
                }
            } else {
                if (
                    asset.assets[0].status === 'inventory' ||
                    asset.assets[0].status === 'for_sale'
                )
                    atomicAssetIds.push(asset.assets[0].aasset_id)
            }
        })
        if (atomicAssetIds.length > 0) {
            const quantity = 5
            actions.push({
                account: 'eosio.token',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    from: userName,
                    to: receiver,
                    quantity: `${quantity.toFixed(8)} WAX`,
                    memo: 'Tube fee',
                },
            })
            actions.push({
                account: 'atomicassets',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    from: userName,
                    memo: tubeId,
                    asset_ids: atomicAssetIds,
                    to: receiver,
                },
            })
        }

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            Object.keys(assets)
                .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                .map((assetId) =>
                    addTransaction(
                        assetId,
                        result['response']['transaction_id'],
                    ),
                )
        } catch (e) {
            Object.keys(assets).map((asset) => addError(asset, e.message))
            setError(e.message)
            transferError = e
        } finally {
            if (!error && !transferError) {
                Object.keys(assets)
                    .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                    .map((assetId) => transferred.push(assets[assetId]))
                setTransferred(true)
            }
        }
        setIsLoading(false)
        setTubed(!errors || Object.keys(errors).length === 0)
        callBack({
            transferred: !errors || Object.keys(errors).length === 0,
            errors: errors,
            transactionIds: transactions,
        })
    }

    let transferInfo = t('asset.transfer')

    const transferField = (
        <MainButton className="w-32 max-w-32" onClick={transfer}>
            {transferInfo}
        </MainButton>
    )

    const cancel = () => {
        callBack({
            transferred: tubed,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title={t('search.bulk_transfer')} cancel={cancel}>
            {tubed ? <CheckIndicator /> : ''}
            {!tubed && Object.keys(transferred).length === 0 ? (
                <div className="mx-auto text-xl font-bold mb-4">
                    {t(
                        'popups.are_you_sure_you_want_to_create_a_tube_from_assets',
                        {
                            number: Object.keys(assets).length,
                            account: receiver,
                        },
                    )}
                </div>
            ) : (
                ''
            )}
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage error={error} />
                </div>
            ) : (
                ''
            )}
            {Object.keys(transferred).length === 0 ? (
                <div className="relative flex justify-end">
                    <MainButton onClick={cancel}>
                        {t('asset.cancel')}
                    </MainButton>
                    {!tubed ? transferField : ''}
                </div>
            ) : (
                ''
            )}
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default TubePopup
