import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import MainButton from '../common/util/input/MainButton'
import { Input } from '@nextui-org/react'

function UpdateDropAccountLimitPopups(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const drop = props['drop']
    const oldAccountLimit = props['dropAccountLimit']
    const [newAccountLimit, setNewAccountLimit] = useState(
        props['dropAccountLimit'],
    )
    const oldAccountLimitCooldown = props['dropAccountLimitCooldown']
    const [newAccountLimitCooldown, setNewAccountLimitCooldown] = useState(
        props['dropAccountLimitCooldown'],
    )

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            authorized_account: userName,
            drop_id: drop.dropId,
            account_limit: newAccountLimit,
            account_limit_cooldown: newAccountLimitCooldown,
        }

        const action = {
            account: 'nfthivedrops',
            name: 'setdroplimit',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: data,
        }

        actions.push(action)
        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Change drop account limits" cancel={cancel}>
            <div className="p-2 ">
                <div className="mb-4 text-lg font-bold text-center">
                    Do you want to update the account limits of Drop{' '}
                    {drop.dropId} ({collectionName})?
                </div>
                <div>
                    <div className="grid grid-cols-1 gap-4 my-6 md:grid-cols-2">
                        <div className="col-span-1 ">
                            <div className=" md:min-h-[40px]">
                                {`Current account limit: ${
                                    oldAccountLimit > 0
                                        ? oldAccountLimit
                                        : 'Infinite'
                                }`}
                            </div>
                            <div className="mt-5">
                                <Input
                                    variant="faded"
                                    onWheel={(e) => e.target.blur()}
                                    placeholder="0"
                                    value={newAccountLimit}
                                    onChange={(e) =>
                                        setNewAccountLimit(
                                            parseInt(e.target.value) >= 0
                                                ? parseInt(e.target.value)
                                                : 0,
                                        )
                                    }
                                    label="New account limit"
                                />
                            </div>

                            <div className="mt-2">Set to 0 for 'Infinite'</div>
                        </div>
                        <div className="col-span-1 ">
                            <div className="">
                                {`Current account limit cooldown: ${
                                    oldAccountLimitCooldown > 0
                                        ? oldAccountLimitCooldown
                                        : 'No Cooldown'
                                }`}
                            </div>
                            <div className="mt-5">
                                <Input
                                    placeholder="0"
                                    variant="faded"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    value={newAccountLimitCooldown}
                                    onChange={(e) =>
                                        setNewAccountLimitCooldown(
                                            parseInt(e.target.value) >= 0
                                                ? parseInt(e.target.value)
                                                : 0,
                                        )
                                    }
                                    label={t(
                                        'drops.new_account_limit_cooldown',
                                    )}
                                />
                            </div>
                            <div className="mt-2">
                                {t('drops.set_0_for_no_cooldown')}
                            </div>
                        </div>
                    </div>
                </div>
                {error ? (
                    <div
                        className="relative w-auto h-auto"
                        onClick={dismissError}
                    >
                        <ErrorMessage layer={5} error={error} />
                    </div>
                ) : (
                    ''
                )}
                <div className="flex flex-row justify-center">
                    <div className="mx-2">
                        <MainButton className="bg-red-500 " onClick={cancel}>
                            Cancel
                        </MainButton>
                    </div>
                    <div className="mx-2">
                        <MainButton onClick={create}>Update Drop</MainButton>
                    </div>
                </div>
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default UpdateDropAccountLimitPopups
