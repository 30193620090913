import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import NavigationSearch from '../searchitems/NavigationSearch'
import { buttonSearchCommonStyles } from '../common/styles'
import { cn } from '@nextui-org/react'
import { useTheme } from 'next-themes'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px',
}

export default function BasicModal() {
    const [modalOpen, setModalOpen] = React.useState(false)
    const handleClose = () => setModalOpen(false)
    const { theme } = useTheme()
    const { t } = useTranslation('common')

    return (
        <div>
            <button
                onClick={setModalOpen}
                className={cn(
                    'bg-zinc-200 hover:bg-zinc-400 dark:!bg-zinc-900 hover:dark:!bg-zinc-800 h-full flex items-center justify-center rounded-md px-3 py-3 text-[16px] font-normal leading-6 dark:text-white text-black',
                )}
            >
                <img
                    src={
                        theme === 'light'
                            ? '/icons/magnifying-glass-black.svg'
                            : '/icons/magnifying-glass-neutral.svg'
                    }
                    className={'h-5 w-5 '}
                    aria-hidden="true"
                    alt={t('navigation.search')}
                    width={150}
                    height={150}
                />
            </button>
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <NavigationSearch />
                </Box>
            </Modal>
        </div>
    )
}
