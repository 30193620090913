import { NextUIProvider } from '@nextui-org/react'
import cn from 'classnames'
import { ThemeProvider as NextThemesProvider } from 'next-themes'
import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { SSRProvider } from 'react-bootstrap'
import CookieConsent from 'react-cookie-consent'
import 'react-dropdown/style.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'regenerator-runtime'
import FullImageView from '../components/fullimageview/FullImageView'
import BottomNavigation from '../components/navigationnew/BottomNavigation'
import NewNavigation from '../components/navigationnew/NewNavigation'
import PopupWrapper from '../components/popups/PopupWrapper'
import PopupWrapperSearch from '../components/popups/PopupWrapperSearch'
import Waxplorer, { Context } from '../components/waxplorer/Waxplorer'
import '../styles/App.css'
import '../styles/globals.css'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { currentThemeAtom } from '../utils/store'
import { useAtom } from 'jotai'
import { Toaster } from '../components/ui/toaster'
import { register } from 'swiper/element/bundle'

register()
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const queryClient = new QueryClient()

const MyApp = ({ Component, pageProps }) => {
    const getDesignTokens = (mode) => ({
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                      primary: {
                          main: '#F6C30D',
                      },
                      secondary: {
                          main: '#F6C30D',
                      },
                      default: {
                          main: '#F6C30D',
                      },
                      info: {
                          main: '#F6C30D',
                      },
                      neutral: {
                          main: '#FEFEFE',
                      },
                      text: {
                          disabled: '#777',
                      },
                      action: {
                          disabled: '#777',
                          disabledBorder: '#777',
                      },
                  }
                : {
                      // palette values for dark mode
                      primary: {
                          main: '#F6C30D',
                      },
                      secondary: {
                          main: '#F6C30D',
                      },
                      default: {
                          main: '#F6C30D',
                      },
                      info: {
                          main: '#F6C30D',
                      },
                      neutral: {
                          main: '#FEFEFE',
                      },
                      text: {
                          disabled: '#777',
                      },
                      action: {
                          disabled: '#777',
                          disabledBorder: '#777',
                      },
                  }),
        },
    })

    const AppContainer = (props) => {
        const [state, dispatch] = useContext(Context)
        const [currentTheme, _] = useAtom(currentThemeAtom)

        const theme = React.useMemo(
            () => createTheme(getDesignTokens(currentTheme)),
            [currentTheme],
        )

        const toggleImage = () => {
            dispatch({
                type: 'FULL_IMAGE',
                payload: null,
            })
        }
        const displayImage = state.full_image

        const router = useRouter()
        const location = router.asPath.substring(
            0,
            router.asPath.indexOf('?') > 0
                ? router.asPath.indexOf('?')
                : router.asPath.length,
        )
        const isMarket = location.includes('/market')
        const isDrops = location.includes('/drop')
        const isUser =
            location.includes('/user/') || location.includes('/account')

        const isCollection = location.includes('/collection/')
        const isBulk = location.includes('/bulk')

        const hasContentScroll =
            isMarket || isDrops || isUser || isCollection || isBulk

        useEffect(() => {
            if (!localStorage.getItem('next-theme')) {
                localStorage.setItem('next-theme', 'dark')
            }
            if (!localStorage.getItem('theme')) {
                localStorage.setItem('theme', '"dark"')
            }
        }, [])

        return (
            <NextUIProvider>
                <NextThemesProvider
                    disableTransitionOnChange="true"
                    attribute="class"
                    defaultTheme="system"
                    storageKey="next-theme"
                >
                    <ThemeProvider theme={theme}>
                        <div
                            className={cn(
                                ' h-screen overflow-hidden relative bg-white dark:bg-appl dark:md:bg-app',
                            )}
                        >
                            <PopupWrapper {...props} />
                            <PopupWrapperSearch {...props} />
                            <NewNavigation />
                            <div
                                className={cn(
                                    'h-[calc(100vh-3.6rem-3.5rem)] lg:h-[calc(100vh-5.5rem)] flex flex-wrap',
                                )}
                            >
                                <div
                                    className={cn(
                                        'h-full w-full transform transition-width duration-200 overflow-x-hidden scrolling-touch InsideScroll',
                                    )}
                                >
                                    <Component
                                        {...props}
                                        hasContentScroll={hasContentScroll}
                                    />
                                </div>
                            </div>
                            {displayImage ? (
                                <FullImageView
                                    image={displayImage}
                                    toggleFunc={toggleImage}
                                />
                            ) : (
                                ''
                            )}
                            <Toaster />

                            <BottomNavigation />
                        </div>
                    </ThemeProvider>
                </NextThemesProvider>
            </NextUIProvider>
        )
    }

    return (
        <SSRProvider>
            <Waxplorer>
                <CookieConsent>
                    This website uses cookies to enhance the user experience.
                </CookieConsent>
                <QueryClientProvider client={queryClient}>
                    <AppContainer {...pageProps} />
                </QueryClientProvider>
            </Waxplorer>
        </SSRProvider>
    )
}

export default MyApp
