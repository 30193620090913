import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropButton } from '../drops/DropButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import MainButton from '../common/util/input/MainButton'
function CreatePackReleasePopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']

    const [usePremint, setUsePremint] = useState(true)

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const createReleaseActions = [getBoostAction(activeUser)]

        const createReleaseData = {
            authorized_account: userName,
            collection_name: collectionName,
            premint: usePremint,
        }

        const createReleaseAction = {
            account: 'nfthivepacks',
            name: 'createreleas',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: createReleaseData,
        }

        createReleaseActions.push(createReleaseAction)
        let createReleaseTransferError = null
        let createReleaseTransactionId = null

        try {
            const createReleaseResult = await activeUser.session.transact(
                {
                    actions: createReleaseActions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            createReleaseTransactionId =
                createReleaseResult?.response?.transaction_id
        } catch (e) {
            createReleaseTransferError = e.message
            setError(e.message)
        }

        if (!createReleaseTransferError) {
            closeCallBack()
            setIsLoading(false)
            callBack(
                !createReleaseTransferError,
                createReleaseTransferError
                    ? [createReleaseTransferError]
                    : null,
                createReleaseTransactionId,
            )
        }

        if (createReleaseTransferError) {
            setIsLoading(false)
            callBack(
                false,
                [createReleaseTransferError],
                createReleaseTransactionId,
            )
        }
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Release" cancel={cancel}>
            <div className="p-2 ">
                <div className="mb-4 text-lg font-bold text-center">
                    Do you want to create a new release for the {collectionName}{' '}
                    collection?
                </div>

                {error ? (
                    <div onClick={dismissError}>
                        <ErrorMessage layer={5} error={error} />
                    </div>
                ) : null}
                <div className="flex flex-row justify-center gap-2 mt-4">
                    <MainButton className="mx-2 bg-red-500 " onClick={cancel}>
                        Cancel
                    </MainButton>
                    <MainButton className="mx-2" onClick={create}>
                        Create Release
                    </MainButton>
                </div>
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : null}
            </div>
        </Popup>
    )
}

export default CreatePackReleasePopup
