import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import cn from 'classnames'
import Link from 'next/link'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { useStorage } from '../../hooks/storage'
import { get, post } from '../helpers/Api'
import { formatNumber } from '../helpers/FormatLinks'
import { getClaimableHoneyBalance, getHoneyBalance } from '../helpers/WaxApi'
import { Picture, UserPicture } from '../user/UserPicture'
import { Context } from '../waxplorer/Waxplorer'
import MobileNavItem from './MobileNavItem'

export const ProfileBottomMenu = ({
    profileMenuOpen,
    setProfileMenuOpen,
    userName,
    logout,
}) => {
    const { t } = useTranslation('common')

    const [imgCache, setImgCache] = useStorage(`image-${userName}`)
    const [balance, setBalance] = useState(null)
    const [honeyBalance, setHoneyBalance] = useState(null)
    const [claimableHoneyBalance, setClaimableHoneyBalance] = useState(null)
    const [showClaimableHoney, setShowClaimableHoney] = useState(false)
    const [userPicture, setUserPicture] = useState(imgCache)

    const [state, dispatch] = useContext(Context)

    const parseUserPicture = (res) => {
        if (res && res.image) {
            setUserPicture(res.image)
            setImgCache(res.image)
        }
        dispatch({ type: 'SET_USER_PICTURE_CHANGED', payload: false })
    }

    const getUserPicture = async (userPictureChanged) => {
        if (imgCache && !userPictureChanged) {
            setUserPicture(imgCache)
        } else if (userPictureChanged) {
            setTimeout(() => {
                get('user-picture/' + userName).then(parseUserPicture)
            }, 3000)
        } else {
            get('user-picture/' + userName).then(parseUserPicture)
        }
    }

    const balanceChanged = state.balanceChanged
    const honeyBalanceChanged = state.honeyBalanceChanged
    const userPictureChanged = state.userPictureChanged

    useEffect(() => {
        if (userName) {
            if (!balance || balanceChanged) getBalance()
            if (!honeyBalance || honeyBalanceChanged)
                getHoneyBalance(userName, state).then((res) =>
                    parseHoneyBalance(res),
                )
            if (!claimableHoneyBalance || honeyBalanceChanged)
                getClaimableHoneyBalance(userName, state).then((res) =>
                    parseClaimableHoneyBalance(res),
                )
            if (!userPicture || userPictureChanged)
                getUserPicture(userPictureChanged)
        }
    }, [balanceChanged, honeyBalanceChanged, userPictureChanged, userName])

    const parseBalance = (res) => {
        if (
            res &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0 &&
            res.data.rows[0].balance
        ) {
            setBalance(parseFloat(res.data.rows[0].balance.replace(' WAX', '')))
        }

        dispatch({ type: 'SET_BALANCE_CHANGED', payload: false })
    }

    const parseHoneyBalance = (res) => {
        if (
            res &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0 &&
            res.data.rows[0].balance
        ) {
            setHoneyBalance(
                parseFloat(res.data.rows[0].balance.replace(' HONEY', '')),
            )
        }

        dispatch({ type: 'SET_HONEY_BALANCE_CHANGED', payload: false })
    }

    const parseClaimableHoneyBalance = (res) => {
        if (
            res &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0 &&
            res.data.rows[0].rewards
        ) {
            const claimableHoney = parseFloat(
                res.data.rows[0].rewards.replace(' HONEY', ''),
            )
            if (claimableHoney > 0) {
                setShowClaimableHoney(true)
            }
            setClaimableHoneyBalance(claimableHoney)
        }

        dispatch({ type: 'SET_HONEY_BALANCE_CHANGED', payload: false })
    }

    const getBalance = async () => {
        const body = {
            code: 'eosio.token',
            index_position: 'primary',
            json: 'true',
            key_type: 'i64',
            limit: 1,
            reverse: 'false',
            scope: userName,
            show_payer: 'false',
            table: 'accounts',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : state.api + '/v1/chain/get_table_rows'
        post(url, body).then((res) => parseBalance(res))
    }

    const shortcuts = [
        {
            name: t('navigation.account'),
            description: t('description.account'),
            href: '/account',
            icon: '/icons/user.svg',
        },
        {
            name: t('profile.inventory'),
            description: t('description.inventory'),
            href: '/user/' + userName + '?tab=inventory#Tabs',
            icon: '/icons/box-heart.svg',
        },
        {
            name: t('search.sales'),
            description: t('description.your_listings'),
            href: '/user/' + userName + '?tab=listings#Tabs',
            icon: '/icons/coins.svg',
        },
        {
            name: t('general.wallet'),
            description: t('description.wallet'),
            href: '/user/' + userName + '?view=wallet',
            icon: '/icons/wallet.svg',
        },
        {
            name: t('asset.unpack') + '/' + t('asset.claim'),
            description: t('description.unpack'),
            href: '/unpack',
            icon: '/icons/gift.svg',
        },
    ]

    const info = [
        {
            name: t('general.honey_info'),
            description: t('description.honey_info'),
            href: '/honey',
            icon: '/tokens/honey_nfthivehoney.png',
            outline: true,
        },
    ]

    const logoutItem = {
        name: t('profile.logout'),
        description: t('description.logout'),
        href: '',
        icon: '/icons/door-open.svg',
        onclick: () => {
            logout()
            setProfileMenuOpen(false)
        },
    }
    return (
        <Dialog
            as="div"
            className="lg:hidden"
            open={profileMenuOpen}
            onClose={() => {
                setTimeout(() => {
                    setProfileMenuOpen(false)
                }, 100)
            }}
        >
            <div className="z-10" />
            <Dialog.Panel className="fixed top-0 bottom-[56px] right-0 z-10 w-full overflow-y-auto bg-white dark:!bg-zinc-900 px-4 py-4 sm:max-w-sm sm:ring-1 sm:ring-white/10 ">
                <div className="flex items-center justify-end">
                    <button
                        type="button"
                        className="-m-2.5 rounded-md p-2.5 text-zinc-900 dark:!text-white"
                        onClick={() => setProfileMenuOpen(false)}
                    >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>

                <div className="mt-2 flow-root text-[14px]">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-1 py-6">
                            <div className={'flex w-full justify-center mb-4'}>
                                <Picture
                                    className="max-h-24 max-w-24 rounded-full p-1.5"
                                    image={userPicture}
                                />

                                <div className={'ml-2 text-left my-auto'}>
                                    <div className={cn('text-lg mb-1')}>
                                        {userName}
                                    </div>
                                    <div className={cn('text-base opacity-60')}>
                                        {formatNumber(
                                            balance || balance === 0
                                                ? balance
                                                : 0.0,
                                        )}{' '}
                                        WAX
                                    </div>
                                    {claimableHoneyBalance ? (
                                        <Link href={'/honey'}>
                                            <Tooltip
                                                title={t(
                                                    'profile.you_have_honey_to_claim',
                                                )}
                                            >
                                                {showClaimableHoney ? (
                                                    <div
                                                        className={cn(
                                                            'text-sm opacity-100 text-primary',
                                                        )}
                                                    >
                                                        {formatNumber(
                                                            claimableHoneyBalance,
                                                        )}{' '}
                                                        HONEY
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={cn(
                                                            'text-sm opacity-60',
                                                        )}
                                                    >
                                                        {formatNumber(
                                                            honeyBalance ||
                                                                honeyBalance ===
                                                                    0
                                                                ? honeyBalance
                                                                : 0.0,
                                                        )}{' '}
                                                        HONEY
                                                    </div>
                                                )}
                                            </Tooltip>
                                        </Link>
                                    ) : (
                                        <div
                                            className={cn('text-sm opacity-60')}
                                        >
                                            {formatNumber(
                                                honeyBalance ||
                                                    honeyBalance === 0
                                                    ? honeyBalance
                                                    : 0.0,
                                            )}{' '}
                                            HONEY
                                        </div>
                                    )}
                                </div>
                            </div>
                            {shortcuts.map((item, index) => (
                                <MobileNavItem
                                    item={item}
                                    key={'MobileNavItemSc' + index}
                                />
                            ))}
                            {info.map((item, index) => (
                                <MobileNavItem
                                    item={item}
                                    key={'MobileNavItemInfo' + index}
                                />
                            ))}
                            <MobileNavItem item={logoutItem} />
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
    )
}
