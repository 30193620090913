import { TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@nextui-org/react'
import CheckIndicator from '../checkindicator/CheckIndicator'
import MainButton from '../common/util/input/MainButton'
import { sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function BulkAuctionPopup(props) {
    const assets = props['assets']
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [errors, setErrors] = useState({})
    const [sold, setSold] = useState([])
    const [transactions, setTransactions] = useState({})
    const [days, setDays] = useState(1)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)

    const maxActions = 10

    const [currentDisplayAction, setCurrentDisplayAction] = useState(0)

    const addError = (assetId, e) => {
        errors[assetId] = e
        setErrors(errors)
    }

    const addTransaction = (assetId, tx) => {
        transactions[assetId] = tx
        setTransactions(transactions)
    }

    const sell = async () => {
        setIsLoading(true)

        let currentAction = 0

        while (currentAction < Object.keys(assets).length / maxActions) {
            const start = currentAction * maxActions
            const end = start + maxActions
            const actions = [getBoostAction(activeUser)]

            let sellError = null

            const assetIds = []

            Object.keys(assets)
                .sort((a, b) => a.assetId - b.assetId)
                .slice(start, end)
                .map((assetId) => {
                    const asset = assets[assetId].asset
                    const price = parseFloat(assets[assetId].price)

                    assetIds.push(assetId)

                    if (asset.standard === 'atomicassets') {
                        actions.push({
                            account: 'atomicmarket',
                            name: 'announceauct',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                duration:
                                    (days ? parseInt(days) * 24 * 60 * 60 : 0) +
                                    (hours ? parseInt(hours) * 60 * 60 : 0) +
                                    (minutes ? parseInt(minutes) * 60 : 0),
                                starting_bid: price.toFixed(8) + ' WAX',
                                seller: userName,
                                maker_marketplace:
                                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                        ? 'nfthiveboost'
                                        : 'nft.hive',
                                asset_ids: [assetId],
                            },
                        })
                        actions.push({
                            account: 'atomicassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                memo: 'auction',
                                asset_ids: [assetId],
                                to: 'atomicmarket',
                            },
                        })
                    }
                })
            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                Object.keys(assets)
                    .sort((a, b) => a.assetId - b.assetId)
                    .slice(start, end)
                    .map((assetId) =>
                        addTransaction(
                            assetId,
                            result['response']['transaction_id'],
                        ),
                    )
            } catch (e) {
                Object.keys(assets)
                    .sort((a, b) => a.assetId - b.assetId)
                    .slice(start, end)
                    .map((assetId) => addError(assetId, e.message))
                console.error(e)
                setError(e.message)
                sellError = e
            } finally {
                currentAction += 1
                setCurrentDisplayAction(currentAction)
                if (!error && !sellError) {
                    Object.keys(assets)
                        .sort((a, b) => a.assetId - b.assetId)
                        .slice(start, end)
                        .map((assetId) => sold.push(assets[assetId]))
                    setSold(sold)
                }
                if (currentAction < Object.keys(assets).length / maxActions) {
                    sleep(1000)
                }
            }
        }
        setIsLoading(false)
        callBack({ sold: sold, errors: errors, transactionIds: transactions })
        closeCallBack()
    }

    let sellInfo = t('popups.auction')

    const sellField = (
        <MainButton className="w-[80px] max-w-32" onClick={sell}>
            {sellInfo}
        </MainButton>
    )

    const cancel = () => {
        callBack({ sold: sold, errors: errors, transactionIds: transactions })
        closeCallBack()
    }

    const changeHours = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setHours(val)
    }

    const changeMinutes = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setMinutes(val)
    }

    const changeDays = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setDays(val)
    }

    return (
        <Popup title={t('search.bulk_sell')} cancel={cancel}>
            {Object.keys(sold).length > 0 ? <CheckIndicator /> : ''}
            {Object.keys(sold).length === 0 ? (
                <div className="mx-auto mt-4 mb-4 text-xl font-bold">
                    {t('popups.auction_x_items', {
                        asset: `${Object.keys(assets).length} Assets`,
                        amount: `${Object.keys(assets)
                            .map((asset) => assets[asset].price)
                            .reduce(
                                (prev, curr) =>
                                    parseFloat(prev) + parseFloat(curr),
                                0,
                            )} WAX`,
                        days: days,
                        minutes: minutes,
                        hours: hours,
                    })}
                </div>
            ) : (
                ''
            )}
            <div className="grid grid-cols-2 gap-2 md:grid-cols-3">
                <div>
                    <Input
                        variant="faded"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={t('popups.days')}
                        onChange={changeDays}
                        value={days ? days : ''}
                    />
                </div>
                <div>
                    <Input
                        variant="faded"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={t('popups.hours')}
                        onChange={changeHours}
                        value={hours ? hours : ''}
                    />
                </div>
                <div>
                    <Input
                        variant="faded"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={t('popups.minutes')}
                        onChange={changeMinutes}
                        value={minutes ? minutes : ''}
                    />
                </div>
            </div>
            {error ? <ErrorMessage error={error} /> : ''}
            {Object.keys(sold).length === 0 ? (
                <div className="relative flex justify-center gap-2 mt-4">
                    <MainButton onClick={cancel} className="bg-red-500">
                        {t('asset.cancel')}
                    </MainButton>
                    {sellField}
                </div>
            ) : (
                ''
            )}
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.signing_multiple_transactions', {
                        x: currentDisplayAction,
                        y: Math.ceil(Object.keys(assets).length / maxActions),
                    })}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BulkAuctionPopup
