import cn from 'classnames'
import React from 'react'
import config from '../../config.json'

export const isVideoPath = (path) => path && path.includes('video:')

export const toVideoURL = (path) =>
    path.includes('http')
        ? path.replace('video:', '')
        : config.ipfs + path.replace('video:', '')

export const VideoElement = ({ video, poster }) => (
    <video
        loop
        className={'m-auto max-h-54 2xl:max-h-64'}
        autoPlay={true}
        muted={true}
        playsInline={true}
        poster={poster}
    >
        <source src={video} />
        Your browser does not support the video tag.
    </video>
)

export const ImageElement = (props) => (
    <img className={'m-auto max-w-64 max-h-48 2xl:max-h-64'} {...props} />
)

export const PopupImage = ({ image, alt, toggleImage, imgSize }) => (
    <div
        className={cn('m-auto', { 'h-54 2xl:h-64': imgSize === 'medium' })}
        onClick={toggleImage}
    >
        {isVideoPath(image) ? (
            <VideoElement video={toVideoURL(image)} />
        ) : (
            <ImageElement src={image} alt={alt} />
        )}
    </div>
)
