import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useLanguage } from '../../hooks'
import Link from '../common/util/input/Link'
import get from '../helpers/Api'
import {
    formatDiff,
    formatDiffFloor,
    formatNumber,
    formatUSD,
    openSearchPopup,
} from '../helpers/FormatLinks'
import { Context } from '../waxplorer/Waxplorer'

export const AssetDetailsPopupContent = ({
    asset,
    userName,
    priceInfo,
    setPriceInfo,
    offer,
    usd_offer,
}) => {
    const [numAndLowestMint, setNumAndLowestMint] = useState(null)

    const getPriceInfo = async (assetId, templateId) => {
        if (!priceInfo && (assetId || templateId)) {
            const pInfo = await get(
                assetId
                    ? 'price-info/' + assetId
                    : 'price-info-template/' + templateId,
            )

            setPriceInfo(pInfo)
        }
    }

    useEffect(() => {
        fetchOwnerShipStats()
        getPriceInfo(asset.assetId, asset.templateId)
    }, [asset.assetId, asset.templateId])

    const fetchOwnerShipStats = async () => {
        if (!numAndLowestMint) {
            if (asset.templateId) {
                get(
                    `ownership-info-template?template_id=${asset.templateId}&owner=${userName}`,
                ).then(setNumAndLowestMint)
            } else if (asset.assetId) {
                get(
                    `ownership-info?asset_id=${asset.assetId}&owner=${userName}`,
                ).then(setNumAndLowestMint)
            }
        }
    }

    const {
        assetId,
        average,
        usd_average,
        last_sold,
        usd_last_sold,
        lowest,
        usd_lowest,
        revenue,
        usd_revenue,
        num_sales,
        market,
        templateId,
    } = asset

    const [, dispatch] = useContext(Context)

    const avgWAX =
        priceInfo && priceInfo['average'] ? priceInfo['average'] : average
    const avgUSD =
        priceInfo && priceInfo['average_usd']
            ? priceInfo['average_usd']
            : usd_average
    const lastSold =
        priceInfo && priceInfo['last_sold'] ? priceInfo['last_sold'] : last_sold
    const lastSoldUSD =
        priceInfo && priceInfo['last_sold_usd']
            ? priceInfo['last_sold_usd']
            : usd_last_sold
    const lowestWAX = priceInfo ? priceInfo['lowest'] : lowest
    const lowestUSD = priceInfo ? priceInfo['lowest_usd'] : usd_lowest
    const revenueWAX = priceInfo ? priceInfo['revenue'] : revenue
    const revenueUSD = priceInfo ? priceInfo['revenue_usd'] : usd_revenue
    const numSales = priceInfo ? priceInfo['num_sales'] : num_sales

    let diffMedian = 0
    if (asset && asset['usd_offer'] && avgUSD)
        diffMedian = asset['usd_offer'] / avgUSD - 1

    let diffLastSold = 0
    if (asset && asset['usd_offer'] && lastSoldUSD)
        diffLastSold = asset['usd_offer'] / lastSoldUSD - 1

    let diffFloor = 0
    if (asset && asset['offer'] && lowestWAX)
        diffFloor = asset['offer'] - lowestWAX

    const { t } = useLanguage('common')

    return (
        <div
            id={'AssetDetailsPopupContent' + (assetId ? assetId : templateId)}
            onClick={(e) => e.preventDefault()}
            className={'w-100 h-100 '}
        >
            <Table size="small">
                <TableBody>
                    {asset.owner ? (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold">
                                    {t('asset.owner')}
                                </div>
                            </TableCell>
                            <TableCell>
                                <Link href={`/user/${asset.owner}`}>
                                    <div className="text-primary hover:underline">
                                        {asset.owner}
                                    </div>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {offer ? (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold">
                                    {t('asset.price')}
                                </div>
                            </TableCell>
                            <TableCell>
                                {formatNumber(offer)} WAX /{' '}
                                {formatUSD(usd_offer)}
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {avgWAX && (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    {t('search.median')}
                                </div>
                            </TableCell>
                            <TableCell>
                                {
                                    <div
                                        className={
                                            'flex dark:!text-white text-black'
                                        }
                                    >
                                        {`${formatNumber(
                                            avgWAX,
                                        )} WAX / $${formatNumber(avgUSD)}`}
                                        {formatDiff(
                                            diffMedian,
                                            market,
                                            t('search.median'),
                                        )}
                                    </div>
                                }
                            </TableCell>
                        </TableRow>
                    )}
                    {lastSold && (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    {t('asset.last_sold')}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div
                                    className="cursor-pointer flex text-primary hover:underline"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        openSearchPopup(
                                            'trades',
                                            asset,
                                            dispatch,
                                        )
                                    }}
                                >
                                    {`${formatNumber(
                                        lastSold,
                                    )} WAX / $${formatNumber(lastSoldUSD)}`}
                                    {formatDiff(
                                        diffLastSold,
                                        market,
                                        t('asset.last_sold'),
                                    )}
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {!lowestWAX && revenueWAX && (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    {t('analytics.volume')}
                                </div>
                            </TableCell>
                            <TableCell>{`${formatNumber(
                                revenueWAX,
                            )} WAX / $${formatNumber(revenueUSD)}`}</TableCell>
                        </TableRow>
                    )}
                    {numSales && (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    {t('search.trades')}
                                </div>
                            </TableCell>
                            <TableCell className="dark:!text-white text-black">
                                {numSales}
                            </TableCell>
                        </TableRow>
                    )}
                    {lowestWAX && (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    {t('search.cheapest')}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div
                                    className="cursor-pointer flex text-primary hover:underline"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        openSearchPopup(
                                            'sales',
                                            asset,
                                            dispatch,
                                        )
                                    }}
                                >
                                    {`${formatNumber(
                                        lowestWAX,
                                    )} WAX / $${formatNumber(lowestUSD)}`}
                                    {formatDiffFloor(
                                        diffFloor,
                                        market,
                                        'Floor',
                                    )}
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {asset.mint ? (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    Mint
                                </div>
                            </TableCell>
                            <TableCell className="dark:!text-white text-black">
                                {asset.mint}
                                {asset.total ? ` / ${asset.total}` : ''}
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {asset.schema ? (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    Schema
                                </div>
                            </TableCell>

                            <TableCell>
                                <Link
                                    href={`/collection/${asset.author}/${asset.schema}`}
                                >
                                    <div className="text-primary hover:underline">
                                        {asset.schema}
                                    </div>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {asset.templateId ? (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    Template
                                </div>
                            </TableCell>

                            <TableCell>
                                <Link href={`/template/${asset.templateId}`}>
                                    <div className="text-primary hover:underline">
                                        {asset.templateId}
                                    </div>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {asset.rarity ? (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    Rarity
                                </div>
                            </TableCell>
                            <TableCell>
                                <Link
                                    href={`/market?rarity=${asset.rarity}&collection=${asset.author}&order_by=offer_asc&search_type=sales&offer_type=sales`}
                                >
                                    <div className="text-primary hover:underline">
                                        {asset.rarity}
                                    </div>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {numAndLowestMint && numAndLowestMint.numAssets !== null ? (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold dark:!text-white text-black">
                                    Owned
                                </div>
                            </TableCell>

                            <TableCell>
                                {numAndLowestMint.numAssets > 0 ? (
                                    <Link
                                        href={`/user/${userName}?tab=inventory&owner=${userName}&collection=${asset.author}&term=${asset.templateId}&order_by=mint_asc&search_type=assets#Tabs`}
                                    >
                                        <div className="text-primary hover:underline">
                                            {numAndLowestMint.numAssets}
                                        </div>
                                    </Link>
                                ) : (
                                    <div className="dark:!text-white text-black">
                                        None
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {numAndLowestMint && numAndLowestMint.minMint ? (
                        <TableRow>
                            <TableCell>
                                <div className="font-bold text-white">
                                    Own Lowest Mint
                                </div>
                            </TableCell>
                            <TableCell>
                                <Link
                                    href={`/user/${userName}?tab=inventory&owner=${userName}&collection=${asset.author}&term=${asset.templateId}&order_by=mint_asc&search_type=assets#Tabs`}
                                >
                                    <div className="text-primary hover:underline">
                                        {numAndLowestMint.minMint}
                                    </div>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ) : null}
                </TableBody>
            </Table>
        </div>
    )
}
