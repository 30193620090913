import cn from 'classnames'
import React, { useState } from 'react'
import config from '../../config.json'

import ErrorNote from '../common/util/ErrorNote'
import { useSharedState } from '../waxplorer/Waxplorer'

export const Picture = ({ className, image }) => {
    const defaultImg = 'QmVVE5aLtq4EtMe4Khev5CSic3AKqX2RuNTAB79thvpqQW'
    const displayImg =
        image && image.includes('http')
            ? image
            : config.ipfs + (image ? image : defaultImg)

    return <img alt="user" className={className} src={displayImg} />
}

export const UserPicture = ({ userPicture, user }) => {
    const [error, setError] = useState(null)
    const [ipfsHash, setIpfsHash] = useState(null)

    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null
    const defaultImg = 'QmVVE5aLtq4EtMe4Khev5CSic3AKqX2RuNTAB79thvpqQW'
    const image =
        ipfsHash && ipfsHash !== defaultImg
            ? ipfsHash
            : userPicture
            ? userPicture
            : defaultImg

    const updatePicture = (pictureInfo) => {
        if (pictureInfo) {
            if (pictureInfo['error']) {
                setError(pictureInfo['error'])
            } else if (pictureInfo['new']) {
                setIpfsHash(pictureInfo['new'])
                dispatch({ type: 'SET_USER_PICTURE_CHANGED', payload: true })
            }
        }
    }

    const changePicture = () => {
        dispatch({ type: 'SET_CALLBACK', payload: updatePicture })
        dispatch({ type: 'SET_ACTION', payload: 'addpicture' })
        dispatch({ type: 'SET_ASSET', payload: image })
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <div
            className={cn(
                'relative flex justify-center w-full h-1/2 md:w-1/2 xl:w-1/3 m-auto',
            )}
        >
            {error ? <ErrorNote error={error} onClick={dismissError} /> : ''}
            <div className={'h-auto w-auto mx-auto relative'}>
                <Picture className="max-h-120 p-8" image={image} />

                {userName && userName === user && (
                    <div
                        className={
                            'absolute right-8 bottom-8 opacity-50 hover:opacity-60'
                        }
                        onClick={changePicture}
                    >
                        <img
                            className={'w-8 h-8'}
                            src={'/camera-outline.svg'}
                            alt={'add'}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserPicture
