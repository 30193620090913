import cn from 'classnames'
import React, { useContext } from 'react'
import { Context } from '../waxplorer/Waxplorer'
import { useTheme } from 'next-themes'

export const ShareButton = ({ asset, type }) => {
    const { theme, setTheme } = useTheme()
    const [state, dispatch] = useContext(Context)

    const share = async () => {
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: () => {},
        })
        dispatch({ type: 'SET_ACTION', payload: 'share' })
    }

    return type === 'asset' ? (
        <div>
            <div
                className={cn(
                    'flex justify-start w-32 transition delay-200 width',
                    'h-4 rounded text-zinc-900 dark:!text-white cursor-pointer outline-none',
                    'text-xs font-bold my-2',
                )}
                onClick={() => share()}
            >
                <div className={cn('w-5 h-5 mx-2')}>
                    <img
                        className={cn('w-5 h-5')}
                        src={
                            theme === 'light'
                                ? '/share-outline-black.svg'
                                : '/share-outline.svg'
                        }
                        alt="share"
                    />
                </div>
                <div className="text-sm">Share</div>
            </div>
        </div>
    ) : (
        <div
            className={cn(
                'flex justify-start w-24 transition delay-200 width',
                'h-4 rounded dark:!text-white text-zinc-900 cursor-pointer outline-none',
                'text-xs font-bold my-2',
            )}
            onClick={() => share()}
        >
            <div className={cn('w-5 h-5 mx-2')}>
                <img src="/share-outline.svg" alt="share" />
            </div>
        </div>
    )
}

export default ShareButton
