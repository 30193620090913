export const selectStyles = {
    label: 'text-sm xl:text-sm text-black dark:dark:text-white font-semibold',
    listbox: 'text-black dark:dark:text-white',
    selectorIcon: 'text-black dark:dark:text-white',
}
export const inputStyles = {
    label: 'text-black font-semibold dark:text-white',
    input: 'text-black font-semibold dark:text-white',
}
export const buttonSearchCommonStyles =
    'bg-gray-100 rounded-xl border-gray-200 dark:border-zinc-700 dark:bg-zinc-800 hover:bg-gray-200 dark:hover:border-zinc-500 hover:border-zinc-400'
