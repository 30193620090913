import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import MainButton from '../common/util/input/MainButton'
import { Input } from '@nextui-org/react'
function UpdateDropMaxClaimablePopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const drop = props['drop']
    const oldMaxClaimable = props['dropMaxClaimableDrops']
    const allowInfiniteClaimable = props['dropAllowInfiniteClaimable']
    const [newMaxClaimable, setNewMaxClaimable] = useState(
        props['dropMaxClaimableDrops'],
    )
    const [error, setError] = useState(null)

    const handleNewMaxClaimableChange = (event) => {
        const newVal = parseInt(event.target.value)
        if (newVal >= 0) {
            if (newVal === 0 && allowInfiniteClaimable) {
                setNewMaxClaimable(newVal)
            } else if (newVal > 0) {
                setNewMaxClaimable(newVal)
            }
        } else {
            if (allowInfiniteClaimable) {
                setNewMaxClaimable(0)
            } else {
                setNewMaxClaimable(1)
            }
        }
    }

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            authorized_account: userName,
            drop_id: drop.dropId,
            new_max_claimable: newMaxClaimable,
        }

        const action = {
            account: 'nfthivedrops',
            name: 'setdropmax',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: data,
        }

        actions.push(action)
        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Change Amount of maximum claimable drops" cancel={cancel}>
            <div className="p-2 ">
                <div className="mb-4 text-lg font-bold text-center">
                    {t('drops.do_you_want_update_total_claimable', {
                        x: drop.dropId,
                    })}
                </div>
                <div>
                    <div className="flex justify-center my-6">
                        <div>
                            <div>
                                {`${t('drops.current_max_claimable_amount')}: ${
                                    oldMaxClaimable > 0
                                        ? oldMaxClaimable
                                        : 'Infinite'
                                }`}
                            </div>
                            <div className="mt-2">
                                <Input
                                    variant="faded"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    value={newMaxClaimable}
                                    onChange={handleNewMaxClaimableChange}
                                    label={t('drops.new_max_claimable_amount')}
                                />
                            </div>
                            {allowInfiniteClaimable && (
                                <div className="mt-3 text-center">
                                    {t('drops.zero_for_infinite')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {error ? (
                    <div onClick={dismissError}>
                        <ErrorMessage layer={5} error={error} />
                    </div>
                ) : (
                    ''
                )}
                <div className="flex flex-row justify-center gap-2">
                    <div className="mx-2">
                        <MainButton className="bg-red-500 " onClick={cancel}>
                            {t('buttons.cancel')}
                        </MainButton>
                    </div>
                    <div className="mx-2">
                        <MainButton onClick={create}>
                            {t('drops.update_drop')}
                        </MainButton>
                    </div>
                </div>
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default UpdateDropMaxClaimablePopup
