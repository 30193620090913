import cn from 'classnames'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { auctionTime } from '../../utils/date'

export const AuctionTimeleft = ({
    asset,
    position = 'justify-left',
    view = '',
}) => {
    const { isAuction, endTime } = asset
    const element = useRef(null)
    const { t } = useTranslation('common')

    useEffect(() => {
        if (!endTime) return
        const intervalId = setInterval(() => {
            if (element.current)
                element.current.innerText = auctionTime(endTime, Date.now())
        }, 1000)
        return () => clearInterval(intervalId)
    })
    return (
        <div
            className={cn(
                'top-0 w-full flex flex-wrap text-sm my-auto dark:!text-white text-dark',
                position,
                { 'absolute bg-paget': view !== 'full' },
                {
                    hidden: !isAuction,
                },
            )}
        >
            <div
                className={cn(
                    { 'ml-4': view !== 'full' },
                    { 'ml-2': view === 'full' },
                )}
            >
                {t('asset.ends')}:{' '}
            </div>
            <div className={'ml-2'} ref={element}>
                {auctionTime(endTime, Date.now())}
            </div>
        </div>
    )
}
