import React, { useContext, useEffect, useState } from 'react'
import { Badge } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import NotificationNav from '../notifications/NotificationNav'
import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'
import { get } from '../helpers/Api'
import { Context, useSharedState } from '../waxplorer/Waxplorer'

import { useGet } from '../../hooks'
import { useCallback } from 'react'
import { useRef } from 'react'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import MainButton from '../common/util/input/MainButton'
const NOTIFICATION_INTERVAL = 60 * 1000 // every minute

export const useNotificationCount = (userName, hasNotifications) => {
    const { data: newNotifications, fetch } = useGet(
        userName ? `num-new-notifications/${userName}` : undefined,
        'api1',
    )

    useEffect(() => {
        if (hasNotifications) {
            const intervalId = setInterval(fetch, NOTIFICATION_INTERVAL)
            return () => clearInterval(intervalId)
        }
    }, [userName, hasNotifications])

    return hasNotifications && newNotifications?.num ? newNotifications.num : 0
}

function NotificationDropDown() {
    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null
    const { t } = useTranslation('common')

    const [isLoading, setIsLoading] = useState(false)

    const [notifications, setNotifications] = useState(null)

    const [showNotifications, setShowNotifications] = useState(false)

    const hasNotifications = state.hasNotifications === userName

    const numNewNotifications = useNotificationCount(userName, hasNotifications)

    const [notificationUrl, setNotificationUrl] = useState()

    const [isHovered, setIsHovered] = useState(false)

    useEffect(() => {
        if (notificationUrl && userName) {
            get(notificationUrl, 'api1').then(setNotifications)
        }
    }, [notificationUrl])

    const parseHasNotifications = (res) => {
        if (process.env.NEXT_PUBLIC_TESTNET === 'FALSE') {
            const data = res['data'] ? res['data'] : res
            if (data['hasNotifications'])
                dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: userName })
            else dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: '' })
        }
    }

    useEffect(() => {
        if (userName) {
            get('has-notifications/' + userName, 'api1').then(
                parseHasNotifications,
            )
        }
    }, [userName])

    const openNotifications = () => {
        if (userName) {
            setShowNotifications(true)
            setNotificationUrl(`notifications/${userName}?days=10`)
        }
    }

    const closeNotifications = useCallback(() => {
        if (!showNotifications) return
        setShowNotifications(false)
        setNotificationUrl(undefined)
    }, [showNotifications])

    const fadeInOut = {
        hidden: { opacity: 0, y: 0 },
        visible: { opacity: 1, y: 0 },
    }

    const handleMouseEnter = () => {
        setIsHovered(true)
        openNotifications()
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
        closeNotifications()
    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="relative hidden md:block"
        >
            <button
                className={cn(
                    'font-semibold items-center px-3 py-3 rounded-md cursor-pointer hidden lg:flex',
                    {
                        'dark:!bg-zinc-900  bg-zinc-200': !isHovered,
                        'dark:!bg-zinc-800 bg-zinc-400': isHovered,
                    },
                )}
                aria-haspopup="true"
            >
                <Badge
                    color="secondary"
                    badgeContent={numNewNotifications}
                    className=""
                >
                    <NotificationsIcon />
                </Badge>
            </button>

            <AnimatePresence>
                {isHovered && (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={fadeInOut}
                        transition={{ duration: 0.1, ease: 'easeInOut' }}
                        className="absolute -right-12 lg:right-0 top-[48px] z-50 min-w-[300px]"
                    >
                        <div className="relative p-3 border border-zinc-200 dark:!border-zinc-800 dark:!bg-zinc-900 bg-white rounded-xl shadow-xl w-full h-[500px] overflow-y-auto">
                            <div className="relative z-10">
                                <div
                                    className={'w-full flex border-b my-2 py-4'}
                                >
                                    <img
                                        className={'w-12 h-12 mr-4'}
                                        src={'/hivee.jpg'}
                                    />
                                    <a
                                        href={'https://t.me/WAXplorerBot'}
                                        target="_blank"
                                    >
                                        {t(
                                            'navigation.get_your_notifications_on_telegram',
                                        )}
                                    </a>
                                </div>
                                {isLoading ? (
                                    <LoadingIndicator />
                                ) : notifications &&
                                  notifications.length > 0 ? (
                                    notifications.map((notification, i) => (
                                        <NotificationNav
                                            key={i}
                                            notification={notification}
                                            userName={userName}
                                        />
                                    ))
                                ) : (
                                    <div
                                        className={
                                            'w-full flex justify-center my-4'
                                        }
                                    >
                                        {t('navigation.no_notifications')}
                                    </div>
                                )}

                                {notifications && notifications.length > 0 && (
                                    <div
                                        className={
                                            'w-full flex justify-center my-4'
                                        }
                                    >
                                        <Link
                                            className={'text-center'}
                                            href={'/notifications'}
                                        >
                                            <p className="uppercase text-primary">
                                                {' '}
                                                {t('navigation.view_all')}
                                            </p>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default NotificationDropDown
