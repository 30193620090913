import {
    Alert,
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    Switch,
} from '@mui/material'
import { useState } from 'react'
import {
    dropEndDateValid,
    dropStartDateValid,
} from '../editor/DropCreatorUtils'
import { DropAlert } from './DropAlert'
import { DropPaper } from './DropPaper'

import { DatePicker } from '@nextui-org/react'
import {
    getLocalTimeZone,
    today,
    now,
    parseAbsoluteToLocal,
    toCalendarDateTime,
} from '@internationalized/date'

export const DropStartEndDateEditor2 = ({
    dropStartDate,
    setDropStartDate,
    dropStartDateEnabled,
    setDropStartDateEnabled,
    dropEndDate,
    setDropEndDate,
    dropEndDateEnabled,
    setDropEndDateEnabled,
    isPremint = false,
}) => {
    const [use24hrFormat, setUse24hrFormat] = useState(false)

    let isDateUnavailable = (date) =>
        date.compare(today(getLocalTimeZone())) < 0

    function isValidDate(d) {
        if (d instanceof Date) {
            // Check if d is a Date object and it is not an Invalid Date
            return !isNaN(d.getTime())
        } else if (typeof d === 'number') {
            // Check if d is a number and can be converted to a valid Date object
            const dateFromTimestamp = new Date(d)
            return !isNaN(dateFromTimestamp.getTime())
        }
        return false
    }

    return (
        <DropPaper
            id={'DropStartEndDateEditor'}
            title="Start and End Date / Time"
            error={
                (dropStartDateEnabled &&
                    !dropStartDateValid(dropStartDateEnabled, dropStartDate)) ||
                (dropEndDateEnabled &&
                    !dropEndDateValid(
                        dropEndDateEnabled,
                        dropStartDateEnabled,
                        dropStartDate,
                        dropEndDate,
                    ))
            }
        >
            <div className="my-5">
                <div className="grid w-full grid-cols-1 gap-4 place-items-center">
                    <div className="flex flex-col mb-5 w-full">
                        <div className="mb-4 sm:my-auto">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dropStartDateEnabled}
                                            onChange={(e) =>
                                                setDropStartDateEnabled(
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    }
                                    label="Set Start Date and Time"
                                />
                            </FormGroup>
                        </div>
                        <Collapse in={dropStartDateEnabled}>
                            <div>
                                <DatePicker
                                    label="Start Date and Time (Your local timezone)"
                                    variant="bordered"
                                    hideTimeZone
                                    showMonthAndYearPickers
                                    defaultValue={now(getLocalTimeZone())}
                                    value={
                                        isValidDate(dropStartDate)
                                            ? parseAbsoluteToLocal(
                                                  new Date(
                                                      dropStartDate,
                                                  ).toISOString(),
                                              )
                                            : null
                                    }
                                    onChange={(e) => {
                                        let date = new Date(
                                            toCalendarDateTime(e),
                                        )

                                        setDropStartDate(date)
                                    }}
                                    hourCycle={use24hrFormat ? 24 : 12}
                                    isDateUnavailable={isDateUnavailable}
                                    minValue={now(getLocalTimeZone())}
                                />
                            </div>
                        </Collapse>
                    </div>
                    <div className="flex flex-col mb-5 w-full">
                        <div className="mb-2 sm:my-auto">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dropEndDateEnabled}
                                            onChange={(e) =>
                                                setDropEndDateEnabled(
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    }
                                    label="Set End Date and Time"
                                />
                            </FormGroup>
                        </div>
                        <Collapse in={dropEndDateEnabled}>
                            <div>
                                <DatePicker
                                    label="End Date and Time (Your local timezone)"
                                    variant="bordered"
                                    hideTimeZone
                                    showMonthAndYearPickers
                                    defaultValue={now(getLocalTimeZone())}
                                    value={
                                        isValidDate(dropEndDate)
                                            ? parseAbsoluteToLocal(
                                                  new Date(
                                                      dropEndDate,
                                                  ).toISOString(),
                                              )
                                            : null
                                    }
                                    onChange={(e) => {
                                        let date = new Date(
                                            toCalendarDateTime(e),
                                        )

                                        setDropEndDate(date)
                                    }}
                                    hourCycle={use24hrFormat ? 24 : 12}
                                    isDateUnavailable={isDateUnavailable}
                                    minValue={now(getLocalTimeZone())}
                                />
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>

            <Collapse in={dropStartDateEnabled || dropEndDateEnabled}>
                <div className="flex justify-center mb-5 md:justify-start">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={use24hrFormat}
                                    onChange={() => {
                                        setUse24hrFormat(!use24hrFormat)
                                    }}
                                />
                            }
                            label="Use 24-hour format"
                        />
                    </FormGroup>
                </div>
            </Collapse>

            <DropAlert
                condition={!dropStartDateEnabled && isPremint}
                severity="warning"
            >
                This is a premint drop, and you will have to add NFTs to this
                drop after creating it. If you do not set a start date and time,
                the drop will go live immediatelty, without any assets. We
                therefore recommend setting a start date at least a couple of
                minutes in the future so you still have time to add your NFTs
                before the drop goes live!
            </DropAlert>

            <Collapse
                in={
                    dropStartDateEnabled &&
                    !dropStartDateValid(dropStartDateEnabled, dropStartDate)
                }
            >
                <div className="mb-5">
                    <Alert severity="error">
                        The start date must be in the future
                    </Alert>
                </div>
            </Collapse>

            <Collapse
                in={
                    dropEndDateEnabled &&
                    !dropEndDateValid(
                        dropEndDateEnabled,
                        dropStartDateEnabled,
                        dropStartDate,
                        dropEndDate,
                    )
                }
            >
                <div className="mb-5">
                    <Alert severity="error">
                        The end date must be{' '}
                        {dropStartDateEnabled
                            ? 'after the start date'
                            : 'in the future'}
                    </Alert>
                </div>
            </Collapse>
        </DropPaper>
    )
}
