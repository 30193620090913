import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { MintInfo } from '../mintinfo/MintInfo'
import CollectionHeaderPopups from '../popups/CollectionHeaderPopups'
import VideoElement from '../videoelement/VideoElement'
import { CollectionAssetName } from '../assetpreview/CollectionAssetName'
import { useElementSize } from '../../hooks'

function ResultCard({ index, asset }) {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), index * 1000)
    }, [])

    let {
        assetId,
        templateId,
        name,
        rarity,
        mint,
        video,
        image,
        thumbnail,
        universalPreview,
        collectionImage,
        collectionThumbnail,
        verified,
        author,
        blacklisted,
        amount,
        logo,
    } = asset

    if (video && (!video.includes('http') || thumbnail)) {
        video = 'https://ipfs.hivebp.io/ipfs/' + video.replace('video:', '')
        if (thumbnail) video = thumbnail
    }

    if (image && (!image.includes('http') || (thumbnail && !video))) {
        image = 'https://ipfs.hivebp.io/ipfs/' + image
        if (thumbnail && !video) image = thumbnail
    }

    if (
        !video &&
        image &&
        (image.includes('video:') || image.includes('video?'))
    ) {
        video = image.includes('http')
            ? image.replace('video:', '')
            : 'https://ipfs.hivebp.io/ipfs/' + image.replace('video:', '')
        image = null
    }

    if (!image && !video && asset['idata']) {
        const data = JSON.parse(asset['idata'])
        video =
            data['video'] && !data['video'].includes('http')
                ? 'https://ipfs.hivebp.io/ipfs/' +
                  data['video'].replace('video:', '')
                : data['video']
        image =
            data['img'] && !data['img'].includes('http')
                ? 'https://ipfs.hivebp.io/ipfs/' + data['img']
                : data['img']
        rarity = data['rarity'] ? data['rarity'] : rarity
    }

    if (!name && asset['idata']) {
        const data = JSON.parse(asset['idata'])
        name = data['name'] ? data['name'] : null
    }

    const {} = asset

    const [elementSize, elementRef] = useElementSize()
    let cardWidth = elementSize.width - 60

    return (
        <div
            className={cn(
                'relative w-full mx-auto rounded-md overflow-hidden',
                'flex flex-col text-base break-words border border-paper',
                'shadow-xl bg-zinc-300 dark:!bg-papert select-none',
            )}
            id={'ResultCard_' + index}
        >
            <div className={cn('flex justify-between my-4 mx-auto px-2')}>
                <div
                    className={cn(
                        'relative flex items-center leading-4',
                        'text-white leading-relaxed text-sm top-4',
                    )}
                >
                    {assetId || templateId ? (
                        <CollectionAssetName
                            linkInactive={true}
                            collectionImage={
                                collectionThumbnail
                                    ? collectionThumbnail
                                    : collectionImage
                            }
                            onClick={() => {}}
                            altOnClick={() => {}}
                            href={''}
                            name={name ? name : assetId ? assetId : templateId}
                            collection={author}
                            verified={verified}
                            blacklisted={blacklisted}
                            cardWidth={cardWidth}
                            elementRef={elementRef}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className="flex h-full m-auto">
                <div className="relative flex justify-center">
                    {loading ? (
                        <LoadingIndicator />
                    ) : video && !universalPreview ? (
                        <VideoElement
                            className="m-auto max-h-144"
                            image={image}
                            backimg={image}
                            video={video}
                            autoPlay={true}
                            timeout={10000}
                        />
                    ) : image || universalPreview ? (
                        <img
                            className="m-auto max-h-144"
                            src={universalPreview ? universalPreview : image}
                            alt="none"
                        />
                    ) : logo ? (
                        <img
                            className="m-auto max-h-48"
                            src={logo}
                            alt="none"
                        />
                    ) : (
                        ''
                    )}

                    {mint && <MintInfo top={'bottom-0 left-1'} mint={mint} />}
                </div>
            </div>
            <div className="relative">
                <p
                    className={cn(
                        'w-full pt-4 px-2 mb-5',
                        'text-center text-base font-light dark:text-white text-black',
                        'overflow-visible',
                    )}
                >
                    {name ? name : amount}
                    <br />
                    {rarity ? `(${rarity})` : ''}
                </p>
            </div>
        </div>
    )
}

export default ResultCard
