import { Button } from '@nextui-org/react'

export const MainButton = ({
    className = 'font-bold rounded-md text-paper',
    textClassName = 'text-sm',
    padding = 'px-2',
    fullWidth = true,
    size = '',
    varient,
    ...props
}) => {
    if (varient === 'solid') {
        return (
            <div className={' my-auto'}>
                <Button
                    fullWidth={fullWidth}
                    variant="solid"
                    color="primary"
                    {...props}
                    className={className}
                />
            </div>
        )
    } else {
        return (
            <div className={' my-auto'}>
                <Button
                    fullWidth={fullWidth}
                    variant="bordered"
                    color="primary"
                    {...props}
                    className={className}
                />
            </div>
        )
    }
}

export default MainButton
