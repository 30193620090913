import cn from 'classnames'
import { useWindowSize } from 'rooks'
import Link from './Link'
import { Button } from '@nextui-org/react'

export const MainButton = ({
    className = '',
    padding = 'px-0',
    rounded = 'rounded-md',
    margin = '',
    fullWidth = true,
    size,
    height,
    variant = 'solid',
    color = 'primary',
    href,
    external,
    disabled = false,
    customColor,
    ...props
}) => {
    const { outerWidth } = useWindowSize()

    let textClassName = 'font-bold sm:text-[14px] text-[12px] '
    if (variant === 'solid') {
        textClassName += 'text-paper'
    } else {
        textClassName += 'text-primary'
    }

    return (
        <div className={cn(padding, 'my-auto')}>
            {href ? (
                <Link href={href} print external={external}>
                    <Button
                        fullWidth={fullWidth}
                        color={
                            customColor === 'error'
                                ? 'danger'
                                : customColor === 'default'
                                ? 'primary'
                                : color
                        }
                        variant={disabled ? 'faded' : variant}
                        size={size ? size : outerWidth < 640 ? 'sm' : 'md'}
                        {...props}
                        href={href}
                        onClick={() => {}}
                        className={cn(
                            className,
                            textClassName,
                            margin,
                            rounded,
                            height,
                        )}
                    />
                </Link>
            ) : (
                <Button
                    fullWidth={fullWidth}
                    color={
                        customColor === 'error'
                            ? 'danger'
                            : customColor === 'default'
                            ? 'primary'
                            : color
                    }
                    variant={disabled ? 'faded' : variant}
                    size={size ? size : outerWidth < 640 ? 'sm' : 'md'}
                    disabled={disabled}
                    {...props}
                    className={cn(
                        className,
                        textClassName,
                        margin,
                        rounded,
                        height,
                    )}
                />
            )}
        </div>
    )
}

export default MainButton
