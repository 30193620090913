import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { DropStartEndDateEditor2 } from '../drops/DropStartEndDateEditor2'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import MainButton from '../common/util/input/MainButton'
import { Input } from '@nextui-org/react'

function UpdateStartEndDatePopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const drop = props['drop']

    const [startDateTime, setStartDateTime] = useState(
        new Date(props['dropStartDateTime']),
    )
    const [endDateTime, setEndDateTime] = useState(
        new Date(props['dropEndDateTime']),
    )

    const [dropStartDateEnabled, setDropStartDateEnabled] = useState(
        props['dropStartDateTime'] !== undefined,
    )
    const [dropEndDateEnabled, setDropEndDateEnabled] = useState(
        props['dropEndDateTime'] !== undefined,
    )

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            authorized_account: userName,
            drop_id: drop.dropId,
            start_time: dropStartDateEnabled
                ? Math.floor(startDateTime.getTime() / 1000)
                : 0,
            end_time: dropEndDateEnabled
                ? Math.floor(endDateTime.getTime() / 1000)
                : 0,
        }

        const action = {
            account: 'nfthivedrops',
            name: 'setdroptimes',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: data,
        }

        actions.push(action)
        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Change Drop Start and Endtime" cancel={cancel}>
            <div className="mb-4 text-xl font-bold">
                Do you want to update the Start- and End Date/Time of Drop{' '}
                {drop.dropId} ({collectionName})?
            </div>
            <div>
                <div>
                    <DropStartEndDateEditor2
                        dropStartDate={startDateTime}
                        setDropStartDate={setStartDateTime}
                        dropStartDateEnabled={dropStartDateEnabled}
                        setDropStartDateEnabled={setDropStartDateEnabled}
                        dropEndDate={endDateTime}
                        setDropEndDate={setEndDateTime}
                        dropEndDateEnabled={dropEndDateEnabled}
                        setDropEndDateEnabled={setDropEndDateEnabled}
                    />
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-center">
                <div className="mx-2">
                    <MainButton className="bg-red-500 " onClick={cancel}>
                        Cancel
                    </MainButton>
                </div>
                <div className="mx-2">
                    <MainButton onClick={create}>
                        {t('drops.update_drop')}
                    </MainButton>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default UpdateStartEndDatePopup
