import { Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import InputField from '../common/util/InputField'
import MainButton from '../common/util/input/MainButton'

function CreateAndMintTemplatePopup(props) {
    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']

    const [receiver, setReceiver] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const schemaName = props['schemaName']
    const templateDataArray = props['templateDataArray']
    const tableKey = props['tableKey']
    const maxSupplyMap = props['maxSupplyMap']
    const mintNumbersMap = props['mintNumbersMap']
    const existingMints = props['existingMints']

    const [currentDisplayAction, setCurrentDisplayAction] = useState(0)
    const [transactionIds, setTransactionIds] = useState([])

    const addTransactionId = (transactionId) => {
        const tempTransactionIds = transactionIds
        tempTransactionIds.push(transactionId)
        setTransactionIds(tempTransactionIds)
    }

    // TODOs for the future: mint to drops, packs, or arbitrary accounts
    // const [toBeUsedInPacks, setToBeUsedInPacks] = useState(false)
    // const [toBeUsedInDrops, setToBeUsedInDrops] = useState(false)

    const [errors, setErrors] = useState([])
    const addError = (error) => {
        const tempErrors = errors
        tempErrors.push(error)
        setErrors(tempErrors)
    }

    const [error, setError] = useState(null)

    const MAX_ACTIONS_PER_TRANSACTION = 10

    const [progressStatus, setProgressStatus] = useState('')

    const createAndMint = async () => {
        setIsLoading(true)

        // Creates an array of ipfs hashes to be created (these don't already exist on the blockchain)
        const templatesToCreate = []
        for (const templateData of templateDataArray) {
            for (const attribute of templateData.immutableData) {
                if (attribute.key === tableKey) {
                    const ipfsHash = attribute.value[1]
                    if (!existingMints.has(ipfsHash)) {
                        templatesToCreate.push(ipfsHash)
                        break
                    }
                }
            }
        }

        let transferError = null
        let actions = null

        let currentCreateTemplateAction = 0
        let templateCreatesDone = 0

        const templatesToCreateAmount = templatesToCreate.length

        setProgressStatus(`Creating ${templatesToCreateAmount} templates...`)
        while (templateCreatesDone < templatesToCreateAmount) {
            const createsThisTransaction = Math.min(
                MAX_ACTIONS_PER_TRANSACTION,
                templatesToCreateAmount - templateCreatesDone,
            )
            actions = [getBoostAction(activeUser)]
            for (let i = 0; i < createsThisTransaction; i++) {
                // Take and remove first image of the array
                const templateImg = templatesToCreate.shift()

                let templateData
                for (const templateDataItem of templateDataArray) {
                    for (const attribute of templateDataItem.immutableData) {
                        if (
                            attribute.key === tableKey &&
                            attribute.value[1] === templateImg
                        ) {
                            templateData = templateDataItem
                            break
                        }
                    }
                }

                const data = {
                    authorized_creator: userName,
                    collection_name: collectionName,
                    schema_name: schemaName,
                    transferable: templateData.transferable,
                    burnable: templateData.burnable,
                    max_supply: maxSupplyMap.get(templateImg),
                    immutable_data: templateData.immutableData,
                }

                const action = {
                    account: 'atomicassets',
                    name: 'createtempl',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: data,
                }

                actions.push(action)
            }

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                addTransactionId(result?.response?.transaction_id)
            } catch (e) {
                transferError = e.message
                addError(transferError)
                setError(transferError)
                break
            } finally {
                templateCreatesDone =
                    templateCreatesDone + createsThisTransaction
                ++currentCreateTemplateAction
                setCurrentDisplayAction(currentCreateTemplateAction)
                if (templatesToCreateAmount - templateCreatesDone > 0) {
                    await sleep(5000)
                }
            }
            setProgressStatus(
                `Creating ${templatesToCreateAmount} templates... Finished: ${templateCreatesDone}. 
                (Adding wait time to avoid Rate Limiting every ${MAX_ACTIONS_PER_TRANSACTION} actions)`,
            )
        }

        // At this point all templates should have been created.
        // If something has been created, we'll wait and grab data from atomic and overwwrite
        // the existingMints map with the new data.
        const templateHashes = new Map(existingMints)
        if (templateCreatesDone > 0) {
            setProgressStatus(
                `Template creation done. Waiting for blockchain to sync up...`,
            )
            await sleep(10000)
            const templatesResponse = await fetch(
                (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                    ? config.atomictest
                    : config.atomic) +
                    `/atomicassets/v1/templates?collection_name=${collectionName}&schema_name=${schemaName}`,
            )
            const templatesJson = await templatesResponse.json()

            if (templatesJson.data) {
                for (const template of templatesJson.data) {
                    templateHashes.set(template.immutable_data[tableKey], {
                        templateId: template.template_id,
                        issuedSupply: template.issued_supply,
                        maxSupply: template.max_supply,
                        burnable: template.is_burnable,
                        transferable: template.is_transferable,
                    })
                }
            }
        }

        const toBeMinted = []
        // Figure out which mints need to be minted
        for (const template of templateDataArray) {
            let ipfs
            for (const immutableData of template.immutableData) {
                if (immutableData['key'] === tableKey) {
                    ipfs = immutableData['value'][1]
                }
            }
            if (ipfs) {
                const amountToBeMinted = parseInt(mintNumbersMap.get(ipfs))
                const issuedSupply = parseInt(
                    templateHashes.get(ipfs).issuedSupply,
                )
                const maxSupply = parseInt(templateHashes.get(ipfs).maxSupply)
                if (
                    amountToBeMinted > issuedSupply &&
                    (amountToBeMinted <= maxSupply || maxSupply === 0)
                ) {
                    toBeMinted.push({
                        ipfs: ipfs,
                        templateId: templateHashes.get(ipfs).templateId,
                        amount: amountToBeMinted - issuedSupply,
                        burnable: template.burnable,
                        transferable: template.transferable,
                        immutableData: template.immutableData,
                    })
                }
            }
        }

        const totalMintAmount = toBeMinted.reduce(
            (acc, cur) => acc + cur.amount,
            0,
        )

        let currentMintAction = 0
        let mintsDone = 0
        setProgressStatus(`Minting ${totalMintAmount} Assets...`)
        while (mintsDone < totalMintAmount) {
            const mintsThisTransaction = Math.min(
                MAX_ACTIONS_PER_TRANSACTION,
                totalMintAmount - mintsDone,
            )
            const actions = [getBoostAction(activeUser)]

            for (let i = 0; i < mintsThisTransaction; i++) {
                const mintData = toBeMinted[0]
                // Substract amount to be minted of this template
                mintData.amount--
                if (mintData.amount === 0) {
                    // if all of this template has been minted, remove it from the array
                    toBeMinted.shift()
                }

                const data = {
                    authorized_minter: userName,
                    collection_name: collectionName,
                    schema_name: schemaName,
                    template_id: parseInt(mintData.templateId),
                    new_asset_owner: receiver,
                    immutable_data: [],
                    mutable_data: [],
                    tokens_to_back: [],
                }

                const action = {
                    account: 'atomicassets',
                    name: 'mintasset',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: data,
                }

                actions.push(action)
            }

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                addTransactionId(result?.response?.transaction_id)
            } catch (e) {
                transferError = e.message
                addError(transferError)
                setError(transferError)
                break
            } finally {
                mintsDone = mintsDone + mintsThisTransaction
                ++currentMintAction
                setCurrentDisplayAction(currentMintAction)
                if (totalMintAmount - mintsDone > 0) {
                    await sleep(10000)
                }
            }
            setProgressStatus(
                `Minting ${totalMintAmount} Assets... Finished: ${mintsDone}. (Adding wait time to avoid Rate Limiting every 
                    ${MAX_ACTIONS_PER_TRANSACTION} actions)`,
            )
        }

        if (!transferError) {
            closeCallBack()
        }
        setIsLoading(false)

        callBack(!transferError, errors, transactionIds)
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
        setErrors([])
    }

    const calcAmountOfTemplatesToCreate = () => {
        const templatesToCreate = []
        for (const templateData of templateDataArray) {
            for (const attribute of templateData.immutableData) {
                if (attribute.key === tableKey) {
                    const ipfsHash = attribute.value[1]
                    if (!existingMints.has(ipfsHash)) {
                        templatesToCreate.push(ipfsHash)
                        break
                    }
                }
            }
        }
        return templatesToCreate.length
    }

    const calcAmountOfAssetsToMint = () => {
        let amount = 0
        // Figure out which mints need to be minted
        for (const template of templateDataArray) {
            const ipfs = template.immutableData[1].value[1]
            if (ipfs) {
                const amountToBeMinted = parseInt(mintNumbersMap.get(ipfs))
                const issuedSupply = existingMints.has(ipfs)
                    ? parseInt(existingMints.get(ipfs).issuedSupply)
                    : 0
                const maxSupply = existingMints.has(ipfs)
                    ? parseInt(existingMints.get(ipfs).maxSupply)
                    : 0
                if (
                    amountToBeMinted > issuedSupply &&
                    (amountToBeMinted <= maxSupply || maxSupply === 0)
                ) {
                    amount += amountToBeMinted - issuedSupply
                }
            }
        }

        return amount
    }

    useEffect(() => {
        if (userName) {
            setReceiver(userName)
        }
    }, [userName])

    return (
        <Popup title="Bulk Create and Mint Templates" cancel={cancel}>
            <div className="mb-4 text-xl font-bold">
                Do you want to create and mint these templates?
            </div>
            <div className="my-10">
                <div>
                    Please make sure your spreadsheet matches what you want to
                    create and mint. You can always create and mint more, but
                    you won{"'"}t be able to delete or edit assets and their
                    immutable attributes once they have been minted!
                </div>
                <div>You are planning to...</div>
                {calcAmountOfTemplatesToCreate() > 0 && (
                    <div className="m-4 text-xl font-bold">
                        ...create {calcAmountOfTemplatesToCreate()} templates
                    </div>
                )}
                {calcAmountOfAssetsToMint() > 0 && (
                    <div className="m-4 text-xl font-bold">
                        ...mint {calcAmountOfAssetsToMint()} assets
                    </div>
                )}
            </div>
            <div className="flex justify-center pb-5">
                <InputField
                    label="Receiver"
                    value={receiver}
                    onChange={(e) => setReceiver(e.target.value)}
                    description="The receiver of the minted assets"
                    isRequired={true}
                    errorMessage={!receiver}
                />
            </div>
            <div className="flex justify-center pb-5">
                <div>Mint to </div>
                <div
                    className="px-2 cursor-pointer text-primary"
                    onClick={() => setReceiver('nfthivedrops')}
                >
                    nfthivedrops
                </div>
                <div>or</div>
                <div
                    className="px-2 cursor-pointer text-primary"
                    onClick={() => setReceiver('nfthivepacks')}
                >
                    nfthivepacks
                </div>
                <div>instead</div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : null}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <MainButton color="danger" onClick={cancel}>
                        Cancel
                    </MainButton>
                </div>
                <div className="mx-2">
                    <MainButton onClick={createAndMint}>
                        Create Templates / Mint Assets
                    </MainButton>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={progressStatus}
                    isLoading={isLoading}
                />
            ) : null}
        </Popup>
    )
}

export default CreateAndMintTemplatePopup
