import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { get } from '../helpers/Api'
import LoadingIndicator from '../loadingindicator'
import Notification from '../notifications/Notification'
import { Context, useSharedState } from '../waxplorer/Waxplorer'

function NotificationDialog({ notificationMenuOpen, setNotificationMenuOpen }) {
    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null
    const { t } = useTranslation('common')

    const [isLoading, setIsLoading] = useState(false)

    const [notifications, setNotifications] = useState(null)

    const [showNotifications, setShowNotifications] = useState(false)

    const [notificationUrl, setNotificationUrl] = useState()

    useEffect(() => {
        if (notificationUrl && userName) {
            get(notificationUrl, 'api1').then(setNotifications)
        }
    }, [notificationUrl])

    const parseHasNotifications = (res) => {
        if (process.env.NEXT_PUBLIC_TESTNET === 'FALSE') {
            const data = res['data'] ? res['data'] : res
            if (data['hasNotifications'])
                dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: userName })
            else dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: '' })
        }
    }

    useEffect(() => {
        if (userName) {
            get('has-notifications/' + userName, 'api1').then(
                parseHasNotifications,
            )
        }
    }, [userName])

    const closeNotifications = useCallback(() => {
        if (!showNotifications) return
        setShowNotifications(false)
        setNotificationUrl(undefined)
    }, [showNotifications])

    const handleNotification = () => {
        setNotificationMenuOpen(false)
        closeNotifications()
    }

    useEffect(() => {
        if (notificationMenuOpen) {
            setNotificationUrl(`notifications/${userName}?days=10`)
        }
    }, [notificationMenuOpen])

    return (
        <>
            <Dialog
                as="div"
                className="lg:hidden"
                open={notificationMenuOpen}
                onClose={() => {
                    setTimeout(() => {
                        setNotificationMenuOpen(false)
                    }, 100)
                }}
            >
                <div className="z-10" />
                <Dialog.Panel className="fixed top-0 bottom-[48px] right-0 z-10 w-full overflow-y-auto dark:!bg-zinc-900 bg-white px-4 py-4 sm:max-w-sm sm:ring-1 sm:ring-white/10 ">
                    <div className="flex items-center justify-end">
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-zinc-900 dark:!text-white"
                            onClick={handleNotification}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="mt-2 flow-root text-[14px]">
                        {isLoading ? (
                            <LoadingIndicator />
                        ) : notifications && notifications.length > 0 ? (
                            notifications.map((notification, i) => (
                                <Notification
                                    key={i}
                                    notification={notification}
                                    userName={userName}
                                />
                            ))
                        ) : (
                            <div className={'w-full flex justify-center my-4'}>
                                {t('navigation.no_notifications')}
                            </div>
                        )}

                        {notifications && notifications.length > 0 && (
                            <div className={'w-full flex justify-center my-4'}>
                                <Link
                                    className={'text-center'}
                                    href={'/notifications'}
                                >
                                    {t('navigation.view_all')}
                                </Link>
                            </div>
                        )}
                    </div>
                </Dialog.Panel>
            </Dialog>
        </>
    )
}

export default NotificationDialog
