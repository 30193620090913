import cn from 'classnames'
import React from 'react'
import Link from '../common/util/input/Link'

export const ProfileItem = ({ item }) => {
    return (
        <Link href={item.href} className={'w-full flex'}>
            <li
                key="logout"
                className="flex justify-start p-2 items-center w-full rounded-lg  hover:bg-gradient-to-br hover:dark:!bg-zinc-800 hover:bg-zinc-200 transition ease-in-out duration-300 cursor-pointer bg-red"
                onClick={item.onclick}
            >
                <div className={'w-full flex justify-start mr-auto'}>
                    <span
                        className={cn(
                            'rounded-lg w-[40px] h-[40px] mr-4 flex items-center justify-center',
                            { 'bg-primary': !item.outline },
                        )}
                    >
                        <img
                            src={item.icon}
                            className={cn(
                                'text-white dark:!text-zinc-900 ',
                                { 'h-5 w-5 ': !item.outline },
                                { 'h-8 w-8 ': item.outline },
                            )}
                            aria-hidden="true"
                            alt={item.name}
                        />
                    </span>

                    <div className="block dark:!text-white text-zinc-900 font-semibold">
                        <p className="block dark:!text-white text-zinc-900 font-semibold ">
                            {item.name}
                        </p>
                        <p className="text-zinc-500 font-normal text-[14px]">
                            {item.description}
                        </p>
                    </div>
                </div>
            </li>
        </Link>
    )
}
