import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getCurrencies } from '../../components/editor/DropCreatorUtils'
import { DropPriceEditor } from '../drops/DropPriceEditor'
import { getDrop, getDropPrices, getPFP } from '../helpers/Api'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import { useSharedState } from '../waxplorer/Waxplorer'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import MainButton from '../common/util/input/MainButton'
import { Input } from '@nextui-org/react'

function UpdateDropPricePopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const drop = props['drop']

    const [payoutRecipients, setPayoutRecipients] = useState([''])
    const [recipientSplits, setRecipientSplits] = useState([1])

    const [dropData, setDropData] = useState(null)

    const [isDropPricesLoading, setIsDropPricesLoading] = useState(true)
    const [dropPriceCurrencyList, setDropPriceCurrencyList] = useState([])

    const [currencies, setCurrencies] = useState(null)

    const [error, setError] = useState(null)

    const buildCurrencyString = (currency, currencies) => {
        let digits = ''
        for (const entry of currencies) {
            if (currency === entry.label) {
                digits = entry.symbol.split(',')[0]
                break
            }
        }
        return (
            digits +
            ',' +
            (currency === 'USD' ? 'WAX' : currency) +
            ',' +
            currency
        )
    }

    const parseDropPrices = (dropPricesRes, currencies, dropData) => {
        const tempDropPriceCurrencyList = []
        if (dropPricesRes) {
            for (const entry of dropPricesRes.listing_prices) {
                const price = Number(entry.split(' ')[0])
                const currency = entry.split(' ')[1]
                tempDropPriceCurrencyList.push({
                    price: price,
                    currency: buildCurrencyString(currency, currencies),
                })
            }
        } else {
            const price = Number(dropData.listing_price.split(' ')[0])
            const currency = dropData.listing_price.split(' ')[1]
            tempDropPriceCurrencyList.push({
                price: price,
                currency: buildCurrencyString(currency, currencies),
            })
        }

        setDropPriceCurrencyList(tempDropPriceCurrencyList)
        setIsDropPricesLoading(false)
    }

    const parseCurrencies = (currencies) => {
        setCurrencies(currencies)
    }

    const parseDropData = (dropData) => {
        setDropData(dropData)
    }

    const fetchDropData = async () => {
        let dropData = await getDrop(drop.dropId, state)
        if (dropData === null) {
            dropData = await getPFP(drop.dropId, state)
        }
        parseDropData(dropData)
    }

    useEffect(() => {
        getCurrencies(state).then(parseCurrencies)
        fetchDropData()
    }, [drop.dropId])

    useEffect(() => {
        if (dropData && currencies && currencies.length > 0) {
            getDropPrices(drop.dropId, state).then((res) =>
                parseDropPrices(res, currencies, dropData),
            )
        }
    }, [dropData, currencies && currencies.length > 0])

    const isWaxInList = () => {
        for (let i = 0; i < dropPriceCurrencyList.length; i++) {
            if (dropPriceCurrencyList[i].currency === '8,WAX,WAX') {
                return true
            }
        }
        return false
    }

    const getSettlementSymbol = () => {
        return dropPriceCurrencyList[0].price === 0
            ? '0,NULL'
            : isWaxInList()
            ? '8,WAX'
            : dropPriceCurrencyList[0].currency.split(',')[0] +
              ',' +
              dropPriceCurrencyList[0].currency.split(',')[1]
    }

    const getListingPrices = () => {
        const listingPrices = []
        for (const priceCurrency of dropPriceCurrencyList) {
            const price_symbol = priceCurrency.currency.split(',')[2]
            const decimals =
                price_symbol === 'USD'
                    ? 2
                    : priceCurrency.currency.split(',')[0]
            const listing_price =
                priceCurrency.price === 0
                    ? '0 NULL'
                    : `${Number(priceCurrency.price).toFixed(
                          decimals,
                      )} ${price_symbol}`
            listingPrices.push(listing_price)
        }
        return listingPrices
    }

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const payoutRecipientWithSplits = []
        for (let i = 0; i < payoutRecipients.length; i++) {
            payoutRecipientWithSplits.push({
                account: payoutRecipients[i],
                share: recipientSplits[i],
            })
        }

        const dropPriceData = {
            authorized_account: userName,
            drop_id: drop.dropId,

            settlement_symbol: getSettlementSymbol(),
        }

        if (dropPriceCurrencyList.length > 1) {
            dropPriceData.listing_prices = getListingPrices()
        } else {
            dropPriceData.listing_prices = getListingPrices()[0]
        }

        const dropPriceRecipientData = {
            authorized_account: userName,
            drop_id: drop.dropId,
            price_recipients: payoutRecipientWithSplits,
        }

        const dropPriceAction = {
            account: 'nfthivedrops',
            name:
                dropPriceCurrencyList.length > 1
                    ? 'setdprices'
                    : 'setdropprice',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: dropPriceData,
        }

        const dropPriceRecipientAction = {
            account: 'nfthivedrops',
            name: 'setrecipient',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: dropPriceRecipientData,
        }

        actions.push(dropPriceAction)
        actions.push(dropPriceRecipientAction)

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title={t('drops.change_drop_price')} cancel={cancel}>
            <div className="text-xl font-bold mb-4 text-center">
                Do you want to update the price of Drop {drop.dropId} (
                {collectionName})?
            </div>
            <div>
                {isDropPricesLoading ? (
                    <LoadingIndicator />
                ) : (
                    <div>
                        <DropPriceEditor
                            dropId={drop.dropId}
                            dropPriceCurrencyList={dropPriceCurrencyList}
                            setDropPriceCurrencyList={setDropPriceCurrencyList}
                            payoutRecipients={payoutRecipients}
                            setPayoutRecipients={setPayoutRecipients}
                            currencies={currencies}
                            recipientSplits={recipientSplits}
                            setRecipientSplits={setRecipientSplits}
                        />
                    </div>
                )}
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-center gap-2">
                <div className="mx-2">
                    <MainButton className="bg-red-500" onClick={cancel}>
                        Cancel
                    </MainButton>
                </div>
                <div className="mx-2">
                    <MainButton onClick={create}>
                        {t('drops.update_drop')}
                    </MainButton>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default UpdateDropPricePopup
