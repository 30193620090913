import cn from 'classnames'
import React from 'react'
import { LinearProgress } from '@mui/material'

export const LoadingIndicator = ({
    text,
    margin = 'm-3',
    size = 'w-10 h-10',
}) => {
    return text ? (
        <div className={cn('flex flex-wrap justify-center w-full m-auto')}>
            <div className={'text-base font-bold leading-6 my-auto w-full'}>
                {text}
            </div>
            <div className={'m-0 w-full'}>
                <LinearProgress />
            </div>
        </div>
    ) : (
        <div className={cn('flex justify-center w-full m-auto')}>
            <div className={cn('flex justify-center', size, margin)}>
                <img src="/Loader.svg" className="animate-spin" alt="loader" />
            </div>
        </div>
    )
}

export default LoadingIndicator
