import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AssetPreviewDisplay from '../assetpreviewdisplay/AssetPreviewDisplay'
import Link from '../common/util/input/Link'

function AssetDropPreview(props) {
    const asset = props['asset']
    const author = props['author']
    const href = props['href']
    const soldOut = props['soldOut']
    const isPfp = props['isPfp']
    const drop = props['drop']
    const className = props['className']

    const { t } = useTranslation('common')

    const assetData = JSON.parse(asset.idata)

    const { collection } = asset

    let imgSource
    for (const imgSourceOption of [
        'img',
        'image',
        'backimg',
        'BackingIMG',
        'backimage',
        'imgback',
        'Back',
        'img_back',
        'Back Image',
        'img back',
        'Cover',
    ]) {
        if (imgSourceOption in assetData) {
            imgSource = assetData[imgSourceOption]
            break
        }
    }

    if (
        !imgSource &&
        drop['displayData'] &&
        drop['displayData'].includes('preview_img')
    ) {
        imgSource = JSON.parse(drop['displayData'])['preview_img']
    }

    const image = imgSource
        ? imgSource.includes('http')
            ? imgSource
            : `https://ipfs.hivebp.io/preview?collection=${author}&size=320&hash=${imgSource}`
        : ''
    const video = assetData['video']
        ? assetData['video'].includes('http')
            ? assetData['video']
            : `https://ipfs.hivebp.io/preview?collection=${author}&size=320&hash=${assetData['video']}`
        : ''

    return (
        <div className="relative flex justify-center h-64 px-3 m-auto sm:h-80 items-center">
            {isPfp ? (
                <div
                    className={cn(
                        'text-primary text-3xl sm:text-sm lg:text-2xl font-extrabold',
                        'absolute top-4 left-4 -rotate-9',
                        'border-primary p-1 rounded-md border-4 bg-slate-900/40 z-50 text-center whitespace-nowrap',
                    )}
                >
                    {t('drops.generative_drop')}
                </div>
            ) : null}
            {soldOut && (
                <div
                    className={cn(
                        'text-red-600 text-3xl font-extrabold uppercase',
                        'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-12',
                        'border-red-600 p-2 rounded-md border-4 bg-red-100/90 z-50 text-center',
                    )}
                >
                    {t('drops.sold_out')}
                </div>
            )}
            <Link href={href} className="h-full my-auto items-center flex">
                <AssetPreviewDisplay
                    image={image}
                    video={video}
                    prevImg={
                        image && image.includes('preview?collection')
                            ? image
                            : video && video.includes('preview?collection')
                            ? video
                            : null
                    }
                    grayScale={soldOut}
                    imgClassName={cn(
                        'max-h-64 sm:max-h-80 transition transform duration-500 cursor-pointer',
                        className,
                    )}
                    useGradient={false}
                />
            </Link>
        </div>
    )
}

export default AssetDropPreview
