import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazy-load'
import Link from '../common/util/input/Link'
import AssetDropPreview from './AssetDropPreview'

const DropPreview = (props) => {
    const drop = props['drop']

    const {
        dropId,
        contract,
        verified,
        author,
        price,
        currency,
        templatesToMint,
        maxClaimable,
        numClaimed,
        displayData,
        authRequired,
    } = drop

    let dropData

    try {
        dropData = displayData ? JSON.parse(displayData) : ''
    } catch (e) {
        dropData = displayData ? { description: displayData } : ''
    }

    const { t } = useTranslation('common')

    return (
        <div id={'preview_' + dropId} o>
            <LazyLoad className="h-full">
                <Link href={`/drop/${contract}/${dropId}`} className="h-full">
                    <div className="h-full hover:shadow-xl shadow-lg dark:shadow rounded-lg">
                        <div
                            className={
                                'bg-gradient-to-b from-black via-[#000000e8] flex items-center justify-center w-full overflow-hidden my-auto relative rounded-t-lg'
                            }
                        >
                            <AssetDropPreview
                                {...props}
                                className="w-full my-auto"
                                drop={drop}
                                author={author}
                                asset={
                                    templatesToMint ? templatesToMint[0] : null
                                }
                                href={`/drop/${contract}/${dropId}`}
                                soldOut={
                                    maxClaimable > 0 &&
                                    maxClaimable <= numClaimed
                                }
                                isPfp={drop.isPFP}
                                imgClassName={
                                    ' cursor-pointer w-full object-contain'
                                }
                            />
                        </div>

                        <div className="w-full px-3 py-3 dark:bg-paper bg-gray-50 h-28 sm:h-32 rounded-b-lg">
                            <div
                                className={cn(
                                    'font-semibold dark:text-white text-black  ',
                                    {
                                        'text-sm sm:text-base':
                                            dropData &&
                                            dropData.name &&
                                            dropData.name.length <= 25,
                                    },
                                    {
                                        'text-sm sm:text-base':
                                            dropData &&
                                            dropData.name &&
                                            dropData.name.length > 25,
                                    },
                                )}
                            >
                                <div className="flex max-w-full">
                                    {authRequired && (
                                        <LockOutlinedIcon className="text-sm sm:text-base my-auto mr-2" />
                                    )}
                                    <p className="my-auto dark:text-white text-black text-left truncate">
                                        {dropData?.name
                                            ? dropData.name
                                            : drop.dropId}
                                    </p>
                                </div>
                            </div>
                            <div className="flex ">
                                <p className="mr-1 text-zinc-400 text-xs sm:text-sm font-semibold">
                                    by{' '}
                                    <span
                                        className={
                                            verified ? 'text-primary' : ''
                                        }
                                    >
                                        {author}
                                    </span>
                                </p>
                                {verified ? (
                                    <div className={cn('w-5 h-4 my-auto')}>
                                        <img
                                            src={'/verified.svg'}
                                            alt="verified"
                                            width={24}
                                            height={24}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div className="flex  justify-between mt-2">
                                <div className="text-left">
                                    <p className="text-sm sm:text-base font-bold dark:text-white text-black">
                                        {price
                                            ? price + ' ' + currency
                                            : 'Free'}
                                    </p>

                                    {authRequired ? (
                                        <p className="italic text-xs font-medium text-zinc-400">
                                            {t('drops.auth_required')}
                                        </p>
                                    ) : null}
                                </div>
                                <div className="text-right">
                                    <p
                                        className={cn(
                                            maxClaimable > 0 &&
                                                maxClaimable <= numClaimed
                                                ? 'text-red-500'
                                                : 'dark:text-neutral text-zinc-800',
                                            'mx-1 font-bold text-sm sm:text-base',
                                        )}
                                    >
                                        {numClaimed
                                            ? numClaimed > maxClaimable
                                                ? maxClaimable
                                                : numClaimed
                                            : 0}
                                        {maxClaimable > 0 &&
                                            ` / ${maxClaimable}`}
                                    </p>
                                    <p className="text-zinc-400 sm:text-sm text-xs font-semibold">
                                        Claimed
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </LazyLoad>
        </div>
    )
}

export default DropPreview
