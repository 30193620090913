import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import cn from 'classnames'
import React from 'react'

// extract the dimension from the card to give control to the external component
export const cardHeight = {
    normal: 'h-140 md:h-80 lg:h-88 xl:h-112 4xl:h-140',
    high: 'h-120 md:h-88 lg:h-96 xl:h-120 4xl:h-144',
}

export const BaseCard = ({ children, height = 'normal' }) => (
    <div
        className={cn(
            'w-full max-w-80 md:max-w-64 lg:max-w-72 xl:max-w-92 4xl:max-w-108',
            'relative min-w-min rounded-xl overflow-hidden flex flex-col',
            'center shadow hover:shadow-bluet bg-zinc-300 dark:!bg-paper hover:bg-paperl transition-colors',
            'rounded-2xl mx-auto my-4 md:my-2 lg:my-4 backdrop-filter backdrop-blur-sm',
        )}
    >
        {children}
    </div>
)

const enterCardAnimation = keyframes`
    from {
        opacity: 0;
        transform: rotateZ(-20deg);
    }
    40% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    to {
        opacity: 1;
    } 
`

const EnterAnimationWrapper = styled.div`
    opacity: 0;
    animation: ${enterCardAnimation} var(--enter-card-animation-duration, 0.2s)
        ease-in-out calc(var(--index, 0) * 0.1s) 1 forwards;
`

export const StandardCard = ({ cardHeader, children, height = 'normal' }) => (
    <div
        className={cn(
            'relative w-full h-full',
            'overflow-hidden flex flex-col',
            'center hover:shadow-xl shadow-lg dark:shadow dark:bg-paper dark:hover:bg-paperl bg-gray-50 hover:bg-gray-100',
            'transition-colors rounded-xl mx-auto my-auto',
            'backdrop-filter backdrop-blur-sm',
        )}
    >
        {cardHeader}
        {children}
    </div>
)

export const MarketCard = ({ ...props }) => {
    const page = props['page']
    const isNew = props['new']

    if (props['cardHeader'])
        return (
            <div className={'relative px-0'}>
                {!page || page !== 'live' || isNew ? (
                    <EnterAnimationWrapper>
                        <StandardCard {...props} />
                    </EnterAnimationWrapper>
                ) : (
                    <StandardCard {...props} />
                )}
            </div>
        )
    return <BaseCard {...props} />
}
