import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AssetList } from '../assetlist/AssetList'
import get from '../helpers/Api'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'

const FixedSearchComponent = (props) => {
    const searchSettings = props['searchSettings']
        ? props['searchSettings']
        : { minimal: false }

    const getValue = (key, def) => {
        if (searchSettings[key]) return searchSettings[key]['value']
        return def
    }

    const { t } = useTranslation('common')

    const userName = props['userName']
    const [searchResults, setSearchResults] = useState([])
    const searchName = getValue('name', null)
    const searchOwner = getValue('owner', null)
    const searchSchema = getValue('schema', null)
    const searchSearch = getValue('search_type', 'sales')
    const orderBy = getValue('order_by', 'offer')
    const orderDir = getValue('order_dir', 'ASC')
    const searchRarity = getValue('rarity', null)
    const searchVariant = getValue('variant', null)
    const searchColor = getValue('color', null)
    const searchAttr7 = getValue('attr7', null)
    const searchAttr8 = getValue('attr8', null)
    const searchAttr9 = getValue('attr9', null)
    const searchAttr10 = getValue('attr10', null)
    const searchTemplateId = getValue('templateId', null)
    const searchSummaryId = getValue('summaryId', null)
    const searchFavorites = getValue('favorites', false)
    const searchBorder = getValue('border', null)
    const searchType = getValue('type', null)
    const [isLoading, setIsLoading] = useState(false)
    const exactSearch = getValue('exact_search', false)
    const limitIncrease = getValue('limit', 10)
    const minPrice = getValue('min_price', 0)
    const maxPrice = getValue('max_price', 0)
    const minMint = getValue('min_mint', 0)
    const maxMint = getValue('max_mint', 0)
    const attributes = getValue('attributes', '')
    const includeTubes = getValue('includeTubes', true)
    const id = props['id']
    const [isInitial, setIsInitial] = useState(true)
    const [searchDate, setSearchDate] = useState(new Date())
    const searchOffset = getValue('offset', 0)
    const currentSearchPath = []

    const searchOfferType = searchSettings['searchOfferType']
        ? searchSettings['searchOfferType']
        : 'sales'

    const searchAuthor = getValue('collection', '*')

    const searchVerified = !searchAuthor

    const getSearchResult = (result, searchPath) => {
        setIsLoading(false)
        if (
            currentSearchPath.length === 0 ||
            currentSearchPath[currentSearchPath.length - 1] === searchPath
        ) {
            setSearchResults(result)
        }

        return result
    }

    const runSearch = async (
        name,
        owner,
        author,
        schema,
        rarity,
        variant,
        isExactSearch,
        order,
        dir,
        search,
        offerType,
        min_price,
        max_price,
        min_mint,
        max_mint,
        color,
        border,
        type,
        attr7,
        attr8,
        attr9,
        attr10,
        templateId,
        summaryId,
        verified,
        favorites,
        attributes,
        includeTubes,
        limit = limitIncrease,
        offset = 0,
        initial = false,
    ) => {
        if (search === 'bulk_bundle' && !author) return

        if (!author || author === '*') schema = ''

        setIsLoading(true)
        setIsInitial(false)

        let result = []

        if (limit === limitIncrease) {
            setSearchResults(result)
        }

        const searchPath = `search?term=${
            name
                ? name.includes('#') || name.includes('&') || name.includes('?')
                    ? escape(name)
                    : name
                : templateId
                ? templateId
                : ''
        }&owner=${owner ? owner : ''}&author=${author ? author : ''}&schema=${
            schema ? schema : ''
        }&rarity=${rarity ? rarity : ''}&variant=${
            variant
                ? variant.includes('#') ||
                  variant.includes('&') ||
                  variant.includes('?')
                    ? escape(variant)
                    : variant
                : ''
        }&order_by=${order ? order : 'asset_id'}&color=${
            color ? color : ''
        }&type=${type ? type : ''}&border=${border ? border : ''}&order_dir=${
            dir ? dir : 'ASC'
        }&limit=${limit}&search_type=${search}&exact_search=${
            isExactSearch ? isExactSearch : false
        }&offer_type=${offerType}&min_price=${min_price}&max_price=${max_price}&min_mint=${min_mint}&max_mint=${max_mint}&offset=${offset}&attr7=${
            attr7 ? attr7 : ''
        }&attr8=${attr8 ? attr8 : ''}&attr9=${attr9 ? attr9 : ''}&attr10=${
            attr10 ? attr10 : ''
        }&verified=${verified}&favorites=${favorites}&user=${
            userName ? userName : ''
        }&summary_id=${summaryId ? summaryId : ''}${
            includeTubes ? '' : '&include_tubes=false'
        }${attributes ? `&attributes=${attributes}` : ''}`

        currentSearchPath.push(searchPath)

        await get(searchPath).then((result) =>
            getSearchResult(result, searchPath),
        )

        let searchTerm = t('search.assets')
        let ownerTerm = t('search.owner')
        switch (search) {
            case 'trades':
                searchTerm = t('search.trades')
                ownerTerm = t('search.buyer')
                break
            case 'sales':
                searchTerm = t('search.sales')
                ownerTerm = t('search.seller') + '/' + t('search.market')
                break
            case 'duplicates':
                searchTerm = t('search.duplicates')
                break
            case 'missing':
                searchTerm = t('search.missing_assets')
                break
            case 'cheapest_missing':
                searchTerm = t('search.cheapest_missing_assets')
                break
            case 'bundles':
                searchTerm = t('search.bundles')
                ownerTerm = t('search.seller') + '/' + t('search.market')
                break
        }

        setSearchDate(new Date())
    }

    useEffect(() => {
        if (
            !isLoading &&
            isInitial &&
            searchResults.length === 0 &&
            typeof window !== 'undefined'
        ) {
            runSearch(
                searchName,
                searchOwner,
                searchAuthor,
                searchSchema,
                searchRarity,
                searchVariant,
                exactSearch,
                orderBy,
                orderDir,
                searchSearch,
                searchOfferType,
                minPrice,
                maxPrice,
                minMint,
                maxMint,
                searchColor,
                searchBorder,
                searchType,
                searchAttr7,
                searchAttr8,
                searchAttr9,
                searchAttr10,
                searchTemplateId,
                searchSummaryId,
                searchVerified,
                searchFavorites,
                attributes,
                includeTubes,
                limitIncrease,
                0,
                true,
            )
        }
    }, [searchOffset, currentSearchPath, searchSearch])

    return (
        <div
            className={cn(
                'w-full px-0 text-left h-full m-auto flex overflow-y-auto InsideScroll',
            )}
            id="AssetSearch"
        >
            {typeof window !== 'undefined' ? (
                <>
                    <AssetList
                        id={id}
                        searchDate={searchDate}
                        search={searchSearch}
                        name="SearchResults"
                        assets={searchResults.length > 0 ? searchResults : []}
                        onSale={true}
                        isLoading={isLoading}
                        type={getValue('preview_type', null)}
                    />
                    {isLoading && searchResults.length > 0 ? (
                        <LoadingIndicator />
                    ) : null}
                </>
            ) : null}
        </div>
    )
}

export default FixedSearchComponent
