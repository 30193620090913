const cn = require('classnames')

const BulkItemBox = ({
    children,
    title,
    contentWidth = 'w-full',
    breakWhat = 'break-all',
    textSize = 'text-xs md:text-xs',
}) => {
    return (
        <div
            className={cn(
                contentWidth,
                'flex flex-col justify-center px-1 items-sta',
                // colSpan,
            )}
        >
            {title ? (
                <div
                    className={cn(
                        'w-full text-center m-auto font-semibold text-xs md:text-xs',
                    )}
                >
                    {title}
                </div>
            ) : (
                ''
            )}
            <div
                className={cn(
                    'w-full text-center m-auto text-primary font-semibold min-h-[40px]',
                    textSize,
                    breakWhat,
                )}
            >
                {children}
            </div>
        </div>
    )
}

export default BulkItemBox
