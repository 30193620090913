import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import MainButton from '../common/util/input/MainButton'
function CreateDropPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']

    const waxRam = props['waxRam']
    const createDropData = props['createDropData']
    const templateIds = createDropData['templateIds']
    const dropName = createDropData['name']
    const dropDescription = createDropData['description']
    const startDateTime = createDropData['startDateTime']
    const endDateTime = createDropData['endDateTime']
    const dropPriceCurrencyList = createDropData['priceCurrencyList']
    const payoutRecipients = createDropData['payoutRecipient']
    const maxClaimableDrops = createDropData['maxClaimable']
    const accountLimit = createDropData['accountLimit']
    const accountLimitCoolDown = createDropData['accountLimitCoolDown']
    const useAuthProof = createDropData['authRequired']
    const isHidden = createDropData['isHidden']

    const usePremint = createDropData['usePremint']

    const [error, setError] = useState(null)

    const isWaxInList = () => {
        for (let i = 0; i < dropPriceCurrencyList.length; i++) {
            if (dropPriceCurrencyList[i].currency === '8,WAX,WAX') {
                return true
            }
        }
        return false
    }

    const getSettlementSymbol = () => {
        return dropPriceCurrencyList[0].price === 0
            ? '0,NULL'
            : isWaxInList()
            ? '8,WAX'
            : dropPriceCurrencyList[0].currency.split(',')[0] +
              ',' +
              dropPriceCurrencyList[0].currency.split(',')[1]
    }

    const getListingPrices = () => {
        const listingPrices = []
        for (const priceCurrency of dropPriceCurrencyList) {
            const price_symbol = priceCurrency.currency.split(',')[2]
            const decimals =
                price_symbol === 'USD'
                    ? 2
                    : priceCurrency.currency.split(',')[0]
            const listing_price =
                priceCurrency.price === 0
                    ? '0 NULL'
                    : `${Number(priceCurrency.price).toFixed(
                          decimals,
                      )} ${price_symbol}`
            listingPrices.push(listing_price)
        }
        return listingPrices
    }

    const create = async () => {
        setIsLoading(true)

        const templateIdsToMint = []

        templateIds.map((templateId) =>
            templateIdsToMint.push({
                template_id: parseInt(templateId),
                tokens_to_back: [],
                // Hack to differentiate between preminted and on demand drops
                pool_id: usePremint ? 1 : 0,
            }),
        )

        const createDropActions = [getBoostAction(activeUser)]

        const createDropData = {
            authorized_account: userName,
            collection_name: collectionName,
            assets_to_mint: templateIdsToMint,
            listing_prices: getListingPrices(),
            settlement_symbol: getSettlementSymbol(),
            price_recipients: payoutRecipients,
            auth_required: useAuthProof,
            is_hidden: isHidden,
            max_claimable: maxClaimableDrops.toString(),
            account_limit: accountLimit.toString(),
            account_limit_cooldown: parseInt(accountLimitCoolDown),
            start_time: startDateTime,
            end_time: endDateTime,
            display_data: JSON.stringify({
                name: dropName,
                description: dropDescription,
            }),
            use_premint: usePremint,
        }

        if (waxRam && parseFloat(waxRam) > 0) {
            createDropActions.push({
                account: 'eosio.token',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    from: userName,
                    to: 'nfthivedrops',
                    quantity: `${parseFloat(waxRam).toFixed(8)} WAX`,
                    memo: 'deposit_collection_ram:' + collectionName,
                },
            })
        }

        const createDropAction = {
            account: 'nfthivedrops',
            name: 'createdrop',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: createDropData,
        }

        createDropActions.push(createDropAction)
        let createDropTransferError = null
        let createDropTransactionId = null

        try {
            const createDropResult = await activeUser.session.transact(
                {
                    actions: createDropActions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            createDropTransactionId = createDropResult?.response?.transaction_id
        } catch (e) {
            createDropTransferError = e.message
            setError(e.message)
        }

        if (!createDropTransferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !createDropTransferError,
            createDropTransferError ? [createDropTransferError] : null,
            createDropTransactionId,
        )

        return
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Drop" cancel={cancel}>
            <div className="p-2 ">
                <div className="mb-4 text-lg font-bold text-center">
                    Do you want to create a drop with these attributes?
                </div>
                <div>
                    <div className="relative flex justify-center w-full mb-4">
                        <div className="grid grid-cols-2">
                            <div>Collection Name:</div>
                            <div>{collectionName}</div>
                            <div>Template IDs:</div>
                            <div>
                                {templateIds.map((id) => (
                                    <a href={`/template/${id}`}>{id} </a>
                                ))}
                            </div>
                            <div>Name of the Drop:</div>
                            <div>{dropName.toString()}</div>
                            <div>Drop Description:</div>
                            <div>
                                {dropDescription
                                    ? dropDescription
                                    : `<no description>`}
                            </div>
                            <div>Start Date / Time (Unix Timestamp):</div>
                            <div>{startDateTime.toString()}</div>
                            <div>End Date / Time (Unix Timestamp):</div>
                            <div>{endDateTime.toString()}</div>
                            <div>Price per Drop Claim:</div>
                            {dropPriceCurrencyList.map((priceCurrency) => (
                                <div>
                                    {priceCurrency.price}{' '}
                                    {priceCurrency.currency}
                                </div>
                            ))}
                            <div>Payout Recipients:</div>
                            {payoutRecipients.map((recipient) => (
                                <>
                                    <div>
                                        <a
                                            href={`/user/${recipient.account}`}
                                            target="_blank"
                                        >
                                            {recipient.account} -{' '}
                                            {recipient.share} parts
                                        </a>
                                    </div>
                                    <div />
                                </>
                            ))}
                            <div></div>

                            <div>Max Claimable Drops:</div>
                            <div>
                                {maxClaimableDrops === 0
                                    ? 'Infinite'
                                    : maxClaimableDrops}
                            </div>
                            <div>Account Limit:</div>
                            <div>
                                {accountLimit === 0 ? 'Infinite' : accountLimit}
                            </div>
                            <div>Account Limit Cooldown:</div>
                            <div>
                                {accountLimitCoolDown === 0
                                    ? 'No Cooldown'
                                    : accountLimitCoolDown}
                            </div>
                            <div>Authorization Required?</div>
                            <div>
                                {useAuthProof
                                    ? 'Yes (Set up in the Drop Overview)'
                                    : 'No'}
                            </div>
                            <div>Hidden?</div>
                            <div>{isHidden ? 'Yes' : 'No'}</div>
                        </div>
                    </div>
                </div>
                {error ? (
                    <div onClick={dismissError}>
                        <ErrorMessage layer={5} error={error} />
                    </div>
                ) : (
                    ''
                )}
                <div className="flex flex-row justify-center">
                    <div className="mx-2">
                        <MainButton className="bg-red-500 " onClick={cancel}>
                            Cancel
                        </MainButton>
                    </div>
                    <div className="mx-2">
                        <MainButton onClick={create}>Create Drop</MainButton>
                    </div>
                </div>
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default CreateDropPopup
