import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import cn from 'classnames'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import NavigationSearch from '../searchitems/NavigationSearch'
import { useTheme } from 'next-themes'
const style = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
    borderRadius: '10px',
}

export default function SearchMobileModal({ searchOpen, setSearchOpen }) {
    const handleClose = () => {
        setSearchOpen(false)
    }

    const { t } = useTranslation('common')

    return (
        <>
            <button
                onClick={() => {
                    setSearchOpen(true)
                }}
                className="flex flex-col items-center justify-center rounded-md text-primary"
            >
                <img
                    src={
                        searchOpen
                            ? '/icons/magnifying-glass-primary.svg'
                            : '/icons/magnifying-glass-black.svg'
                    }
                    className={'h-5 w-5 block dark:hidden'}
                    aria-hidden="true"
                    alt={t('navigation.search')}
                />
                <img
                    src={
                        searchOpen
                            ? '/icons/magnifying-glass-primary.svg'
                            : '/icons/magnifying-glass-neutral.svg'
                    }
                    className={'h-5 w-5 hidden dark:block'}
                    aria-hidden="true"
                    alt={t('navigation.search')}
                    width={150}
                    height={150}
                />
                <p
                    className={cn('text-[12px]', {
                        'text-primary': searchOpen,
                        'dark:!text-neutral text-zinc-900': !searchOpen,
                    })}
                >
                    {t('navigation.search')}
                </p>
            </button>
            <Modal
                open={searchOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <NavigationSearch />
                </Box>
            </Modal>
        </>
    )
}
