import { DropPaper } from '../drops/DropPaper'
import {
    Alert,
    Collapse,
    FormControlLabel,
    FormGroup,
    Switch,
} from '@mui/material'
import config from '../../config.json'
import { isUnpackAnimationInvalid } from './DropCreatorUtils'
import { useTranslation } from 'react-i18next'
import Inputfield from '../common/util/InputField'
import MainButton from '../common/util/input/MainButton'
import { checkCID, ipfsToCID } from '../helpers/Validators'

export const EditUnpackAnimation = ({
    useUnpackAnimation,
    setUseUnpackAnimation,
    unpackAnimation,
    setUnpackAnimation,
}) => {
    const { t } = useTranslation('common')

    return (
        <DropPaper
            error={isUnpackAnimationInvalid(
                t,
                useUnpackAnimation,
                unpackAnimation,
            )}
            title={t('packs.unpack_animation')}
        >
            <div className="my-5">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={useUnpackAnimation}
                                onChange={() => {
                                    setUseUnpackAnimation(!useUnpackAnimation)
                                }}
                            />
                        }
                        label={t('packs.use_unpack_animation')}
                    />
                </FormGroup>
            </div>
            <Collapse in={useUnpackAnimation}>
                <div className="w-full md:w-96 mb-5">
                    <Inputfield
                        variant="bordered"
                        fullWidth
                        label={t('packs.unpack_animation_ipfs_hash')}
                        value={unpackAnimation}
                        onChange={(e) => {
                            let ipfs = e.target.value.trim()
                            if (checkCID(ipfs)) {
                                ipfs = ipfsToCID(ipfs)
                            }
                            setUnpackAnimation(ipfs)
                        }}
                        isInvalid={isUnpackAnimationInvalid(
                            t,
                            useUnpackAnimation,
                            unpackAnimation,
                        )}
                    />
                </div>
                <div className="w-full md:w-96 mb-5">
                    <MainButton
                        //as="a"
                        href={config.ipfs + unpackAnimation}
                        target="_blank"
                        disabled={isUnpackAnimationInvalid(
                            t,
                            useUnpackAnimation,
                            unpackAnimation,
                        )}
                        isDisabled={isUnpackAnimationInvalid(
                            t,
                            useUnpackAnimation,
                            unpackAnimation,
                        )}
                    >
                        {t('packs.preview_animation_in_new_tab')}
                    </MainButton>
                </div>
                <Collapse
                    in={isUnpackAnimationInvalid(
                        t,
                        useUnpackAnimation,
                        unpackAnimation,
                    )}
                >
                    <div className="mb-5">
                        <Alert severity="error">
                            {isUnpackAnimationInvalid(
                                t,
                                useUnpackAnimation,
                                unpackAnimation,
                            )}
                        </Alert>
                    </div>
                </Collapse>
            </Collapse>
        </DropPaper>
    )
}
