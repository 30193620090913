import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainButton from '../common/util/input/MainButton'
import { sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function BulkTransferPopup(props) {
    const assets = props['assets']

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [errors, setErrors] = useState({})
    const [transactions, setTransactions] = useState({})

    const addError = (assetId, e) => {
        errors[assetId] = e
        setErrors(errors)
    }

    const addTransaction = (assetId, tx) => {
        transactions[assetId] = tx
        setTransactions(transactions)
    }

    const maxActions = 10

    const [currentDisplayAction, setCurrentDisplayAction] = useState(0)

    const transfer = async () => {
        setIsLoading(true)

        let currentAction = 0

        const atomicBurns = []
        const simpleBurns = []

        for (const assetId of Object.keys(assets)) {
            if (assets[assetId].standard === 'atomicassets') {
                atomicBurns.push(assets[assetId].aAssetId)
            } else if (assets[assetId].standard === 'simpleassets') {
                simpleBurns.push(assets[assetId].assetId)
            }
        }

        const actions = [getBoostAction(activeUser)]

        if (simpleBurns.length > 0) {
            actions.push({
                account: 'simpleassets',
                name: 'burn',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    owner: userName,
                    assetids: simpleBurns,
                    memo: 'NFTHive.io Bulk Burn',
                },
            })

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                simpleBurns
                    .sort((a, b) => parseInt(a) - parseInt(b))
                    .map((assetId) =>
                        addTransaction(
                            assetId,
                            result['response']['transaction_id'],
                        ),
                    )
            } catch (e) {
                Object.keys(assets)
                    .filter((asset) =>
                        simpleBurns.includes(assets[asset].assetId),
                    )
                    .map((asset) => addError(asset, e.message))
                console.error(e)
                setError(e.message)
            }
        }

        if (atomicBurns.length > 0 && simpleBurns.length > 0) {
            sleep(1000)
        }

        while (currentAction < atomicBurns.length / maxActions) {
            const start = currentAction * maxActions
            const end = start + maxActions
            const actions = [getBoostAction(activeUser)]

            const currentBurns = atomicBurns
                .sort((a, b) => parseInt(a) - parseInt(b))
                .slice(start, end)

            currentBurns.map((assetId) => {
                actions.push({
                    account: 'atomicassets',
                    name: 'burnasset',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        asset_owner: userName,
                        asset_id: assetId,
                    },
                })
            })

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                Object.keys(assets)
                    .filter((assetId) =>
                        currentBurns.includes(assets[assetId].aAssetId),
                    )
                    .map((assetId) =>
                        addTransaction(
                            assetId,
                            result['response']['transaction_id'],
                        ),
                    )
            } catch (e) {
                Object.keys(assets)
                    .filter((assetId) =>
                        currentBurns.includes(assets[assetId].aAssetId),
                    )
                    .map((asset) => addError(asset, e.message))
                console.error(e)
                setError(e.message)
            } finally {
                currentAction += 1
                setCurrentDisplayAction(currentAction)
            }

            if (currentAction < atomicBurns.length / maxActions) {
                sleep(1000)
            }
        }

        setIsLoading(false)
        callBack({
            transferred: true,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    let transferInfo = t('asset.burn')

    const transferField = (
        <MainButton onClick={transfer}>{transferInfo}</MainButton>
    )

    const cancel = () => {
        callBack({
            transferred: false,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    const name = t('search.bulk_burn')

    return (
        <Popup title={name} cancel={cancel}>
            <div className="mx-auto text-xl font-bold mb-4 mr-4 text-center">
                {t('popups.are_you_sure_you_want_to_burn_assets', {
                    number: Object.keys(assets).length,
                })}
            </div>
            {error ? <ErrorMessage error={error} /> : ''}
            <div className="relative flex justify-center space-x-2 flex-1 mt-8">
                <MainButton className="bg-red-500" onClick={cancel}>
                    {t('asset.cancel')}
                </MainButton>
                {transferField}
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.signing_multiple_transactions', {
                        x: currentDisplayAction,
                        y: Math.ceil(Object.keys(assets).length / maxActions),
                    })}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BulkTransferPopup
