import React from 'react'
import Link from '../common/util/input/Link'
import cn from 'classnames'

export const NavItem = ({ item }) => {
    return (
        <Link
            href={item.href}
            className="flex font-semibold mx-0 h-full justify-start"
        >
            <li
                key={item.name}
                className={cn(
                    'flex dark:!text-white text-zinc-900 px-3 py-2 mx-2 items-start w-full rounded-lg transition ease-in-out duration-300',
                    {
                        'hover:bg-gradient-to-br hover:dark:!bg-zinc-800 hover:bg-zinc-300 cursor-pointer':
                            item.href || item.onclick,
                    },
                )}
            >
                <p
                    className={cn(
                        'bg-primary rounded-lg min-w-[44px] w-[44px] h-[44px] mr-4 flex items-center justify-center',
                        {
                            grayscale: !item.href && !item.onclick,
                        },
                    )}
                >
                    <img
                        src={item.icon}
                        className="h-6 w-6 dark:!text-zinc-900 text-white"
                        aria-hidden="true"
                    />
                </p>
                <div
                    className={
                        'block  dark:!text-white text-zinc-900 font-semibold '
                    }
                    onClick={item.onclick}
                >
                    {item.name}
                    <p className="text-zinc-500 font-normal text-[14px] line-clamp-2">
                        {item.description}
                    </p>
                </div>
            </li>
        </Link>
    )
}

export default NavItem
