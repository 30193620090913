import { Snackbar } from '@mui/material'
import cn from 'classnames'
import { useRouter } from 'next/router'
import qs from 'qs'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { DropPaper } from '../drops/DropPaper'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import { BuyInput } from './BuyInput'
import { CancelInput } from './CancelInput'
import { TransferInput } from './TransferInput'

export const MultiAssetControlPanel = () => {
    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const { t } = useTranslation('common')

    let values =
        typeof window !== 'undefined'
            ? qs.parse(
                  window.location.search.substring(
                      1,
                      window.location.search.length,
                  ),
              )
            : []

    const router = useRouter()
    const location = router.asPath.substring(
        0,
        router.asPath.indexOf('?') > 0
            ? router.asPath.indexOf('?')
            : router.asPath.length,
    )

    const isMarket = location.includes('/market')
    const isTemplate = location.includes('/template')
    const isAccount = [`/user/${userName}`, '/account'].includes(location)
    const isCollection = location && location.includes('/collection')

    const cancel = () => {
        dispatch({ type: 'SET_SELECTED_ASSETS', payload: [] })
    }

    return (
        <Snackbar
            open={
                state.selectedAssets &&
                state.selectedAssets.length > 0 &&
                (isMarket ||
                    (isTemplate &&
                        (!values['tab'] ||
                            (values['tab'] && 'sales' === values['tab']))) ||
                    (isAccount &&
                        values['tab'] &&
                        ['listings', 'inventory'].includes(values['tab'])) ||
                    (isCollection &&
                        values['tab'] &&
                        ['open_market', 'market'].includes(values['tab'])))
            }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            style={{ zIndex: 1200 }}
        >
            <div className={cn('relative w-160 lg:w-180 xl:w-240 max-h-110')}>
                <img
                    className="absolute top-4 right-4 cursor-pointer z-50 h-4"
                    onClick={cancel}
                    src="/close_btn.svg"
                    alt={t('popups.wrapper.close-alt')}
                />
                <DropPaper
                    title={t('search.selection')}
                    expandable
                    expand={true}
                    noMargin
                >
                    <div
                        className={cn(
                            'relative z-40 justify-center border border-papere',
                            'text-xs md:text-sm dark:text-white text-black overflow-y-auto bg-zinc-200 dark:!bg-page ',
                            'rounded-lg m-auto InsideScroll flex w-full max-h-70',
                        )}
                    >
                        {isAccount && values['tab'] === 'inventory' && (
                            <TransferInput />
                        )}
                        {isAccount && values['tab'] === 'listings' && (
                            <CancelInput />
                        )}
                        {(isMarket ||
                            (isTemplate &&
                                (!values['tab'] ||
                                    (values['tab'] &&
                                        'sales' === values['tab']))) ||
                            (isCollection &&
                                ['open_market', 'market'].includes(
                                    values['tab'],
                                ))) && <BuyInput />}
                    </div>
                </DropPaper>
            </div>
        </Snackbar>
    )
}
