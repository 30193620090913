import React, { useEffect, useState } from 'react'
import {
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share'
import CheckIndicator from '../checkindicator/CheckIndicator'
import { copyTextToClipboard } from '../helpers/FormatLinks'
import Popup from './Popup'

const SharePopup = (props) => {
    const asset = props['asset']
    const closeCallBack = props['closeCallBack']
    const [copied, setCopied] = useState(null)

    const cancel = () => {
        closeCallBack()
    }

    const { assetId, offer, orderId, image, templateId, market } = asset.assetId
        ? asset
        : asset.assets[0]

    const link =
        'https://nfthive.io' +
        (assetId
            ? offer
                ? '/listing/' + market + '/' + orderId
                : '/asset/' + assetId
            : '/template/' + templateId)

    const copy = () => {
        copyTextToClipboard(link)
        setCopied(true)
    }

    useEffect(() => {
        if (copied)
            setTimeout(() => {
                setCopied(false)
            }, [2000])
    }, [copied])

    return (
        <Popup
            title={`Share "${asset && asset['name']}"`}
            image={image}
            cancel={cancel}
        >
            <div className="flex justify-evenly w-auto h-auto left-auto">
                <TelegramShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <TelegramIcon size={36} />
                </TelegramShareButton>
                <TwitterShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <TwitterIcon size={36} />
                </TwitterShareButton>
                <FacebookShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <FacebookIcon size={36} />
                </FacebookShareButton>
                <LineShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <LineIcon size={36} />
                </LineShareButton>
                <RedditShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <RedditIcon size={36} />
                </RedditShareButton>
                <WhatsappShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <WhatsappIcon size={36} />
                </WhatsappShareButton>
                <WeiboShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <WeiboIcon size={36} />
                </WeiboShareButton>
            </div>
            <div
                className={
                    'flex justify-center mt-4 hover:underline cursor-pointer h-8'
                }
                onClick={copy}
            >
                {copied ? <CheckIndicator /> : 'Copy Link'}
            </div>
        </Popup>
    )
}

export default SharePopup
