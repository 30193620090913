import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainButton from '../common/util/input/MainButton'
import { sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function BulkMultiSellPopup(props) {
    const assets = props['assets']
    const soldAssets = props['soldAssets']
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']

    const maxActions = 10

    const [currentDisplayAction, setCurrentDisplayAction] = useState(0)

    const assetsToSell = {}

    for (const summaryId of Object.keys(assets)) {
        const summary = assets[summaryId]
        const sellAssets = summary.asset.assets
            .filter(
                (asset) =>
                    !soldAssets ||
                    !soldAssets[summaryId] ||
                    !soldAssets[summaryId]
                        .map((asset) => asset.assetId.toString())
                        .includes(asset.assetId.toString()),
            )
            .sort((a, b) => a.mint - b.mint)
            .slice(
                summary.asset.numOwned -
                    (soldAssets ? Object.keys(soldAssets).length : 0) -
                    summary.amount,
                summary.asset.numOwned -
                    (soldAssets ? Object.keys(soldAssets).length : 0),
            )

        for (const asset of sellAssets) {
            const id =
                asset.standard === 'atomicassets'
                    ? asset['aAssetId']
                    : asset['assetId']

            if (!soldAssets || !Object.keys(soldAssets).includes(id.toString()))
                assetsToSell[id] = {
                    assetId:
                        asset.standard === 'atomicassets'
                            ? asset['aAssetId']
                            : asset['assetId'],
                    standard: asset.standard,
                    mint: asset.mint,
                    price: summary.price,
                    summaryId: summaryId,
                }
        }
    }

    const sell = async () => {
        setIsLoading(true)

        let currentAction = 0

        const sold = {}
        const errors = {}
        const transactions = {}

        let sellError = null

        while (currentAction < Object.keys(assetsToSell).length / maxActions) {
            const start = currentAction * maxActions
            const end = start + maxActions
            const actions = [getBoostAction(activeUser)]

            Object.keys(assetsToSell)
                .sort((a, b) => parseFloat(a.assetId) - parseFloat(b.assetId))
                .slice(start, end)
                .map((assetId) => {
                    const asset = assetsToSell[assetId]
                    const price = parseFloat(asset.price)

                    if (asset.standard === 'atomicassets') {
                        actions.push({
                            account: 'atomicmarket',
                            name: 'announcesale',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                seller: userName,
                                maker_marketplace:
                                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                        ? 'nfthiveboost'
                                        : 'nft.hive',
                                settlement_symbol: '8,WAX',
                                asset_ids: [
                                    asset.aAssetId
                                        ? asset.aAssetId
                                        : asset.assetId,
                                ],
                                listing_price: price.toFixed(8) + ' WAX',
                            },
                        })
                        actions.push({
                            account: 'atomicassets',
                            name: 'createoffer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                sender: userName,
                                recipient: 'atomicmarket',
                                sender_asset_ids: [
                                    asset.aAssetId
                                        ? asset.aAssetId
                                        : asset.assetId,
                                ],
                                recipient_asset_ids: [],
                                memo: 'sale',
                            },
                        })
                    } else {
                        actions.push({
                            account: 'waxplorercom',
                            name: 'announcesale',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                seller: userName,
                                asset_ids: [assetId],
                                listing_price: price.toFixed(8) + ' WAX',
                            },
                        })
                        actions.push({
                            account: 'simpleassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: 'waxplorercom',
                                assetids: [assetId],
                                memo: 'sale',
                            },
                        })
                    }
                })

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                Object.keys(assetsToSell)
                    .sort((a, b) => parseInt(a.assetId) - parseInt(b.assetId))
                    .slice(start, end)
                    .map((assetId) => {
                        sold[assetId] = assetsToSell[assetId]
                        sold[assetId]['transactionId'] =
                            result['response']['transaction_id']
                    })
            } catch (e) {
                Object.keys(assetsToSell)
                    .sort((a, b) => parseInt(a.assetId) - parseInt(b.assetId))
                    .slice(start, end)
                    .map((assetId) => {
                        errors[assetId] = {
                            message: e.message,
                            summaryId: assetsToSell[assetId].summaryId,
                        }
                    })
                console.error(e)
                setError(e.message)
                sellError = e
            } finally {
                currentAction += 1
                setCurrentDisplayAction(currentAction)
                if (currentAction < Object.keys(assets).length / maxActions) {
                    sleep(2000)
                }
            }
        }
        setIsLoading(false)
        if (!sellError) {
            callBack({
                sold: sold,
                errors: errors,
                transactionIds: transactions,
            })
            closeCallBack()
        }
    }

    let sellInfo = t('asset.sell')

    const sellField = (
        <MainButton className="w-32 max-w-32" onClick={sell}>
            {sellInfo}
        </MainButton>
    )

    const cancel = () => {
        callBack({ sold: null, errors: null, transactionIds: null })
        closeCallBack()
    }

    const amount = Object.keys(assets)
        .map((summaryId) => assets[summaryId].amount)
        .reduce((a, b) => a + b, 0)

    const price = Object.keys(assets)
        .map(
            (summaryId) =>
                parseFloat(assets[summaryId].price) *
                parseFloat(assets[summaryId].amount),
        )
        .reduce((a, b) => a + b, 0)

    return (
        <Popup title={t('search.bulk_sell')} cancel={cancel}>
            <div className="mx-auto mb-4 text-xl font-bold">
                {t('popups.are_you_sure_you_want_to_sell_asset_for_amount', {
                    asset: `${amount} Assets`,
                    amount: `${price} WAX`,
                })}
            </div>
            {error ? <ErrorMessage error={error} /> : ''}

            <div className="relative flex justify-end">
                <MainButton onClick={cancel}>{t('asset.cancel')}</MainButton>
                {sellField}
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.signing_multiple_transactions', {
                        x: currentDisplayAction,
                        y: Math.ceil(Object.keys(assets).length / maxActions),
                    })}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BulkMultiSellPopup
