import { TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getDropReferrals } from '../helpers/Api'
import { formatPercentage } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import MainButton from '../common/util/input/MainButton'
import { Input, Button } from '@nextui-org/react'

function UpdateDropReferralPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const drop = props['drop']

    const [referralFee, setReferralFee] = useState(0)

    const [newReferralFee, setNewReferralFee] = useState(0)

    const parseReferrals = (res) => {
        if (res && res['share']) {
            setReferralFee(parseFloat(res['share']))
            setNewReferralFee(parseFloat(res['share']))
        }
    }

    useEffect(() => {
        getDropReferrals(drop.dropId, state).then(parseReferrals)
    }, [drop.dropId])

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            drop_id: drop.dropId,
            authorized_account: userName,
            share: newReferralFee,
        }

        const action = {
            account: 'nfthivedrops',
            name: 'setdropref',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: data,
        }

        actions.push(action)
        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Change drop account limits" cancel={cancel}>
            <div className="p-2 ">
                <div className="mb-4 text-lg font-bold text-center">
                    {t('drops.setup_referral_fee')}
                </div>
                <div>
                    <div className="flex justify-center my-6">
                        <div>
                            <div className="flex justify-between gap-4 ">
                                <div>
                                    {`${t(
                                        'drops.current_referral_fee',
                                    )}: ${formatPercentage(
                                        referralFee / 100.0,
                                    )}`}
                                </div>
                                <div>
                                    {`${t(
                                        'drops.new_referral_fee',
                                    )}: ${formatPercentage(
                                        newReferralFee / 100.0,
                                    )}`}
                                </div>
                            </div>
                            <div className="w-full mt-5">
                                <Input
                                    variant="faded"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    value={
                                        newReferralFee
                                            ? parseFloat(newReferralFee)
                                            : 0
                                    }
                                    onChange={(e) =>
                                        setNewReferralFee(e.target.value)
                                    }
                                    fullWidth={true}
                                    placeholder="0"
                                    inputProps={{ min: 0, max: 50, step: 1 }}
                                    label={t('drops.referral_fee')}
                                />
                            </div>
                            <div className="mt-2 text-center">
                                {t('drops.set_0_to_remove_fees')}
                            </div>
                        </div>
                    </div>
                </div>
                {error ? (
                    <div
                        className="relative w-auto h-auto"
                        onClick={dismissError}
                    >
                        <ErrorMessage layer={5} error={error} />
                    </div>
                ) : (
                    ''
                )}
                <div className="flex flex-row justify-center gap-2">
                    <div className="mx-2">
                        <MainButton className="bg-red-500" onClick={cancel}>
                            Cancel
                        </MainButton>
                    </div>
                    <div className="mx-2">
                        <MainButton onClick={create}>Update Drop</MainButton>
                    </div>
                </div>
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
            {/* {error ? (
                <div className="relative h-auto w-auto" onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={create}>
                        {t('drops.update_drop')}
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )} */}
        </Popup>
    )
}

export default UpdateDropReferralPopup
