import cn from 'classnames'
import { Tabs } from 'react-bootstrap'

const ContentTabs = ({
    children,
    tabKey,
    onSelect,
    bg = ' bg-gradient-to-t dark:!from-app from-zinc-200 to-transparent',
    justify = 'justify-evenly',
    mt = ' mt-6 md:mt-0 lg:mt-4',
    id,
}) => {
    return (
        <div className={'relative'} id={id}>
            <Tabs
                className={cn(
                    'flex flex-wrap md:flex-nowrap h-auto md:h-30px dark:!text-white text-black',
                    'text-base md:text-xs lg:text-base pb-6 border-b-0 md:border-b-2 ',
                    'border-primary',
                    mt,
                    bg,
                    justify,
                )}
                // className={cn(
                //     'flex flex-wrap md:flex-nowrap h-auto md:h-30px dark:!text-white text-black',
                //     'text-base md:text-xs lg:text-base mb-6 border-b-0 md:border-b-2 ',
                //     'border-primary',
                //     mt,
                //     bg,
                //     justify,
                // )}
                activeKey={tabKey}
                id="latest-trades-newest-assets-switch"
                onSelect={onSelect}
            >
                {children}
            </Tabs>
        </div>
    )
}

export default ContentTabs
