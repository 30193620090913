import { Dialog, LinearProgress } from '@mui/material'
import React, { useEffect } from 'react'

export const PopupLoadingIndicator = ({
    text,
    successCount,
    errorCount,
    isLoading,
}) => {
    useEffect(() => {}, [successCount, errorCount])

    return (
        <Dialog open={isLoading}>
            <div className={'flex w-7/8 m-auto flex-wrap p-8'}>
                <div className={'text-base font-bold leading-6 my-auto'}>
                    {text}
                </div>
                <div className={'w-full h-4'}>
                    <LinearProgress />
                </div>
                <div className={'relative block'}>
                    {successCount ? (
                        <div>Successful Transactions: {successCount}</div>
                    ) : (
                        ''
                    )}
                    {errorCount ? <div>Errors: {errorCount}</div> : ''}
                </div>
            </div>
        </Dialog>
    )
}
