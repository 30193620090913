import ExpandIcon from '@mui/icons-material/Expand'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { Collapse, Dialog, IconButton, Tooltip } from '@mui/material'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import { DropButton } from './DropButton'
export const DropPaper = ({
    children,
    title,
    className,
    error,
    id,
    noMargin,
    noPadding,
    expandable,
    defaultExpanded = true,
    expand = null,
    fullscreenable = false,
    fullscreen = false,
    setFullscreen,
    bottomCutOff = false,
    titleMargin = 'my-auto',
}) => {
    const [expanded, setExpanded] = useState(defaultExpanded)
    const [isCutOff, setIsCutOff] = useState(bottomCutOff)

    useEffect(() => {
        if (expand !== null) {
            setExpanded(expand)
        }
    }, [expand])

    return (
        <>
            {fullscreenable && fullscreen ? (
                <Dialog
                    open
                    fullScreen
                    style={{
                        zIndex: 99,
                    }}
                >
                    <div
                        id={id}
                        className={cn(
                            'dark:!bg-paper bg-zinc-100 overflow-hidden p-5',
                            className,
                        )}
                    >
                        <div className="w-full flex justify-between h-[5vh] text-black dark:text-white">
                            {title ? (
                                <div className="text-xl my-auto">{title}</div>
                            ) : (
                                <div />
                            )}
                            <Tooltip title="Exit Fullscreen">
                                <IconButton
                                    color="primary"
                                    className="my-auto"
                                    onClick={() => setFullscreen(false)}
                                >
                                    <FullscreenExitIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="h-[93vh] overflow-y-auto">
                            {children}
                        </div>
                    </div>
                </Dialog>
            ) : (
                <div
                    id={id}
                    className={cn(
                        'border bg-zinc-100 dark:!bg-paper rounded-md shadow-xl',
                        className,
                        { 'border-red-600': error },
                        { 'dark:!border-paperl border-zinc-200': !error },
                        { 'p-2 md:p-5': !noPadding },
                        { 'my-10': !noMargin },
                    )}
                >
                    <div className="w-full flex justify-between">
                        {title ? (
                            <div className="flex justify-start">
                                {expandable ? (
                                    <div className="my-auto">
                                        <IconButton
                                            color="primary"
                                            onClick={() =>
                                                setExpanded(!expanded)
                                            }
                                        >
                                            {expanded ? (
                                                <UnfoldLessIcon />
                                            ) : (
                                                <UnfoldMoreIcon />
                                            )}
                                        </IconButton>
                                    </div>
                                ) : null}
                                <div className={cn('text-xl', titleMargin)}>
                                    {title}
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                        {fullscreenable ? (
                            <Tooltip title="Enter Fullscreen">
                                <IconButton
                                    color="primary"
                                    className="my-auto"
                                    onClick={() => setFullscreen(true)}
                                >
                                    <FullscreenIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <div />
                        )}
                    </div>
                    <Collapse in={expanded}>
                        <div className={title ? 'mb-5' : ''} />
                        <div
                            className={cn({
                                'h-[60vh] overflow-hidden border-b border-paperl':
                                    isCutOff,
                            })}
                        >
                            {children}
                        </div>
                        {isCutOff ? (
                            <div>
                                <DropButton
                                    className="flex justify-center mt-5"
                                    size="small"
                                    variant="text"
                                    fullWidth
                                    startIcon={<ExpandIcon />}
                                    onClick={() => setIsCutOff(false)}
                                >
                                    Expand
                                </DropButton>
                            </div>
                        ) : null}
                    </Collapse>
                </div>
            )}
        </>
    )
}
