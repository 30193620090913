import cn from 'classnames'
import NextLink from 'next/link'
import React from 'react'

export const DropLink = ({ children, href, external, className }) => {
    if (external)
        return (
            <a
                className={cn(className, 'text-center')}
                href={href.toString()}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        )
    return (
        <NextLink href={href} passHref>
            <span className={cn(className, 'text-center')}>{children}</span>
        </NextLink>
    )
}
