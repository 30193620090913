import cn from 'classnames'
import React from 'react'
import Link from '../common/util/input/Link'

export const CollectionAssetName = ({
    verified,
    blacklisted,
    elementRef,
    collectionImage,
    linkInactive,
    onClick,
    altOnClick,
    href,
    name,
    collection,
    cardWidth,
}) => {
    return (
        <div className={'flex  w-full  y-auto h-[56px] sm:h-[68px]'}>
            <div
                ref={elementRef}
                className="flex items-center justify-start w-full -mt-2 "
            >
                <div className="flex items-center justify-center mr-2 border-2 rounded-lg w-9 sm:w-12 h-9 sm:h-12 border-zinc-300 dark:border-zinc-700">
                    {collectionImage ? (
                        <div
                            className={cn(
                                'w-11 h-11 flex justify-center items-center overflow-hidden rounded-lg m-1/2',
                            )}
                        >
                            <img
                                src={collectionImage}
                                alt={
                                    collection ? collection : 'collection-image'
                                }
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="flex flex-col ">
                    {/* tittle */}
                    <div className="w-full mt-2 text-left ">
                        <Link
                            inactive={linkInactive}
                            href={href}
                            onClick={onClick}
                            altOnClick={altOnClick}
                            className={cn(
                                'w-full flex justify-start text-zinc-900 dark:text-white',
                                'cursor-pointer font-bold h-1/5  overflow-hidden',
                                {
                                    'text-xs md:text-xs lg:text-xs':
                                        name && name.length >= 30,
                                },
                                {
                                    'text-sm md:text-sm lg:text-sm':
                                        name && name.length < 30,
                                },
                            )}
                        >
                            <div
                                className={''}
                                style={{
                                    width: cardWidth,
                                }}
                            >
                                <p title={name} className="truncate">
                                    {name}
                                </p>
                            </div>
                        </Link>
                    </div>
                    <div className="flex flex-row items-center mb-2">
                        <Link
                            href={'/collection/' + collection}
                            inactive={blacklisted}
                        >
                            <div className="w-full font-sans">
                                <div className={'flex justify-start'}>
                                    <div
                                        className={cn(
                                            'flex my-auto bottom-2 h-full ',
                                        )}
                                    >
                                        {' '}
                                        <p className="text-xs font-light text-gray-400 md:text-sm">
                                            {' '}
                                            <b>by&nbsp;</b>
                                        </p>
                                        <p
                                            className={cn(
                                                'mb-auto h-full',
                                                {
                                                    'text-xs font-light text-gray-400 md:text-sm':
                                                        collection &&
                                                        collection.length > 15,
                                                },
                                                {
                                                    'text-xs font-light text-gray-400 md:text-sm':
                                                        collection &&
                                                        collection.length <= 15,
                                                },
                                            )}
                                        >
                                            <b>
                                                {collection &&
                                                collection.length > 20
                                                    ? collection.substring(
                                                          0,
                                                          20,
                                                      ) + '...'
                                                    : collection}
                                            </b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div>
                            {verified && !blacklisted && (
                                <div className={cn('w-5 h-4 ml-1 my-auto')}>
                                    <img
                                        src={'/verified.svg'}
                                        alt="verified"
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            )}
                            {blacklisted && (
                                <div className={cn('w-5 h-4 ml-1 my-auto')}>
                                    <img
                                        src={'/blacklisted.svg'}
                                        alt="blacklisted"
                                        width={150}
                                        height={150}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
