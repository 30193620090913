import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CheckIndicator from '../checkindicator/CheckIndicator'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function BulkUnstakePopup(props) {
    const assets = props['assets']

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [errors, setErrors] = useState({})
    const [transferred, setTransferred] = useState([])
    const [transactions, setTransactions] = useState({})

    const addError = (assetId, e) => {
        errors[assetId] = e
        setErrors(errors)
    }

    const addTransaction = (assetId, tx) => {
        transactions[assetId] = tx
        setTransactions(transactions)
    }

    const transfer = async () => {
        setIsLoading(true)

        let transferError = null

        const simpleAssetIds = []
        const atomicAssetsRplanet = []
        const atomicAssetsKryptonMining = []
        const atomicAssetsKryptonAttack = []
        const atomicAssetsKryptonBarrack = []
        const actions = [getBoostAction(activeUser)]

        Object.keys(assets).map((assetId) => {
            const asset = assets[assetId]

            if (
                asset.standard === 'atomicassets' &&
                asset.owner === 's.rplanet'
            )
                atomicAssetsRplanet.push(asset.aAssetId)
            else if (
                asset.standard === 'atomicassets' &&
                asset.owner === 'waxdaofarmer'
            ) {
                actions.push({
                    account: 'waxdaofarmer',
                    name: 'unstake',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        asset_id:
                            asset.standard === 'simpleassets'
                                ? asset.assetId
                                : asset.aAssetId,
                        user: userName,
                        farmname: asset.action.split('|')[2],
                    },
                })
            } else if (
                asset.standard === 'atomicassets' &&
                asset.owner === 'pepperstake'
            ) {
                actions.push({
                    account: 'pepperstake',
                    name: 'unstake',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        asset_ids: [asset.aAssetId],
                        quantity: '0 POW',
                        owner: userName,
                        pool_id: asset.action.replace('stake:', ''),
                    },
                })
            } else if (
                asset.standard === 'atomicassets' &&
                asset.owner === 'kryptonstake' &&
                asset.action === 'mining'
            )
                atomicAssetsKryptonMining.push(asset.aAssetId)
            else if (
                asset.standard === 'atomicassets' &&
                asset.owner === 'kryptonstake' &&
                asset.action === 'attack'
            )
                atomicAssetsKryptonAttack.push(asset.aAssetId)
            else if (
                asset.standard === 'atomicassets' &&
                asset.owner === 'kryptonstake' &&
                asset.action === 'barrack'
            )
                atomicAssetsKryptonBarrack.push(asset.aAssetId)
            else if (asset.standard === 'simpleassets')
                simpleAssetIds.push(asset.assetId)
        })

        if (simpleAssetIds.length > 0) {
            actions.push({
                account: 's.rplanet',
                name: 'unstake',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    asset_ids: simpleAssetIds,
                    contract: 'simpleassets',
                    to: userName,
                },
            })
        }

        if (atomicAssetsRplanet.length > 0) {
            actions.push({
                account: 's.rplanet',
                name: 'unstake',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    asset_ids: atomicAssetsRplanet,
                    contract: 'atomicassets',
                    to: userName,
                },
            })
        }

        if (atomicAssetsKryptonMining.length > 0) {
            actions.push({
                account: 'kryptonstake',
                name: 'unstake',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    asset_ids: atomicAssetsKryptonMining,
                    authorized_user: userName,
                    memo: 'mining',
                },
            })
        }

        if (atomicAssetsKryptonAttack.length > 0) {
            actions.push({
                account: 'kryptonstake',
                name: 'unstake',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    asset_ids: atomicAssetsKryptonAttack,
                    authorized_user: userName,
                    memo: 'attack',
                },
            })
        }

        if (atomicAssetsKryptonBarrack.length > 0) {
            actions.push({
                account: 'kryptonstake',
                name: 'unstake',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    asset_ids: atomicAssetsKryptonBarrack,
                    authorized_user: userName,
                    memo: 'barrack',
                },
            })
        }

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            Object.keys(assets)
                .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                .map((assetId) =>
                    addTransaction(
                        assetId,
                        result['response']['transaction_id'],
                    ),
                )
        } catch (e) {
            Object.keys(assets).map((asset) => addError(asset, e.message))
            console.error(e)
            setError(e.message)
            transferError = e
        } finally {
            if (!error && !transferError) {
                Object.keys(assets)
                    .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                    .map((assetId) => transferred.push(assets[assetId]))
                setTransferred(true)
            }
        }
        setIsLoading(false)
        callBack({
            transferred: true,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    let transferInfo = t('asset.unstake')

    const transferField = (
        <MainButton className="w-[80px] max-w-32" onClick={transfer}>
            {transferInfo}
        </MainButton>
    )

    const cancel = () => {
        callBack({
            transferred: true,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    return (
        <Popup title={transferInfo} cancel={cancel}>
            {Object.keys(transferred).length > 0 ? <CheckIndicator /> : ''}
            {Object.keys(transferred).length === 0 ? (
                <div className="p-4 mx-auto mb-4 text-xl font-bold text-center">
                    {t('popups.are_you_sure_you_want_to_unstake_assets', {
                        number: Object.keys(assets).length,
                    })}
                </div>
            ) : (
                ''
            )}
            {error ? <ErrorMessage error={error} /> : ''}
            {Object.keys(transferred).length === 0 ? (
                <div className="relative flex justify-center gap-4">
                    <MainButton onClick={cancel} className="bg-red-500">
                        {t('asset.cancel')}
                    </MainButton>
                    {transferField}
                </div>
            ) : (
                ''
            )}
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BulkUnstakePopup
