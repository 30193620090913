import { Modal, ModalContent, ModalBody } from '@nextui-org/react'
import React, { useContext, useEffect } from 'react'
import { Context } from '../waxplorer/Waxplorer'
import AuctionPopup from './AuctionPopup'
import AuthorizeNfthivedropsPopup from './AuthorizeNfthivedropsPopup'
import BidPopup from './BidPopup'
import BreakPopup from './BreakPopup'
import BulkAuctionPopup from './BulkAuctionPopup'
import BulkBurnPopup from './BulkBurnPopup'
import BulkBuyPopup from './BulkBuyPopup'
import BulkCancelPopup from './BulkCancelPopup'
import BulkDistributePopup from './BulkDistributePopup'
import BulkEditPopup from './BulkEditPopup'
import BulkMultiSellPopup from './BulkMultiSellPopup'
import BulkSellPopup from './BulkSellPopup'
import BulkStakePopup from './BulkStakePopup'
import BulkSwapPopup from './BulkSwapPopup'
import BulkTransferPopup from './BulkTransferPopup'
import BulkUnstakePopup from './BulkUnstakePopup'
import BundlePopup from './BundlePopup'
import BurnPopup from './BurnPopup'
import BuyDropPopup from './BuyDropPopup'
import BuyMissingPopup from './BuyMissingPopup'
import BuyOfferPopup from './BuyOfferPopup'
import BuyPopup from './BuyPopup'
import BuyWaxPopup from './BuyWaxPopup'
import CollectionCategoryPopup from './CollectionCategoryPopup'
import CollectionPopup from './CollectionPopup'
import CreateAndMintTemplatePopup from './CreateAndMintTemplatePopup'
import CreateDropPopup from './CreateDropPopup'
import CreatePackPopup from './CreatePackPopup'
import CreatePackReleasePopup from './CreatePackReleasePopup'
import CreatePoolPopup from './CreatePoolPopup'
import DeleteDropPopup from './DeleteDropPopup'
import DeletePackPopup from './DeletePackPopup'
import DeletePackReleasePopup from './DeletePackReleasePopup'
import DeletePoolPopup from './DeletePoolPopup'
import DisableFilterPopup from './DisableFilterPopup'
import EditPackDescriptionAnimationPopup from './EditPackDescriptionAnimationPopup'
import EditPackTotalsPopup from './EditPackTotalsPopup'
import EditPackUnlockTimePopup from './EditPackUnlockTimePopup'
import EditPopup from './EditPopup'
import EditSchemaPopup from './EditSchemaPopup'
import EnableFilterPopup from './EnableFilterPopup'
import HandleTradePopup from './HandleTradePopup'
import SellOfferPopup from './SellOfferPopup'
import SellPopup from './SellPopup'
import SetItemPopup from './SetItemPopup'
import SharePopup from './SharePopup'
import ShowDropBuyerPopup from './ShowDropBuyerPopup'
import SkipConfirmationPopup from './SkipConfirmationPopup'
import SpecialUpdatePopup from './SpecialUpdatePopup'
import StakeCpuNetPopup from './StakeCpuNetPopup'
import StakeHoneyPopup from './StakeHoneyPopup'
import TagsPopup from './TagsPopup'
import TradePopup from './TradePopup'
import TransferPopup from './TransferPopup'
import TransferTokenPopup from './TransferTokenPopup'
import TubePopup from './TubePopup'
import UnlockReleasePopup from './UnlockReleasePopup'
import UnpackPopup from './UnpackPopup'
import UnstakeCpuNetPopup from './UnstakeCpuNetPopup'
import UnstakeHoneyPopup from './UnstakeHoneyPopup'
import UpdateDropAccountLimitPopups from './UpdateDropAccountLimitsPopup'
import UpdateDropMaxClaimablePopup from './UpdateDropMaxClaimablePopup'
import UpdateDropNameDescrPopup from './UpdateDropNameDescrPopup'
import UpdateDropPricePopup from './UpdateDropPricePopup'
import UpdateDropReferralPopup from './UpdateDropReferralPopup'
import UpdatePoolPopup from './UpdatePoolPopup'
import UpdateStartEndDatePopup from './UpdateStartEndDatePopup'
import UserPicturePopup from './UserPicturePopup'
import WithdrawBuyOffersPopup from './WithdrawBuyOffersPopup'
import CreatePackMintOnDemandPopup from './CreatePackMintOnDemandPopup'

function PopupWrapper() {
    const [state, dispatch] = useContext(Context)

    const asset = state.asset
    const amount = state.claimAmount
    const includeTubes = state.includeTubes

    const drop = state.drop
    const sellPrice = state.sellPrice
    const action = state.action
    const callBack = state.callBack
    const assets = state.assets
    const soldAssets = state.soldAssets
    const receiver = state.receiver ? state.receiver.trim().toLowerCase() : ''
    const wax_balance = state.wax_balance
    const offerId = state.offerId
    const tradeOption = state.tradeOption
    const tubeId = state.tubeId
    const lowestMints = state.lowestMints

    const editSchemaName = state.editSchemaName
    const newCollection = state.newCollection
    const editSchemaAttributes = state.editSchemaAttributes
    const cpu = state.cpu
    const net = state.net
    const memo = state.memo
    const filter = state.filter

    const collectionName = state.collectionName
    const schemaName = state.schemaName
    const tags = state.tags

    const dropId = state.dropId
    const waxRam = state.waxRam
    const createDropData = state.createDropData

    const packId = state.packId
    const poolId = state.poolId
    const assetsToAddToPool = state.assetsToAddToPool
    const assetsToRemoveFromPool = state.assetsToRemoveFromPool
    const packTemplateId = state.packTemplateId
    const packReleaseId = state.packReleaseId
    const packName = state.packName
    const packDescription = state.packDescription
    const packUnpackAnimation = state.packUnpackAnimation
    const packUnlockTime = state.packUnlockTime
    const packTotalPacks = state.packTotalPacks
    const packSlots = state.packSlots
    const packTokens = state.packTokens
    const templateDataArray = state.templateDataArray
    const tableKey = state.tableKey
    const maxSupplyMap = state.maxSupplyMap
    const mintNumbersMap = state.mintNumbersMap
    const existingMints = state.existingMints

    const sellElement = (
        <SellPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const auctionElement = (
        <AuctionPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const buyOfferElement = (
        <BuyOfferPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(item) => {
                callBack(item)
            }}
            offer={sellPrice}
        />
    )

    const backAssetElement = <></>

    const sellOfferElement = (
        <SellOfferPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold) => {
                callBack(wasSold)
            }}
            offer={sellPrice}
        />
    )

    const bulkSellElement = (
        <BulkSellPopup
            assets={assets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(sold) => {
                callBack(sold)
            }}
            offer={sellPrice}
        />
    )

    const bulkAuctionElement = (
        <BulkAuctionPopup
            assets={assets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(sold) => {
                callBack(sold)
            }}
            offer={sellPrice}
        />
    )

    const bulkMultiSellElement = (
        <BulkMultiSellPopup
            assets={assets}
            soldAssets={soldAssets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(sold) => {
                callBack(sold)
            }}
            offer={sellPrice}
        />
    )

    const bulkDistributeElement = (
        <BulkDistributePopup
            assets={assets}
            memo={memo}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(distributed) => {
                callBack(distributed)
            }}
        />
    )

    const bulkBuyElement = (
        <BulkBuyPopup
            assets={assets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(sold) => {
                callBack(sold)
            }}
            offer={sellPrice}
        />
    )

    const bulkEditElement = (
        <BulkEditPopup
            assets={assets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(sold) => {
                callBack(sold)
            }}
            offer={sellPrice}
        />
    )

    const bulkSwapElement = (
        <BulkSwapPopup
            assets={assets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const bulkTransferElement = (
        <BulkTransferPopup
            assets={assets}
            receiver={receiver}
            memo={memo}
            closeCallBack={() => {
                dispatch({ type: 'SET_RECEIVER', payload: '' })
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                dispatch({ type: 'SET_RECEIVER', payload: '' })
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const editElement = (
        <EditPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const bulkCancelElement = (
        <BulkCancelPopup
            assets={assets}
            receiver={receiver}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const bulkBurnElement = (
        <BulkBurnPopup
            assets={assets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const bulkStakeElement = (
        <BulkStakePopup
            assets={assets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const bulkUnstakeElement = (
        <BulkUnstakePopup
            assets={assets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const bundleElement = (
        <BundlePopup
            assets={assets}
            receiver={receiver}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const bidElement = (
        <BidPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(bidInfo) => {
                callBack(bidInfo)
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            offer={sellPrice}
        />
    )

    const burnElement = (
        <BurnPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(burned) => {
                callBack(burned)
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const breakElement = (
        <BreakPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(burned) => {
                callBack(burned)
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const buyElement = (
        <BuyPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasBought, e = null, asset_id = null) => {
                callBack(wasBought)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const skipElement = (
        <SkipConfirmationPopup
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasBought) => {
                callBack(wasBought)
            }}
        />
    )

    const disableFilterElement = (
        <DisableFilterPopup
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            filter={filter}
            callBack={(wasBought) => {
                callBack(wasBought)
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_FILTER', payload: '' })
            }}
        />
    )

    const enableFilterElement = (
        <EnableFilterPopup
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            filter={filter}
            callBack={(wasBought) => {
                callBack(wasBought)
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_FILTER', payload: '' })
            }}
        />
    )

    const buyMissingElement = (
        <BuyMissingPopup
            asset={asset}
            includeTubes={includeTubes}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(buyInfo, e = null, asset_id = null) => {
                callBack(buyInfo)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const buyDropElement = (
        <BuyDropPopup
            drop={drop}
            amount={amount}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_DROP', payload: '' })
            }}
            action={action}
            callBack={(buyInfo) => {
                callBack(buyInfo)
            }}
        />
    )

    const createDropElement = (
        <CreateDropPopup
            collectionName={collectionName}
            waxRam={waxRam}
            createDropData={createDropData}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_CREATE_DROP_DATA', payload: null })
            }}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
        />
    )

    const showDropBuyersElement = (
        <ShowDropBuyerPopup
            collectionName={collectionName}
            dropId={dropId}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_ID', payload: '' })
            }}
            callBack={(success, e = null, transactionId = null) => {
                callBack(success, e, transactionId)
            }}
        />
    )

    const createPackReleaseElement = (
        <CreatePackReleasePopup
            collectionName={collectionName}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
            }}
        />
    )

    const createPoolElement = (
        <CreatePoolPopup
            collectionName={collectionName}
            packReleaseId={packReleaseId}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_RELEASE_ID', payload: '' })
            }}
        />
    )

    const createPackModElement = (
        <CreatePackMintOnDemandPopup
            collectionName={collectionName}
            packReleaseId={packReleaseId}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_RELEASE_ID', payload: '' })
            }}
        />
    )

    const updatePoolElement = (
        <UpdatePoolPopup
            collectionName={collectionName}
            poolId={poolId}
            assetsToAddToPool={assetsToAddToPool}
            assetsToRemoveFromPool={assetsToRemoveFromPool}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_POOL_ID', payload: '' })
                dispatch({ type: 'SET_ASSETS_TO_ADD_TO_POOL', payload: '' })
                dispatch({
                    type: 'SET_ASSETS_TO_REMOVE_FROM_POOL',
                    payload: '',
                })
            }}
        />
    )

    const authorizeNfthivedropsElement = (
        <AuthorizeNfthivedropsPopup
            collectionName={collectionName}
            callBack={(wasAuthorized, e = null, transactionId = null) => {
                callBack(wasAuthorized, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
            }}
        />
    )

    const updateDropNameDescrElement = (
        <UpdateDropNameDescrPopup
            collectionName={collectionName}
            drop={drop}
            dropName={createDropData?.name}
            dropDescription={createDropData?.description}
            previewImg={createDropData?.previewImg}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP', payload: '' })
                dispatch({
                    type: 'SET_CREATE_DROP_DATA',
                    payload: null,
                })
            }}
        />
    )

    const updateDropPriceElement = (
        <UpdateDropPricePopup
            collectionName={collectionName}
            drop={drop}
            dropPriceCurrencyList={createDropData?.priceCurrencyList}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP', payload: '' })
                dispatch({
                    type: 'SET_CREATE_DROP_DATA',
                    payload: null,
                })
            }}
        />
    )

    const updateDropStartEndDateElement = (
        <UpdateStartEndDatePopup
            collectionName={collectionName}
            drop={drop}
            dropStartDateTime={createDropData?.startDate}
            dropEndDateTime={createDropData?.endDate}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({
                    type: 'SET_CREATE_DROP_DATA',
                    payload: null,
                })
                dispatch({ type: 'SET_DROP', payload: '' })
            }}
        />
    )

    const updateDropMaxClaimableElement = (
        <UpdateDropMaxClaimablePopup
            collectionName={collectionName}
            drop={drop}
            dropMaxClaimableDrops={createDropData?.maxClaimable}
            dropAllowInfiniteClaimable={createDropData?.allowInfiniteClaimable}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({
                    type: 'SET_CREATE_DROP_DATA',
                    payload: null,
                })
                dispatch({ type: 'SET_DROP', payload: '' })
            }}
        />
    )

    const updateDropAccountLimitElement = (
        <UpdateDropAccountLimitPopups
            collectionName={collectionName}
            drop={drop}
            dropAccountLimit={createDropData?.accountLimit}
            dropAccountLimitCooldown={createDropData?.accountLimitCooldown}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_CREATE_DROP_DATA', payload: null })
                dispatch({ type: 'SET_DROP', payload: '' })
            }}
        />
    )

    const updateDropReferralElement = (
        <UpdateDropReferralPopup
            collectionName={collectionName}
            drop={drop}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP', payload: '' })
            }}
        />
    )

    const deleteDropElement = (
        <DeleteDropPopup
            collectionName={collectionName}
            drop={drop}
            callBack={(wasDeleted, e = null, transactionId = null) => {
                callBack(wasDeleted, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP', payload: '' })
            }}
        />
    )

    const deletePackReleaseElement = (
        <DeletePackReleasePopup
            collectionName={collectionName}
            releaseId={packReleaseId}
            callBack={(wasDeleted, e = null, transactionId = null) => {
                callBack(wasDeleted, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_RELEASE_ID', payload: '' })
            }}
        />
    )

    const unlockReleaseElement = (
        <UnlockReleasePopup
            collectionName={collectionName}
            releaseId={packReleaseId}
            callBack={(wasDeleted, e = null, transactionId = null) => {
                callBack(wasDeleted, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_RELEASE_ID', payload: '' })
            }}
        />
    )

    const deletePackElement = (
        <DeletePackPopup
            collectionName={collectionName}
            packId={packId}
            releaseId={packReleaseId}
            callBack={(wasDeleted, e = null, callBackData = null) => {
                callBack(wasDeleted, e, callBackData)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_ID', payload: '' })
            }}
        />
    )

    const deletePoolElement = (
        <DeletePoolPopup
            collectionName={collectionName}
            poolId={poolId}
            releaseId={packReleaseId}
            callBack={(wasDeleted, e = null, callBackData = null) => {
                callBack(wasDeleted, e, callBackData)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_POOL_ID', payload: '' })
            }}
        />
    )

    const createPackElement = (
        <CreatePackPopup
            collectionName={collectionName}
            packReleaseId={packReleaseId}
            packTemplateId={packTemplateId}
            packName={packName}
            packDescription={packDescription}
            packUnpackAnimation={packUnpackAnimation}
            packTotalPacks={packTotalPacks}
            packSlots={packSlots}
            packTokens={packTokens}
            packUnlockTime={packUnlockTime}
            callBack={(wasCreated, e = null, callBackData = null) => {
                callBack(wasCreated, e, callBackData)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_DESCRIPTION', payload: '' })
                dispatch({ type: 'SET_PACK_UNPACK_ANIMATION', payload: '' })
                dispatch({ type: 'SET_PACK_TOTAL_PACKS', payload: '' })
                dispatch({ type: 'SET_PACK_SLOTS', payload: '' })
                dispatch({ type: 'SET_PACK_UNLOCK_TIME', payload: '' })
                dispatch({ type: 'SET_PACK_RELEASE_ID', payload: '' })
                dispatch({ type: 'SET_PACK_TEMPLATE_ID', payload: '' })
            }}
        />
    )

    const editPackDescriptionAnimationElement = (
        <EditPackDescriptionAnimationPopup
            collectionName={collectionName}
            packId={packId}
            packName={packName}
            packDescription={packDescription}
            packUnpackAnimation={packUnpackAnimation}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({
                    type: 'SET_ACTION',
                    payload: '',
                })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_ID', payload: '' })
                dispatch({ type: 'SET_PACK_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_DESCRIPTION', payload: '' })
                dispatch({
                    type: 'SET_PACK_UNPACK_ANIMATION',
                    payload: '',
                })
            }}
        />
    )

    const editPackTotalsElement = (
        <EditPackTotalsPopup
            collectionName={collectionName}
            packId={packId}
            packTotalPacks={packTotalPacks}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({
                    type: 'SET_ACTION',
                    payload: '',
                })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_ID', payload: '' })
                dispatch({ type: 'SET_PACK_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_TOTAL_PACKS', payload: '' })
            }}
        />
    )

    const editPackUnlockTimeElement = (
        <EditPackUnlockTimePopup
            collectionName={collectionName}
            packId={packId}
            packUnlockTime={packUnlockTime}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({
                    type: 'SET_ACTION',
                    payload: '',
                })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_ID', payload: '' })
                dispatch({ type: 'SET_PACK_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_UNLOCK_TIME', payload: '' })
            }}
        />
    )

    const setItemElement = (
        <SetItemPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const editSchemaElement = (
        <EditSchemaPopup
            drop={drop}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_SCHEMA_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_NEW', payload: '' })
                dispatch({ type: 'SET_EDIT_SCHEMA_ATTRIBUTES', payload: [] })
            }}
            // Repurposing SET_EDIT_NEW / newCollection here, maybe change later
            collectionUniqueName={collectionName}
            editSchemaName={editSchemaName}
            newSchema={newCollection}
            editSchemaAttributes={editSchemaAttributes}
            callBack={(
                wasEdited,
                wasNewSchema,
                e = null,
                transactionId = null,
            ) => {
                callBack(wasEdited, wasNewSchema, e, transactionId)
            }}
        />
    )

    const createAndMintTemplateElement = (
        <CreateAndMintTemplatePopup
            collectionName={collectionName}
            schemaName={schemaName}
            templateDataArray={templateDataArray}
            tableKey={tableKey}
            maxSupplyMap={maxSupplyMap}
            mintNumbersMap={mintNumbersMap}
            existingMints={existingMints}
            callBack={(success, errors = null, transactionIds = null) => {
                callBack(success, errors, transactionIds)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_SCHEMA_NAME', payload: '' })
                dispatch({ type: 'SET_TEMPLATE_DATA_ARRAY', payload: '' })
                dispatch({ type: 'SET_TABLE_KEY', payload: '' })
                dispatch({ type: 'SET_MAX_SUPPLY_MAP', payload: '' })
                dispatch({ type: 'SET_MINT_NUMBERS_MAP', payload: '' })
                dispatch({ type: 'SET_EXISTING_MINTS', payload: '' })
            }}
        />
    )

    const editCollectionCategoryElement = (
        <CollectionCategoryPopup
            collectionName={collectionName}
            callBack={callBack}
            suggest={action === 'suggestCollectionCategory'}
            closeCallBack={() => {
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const editTagsElement = (
        <TagsPopup
            collectionName={collectionName}
            callBack={callBack}
            tags={tags}
            suggest={action === 'suggestTag'}
            closeCallBack={() => {
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const transferElement = (
        <TransferPopup
            asset={asset}
            receiver={receiver}
            closeCallBack={() => {
                dispatch({ type: 'SET_RECEIVER', payload: '' })
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasTransferred, receiver, e = null, asset_id = null) => {
                callBack(wasTransferred, receiver)
                dispatch({ type: 'SET_RECEIVER', payload: '' })
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const collectionElement = (
        <CollectionPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasBought) => {
                callBack(wasBought)
            }}
        />
    )

    const shareElement = (
        <SharePopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const tradeElement = (
        <TradePopup
            assets={assets}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(tradeInfo) => {
                callBack(tradeInfo)
            }}
        />
    )

    const transferWaxElement = (
        <TransferTokenPopup
            contract={'eosio.token'}
            token={'WAX'}
            digits={8}
            balance={wax_balance}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const transferHoneyElement = (
        <TransferTokenPopup
            contract={'nfthivehoney'}
            token={'HONEY'}
            digits={4}
            balance={wax_balance}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const unstakeHoneyElement = (
        <UnstakeHoneyPopup
            contract={'nfthivehoney'}
            token={'HONEY'}
            digits={4}
            balance={wax_balance}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const stakeHoneyElement = (
        <StakeHoneyPopup
            contract={'nfthivehoney'}
            token={'HONEY'}
            digits={4}
            balance={wax_balance}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const transferAetherElement = (
        <TransferTokenPopup
            contract={'e.rplanet'}
            token={'AETHER'}
            digits={4}
            balance={wax_balance}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const transferTLMElement = (
        <TransferTokenPopup
            contract={'alien.worlds'}
            token={'TLM'}
            digits={4}
            balance={wax_balance}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const handleTradeElement = (
        <HandleTradePopup
            offerId={offerId}
            tradeOption={tradeOption}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const tubeElement = (
        <TubePopup
            assets={assets}
            tubeId={tubeId}
            lowestMints={lowestMints}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const unpackElement = (
        <UnpackPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const claimElement = (
        <UnpackPopup
            asset={asset}
            claimOnly={true}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const unstakeCpuNetElement = (
        <UnstakeCpuNetPopup
            cpu={cpu}
            net={net}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const stakeCpuNetElement = (
        <StakeCpuNetPopup
            balance={wax_balance}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const withdrawBuyOffersElement = (
        <WithdrawBuyOffersPopup
            balance={wax_balance}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const buyWaxElement = (
        <BuyWaxPopup
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const specialUpdateElement = (
        <SpecialUpdatePopup
            callBack={callBack}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const addPictureElement = (
        <UserPicturePopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    useEffect(() => {}, [action])

    const getModalSize = (action) => {
        switch (action) {
            case 'addpicture':
                return 'sm'
            case 'auction':
                return '2xl'
            case 'bid':
                return '3xl'
            case 'bulk_buy':
                return 'xl'
            case 'buy':
                return '3xl'
            case 'buy_drop':
                return '3xl'
            case 'burn_asset':
                return '3xl'
            case 'buy_pfp':
                return '3xl'
            case 'selloffer':
                return '3xl'
            case 'createoffer':
                return '3xl'
            case 'backasset':
                return '3xl'
            case 'buy_wax':
                return '3xl'
            case 'sell':
                return '3xl'
            case 'unpack':
                return '3xl'
            case 'transfer_wax':
                return 'sm'
            case 'editoffer':
                return 'sm'
            case 'bulk_cancel':
                return 'sm'
            case 'bulk_transfer':
                return 'sm'
            case 'disable_filter':
                return 'sm'
            case 'skip_confirmations':
                return 'sm'
            case 'deleteDrop':
                return 'sm'
            case 'deletePackRelease':
                return 'sm'
            case 'createPackRelease':
                return 'sm'
            case 'updateDropMaxClaimable':
                return 'sm'
            case 'updateDropReferral':
                return 'sm'
            case 'trade':
                return 'md'
            case 'stake_honey':
                return 'lg'
            case 'unstake_honey':
                return 'lg'
            case 'updateDropAccountLimit':
                return 'lg'
            case 'updateDropStartEndDate':
                return 'lg'
            case 'unstake_cpu_net':
                return 'lg'
            case 'setTag':
                return 'xl'
            case 'updateDropPrice':
                return '2xl'
            case 'createPackMod':
                return '2xl'
            case 'suggestTag':
                return '2xl'
            default:
                return 'xl'
        }
    }

    return (
        <>
            <Modal
                isDismissable={false}
                size={getModalSize(action)}
                isOpen={action}
                onClose={() => {
                    if (
                        [
                            'tube',
                            'transfer_wax',
                            'transfer_honey',
                            'transfer_tlm',
                            'transfer_aether',
                            'transfer_wuf',
                            'trade',
                            'stake_honey',
                            'unstake_honey',
                            'handle_trade',
                        ].includes(action)
                    ) {
                        callBack({ transferred: false, errors: null })
                    } else if (['bid'].includes(action)) {
                        callBack({ bidPlaced: false })
                    } else {
                        callBack(false)
                    }
                    dispatch({ type: 'SET_ACTION', payload: '' })
                }}
                scrollBehavior="inside"
                classNames={{
                    wrapper: 'z-[1301]',
                    body: 'p-0',
                    closeButton:
                        'absolute top-2 right-2 z-50 bg-red-500 text-white hover:bg-red-600',
                }}
            >
                <ModalContent>
                    <>
                        <ModalBody className="px-0">
                            {action === 'addpicture' ? addPictureElement : null}
                            {action === 'auction' ? auctionElement : null}
                            {action === 'bid' ? bidElement : null}
                            {action === 'bulk_burn' ? bulkBurnElement : null}
                            {action === 'bulk_buy' ? bulkBuyElement : null}
                            {action === 'bulk_cancel'
                                ? bulkCancelElement
                                : null}
                            {action === 'bulk_distribute'
                                ? bulkDistributeElement
                                : null}
                            {action === 'bulk_edit' ? bulkEditElement : null}
                            {action === 'bulk_sell' ? bulkSellElement : null}
                            {action === 'bulk_auction'
                                ? bulkAuctionElement
                                : null}
                            {action === 'bulk_multi_sell'
                                ? bulkMultiSellElement
                                : null}
                            {action === 'bulk_stake' ? bulkStakeElement : null}
                            {action === 'bulk_transfer'
                                ? bulkTransferElement
                                : null}
                            {action === 'bulk_swap' ? bulkSwapElement : null}
                            {action === 'bulk_unstake'
                                ? bulkUnstakeElement
                                : null}
                            {action === 'bundle' ? bundleElement : null}
                            {action === 'burn_asset' ? burnElement : null}
                            {action === 'break_asset' ? breakElement : null}
                            {action === 'buy_missing'
                                ? buyMissingElement
                                : null}
                            {action === 'buy_wax' ? buyWaxElement : null}
                            {action === 'buy' ? buyElement : null}
                            {action === 'claim_pack' ? claimElement : null}
                            {action === 'claimdrop' ? buyDropElement : null}
                            {action === 'createoffer' ? buyOfferElement : null}
                            {action === 'backasset' ? backAssetElement : null}
                            {action === 'editCollectionCategory' ||
                            action === 'suggestCollectionCategory'
                                ? editCollectionCategoryElement
                                : null}
                            {action === 'editSchema' ? editSchemaElement : null}
                            {action === 'createDrop' ? createDropElement : null}
                            {action === 'showDropBuyers'
                                ? showDropBuyersElement
                                : null}
                            {action === 'authorizeNfthivedrops'
                                ? authorizeNfthivedropsElement
                                : null}
                            {action === 'createPackRelease'
                                ? createPackReleaseElement
                                : null}
                            {action === 'createPool' ? createPoolElement : null}
                            {action === 'createPackMod'
                                ? createPackModElement
                                : null}
                            {action === 'updatePoolAssets'
                                ? updatePoolElement
                                : null}
                            {action === 'createPack' ? createPackElement : null}
                            {action === 'updatePackDescriptionAnimation'
                                ? editPackDescriptionAnimationElement
                                : null}
                            {action === 'updatePackTotals'
                                ? editPackTotalsElement
                                : null}
                            {action === 'updatePackUnlockTime'
                                ? editPackUnlockTimeElement
                                : null}
                            {action === 'updateDropNameDescr'
                                ? updateDropNameDescrElement
                                : null}
                            {action === 'updateDropPrice'
                                ? updateDropPriceElement
                                : null}
                            {action === 'updateDropStartEndDate'
                                ? updateDropStartEndDateElement
                                : null}
                            {action === 'updateDropMaxClaimable'
                                ? updateDropMaxClaimableElement
                                : null}
                            {action === 'updateDropAccountLimit'
                                ? updateDropAccountLimitElement
                                : null}
                            {action === 'updateDropReferral'
                                ? updateDropReferralElement
                                : null}
                            {action === 'deleteDrop' ? deleteDropElement : null}
                            {action === 'deletePackRelease'
                                ? deletePackReleaseElement
                                : null}
                            {action === 'unlockRelease'
                                ? unlockReleaseElement
                                : null}
                            {action === 'deletePack' ? deletePackElement : null}
                            {action === 'deletePool' ? deletePoolElement : null}
                            {action === 'createAndMintTemplates'
                                ? createAndMintTemplateElement
                                : null}
                            {action === 'suggestTag' || action === 'setTag'
                                ? editTagsElement
                                : null}
                            {action === 'editoffer' ? editElement : null}
                            {action === 'handle_trade'
                                ? handleTradeElement
                                : null}
                            {action === 'sell' ? sellElement : null}
                            {action === 'selloffer' ? sellOfferElement : null}
                            {action === 'setitem' ? setItemElement : null}
                            {action === 'share' ? shareElement : null}
                            {action === 'stake_cpu_net'
                                ? stakeCpuNetElement
                                : null}
                            {action === 'trade' ? tradeElement : null}
                            {action === 'transfer_wax'
                                ? transferWaxElement
                                : null}
                            {action === 'transfer_honey'
                                ? transferHoneyElement
                                : null}
                            {action === 'transfer_aether'
                                ? transferAetherElement
                                : null}
                            {action === 'transfer_tlm'
                                ? transferTLMElement
                                : null}
                            {action === 'stake_honey'
                                ? stakeHoneyElement
                                : null}
                            {action === 'unstake_honey'
                                ? unstakeHoneyElement
                                : null}
                            {action === 'transfer' ? transferElement : null}
                            {action === 'tube' ? tubeElement : null}
                            {action === 'unpack' ? unpackElement : null}
                            {action === 'unstake_cpu_net'
                                ? unstakeCpuNetElement
                                : null}
                            {action === 'view' ? collectionElement : null}
                            {action === 'skip_confirmations'
                                ? skipElement
                                : null}
                            {action === 'enable_filter'
                                ? enableFilterElement
                                : null}
                            {action === 'disable_filter'
                                ? disableFilterElement
                                : null}
                            {action === 'withdraw_waxbuyoffers'
                                ? withdrawBuyOffersElement
                                : null}
                            {action === 'buy_drop' || action === 'buy_pfp'
                                ? buyDropElement
                                : null}
                            {action === 'special_update'
                                ? specialUpdateElement
                                : null}
                        </ModalBody>
                    </>
                </ModalContent>
            </Modal>
        </>
    )
}

export default PopupWrapper
