import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { formatWAX } from '../helpers/FormatLinks'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import { InputAssetListItem } from './InputAssetListItem'
import { Button } from '@nextui-org/react'
import MainButton from '../common/util/input/MainButton'

export const BuyInput = () => {
    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const handleBought = (sold) => {
        const errors = sold['errors']
        const transferredItems = {}
        const newSelected = []

        const boughtAssets = sold['bought']
        if (boughtAssets) {
            boughtAssets.map((a) => {
                const asset = a.assets ? a.assets[0] : a
                if (
                    !errors ||
                    (!Object.keys(errors).includes(
                        asset['assetId'].toString(),
                    ) &&
                        (!asset['aAssetId'] ||
                            !Object.keys(errors).includes(
                                asset['aAssetId'].toString(),
                            )))
                ) {
                    const localId =
                        asset['standard'] === 'atomicassets'
                            ? asset['aAssetId']
                            : asset['assetId']
                    transferredItems[localId] = asset
                    transferredItems[localId]['newOwner'] = userName
                    transferredItems[localId]['bought'] = true
                } else {
                    newSelected.push(asset)
                }
            })
            dispatch({ type: 'SET_BALANCE_CHANGED', payload: true })
            dispatch({
                type: 'SET_TRANSFERRED_ASSETS',
                payload: transferredItems,
            })
            dispatch({
                type: 'SET_SELECTED_ASSETS',
                payload: newSelected,
            })
        }
        dispatch({
            type: 'SET_ERROR_ASSETS',
            payload: errors,
        })
    }

    const { t } = useTranslation('common')

    const buy = async () => {
        if (state.selectedAssets.length > 0) {
            const transferItems = {}
            for (let a of state.selectedAssets) {
                const asset = a.assets ? a.assets[0] : a

                asset.orderId = a.orderId
                asset.price = a.price
                asset.currency = a.currency
                asset.offer = a.offer
                asset.usd_offer = a.usd_offer
                asset.market = a.market

                const localId =
                    asset['standard'] === 'atomicassets'
                        ? asset['aAssetId']
                        : asset['assetId']
                transferItems[localId] = asset
            }
            dispatch({
                type: 'SET_ASSETS',
                payload: transferItems,
            })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_buy' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleBought(transferred),
            })
        }
    }

    const removeItem = async (asset) => {
        const newSelected = []
        state.selectedAssets.map((item) => {
            if (
                (item.assets ? item.assets[0] : item).assetId !==
                (asset.assets ? asset.assets[0] : asset).assetId
            ) {
                newSelected.push(item)
            }
        })
        dispatch({
            type: 'SET_SELECTED_ASSETS',
            payload: newSelected,
        })
    }

    return (
        <div className={'flex flex-wrap w-full h-full mx-auto md:p-6 p-4'}>
            <div
                className={
                    'grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4 w-full'
                }
            >
                {state.selectedAssets.map((asset) => (
                    <InputAssetListItem
                        asset={asset.assets ? asset.assets[0] : asset}
                        transferType={'buy'}
                        removeItem={() => removeItem(asset)}
                        listing={asset}
                    />
                ))}
            </div>
            <div className={'w-full mb-4 font-bold text-lg text-center'}>
                {t('search.buying_x_items_for_y', {
                    x: state.selectedAssets.length,
                    y:
                        state.selectedAssets &&
                        state.selectedAssets.length > 0 &&
                        formatWAX(
                            state.selectedAssets
                                .map((x) => x.offer)
                                .reduce((x, y) => x + y),
                        ),
                })}
            </div>
            <div
                className={
                    'w-full flex flex-col lg:flex-row lg:flex-wrap justify-center lg:justify-evenly'
                }
            >
                <div className="xl:mt-2">
                    <MainButton
                        fullWidth={true}
                        size="large"
                        onClick={buy}
                        disabled={!userName}
                    >
                        {t('asset.buy')}
                    </MainButton>
                </div>
            </div>
        </div>
    )
}
