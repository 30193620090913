import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import Popup from './Popup'
import MainButton from '../common/util/input/MainButton'
function SkipConfirmationPopup(props) {
    const { t } = useTranslation('common')

    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']

    const cancel = () => {
        callBack(false)
        closeCallBack()
    }

    const confirm = () => {
        callBack(true)
        closeCallBack()
    }

    return (
        <Popup title="Skip Confirmation" cancel={cancel}>
            <div className="p-2">
                <div className="mt-2 mb-4 text-lg font-bold text-center">
                    Are you sure you want to skip purchase confirmations? You
                    will immidiately spend WAX when you click BUY.
                </div>
                <div className="flex flex-row justify-center gap-2">
                    <div>
                        <MainButton className="bg-red-500 " onClick={cancel}>
                            Cancel
                        </MainButton>
                    </div>
                    <div>
                        <MainButton onClick={confirm}>Yes</MainButton>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default SkipConfirmationPopup
