import { Alert, Button, Collapse, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import get from '../helpers/Api'
import { sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function SpecialUpdatePopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const [templateId, setTemplateId] = useState(null)
    const [rarity, setRarity] = useState(null)

    const [transactionIds, setTransactionIds] = useState([])
    const addTransactionId = (transactionId) => {
        const tempTransactionIds = [...transactionIds]
        tempTransactionIds.push(transactionId)
        setTransactionIds(tempTransactionIds)
    }

    const [errors, setErrors] = useState([])
    const addError = (error) => {
        const tempErrors = [...errors]
        tempErrors.push(error)
        setErrors(tempErrors)
    }

    const fetchAssetsAndOwners = async () => {
        return await get(`smelly-ids/${templateId}`, 'api')
    }

    const [error, setError] = useState(null)

    const MAX_ACTIONS_PER_TRANSACTION = 50

    const update = async () => {
        setIsLoading(true)

        const assetOwners = await fetchAssetsAndOwners()

        const updatesToRun = assetOwners.length

        let currentAction = 0

        let transferError = null
        let updatesDone = 0
        while (updatesDone < updatesToRun) {
            const updatesThisTransaction = Math.min(
                MAX_ACTIONS_PER_TRANSACTION,
                updatesToRun - updatesDone,
            )
            const actions = [getBoostAction(activeUser)]

            for (let i = 0; i < updatesThisTransaction; i++) {
                const asset = assetOwners.shift()
                const data = {
                    authorized_editor: userName,
                    asset_id: asset.assetId.toString(),
                    asset_owner: asset.owner,
                    new_mutable_data: [
                        {
                            key: 'rarity',
                            value: ['string', rarity],
                        },
                    ],
                }

                const action = {
                    account: 'atomicassets',
                    name: 'setassetdata',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: data,
                }

                actions.push(action)
            }

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                addTransactionId(result?.response?.transaction_id)
            } catch (e) {
                transferError = e.message
                addError(transferError)
                setError(transferError)
            } finally {
                updatesDone = updatesDone + updatesThisTransaction
                ++currentAction
                if (updatesToRun - updatesDone > 0) {
                    sleep(10000)
                }
            }
        }

        if (transferError === null) {
            closeCallBack()
        }

        setIsLoading(false)

        callBack(!transferError, error, transactionIds)
    }

    const cancel = () => {
        closeCallBack()
    }

    return (
        <Popup title="Special Update" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to run this special update?
            </div>
            <Collapse in={error}>
                <Alert severity="error">{error}</Alert>
            </Collapse>
            <div className="flex flex-row justify-end">
                <div>
                    <TextField
                        label="templateId"
                        value={templateId}
                        required={true}
                        onChange={(e) => setTemplateId(e.target.value)}
                    />
                </div>
                <div>
                    <TextField
                        label="rarity"
                        value={rarity}
                        required={true}
                        onChange={(e) => setRarity(e.target.value)}
                    />
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={() => update()}>
                        Update
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : null}
        </Popup>
    )
}

export default SpecialUpdatePopup
