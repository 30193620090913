import cn from 'classnames'
import React from 'react'
import Link from '../common/util/input/Link'

export const MobileNavItem = ({ item }) => {
    return (
        <Link
            href={item.href}
            className="block dark:!text-white text-zinc-200 font-semibold mx-0"
        >
            <li
                key={item.name}
                className="flex px-3 ml-2 py-1 items-center w-full rounded-lg hover:bg-gradient-to-br hover:bg-zinc-200 hover:dark:!bg-zinc-800 transition ease-in-out duration-300 cursor-pointer"
            >
                <span
                    className={cn(
                        'rounded-lg w-[36px] h-[36px] mr-4 flex items-center justify-center',
                        { 'bg-primary': !item.outline },
                    )}
                >
                    <img
                        src={item.icon}
                        className={cn(
                            'dark:!text-zinc-900 text-white',
                            { 'h-5 w-5': !item.outline },
                            { 'h-8 w-8 ': item.outline },
                        )}
                        aria-hidden="true"
                    />
                </span>
                <div
                    className={
                        'block  dark:!text-white text-zinc-900 font-semibold '
                    }
                    onClick={item.onclick}
                >
                    {item.name}
                    <p className="text-zinc-500 font-normal text-[14px]">
                        {item.description}
                    </p>
                </div>
            </li>
        </Link>
    )
}

export default MobileNavItem
