import { Bars3Icon, MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { useGet } from '../../hooks'
import { useStorage } from '../../hooks/storage'
import Link from '../common/util/input/Link'
import { get, post } from '../helpers/Api'
import { setCookie } from '../helpers/cookies'
import {
    getBoostAction,
    getClaimableHoneyBalance,
    getHoneyBalance,
} from '../helpers/WaxApi'
import { MultiAssetControlPanel } from '../multiassetcontrolpanel/MultiAssetControlPanel'
import { useSharedState } from '../waxplorer/Waxplorer'
import MobileNavigationMenu from './MobileNavigationMenu'
import NavigationDropDownItem from './NavigationDropDownItem'
import NotificationDropDown from './NotificationDropDown'
import NotificationMobileDropDown from './NotificationMobileDropDown'
import ProfileDropDown from './ProfileDropDown'
import QuickSettingsDown from './QuickSettingsDown'
import QuickSettingsMobileDropDown from './QuickSettingsMobileDropDown'
import SearchModal from './SearchModal'
import { Switch } from '@nextui-org/react'
import { currentThemeAtom } from '../../utils/store'
import { useAtom } from 'jotai'
import { useTheme } from 'next-themes'

const NOTIFICATION_INTERVAL = 60 * 1000 // every minute

export const useNotificationCount = (userName, hasNotifications) => {
    const { data: newNotifications, fetch } = useGet(
        userName ? `num-new-notifications/${userName}` : undefined,
        'api1',
    )

    useEffect(() => {
        if (hasNotifications) {
            const intervalId = setInterval(fetch, NOTIFICATION_INTERVAL)
            return () => clearInterval(intervalId)
        }
    }, [userName, hasNotifications])

    return hasNotifications && newNotifications?.num ? newNotifications.num : 0
}

export default function NewNavigation() {
    const { t } = useTranslation('common')

    const { theme, setTheme } = useTheme()
    const [mainTheme, setMainTheme] = useAtom(currentThemeAtom)

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const [state, dispatch, { login }] = useSharedState()

    const userName = state?.activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const [imgCache, setImgCache] = useStorage(`image-${userName}`)
    const [balance, setBalance] = useState(null)
    const [honeyBalance, setHoneyBalance] = useState(null)
    const [claimableHoneyBalance, setClaimableHoneyBalance] = useState(null)
    const [showClaimableHoney, setShowClaimableHoney] = useState(false)
    const [userPicture, setUserPicture] = useState(imgCache)
    const [userFilters, setUserFilters] = useState(null)
    const [filterChanged, setFilterChanged] = useState(null)

    const skipBuyConfirmation = state?.skipBuyConfirmation

    const playAnimations = state?.playAnimations

    // notifications
    const router = useRouter()

    const [isLoading, setIsLoading] = useState(false)

    const hasNotifications = state?.hasNotifications === userName

    useEffect(() => {}, [skipBuyConfirmation, playAnimations])

    const market = [
        {
            name: t('search.sales'),
            description: t('description.listings'),
            href: '/market',
            icon: '/icons/shop.svg',
        },
        {
            name: t('navigation.drops'),
            description: t('description.drops'),
            href: '/drops',
            icon: '/icons/droplet.svg',
        },
        {
            name: t('navigation.public_buy_offers'),
            description: t('description.buy_offers'),
            href: '/buyoffers',
            icon: '/icons/hand-holding-dollar.svg',
        },
        {
            name: t('search.auctions'),
            description: t('description.auctions'),
            href: '/auctions',
            icon: '/icons/scale-unbalanced-flip.svg',
        },
        {
            name: t('search.trades'),
            description: t('description.sales'),
            href: '/sales',
            icon: '/icons/coins.svg',
        },
        {
            name: t('landing.live_listings'),
            description: t('description.live_listings'),
            href: '/live',
            icon: '/icons/timer.svg',
        },
    ]

    const pfpf = [
        {
            name: t('navigation.ranking'),
            description: t('description.pfp_ranking'),
            href: '/pfps',
            icon: '/icons/ranking-star.svg',
        },
        {
            name: t('search.sales'),
            description: t('description.pfp_sales'),
            href: '/pfps?search_type=sales',
            icon: '/icons/shop.svg',
        },
    ]

    const crafts = [
        {
            name: t('navigation.crafting_recipes'),
            description: t('description.crafting'),
            href: '/crafts',
            icon: '/icons/hammer.svg',
        },
        {
            name: t('navigation.locked_assets'),
            description: t('description.locked_assets'),
            href: '/lockedcrafts',
            icon: '/icons/lock-keyhole.svg',
        },
    ]

    const bulk = [
        {
            name: t('bulk_overview.sell_assets'),
            description: t('bulk_overview.sell_assets_descr'),
            href: '/bulk?search_type=bulk_sell',
            icon: '/icons/file-invoice-dollar.svg',
        },
        {
            name: t('bulk_overview.buy_assets'),
            description: t('bulk_overview.buy_assets_descr'),
            href: '/bulk?search_type=bulk_buy',
            icon: '/icons/cart-circle-arrow-down.svg',
        },
        {
            name: t('bulk_overview.edit_listings'),
            description: t('bulk_overview.edit_listings_descr'),
            href: '/bulk?search_type=bulk_edit',
            icon: '/icons/money-check-dollar-pen.svg',
        },
        {
            name: t('bulk_overview.transfer_assets'),
            description: t('bulk_overview.transfer_assets_descr'),
            href: '/bulk?search_type=bulk_transfer',
            icon: '/icons/arrow-up-small-big.svg',
        },
        {
            name: t('bulk_overview.unstake_assets'),
            description: t('bulk_overview.unstake_assets_descr'),
            href: '/bulk?search_type=bulk_unstake',
            icon: '/icons/arrow-down-up-lock.svg',
        },
        {
            name: t('bulk_overview.all_tools'),
            description: t('bulk_overview.all_tools_descriptions'),
            href: '/bulkoverview',
            icon: '/icons/list-ul.svg',
        },
    ]

    const showBuyWaxPopup = async () => {
        dispatch({
            type: 'SET_ACTION',
            payload: 'buy_wax',
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: () => {},
        })
    }

    const more = [
        {
            name: t('trade.trade'),
            description: t('description.trade'),
            href: '/trade',
            icon: '/icons/handshake-simple.svg',
        },
        {
            name: t('navigation.analytics'),
            description: t('description.analytics'),
            href: '/stats',
            icon: '/icons/chart-mixed.svg',
        },
        {
            name: t('footer.buy_wax'),
            description: t('description.buy_wax'),
            href: '',
            icon: '/icons/WAX_W_Black.svg',
            onclick: showBuyWaxPopup,
        },
        {
            name: t('navigation.rent_cpu'),
            description: t('description.cpu'),
            href: '/cpu',
            icon: '/icons/microchip.svg',
        },
        {
            name: t('navigation.airdrops'),
            description: t('description.airdrops'),
            href: '/airdrops',
            icon: '/icons/parachute-box.svg',
        },
        {
            name: t('navigation.admin'),
            description: t('description.admin'),
            href: '/admin',
            icon: '/icons/user-pilot.svg',
        },
    ]

    const parseBalance = (res) => {
        if (
            res &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0 &&
            res.data.rows[0].balance
        ) {
            setBalance(parseFloat(res.data.rows[0].balance.replace(' WAX', '')))
        }

        dispatch({ type: 'SET_BALANCE_CHANGED', payload: false })
    }

    const parseHoneyBalance = (res) => {
        if (
            res &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0 &&
            res.data.rows[0].balance
        ) {
            setHoneyBalance(
                parseFloat(res.data.rows[0].balance.replace(' HONEY', '')),
            )
        }

        dispatch({ type: 'SET_HONEY_BALANCE_CHANGED', payload: false })
    }

    const parseClaimableHoneyBalance = (res) => {
        if (
            res &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0 &&
            res.data.rows[0].rewards
        ) {
            const claimableHoney = parseFloat(
                res.data.rows[0].rewards.replace(' HONEY', ''),
            )
            if (claimableHoney > 0) {
                setShowClaimableHoney(true)
            }
            setClaimableHoneyBalance(claimableHoney)
        }

        dispatch({ type: 'SET_HONEY_BALANCE_CHANGED', payload: false })
    }

    const balanceChanged = state?.balanceChanged
    const honeyBalanceChanged = state?.honeyBalanceChanged
    const userPictureChanged = state?.userPictureChanged

    const getBalance = async () => {
        const body = {
            code: 'eosio.token',
            index_position: 'primary',
            json: 'true',
            key_type: 'i64',
            limit: 1,
            reverse: 'false',
            scope: userName,
            show_payer: 'false',
            table: 'accounts',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : state?.api + '/v1/chain/get_table_rows'
        post(url, body).then((res) => parseBalance(res))
    }

    const parseFilters = (res) => {
        if (
            res.status === 200 &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0 &&
            res.data.rows[0]['user_name'] === userName
        ) {
            setUserFilters(res.data.rows[0]['tag_ids'])
            setFilterChanged(false)
        }
    }

    const getFilters = async (userName) => {
        if (process.env.NEXT_PUBLIC_TESTNET === 'TRUE') {
            return
        }

        const body = {
            code: 'clltncattool',
            index_position: 'primary',
            json: 'true',
            key_type: 'i64',
            limit: 1,
            reverse: 'false',
            lower_bound: userName,
            upper_bound: userName,
            scope: 'clltncattool',
            show_payer: 'false',
            table: 'userfilters',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : state?.api + '/v1/chain/get_table_rows'

        post(url, body).then((res) => parseFilters(res))
    }

    const parseUserPicture = (res) => {
        if (res && res.image) {
            setUserPicture(res.image)
            setImgCache(res.image)
        }
        dispatch({ type: 'SET_USER_PICTURE_CHANGED', payload: false })
    }

    const getUserPicture = async (userPictureChanged) => {
        if (imgCache && !userPictureChanged) {
            setUserPicture(imgCache)
        } else if (userPictureChanged) {
            setTimeout(() => {
                get('user-picture/' + userName).then(parseUserPicture)
            }, 3000)
        } else {
            get('user-picture/' + userName).then(parseUserPicture)
        }
    }

    useEffect(() => {
        if (userName) {
            if (!balance || balanceChanged) getBalance()
            if (!honeyBalance || honeyBalanceChanged)
                getHoneyBalance(userName, state).then((res) =>
                    parseHoneyBalance(res),
                )
            if (!claimableHoneyBalance || honeyBalanceChanged)
                getClaimableHoneyBalance(userName, state).then((res) =>
                    parseClaimableHoneyBalance(res),
                )
            if (!userPicture || userPictureChanged)
                getUserPicture(userPictureChanged)
            getFilters(userName)
        }
    }, [
        balanceChanged,
        honeyBalanceChanged,
        userPictureChanged,
        userName,
        filterChanged,
    ])

    const changeAutoPlay = (e) => {
        const autoplay = e.target.checked

        setCookie('play-animations', autoplay)

        dispatch({
            type: 'SET_PLAY_ANIMATIONS',
            payload: autoplay,
        })
    }

    const changeFilter = async (num) => {
        dispatch({
            type: 'SET_ACTION',
            payload:
                !userFilters || !userFilters.includes(num)
                    ? 'enable_filter'
                    : 'disable_filter',
        })
        dispatch({
            type: 'SET_FILTER',
            payload: num,
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (result) => {
                if (result && result['changed']) {
                    const filter = result['filter']
                    const enabled = result['enabled']
                    if (enabled) {
                        if (userFilters && !userFilters.includes(filter)) {
                            userFilters.push(filter)
                            setUserFilters(userFilters)
                        }
                    } else {
                        if (userFilters && userFilters.includes(filter)) {
                            const filters = []
                            for (const f of userFilters) {
                                if (f !== filter) {
                                    filters.push(f)
                                }
                            }
                            setUserFilters(filters)
                        }
                    }
                    setFilterChanged(true)
                }
            },
        })
    }

    const changeSkipBuyConfirmation = (e) => {
        const skip = e.target.checked

        if (skip) {
            dispatch({
                type: 'SET_ACTION',
                payload: 'skip_confirmations',
            })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (result) => {
                    if (result) {
                        setCookie('skip-buy-confirmation', true)

                        dispatch({
                            type: 'SET_SKIP_BUY_CONFIRMATION',
                            payload: true,
                        })
                    }
                },
            })
        } else {
            setCookie('skip-buy-confirmation', false)

            dispatch({
                type: 'SET_SKIP_BUY_CONFIRMATION',
                payload: false,
            })
        }
    }

    const parseHasNotifications = (res) => {
        if (process.env.NEXT_PUBLIC_TESTNET === 'FALSE') {
            const data = res['data'] ? res['data'] : res
            if (data['hasNotifications'])
                dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: userName })
            else dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: '' })
        }
    }

    useEffect(() => {
        if (userName) {
            get('has-notifications/' + userName, 'api1').then(
                parseHasNotifications,
            )
        }
    }, [userName])

    const handleChangeNotifications = async (e) => {
        setIsLoading(true)
        const res = await activeUser.session.transact(
            {
                actions: [
                    getBoostAction(activeUser),
                    {
                        account: 'nft.hive',
                        name: 'execute',
                        authorization: [
                            {
                                actor: userName,
                                permission: activeUser.requestPermission
                                    ? activeUser.requestPermission
                                    : activeUser.scatter.identity.accounts[0]
                                          .authority,
                            },
                        ],
                        data: {
                            signer: userName,
                            action: JSON.stringify({
                                name: 'toggle-notifications',
                                data: {
                                    enable: !state?.hasNotifications,
                                },
                            }),
                        },
                    },
                ],
            },
            {
                expireSeconds: 300,
                blocksBehind: 0,
            },
        )

        if (res?.response?.transaction_id) {
            if (!state?.hasNotifications)
                dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: userName })
            else dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: '' })
        }
        setIsLoading(false)
    }

    return (
        <div className="h-14 lg:h-22">
            <div className="mx-auto flex w-full flex-row items-center justify-between px-2 xl:px-3 2xl:px-4 py-2 lg:py-2 fixed top-0 z-[9] dark:bg-zinc-900 bg-white border border-b dark:border-zinc-800 border-zinc-100">
                <Link href="/" className="">
                    <img
                        src={'/nfthive-logo-black.svg'}
                        alt="NFTHive on WAX"
                        className={cn(
                            'h-10 md:h-12 lg:h-10 xl:h-12 2xl:h-14 cursor-pointer block dark:hidden',
                        )}
                    />
                    <img
                        src={'/nfthive-logo.svg'}
                        alt="NFTHive on WAX"
                        className={cn(
                            'h-10 md:h-12 lg:h-10 xl:h-12 2xl:h-14 cursor-pointer hidden dark:block',
                        )}
                    />
                </Link>

                <div className="flex justify-end w-full space-x-2 lg:hidden">
                    <Switch
                        isSelected={mainTheme !== 'light'}
                        onValueChange={(e) => {
                            setTheme(theme === 'light' ? 'dark' : 'light')
                            setMainTheme(theme === 'light' ? 'dark' : 'light')
                        }}
                        defaultSelected
                        size="md"
                        color="primary"
                        startContent={<SunIcon />}
                        endContent={<MoonIcon />}
                    />

                    {userName && (
                        <QuickSettingsDown
                            skipBuyConfirmation={skipBuyConfirmation}
                            changeSkipBuyConfirmation={
                                changeSkipBuyConfirmation
                            }
                            playAnimations={playAnimations}
                            changeAutoPlay={changeAutoPlay}
                            changeFilter={changeFilter}
                            hasNotifications={hasNotifications}
                            handleChangeNotifications={
                                handleChangeNotifications
                            }
                            userFilters={userFilters}
                        />
                    )}

                    {userName && (
                        <QuickSettingsMobileDropDown
                            skipBuyConfirmation={skipBuyConfirmation}
                            changeSkipBuyConfirmation={
                                changeSkipBuyConfirmation
                            }
                            playAnimations={playAnimations}
                            changeAutoPlay={changeAutoPlay}
                            changeFilter={changeFilter}
                            hasNotifications={hasNotifications}
                            handleChangeNotifications={
                                handleChangeNotifications
                            }
                            userFilters={userFilters}
                        />
                    )}

                    {userName && <NotificationDropDown />}
                    {userName && <NotificationMobileDropDown />}

                    <button
                        type="button"
                        className="rounded-md px-2 py-2 text-[#FFC30B] bg-white dark:!bg-zinc-900"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <Bars3Icon
                            className="w-5 h-5 md:w-6 md:h-6"
                            aria-hidden="true"
                        />
                    </button>
                </div>

                <div className="items-center justify-center flex-1 hidden lg:flex 2xl:gap-x-12 xl:gap-x-8 lg:gap-4 gap-x-1">
                    <Link
                        href="/collections"
                        className={`dark:text-white text-black py-2 2xl:text-[16px] xl:text-[14px] text-[12px] !font-semibold  leading-6 hover:text-[#FFC30B] !mx-0`}
                    >
                        {t('navigation.collections')}
                    </Link>

                    <NavigationDropDownItem
                        items={market}
                        name={t('landing.market_title')}
                        userName={userName}
                    />

                    <NavigationDropDownItem
                        items={pfpf}
                        name={t('navigation.pfps')}
                        userName={userName}
                    />

                    <NavigationDropDownItem
                        items={crafts}
                        name={t('navigation.crafting')}
                        userName={userName}
                    />
                    <Link
                        href="/creator"
                        className={`${
                            router.pathname === '/creator'
                                ? 'border-blue-600 text-blue-600'
                                : ' border-transparent dark:text-white text-black'
                        } border-b-2  py-2 font-semibold  leading-6 hover:border-b-2 hover:border-blue-800 hover:text-blue-800 2xl:text-[16px] xl:text-[14px] text-[12px] hover:text-[#FFC30B] !mx-0`}
                    >
                        {t('navigation.creator')}
                    </Link>

                    <NavigationDropDownItem
                        items={bulk}
                        name={t('navigation.bulk_tools')}
                        userName={userName}
                    />

                    <NavigationDropDownItem
                        items={more}
                        name={t('navigation.more')}
                        userName={userName}
                    />
                </div>

                <div className="items-center hidden space-x-1 lg:flex lg:justify-end">
                    <SearchModal />

                    <Switch
                        isSelected={mainTheme !== 'light'}
                        onValueChange={(e) => {
                            setTheme(theme === 'light' ? 'dark' : 'light')
                            setMainTheme(theme === 'light' ? 'dark' : 'light')
                        }}
                        defaultSelected
                        size="sm"
                        color="primary"
                        startContent={<SunIcon />}
                        endContent={<MoonIcon />}
                    />

                    {userName && (
                        <QuickSettingsDown
                            skipBuyConfirmation={skipBuyConfirmation}
                            changeSkipBuyConfirmation={
                                changeSkipBuyConfirmation
                            }
                            playAnimations={playAnimations}
                            changeAutoPlay={changeAutoPlay}
                            changeFilter={changeFilter}
                            hasNotifications={hasNotifications}
                            handleChangeNotifications={
                                handleChangeNotifications
                            }
                            userFilters={userFilters}
                        />
                    )}

                    {userName && <NotificationDropDown />}

                    {userName ? (
                        <ProfileDropDown
                            userName={userName}
                            balance={balance}
                            claimableHoneyBalance={claimableHoneyBalance}
                            honeyBalance={honeyBalance}
                            showClaimableHoney={showClaimableHoney}
                            userPicture={userPicture}
                        />
                    ) : (
                        <button
                            onClick={() => login()}
                            className="dark:!bg-zinc-900 bg-zinc-200 rounded-md font-semibold flex items-center justify-center bg-blue-600 px-3 py-3 text-[16px] text-zinc-900 dark:!text-white hover:bg-zinc-400 hover:dark:!bg-zinc-800"
                        >
                            <img
                                src={
                                    theme === 'light'
                                        ? '/icons/user-black.svg'
                                        : '/icons/user-neutral.svg'
                                }
                                className={'h-5 w-5'}
                                aria-hidden="true"
                                alt={t('navigation.login')}
                            />
                            <span className="ml-2 ">
                                {t('navigation.login')}
                            </span>
                        </button>
                    )}
                </div>
            </div>

            <MobileNavigationMenu
                bulk={bulk}
                market={market}
                pfpf={pfpf}
                crafts={crafts}
                more={more}
                mobileMenuOpen={mobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
                userName={userName}
            />
            {typeof window !== undefined ? <MultiAssetControlPanel /> : ''}
        </div>
    )
}
