import { Alert } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Link from '../common/util/input/Link'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

const BreakPopup = ({ asset, callBack, closeCallBack }) => {
    const { t } = useTranslation('common')

    const { name, mdata, assetId } = asset

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    // create a transaction callback that signs the actions
    const startBurn = async () => {
        try {
            await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'atomicassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: await activeUser.accountName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: await activeUser.accountName,
                                memo: 'break',
                                asset_ids: [assetId],
                                to: 'nfthivecraft',
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        } catch (e) {
            callBack({
                burned: false,
                error: e.message,
            })
        }
        callBack({
            burned: true,
        })
    }

    const cancel = () => {
        callBack({
            burned: false,
            asset,
        })
        closeCallBack()
    }

    let asset_ids = []
    try {
        const data = JSON.parse(mdata)
        asset_ids = JSON.parse(data['locked_assets'].replaceAll("'", ''))
    } catch (e) {
        console.error(e)
    }

    return (
        <Popup
            title={t('popups.break.title', { assetName: name })}
            asset={asset}
            cancel={cancel}
        >
            <div className="mx-auto text-xl font-bold mb-4">
                {t('popups.break.question', { assetName: name })}
            </div>
            <div className="flex mx-auto text-xl font-bold mb-4 justify-center">
                <Alert severity="info">
                    {t('popups.break.explanation', { assetName: name })}
                </Alert>
            </div>
            <div className="flex mx-auto text-xl font-bold mb-4 justify-center">
                {t('popups.break.locked')}
            </div>
            <div className={'flex flex-wrap my-4'}>
                {asset_ids.map((assetId) => (
                    <Link external={true} href={'/asset/' + assetId}>
                        <div className={'a'}>{assetId}</div>
                    </Link>
                ))}
            </div>
            <div className="relative flex justify-end mt-4">
                <MainButton onClick={cancel}>{t('asset.cancel')}</MainButton>
                <MainButton className="w-32 max-w-32" onClick={startBurn}>
                    {t('asset.break')}
                </MainButton>
            </div>
        </Popup>
    )
}

export default BreakPopup
