import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import MainButton from '../common/util/input/MainButton'
import ErrorNote from '../common/util/ErrorNote'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function DisableFilterPopup(props) {
    const { t } = useTranslation('common')

    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']
    const filter = props['filter']

    const cancel = () => {
        callBack({ changed: false })
        closeCallBack()
    }

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const toggleFilter = async () => {
        setIsLoading(true)
        try {
            await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'clltncattool',
                            name: 'remfilter',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                user_name: userName,
                                tag_id: filter,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ changed: true, filter: filter, enabled: false })
        } catch (e) {
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Popup title={t('filter.disable_filter')} cancel={cancel}>
            <div className="p-2">
                <div className="mt-2 mb-4 text-lg font-bold text-center">
                    {/* """_summary_ """{' '} */}
                    {filter === 72
                        ? t('filter.disable_ai_filter')
                        : t('filter.disable_nsfw_filter')}
                </div>
                {error ? (
                    <ErrorNote error={error} onClick={() => setError(null)} />
                ) : (
                    ''
                )}
                {isLoading ? <LoadingIndicator /> : ''}
                <div className="flex justify-center gap-2">
                    <div className="">
                        <MainButton onClick={cancel} className="bg-red-500">
                            Cancel
                        </MainButton>
                    </div>
                    <div className="">
                        <MainButton onClick={toggleFilter}>Yes</MainButton>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default DisableFilterPopup
