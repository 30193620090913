import cn from 'classnames'
import React from 'react'
import config from '../../config.json'
import VideoElement from '../videoelement/VideoElement'

function CollectionPopup(props) {
    const asset = props['asset']
    const closeCallBack = props['closeCallBack']

    return (
        <div
            className={cn('left-0 w-full h-screen backdrop-blur-xl z-70')}
            onClick={closeCallBack}
        >
            {asset.image.includes('video:') ? (
                <VideoElement
                    className={cn('max-w-full max-h-full m-auto')}
                    video={
                        asset.image.includes('video:')
                            ? asset.image.replace('video:', config.ipfs)
                            : asset.image
                    }
                />
            ) : (
                <img
                    className={cn('max-h-full max-w-full m-auto')}
                    src={asset.image}
                    alt="none"
                />
            )}
        </div>
    )
}

export default CollectionPopup
