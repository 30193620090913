import cn from 'classnames'
import NextLink from 'next/link'
import React from 'react'

export const Link = ({
    children,
    inactive,
    className,
    href,
    onClick,
    altOnClick = () => {},
    external,
    forcePointer,
    margin = 'm-auto',
}) => {
    const linkClassNames = cn(
        'block',
        'focus-visible:ring-1 focus-visible:ring-inset text-black dark:!text-white',
        'focus-visible:ring-primary',
        margin,
        { 'cursor-default': !forcePointer && inactive },
        { 'cursor-pointer': forcePointer || !inactive },
        className,
    )
    if (inactive || !href)
        return (
            <div onClick={altOnClick} className={linkClassNames}>
                {children}
            </div>
        )

    if (external)
        return (
            <NextLink
                href={href.toString()}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick}
            >
                <span className={linkClassNames} onClick={onClick}>
                    {children}
                </span>
            </NextLink>
        )
    return (
        <NextLink href={href} passHref>
            <span className={linkClassNames} onClick={onClick}>
                {children}
            </span>
        </NextLink>
    )
}

Link.displayName = 'Hive-Link'

export default Link
