import { InputAdornment } from '@mui/material'
import { Input, Card } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { floatRegEx } from '../editor/EditorComponents'
import get from '../helpers/Api'
import { formatNumber } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function EditPopup(props) {
    const asset = props['asset']

    let {
        schema,
        number,
        variant,
        rarity,
        author,
        assetId,
        image,
        name,
        offer,
        bundle,
        assets,
    } = asset

    const assetIds = []

    if (assets) {
        const a = assets[0]
        assetId = a.assetId
        assets.forEach((asset) => assetIds.push(asset.assetId))
        schema = a.schema
        variant = a.variant
        rarity = a.rarity
        author = a.author
        number = a.number
        name = `${a.name} ${
            assets.length > 1 ? `(Bundle of ${assets.length})` : '(In a Bundle)'
        }`
        image = a.image
    } else {
        if (assetId) assetIds.push(assetId)
    }

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [price, setPrice] = useState(offer)
    const [priceInfo, setPriceInfo] = useState(null)

    const loadPriceInfo = async (assetId) => {
        return await get('price-info/' + assetId)
    }

    const getOrderId = async (assetId, market, bundle) => {
        const orderId = await get(
            'get-order-id/' +
                (market ? market + '/' : '') +
                assetId +
                '?bundle=' +
                bundle,
        )
        return orderId ? orderId['orderId'] : null
    }

    useEffect(() => {
        loadPriceInfo(assetId).then((res) => setPriceInfo(res))
    }, [assetId])

    const cancelSale = async () => {
        setIsLoading(true)

        let { market, orderId, owner } = asset

        let newOrderId = null

        if (assetId || (assetIds.length > 0 && assetIds[0]))
            newOrderId = await getOrderId(
                assetId ? assetId : assetIds[0],
                market ? market : owner,
                assets && assets.length > 1,
            )

        if (newOrderId) orderId = newOrderId

        const actions = [getBoostAction(activeUser)]

        if (market === 'atomicmarket') {
            actions.push({
                account: market,
                name: 'cancelsale',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    sale_id: orderId,
                },
            })
        }

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        } catch (e) {
            console.error(e)
            callBack({ edited: false, canceled: false, error: e.message })
        }

        setIsLoading(false)
        callBack({
            edited: false,
            canceled: true,
            error: null,
        })
        closeCallBack()
    }

    const editSale = async () => {
        setIsLoading(true)

        let { orderId, market, owner } = asset
        const actions = [getBoostAction(activeUser)]

        let newOrderId = null

        if (assetId || (assetIds.length > 0 && assetIds[0]))
            newOrderId = await getOrderId(
                assetId ? assetId : assetIds[0],
                market ? market : owner,
                assets && assets.length > 1,
            )

        if (newOrderId) orderId = newOrderId

        actions.push({
            account: 'atomicmarket',
            name: 'cancelsale',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                sale_id: orderId,
            },
        })

        actions.push({
            account: 'atomicmarket',
            name: 'announcesale',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                seller: userName,
                maker_marketplace:
                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                        ? 'nfthiveboost'
                        : 'nft.hive',
                settlement_symbol: '8,WAX',
                asset_ids: assetIds,
                listing_price: parseFloat(price).toFixed(8) + ' WAX',
            },
        })

        actions.push({
            account: 'atomicassets',
            name: 'createoffer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: {
                sender: userName,
                recipient: 'atomicmarket',
                sender_asset_ids: assetIds,
                recipient_asset_ids: [],
                memo: 'sale',
            },
        })

        let error = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        } catch (e) {
            console.error(e)
            error = e.message
            callBack({ edited: false, canceled: false, error: e.message })
        }

        setIsLoading(false)
        if (!error)
            callBack({
                edited: price,
                canceled: false,
                error: null,
            })
        closeCallBack()
    }

    const changePrice = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) setPrice(val)
    }

    const cancel = () => {
        callBack({
            edited: false,
            wasCanceled: false,
            error: null,
        })
        closeCallBack()
    }

    return (
        <Popup title={'Edit - ' + name} cancel={cancel} image={image}>
            <div className="p-4 ">
                <Card className="p-2 mt-2 mb-4">
                    <div className="grid grid-cols-2">
                        <div className="col-span-1 ">
                            <div className="flex flex-col gap-2 ">
                                <b>{t('asset.schema')}:</b>
                                {number && variant ? (
                                    <b>{t('asset.card')}:</b>
                                ) : null}
                                {rarity ? <b>{t('asset.rarity')}:</b> : null}
                                <b>{t('asset.author')}:</b>
                                {bundle ? (
                                    ''
                                ) : (
                                    <b>{t('asset.lowest_available_price')}:</b>
                                )}
                                {bundle ? (
                                    ''
                                ) : (
                                    <b>{t('asset.average_bought')}:</b>
                                )}
                                <b>{t('asset.lowest_available_price')}:</b>
                                <b>{t('asset.last_sold')}:</b>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="flex flex-col items-end justify-end gap-2 ">
                                <div>{schema}</div>
                                {number && variant ? (
                                    <div>
                                        <>
                                            {number}
                                            {variant}
                                        </>
                                    </div>
                                ) : null}
                                {rarity ? <div> {rarity} </div> : null}
                                <div>{author}</div>
                                {bundle ? (
                                    ''
                                ) : (
                                    <div>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['lowest']
                                            ? `${formatNumber(
                                                  priceInfo['lowest'],
                                              )} WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : (
                                            <span className="usd">
                                                (
                                                {!priceInfo
                                                    ? `$${formatNumber(
                                                          priceInfo[
                                                              'lowest_usd'
                                                          ],
                                                      )}`
                                                    : '-'}
                                                )
                                            </span>
                                        )}
                                    </div>
                                )}
                                {bundle ? (
                                    ''
                                ) : (
                                    <div>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['average']
                                            ? `${
                                                  Math.round(
                                                      priceInfo['average'] *
                                                          100,
                                                  ) / 100
                                              } WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : priceInfo['average_usd'] ? (
                                            <span className="usd">
                                                /$
                                                {formatNumber(
                                                    priceInfo['average_usd'],
                                                )}
                                            </span>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                )}
                                <div>
                                    {!priceInfo
                                        ? 'Loading...'
                                        : priceInfo['lowest']
                                        ? `${formatNumber(
                                              priceInfo['lowest'],
                                          )} WAX`
                                        : ''}
                                    {!priceInfo ? (
                                        ''
                                    ) : (
                                        <span className="usd">
                                            (
                                            {priceInfo['lowest_usd']
                                                ? `$${formatNumber(
                                                      priceInfo['lowest_usd'],
                                                  )}`
                                                : '-'}
                                            )
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {!priceInfo
                                        ? 'Loading...'
                                        : priceInfo['last_sold']
                                        ? `${
                                              Math.round(
                                                  priceInfo['last_sold'] * 100,
                                              ) / 100
                                          } WAX`
                                        : ''}
                                    {!priceInfo ? (
                                        ''
                                    ) : priceInfo['last_sold_usd'] ? (
                                        <span className="usd">
                                            /$
                                            {formatNumber(
                                                priceInfo['last_sold_usd'],
                                            )}
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  <div className="relative flex justify-center w-full mb-4">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <b>{t('asset.schema')}:</b>
                                </td>
                                <td>{schema}</td>
                            </tr>
                            {number && variant ? (
                                <tr>
                                    <td>
                                        <b>{t('asset.card')}:</b>
                                    </td>
                                    <td>
                                        {number}
                                        {variant}
                                    </td>
                                </tr>
                            ) : (
                                ''
                            )}
                            {rarity ? (
                                <tr>
                                    <td>
                                        <b>{t('asset.rarity')}:</b>
                                    </td>
                                    <td>{rarity}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                            <tr>
                                <td>
                                    <b>{t('asset.author')}:</b>
                                </td>
                                <td>{author}</td>
                            </tr>
                            {bundle ? (
                                ''
                            ) : (
                                <tr>
                                    <td>
                                        <b>
                                            {t('asset.lowest_available_price')}:
                                        </b>
                                    </td>
                                    <td>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['lowest']
                                            ? `${formatNumber(
                                                  priceInfo['lowest'],
                                              )} WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : (
                                            <span className="usd">
                                                (
                                                {!priceInfo
                                                    ? `$${formatNumber(
                                                          priceInfo[
                                                              'lowest_usd'
                                                          ],
                                                      )}`
                                                    : '-'}
                                                )
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            )}
                            {bundle ? (
                                ''
                            ) : (
                                <tr>
                                    <td>
                                        <b>{t('asset.average_bought')}:</b>
                                    </td>
                                    <td>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['average']
                                            ? `${
                                                  Math.round(
                                                      priceInfo['average'] *
                                                          100,
                                                  ) / 100
                                              } WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : priceInfo['average_usd'] ? (
                                            <span className="usd">
                                                /$
                                                {formatNumber(
                                                    priceInfo['average_usd'],
                                                )}
                                            </span>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                            )}
                            {
                                <tr>
                                    <td>
                                        <b>
                                            {t('asset.lowest_available_price')}:
                                        </b>
                                    </td>
                                    <td>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['lowest']
                                            ? `${formatNumber(
                                                  priceInfo['lowest'],
                                              )} WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : (
                                            <span className="usd">
                                                (
                                                {priceInfo['lowest_usd']
                                                    ? `$${formatNumber(
                                                          priceInfo[
                                                              'lowest_usd'
                                                          ],
                                                      )}`
                                                    : '-'}
                                                )
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            }
                            {
                                <tr>
                                    <td>
                                        <b>{t('asset.last_sold')}:</b>
                                    </td>
                                    <td>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['last_sold']
                                            ? `${
                                                  Math.round(
                                                      priceInfo['last_sold'] *
                                                          100,
                                                  ) / 100
                                              } WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : priceInfo['last_sold_usd'] ? (
                                            <span className="usd">
                                                /$
                                                {formatNumber(
                                                    priceInfo['last_sold_usd'],
                                                )}
                                            </span>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div> */}
                </Card>
                {error ? <ErrorMessage error={error} /> : ''}

                <div className={'flex m-auto'}>
                    <Input
                        variant="faded"
                        endContent={'WAX'}
                        value={price ? price : ''}
                        placeholder={t('asset.new_price')}
                        inputProps={{
                            style: {
                                height: '1.2rem',
                                fontSize:
                                    String(price ? price : '').length > 8
                                        ? '0.6rem'
                                        : String(price ? price : '').length > 5
                                        ? '0.7rem'
                                        : '1rem',
                            },
                        }}
                        onChange={changePrice}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    disableTypography
                                    sx={{
                                        fontSize: '0.7rem',
                                        marginLeft: 0.2,
                                    }}
                                >
                                    WAX
                                </InputAdornment>
                            ),
                        }}
                        error={!floatRegEx.test(price)}
                    />
                </div>
                <div className="flex justify-between gap-2 mt-4">
                    <MainButton
                        disabled={!price && 'disabled'}
                        onClick={editSale}
                    >
                        {'Set Price'}
                    </MainButton>
                    <MainButton className="bg-red-500" onClick={cancelSale}>
                        {'Cancel Listing'}
                    </MainButton>
                </div>
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={'Loading Transactions'}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default EditPopup
