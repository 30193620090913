import { Button } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Context, useSharedState } from '../waxplorer/Waxplorer'
import { InputAssetListItem } from './InputAssetListItem'

export const CancelInput = () => {
    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const handleCanceled = (transferred) => {
        const errors = transferred['errors']

        const transferredItems = {}
        const newSelected = []

        const canceledAssets = transferred['transferred']
        if (canceledAssets) {
            canceledAssets.map((asset) => {
                if (
                    !errors ||
                    (!Object.keys(errors).includes(
                        (asset.assets ? asset.assets[0] : asset)[
                            'assetId'
                        ].toString(),
                    ) &&
                        (!asset['aAssetId'] ||
                            !Object.keys(errors).includes(
                                (asset.assets ? asset.assets[0] : asset)[
                                    'aAssetId'
                                ].toString(),
                            )))
                ) {
                    const localId =
                        (asset.assets ? asset.assets[0] : asset)['standard'] ===
                        'atomicassets'
                            ? (asset.assets ? asset.assets[0] : asset)[
                                  'aAssetId'
                              ]
                            : (asset.assets ? asset.assets[0] : asset)[
                                  'assetId'
                              ]
                    transferredItems[localId] = asset
                    transferredItems[localId]['newOwner'] = userName
                    transferredItems[localId]['canceled'] = true
                    transferredItems[localId]['offer'] = null
                } else {
                    newSelected.push(asset)
                }
            })
            dispatch({ type: 'SET_BALANCE_CHANGED', payload: true })
            dispatch({
                type: 'SET_TRANSFERRED_ASSETS',
                payload: transferredItems,
            })
            dispatch({
                type: 'SET_SELECTED_ASSETS',
                payload: newSelected,
            })
        }
        dispatch({
            type: 'SET_ERROR_ASSETS',
            payload: errors,
        })
    }

    const { t } = useTranslation('common')

    const cancel = async () => {
        if (state.selectedAssets.length > 0) {
            const transferItems = {}
            for (let asset of state.selectedAssets) {
                const localId =
                    (asset.assets ? asset.assets[0] : asset)['standard'] ===
                    'atomicassets'
                        ? (asset.assets ? asset.assets[0] : asset)['aAssetId']
                        : (asset.assets ? asset.assets[0] : asset)['assetId']
                transferItems[localId] = asset
            }
            dispatch({
                type: 'SET_ASSETS',
                payload: transferItems,
            })
            dispatch({ type: 'SET_ACTION', payload: 'bulk_cancel' })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (transferred) => handleCanceled(transferred),
            })
        }
    }

    const removeItem = async (asset) => {
        const newSelected = []
        state.selectedAssets.map((item) => {
            if (
                (item.assets ? item.assets : item).assetId !==
                (asset.assets ? asset.assets : asset).assetId
            ) {
                newSelected.push(item)
            }
        })
        dispatch({
            type: 'SET_SELECTED_ASSETS',
            payload: newSelected,
        })
    }

    return (
        <div className={'flex flex-wrap w-full h-full mx-auto p-3'}>
            <div className={'w-full'}>
                {state.selectedAssets.map((asset) => (
                    <InputAssetListItem
                        asset={asset.assets ? asset.assets[0] : asset}
                        transferType={'buy'}
                        removeItem={() => removeItem(asset)}
                        listing={asset}
                    />
                ))}
            </div>
            <div className={'w-full mb-4 font-bold text-lg'}>
                {t('search.cancel_x_listings', {
                    x: state.selectedAssets.length,
                })}
            </div>
            <div
                className={
                    'w-full flex flex-col lg:flex-row lg:flex-wrap justify-center lg:justify-evenly'
                }
            >
                <div className="xl:mt-2">
                    <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        onClick={cancel}
                        disabled={!userName}
                    >
                        {t('asset.cancel')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
