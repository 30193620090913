import cn from 'classnames'
import React from 'react'

function ErrorMessage(props) {
    const error = props['error']
    const layer = props['layer']
    const onClick = props['onClick']

    return (
        <div
            className={cn(
                'dark:!bg-paper bg-white backdrop-filter backdrop-blur-3xl my-2',
                'w-full h-20 max-w-160',
                'flex justify-center text-primary overflow-y-auto InsideScroll',
                'text-xs mx-auto p-2 leading-5',
                'border border-solid border-negative',
                'rounded-xl',
                { 'z-30': !layer || layer === 3 },
                { 'z-40': !layer || layer === 4 },
                { 'z-50': !layer || layer === 5 },
            )}
            onClick={onClick}
        >
            <div className="m-auto">
                <img className="w-5 h-5" src="/Warning_icn.svg" alt="!" />
            </div>
            <div className="pl-4 m-auto text-center">{error}</div>
        </div>
    )
}

export default ErrorMessage
