import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'

import ErrorNote from '../common/util/ErrorNote'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function EnableFilterPopup(props) {
    const { t } = useTranslation('common')

    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']
    const filter = props['filter']

    const cancel = () => {
        callBack({ changed: false })
        closeCallBack()
    }

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const toggleFilter = async () => {
        setIsLoading(true)
        try {
            await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'clltncattool',
                            name: 'addfilter',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                user_name: userName,
                                tag_id: filter,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ changed: true, filter: filter, enabled: true })
        } catch (e) {
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Popup title={t('filter.enable_filter')} cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                {filter === 72
                    ? t('filter.enable_ai_filter')
                    : t('filter.enable_nsfw_filter')}
            </div>
            {error ? (
                <ErrorNote error={error} onClick={() => setError(null)} />
            ) : (
                ''
            )}
            {isLoading ? <LoadingIndicator /> : ''}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={toggleFilter}>
                        Yes
                    </Button>
                </div>
            </div>
        </Popup>
    )
}

export default EnableFilterPopup
