import cn from 'classnames'
import React, { useEffect } from 'react'

function TabItem(props) {
    const { title, tabKey, target, isNew, disabled, size = 'base' } = props

    const active = tabKey === target

    useEffect(() => {}, [disabled, active, tabKey, target])

    const className = cn(
        'flex flex-nowrap h-auto md:h-30px font-bold md:font-semibold transition-colors duration-500',
        'text-base overflow-hidden whitespace-nowrap',
        'leading-8 lg:leading-6',
        {
            'text-graphbg': disabled,
        },
        {
            'dark:!text-neutral text-black border-b-0 md:border-b-2 border-transparent hover:text-primary':
                !active && !disabled,
        },
        {
            'relative text-primary border-b-0 md:border-b-2 border-primary':
                active && !disabled,
        },
        {
            'md:text-xs lg:text-sm xl:text-base px-2': size === 'base',
        },
        {
            'md:text-1xs lg:text-xs xl:text-sm 2xl:text-base px-0':
                size === 'small',
        },
    )

    return (
        <div
            className={cn('flex h-full my-auto mx-0 lg:mx-2 xl:mx-2 ', {
                '': active,
            })}
        >
            <img
                className={cn(
                    'visible md:hidden h-4 lg:h-6 w-6 my-auto transition-opacity duration-500',
                    {
                        'opacity-100': active,
                        'opacity-75 ': !active,
                    },
                )}
                src={active ? '/radio-button-on.svg' : '/radio-button-off.svg'}
                alt="radio"
                width={150}
                height={150}
            />
            <div className={className}>
                <div className={'my-auto'}>{title}</div>
            </div>
            {isNew ? (
                <div className={'my-auto h-full ml-2 text-primary font-bold'}>
                    New!
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default TabItem
