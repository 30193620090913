import { Input } from '@nextui-org/react'
import React from 'react'

function InputField({
    type = 'text',
    variant = 'faded',
    size = 'md',
    label = '',
    value = '',
    onChange = () => {},
    errorMessage = '',
    isRequired = false,
    isDisabled = false,
    isInvalid = false,
    color = 'primary',
    description = '',
    ...props
}) {
    return (
        <Input
            type={type}
            variant={variant}
            size={size}
            color={color}
            classNames={{
                label: 'text-black font-semibold dark:text-white',
                input: 'text-black font-semibold dark:text-white',
                errorMessage:
                    'text-red-500 dark:text-red-500 font-semibold text-left mt-1',
                description: isInvalid ? 'text-red-500' : '',
            }}
            label={label}
            value={value}
            onChange={onChange}
            errorMessage={errorMessage}
            isRequired={isRequired}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            description={description}
            {...props}
        />
    )
}

export default InputField
