import { Tooltip } from '@mui/material'
import cn from 'classnames'
import React from 'react'

export const MintInfo = ({ mint, myMint, small = false, top = 'top-0' }) => {
    return (
        <div
            className={cn(
                //'absolute flex top-0 m-auto h-8 rounded-3xl bg-dark b-1',
                'absolute flex h-6 z-8 font-sans',
                { 'left-0': small },
                top,
            )}
        >
            <div
                className={cn(
                    ' text-black dark:text-white bg-zinc-300 dark:bg-zinc-900 rounded-lg',
                    'text-xs md:text-xs lg:text-xs xl:text-xs 4xl:text-xs px-2 py-1',
                )}
            >
                <b>
                    {'Mint: #' +
                        mint +
                        (myMint ? ' - My lowest Mint: ' + myMint : '')}
                </b>
            </div>
        </div>
    )
}
export default MintInfo
