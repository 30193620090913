import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete } from '@mui/lab'
import { InputAdornment, TextField } from '@mui/material'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { get } from '../helpers/Api'

export const NavigationSearch = () => {
    const [tempSearch, setTempSearch] = useState('')
    const router = useRouter()
    const [searchOptions, setSearchOptions] = useState([])
    const [timer, setTimer] = useState(null)

    const search = (e) => {
        e.preventDefault()
        router.push(`/search?term=${escape(tempSearch)}`)
    }

    const searchSelect = (value) => {
        router.push(`/search?term=${escape(value)}`)
    }

    useEffect(() => {}, [searchOptions])

    const parseQuickSearch = (res) => {
        const newOptions = []
        if (res && !res.error)
            for (const item of res) {
                newOptions.push({ label: item['label'], id: item['label'] })
            }
        setSearchOptions(newOptions)
    }

    const quickSearch = (e) => {
        const val = e.target.value
        setTempSearch(val)
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(
            setTimeout(() => {
                get('quick-search/' + escape(e.target.value)).then(
                    parseQuickSearch,
                )
            }, 200),
        )
    }

    return (
        <div>
            <div className="mx-auto">
                <Autocomplete
                    id="free-solo-2-demo"
                    options={searchOptions}
                    freeSolo
                    disableClearable
                    onChange={(event, newValue) =>
                        searchSelect(newValue.id ? newValue.id : newValue)
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            value={tempSearch}
                            onChange={quickSearch}
                            size="small"
                            variant="outlined"
                            label={'Search'}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    search(e)
                                }
                            }}
                            inputRef={(input) => {
                                if (input != null) {
                                    input.focus()
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        onClick={search}
                                    >
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        </div>
    )
}

export default NavigationSearch
