import { HeartBroken } from '@mui/icons-material'
import { ClickAwayListener } from '@mui/material'
import cn from 'classnames'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Link from '../common/util/input/Link'
import { getBoostAction, swapTransaction } from '../helpers/WaxApi'
import ShareButton from '../sharebutton/ShareButton'
import { useSharedState } from '../waxplorer/Waxplorer'
const moreOptionsBtnClassName = cn(
    'flex justify-start w-32 transition delay-200 width',
    'h-5 rounded text-zinc-900 dark:!text-white cursor-pointer outline-none',
    'text-xs font-bold my-2',
)

const MoreOptionsButton = ({
    onClick,
    children,
    imgSrcDark,
    imgSrcLight,
    icon,
    imgAlt = typeof children === 'string' ? children : '',
}) => (
    <div className={moreOptionsBtnClassName} onClick={onClick}>
        <div className="w-5 h-5 mx-2 text-zinc-900 dark:!text-white">
            {imgSrcDark ? (
                <img
                    className="w-5 h-5 text-zinc-900 dark:!text-white hidden dark:!block"
                    src={imgSrcDark}
                    alt={imgAlt}
                />
            ) : (
                ''
            )}
            {imgSrcLight ? (
                <img
                    className="w-5 h-5 text-zinc-900 dark:!text-white block dark:!hidden"
                    src={imgSrcLight}
                    alt={imgAlt}
                />
            ) : (
                ''
            )}
            {icon ? icon : ''}
        </div>
        <div className="flex my-auto text-sm">{children}</div>
    </div>
)

const MoreOptionsAnchor = ({
    href,
    children,
    imgSrcLight,
    imgSrcDark,
    imgAlt,
}) => (
    <Link href={href} rel="noreferrer" external={href.includes('http')}>
        <MoreOptionsButton
            imgSrcLight={imgSrcLight}
            imgSrcDark={imgSrcDark}
            imgAlt={imgAlt}
            children={children}
        />
    </Link>
)

export const MoreOptions = ({
    asset,
    burned,
    created,
    handleBurn,
    handleCreateOffer,
    handleBackAsset,
    handleSell,
    handleSwap,
    handleTransfer,
    newOwner,
    setError,
    setIsLoading,
    setShowMenu,
    showMenu,
    sold,
    staked,
    transferred,
    toggleShowMenu,
    currentAsset,
    backed,
    setBackMenuOpen,
}) => {
    const { t } = useTranslation('common')
    const [state, dispatch] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    if (newOwner) asset['owner'] = newOwner

    const { sender, seller, author, unpackUrl, assets } = asset

    const {
        templateId,
        schema,
        assetId,
        standard,
        mdata,
        burnable,
        transferable,
        owner,
    } = assets ? assets[currentAsset] : asset

    let isBreakable =
        mdata && mdata.includes('locked_assets') && mdata.includes('craft_id')

    const [favored, setFavored] = useState(asset['isFavorited'])

    const auction = async () => {
        setIsLoading(true)
        setShowMenu(false)
        dispatch({
            type: 'SET_ASSET',
            payload: assets ? assets[currentAsset] : asset,
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (sellInfo) => handleSell(sellInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'auction' })
    }

    const createoffer = async () => {
        setIsLoading(true)
        setShowMenu(false)
        dispatch({
            type: 'SET_ASSET',
            payload: assets ? assets[currentAsset] : asset,
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (offerInfo) => handleCreateOffer(offerInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'createoffer' })
    }

    const openBackMenu = async () => {
        setIsLoading(true)
        setBackMenuOpen(true)
    }

    const burnAsset = async () => {
        setIsLoading(true)
        setShowMenu(false)
        dispatch({
            type: 'SET_ASSET',
            payload: assets ? assets[currentAsset] : asset,
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (burnInfo) => handleBurn(burnInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'burn_asset' })
    }

    const breakAsset = async () => {
        setIsLoading(true)
        setShowMenu(false)
        dispatch({
            type: 'SET_ASSET',
            payload: assets ? assets[currentAsset] : asset,
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (burnInfo) => handleBurn(burnInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'break_asset' })
    }

    const transfer = async () => {
        setIsLoading(true)
        setShowMenu(false)
        dispatch({
            type: 'SET_ASSET',
            payload: assets ? assets[currentAsset] : asset,
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (sellInfo) => handleTransfer(sellInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'transfer' })
    }

    const doSwap = async () => {
        const { assetId, aAssetId, standard } = assets
            ? assets[currentAsset]
            : asset
        setShowMenu(false)
        setError(null)
        setIsLoading(true)

        const data = {
            from: userName,
            to: 'atomicbridge',
            memo: 'swap',
        }

        if (standard === 'atomicassets') data['asset_ids'] = [aAssetId]
        else data['assetids'] = [assetId]

        try {
            await swapTransaction(activeUser, standard, data)
            handleSwap(true)
        } catch (e) {
            if (!(e instanceof Error)) return console.error('Invalid error', e)
            console.error(e)
            handleSwap(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const favor = async () => {
        setIsLoading(true)
        const res = await activeUser.session.transact(
            {
                actions: [
                    getBoostAction(activeUser),
                    {
                        account: 'nft.hive',
                        name: 'execute',
                        authorization: [
                            {
                                actor: userName,
                                permission: activeUser.requestPermission
                                    ? activeUser.requestPermission
                                    : 'active',
                            },
                        ],
                        data: {
                            signer: userName,
                            action: JSON.stringify({
                                name: assetId
                                    ? 'favorite-asset'
                                    : 'favorite-template',
                                data: {
                                    id: assetId ? assetId : templateId,
                                },
                            }),
                        },
                    },
                ],
            },
            {
                expireSeconds: 300,
                blocksBehind: 0,
            },
        )

        if (res?.response?.transaction_id) {
            setFavored(true)
        }
        setIsLoading(false)
    }

    const unfavor = async () => {
        setIsLoading(true)
        const res = await activeUser.session.transact(
            {
                actions: [
                    getBoostAction(activeUser),
                    {
                        account: 'nft.hive',
                        name: 'execute',
                        authorization: [
                            {
                                actor: userName,
                                permission: activeUser.requestPermission
                                    ? activeUser.requestPermission
                                    : 'active',
                            },
                        ],
                        data: {
                            signer: userName,
                            action: JSON.stringify({
                                name: assetId
                                    ? 'unfavorite-asset'
                                    : 'unfavorite-template',
                                data: {
                                    id: assetId ? assetId : templateId,
                                },
                            }),
                        },
                    },
                ],
            },
            {
                expireSeconds: 300,
                blocksBehind: 0,
            },
        )

        if (res?.response?.transaction_id) {
            setFavored(false)
        }
        setIsLoading(false)
    }

    const tradable =
        showMenu &&
        owner !== userName &&
        standard === 'atomicassets' &&
        !staked &&
        !transferred &&
        !sold &&
        !burned &&
        sender !== userName &&
        assetId

    const auctionable =
        showMenu &&
        standard === 'atomicassets' &&
        owner === userName &&
        !sold &&
        assetId &&
        !burned

    const isburnable =
        showMenu &&
        burnable &&
        !created &&
        !transferred &&
        userName === owner &&
        !staked

    const unpackable =
        showMenu && unpackUrl && owner === userName && assetId && !burned

    const buyable =
        showMenu &&
        handleCreateOffer &&
        !created &&
        userName &&
        templateId &&
        templateId > 0 &&
        !burned

    const backable = !burned && userName === owner && templateId > 0 && !backed

    const favorable = showMenu && (assetId || templateId)

    const swappable =
        showMenu &&
        author === 'gpk.topps' &&
        ['series1', 'series2', 'exotic'].includes(schema) &&
        ['simpleassets', 'atomicassetes'].includes(standard) &&
        owner === userName &&
        !burned

    const isTransferable =
        showMenu &&
        !sold &&
        !transferred &&
        owner === userName &&
        assetId &&
        !burned &&
        (transferable || transferable === undefined)

    return (
        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
            <div
                className={cn(
                    'h-auto absolute  p-2 z-20 w-40 right-0 top-0 flex-wrap',
                )}
            >
                <div
                    className={cn(
                        'h-auto absolute  p-2 z-20 w-40 right-0 top-0 flex-wrap',
                        'dark:!bg-paper bg-white rounded-xl',
                        { 'opacity-100 z-10 transition delay-200': showMenu },
                        {
                            'opacity-0 z-10 hidden transition delay-200':
                                !showMenu,
                        },
                    )}
                >
                    {tradable ? (
                        <MoreOptionsAnchor
                            href={`/trade/new?user=${userName}&partner=${
                                sender ? sender : seller ? seller : owner
                            }&asset_id=${assetId}&tab=new-trade`}
                            imgSrcLight={'/trade-black.svg'}
                            imgSrcDark={'/trade.svg'}
                            imgAlt="trade"
                        >
                            {t('trade.send_offer')}
                        </MoreOptionsAnchor>
                    ) : null}
                    <ShareButton
                        type={'asset'}
                        asset={assets ? assets[currentAsset] : asset}
                    />
                    {isTransferable ? (
                        <MoreOptionsButton
                            onClick={transfer}
                            imgSrcLight={
                                '/diagonal-arrow-right-up-outline-black.svg'
                            }
                            imgSrcDark={'/diagonal-arrow-right-up-outline.svg'}
                            imgAlt={t('asset.transfer')}
                        >
                            {t('asset.transfer')}
                        </MoreOptionsButton>
                    ) : null}
                    {swappable ? (
                        <MoreOptionsButton
                            onClick={doSwap}
                            imgSrc={
                                standard === 'atomicassets'
                                    ? '/simpleassets.svg'
                                    : '/atomic.png'
                            }
                            imgAlt={t('profile.swap')}
                        >
                            {t('profile.swap')}
                        </MoreOptionsButton>
                    ) : null}
                    {auctionable ? (
                        <MoreOptionsButton
                            onClick={auction}
                            imgSrcLight={'/pricetags-outline-white.svg'}
                            imgSrcDark={'/pricetags-outline.svg'}
                            imgAlt={t('popups.auction')}
                        >
                            {t('popups.auction')}
                        </MoreOptionsButton>
                    ) : null}
                    {unpackable ? (
                        <MoreOptionsAnchor
                            href={unpackUrl}
                            imgSrcDark={'/cube-outline.svg'}
                            imgSrcLight={'/cube-outline-black.svg'}
                            imgAlt={t('asset.unpack')}
                            onClick={() => setShowMenu(false)}
                        >
                            {t('asset.unpack')}
                        </MoreOptionsAnchor>
                    ) : null}
                    {buyable ? (
                        <MoreOptionsButton
                            imgSrcLight={'/pricetags-outline-white.svg'}
                            imgSrcDark={'/pricetags-outline.svg'}
                            onClick={createoffer}
                            imgAlt={t('Create Buy Offer')}
                        >
                            Buy Offer
                        </MoreOptionsButton>
                    ) : null}
                    {backable ? (
                        <MoreOptionsButton
                            imgSrcLight={'/icons/coins.svg'}
                            imgSrcDark={'/icons/coins-white.svg'}
                            onClick={openBackMenu}
                            imgAlt={t('Back Tokens')}
                        >
                            Back Tokens
                        </MoreOptionsButton>
                    ) : null}
                    {favorable ? (
                        <MoreOptionsButton
                            imgSrcDark={
                                favored
                                    ? '/bookmark.svg'
                                    : '/bookmark-outline.svg'
                            }
                            imgSrcLight={
                                favored
                                    ? '/bookmark.svg'
                                    : '/bookmark-outline.svg'
                            }
                            onClick={favored ? unfavor : favor}
                            imgAlt={
                                favored
                                    ? t('asset.unfavorite')
                                    : t('asset.favorite')
                            }
                        >
                            {favored
                                ? t('asset.unfavorite')
                                : t('asset.favorite')}
                        </MoreOptionsButton>
                    ) : null}
                    {isburnable ? (
                        <MoreOptionsButton
                            imgSrcLight="/fire.png"
                            imgSrcDark="/fire.png"
                            onClick={burnAsset}
                            imgAlt={t('asset.burn')}
                        >
                            {t('asset.burn')}
                        </MoreOptionsButton>
                    ) : null}
                    {isBreakable ? (
                        <MoreOptionsButton
                            icon={<HeartBroken fontSize="small" className="" />}
                            onClick={breakAsset}
                            imgAlt={t('asset.break')}
                        >
                            {t('asset.break')}
                        </MoreOptionsButton>
                    ) : null}
                </div>
                <div
                    onClick={toggleShowMenu}
                    className={cn(
                        'absolute text-sm w-8 h-8 right-0 top-0 text-zinc-900 dark:!text-white m-auto z-30',
                    )}
                >
                    <img
                        className={cn(
                            'absolute w-6 h-6 right-4 top-3 text-primary',
                            {
                                'transition transform duration-250 rotate-0':
                                    showMenu,
                            },
                            {
                                'transition transform duration-250 rotate-0':
                                    !showMenu,
                            },
                        )}
                        src="/more.svg"
                        alt="more"
                    />
                </div>
            </div>
        </ClickAwayListener>
    )
}

export default MoreOptions
