import qs from 'qs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { post } from 'superagent/lib/client'
import { get, get_ext, getAtomicCollection } from '../components/helpers/Api'
import {
    getAetherAuthors,
    getAttributeNames,
    getCraftingAuthors,
    getDefaultAttrNames,
    getPFPAuthors,
} from '../components/helpers/FormatLinks'
import config from '../config.json'
import { useStorage } from './storage'
import { getCurrencies } from '../components/editor/DropCreatorUtils'
import { useSharedState } from '../components/waxplorer/Waxplorer'

function getElementSize({ offsetWidth, offsetHeight }) {
    return {
        width: offsetWidth,
        height: offsetHeight,
    }
}

export const useElementSize = () => {
    const [elementSize, setElementSize] = useState({
        width: 0,
        height: 0,
    })

    const onRef = useCallback((elementRef) => {
        if (elementRef == null) return
        setElementSize(getElementSize(elementRef))
    }, [])
    return [elementSize, onRef]
}

export const useGetter = (path, endpoint = 'api', init = {}) => {
    return (handler) => {
        const controller = new AbortController()
        get(path, endpoint, { ...init, signal: controller.signal }).then(
            handler,
        )
        return () => controller.abort()
    }
}

export const useCurrencies = () => {
    const TTL_COLLECTION_CACHE = 1000 // 1h

    const [cache, setCache] = useStorage(`useCurrencies`)
    const [loading, setLoading] = useState(true)
    const [currencies, setCurrencies] = useState(undefined)
    const [state] = useSharedState()

    const parseResponse = (res) => {
        setLoading(false)
        if (res?.error) return setCurrencies(undefined)
        setCurrencies(res)

        setCache({
            // store the current time
            updated: Date.now(),
            value: res,
            key: `useCurrencies`,
        })
    }

    useEffect(() => {
        if (cache && cache.key && cache.key === `useCurrencies`) {
            const { updated, value } = cache

            if (
                value &&
                value.length && // careful as this could break other caches, here we are storing arrays
                Date.now() - updated < TTL_COLLECTION_CACHE
            ) {
                setLoading(false)
                setCurrencies(value)

                return
            }
        }

        setLoading(true)

        getCurrencies(state).then(parseResponse)
    }, [])

    return { loading, currencies }
}

export const useCollectionType = (
    search,
    tagId,
    load,
    type,
    limit,
    verified,
    trending = false,
) => {
    const TTL_COLLECTION_CACHE = 1000 * 60 * 5 // 5 minutes

    const [cache, setCache] = useStorage(
        `collection-${type}-${limit}-${search}-${tagId}-${verified}-${trending}`,
    )
    const [loading, setLoading] = useState(true)
    const [collections, setCollections] = useState(undefined)

    const parseResponse = (res) => {
        setLoading(false)
        if (res?.error) return setCollections(undefined)
        setCollections(res)

        setCache({
            // store the current time
            updated: Date.now(),
            value: res,
            key: `collection-${type}-${limit}-${search}-${tagId}-${verified}`,
        })
    }

    useEffect(() => {
        if (!load) return

        if (
            cache &&
            cache.key &&
            cache.key ===
                `collection-${type}-${limit}-${search}-${tagId}-${verified}`
        ) {
            const { updated, value } = cache

            if (
                value &&
                value.length && // careful as this could break other caches, here we are storing arrays
                Date.now() - updated < TTL_COLLECTION_CACHE
            ) {
                setLoading(false)
                return setCollections(value)
            }
        }

        setLoading(true)

        const query = new URLSearchParams({})
        if (typeof type === 'number') query.set('type', type)
        if (tagId) query.set('tag_id', tagId)
        if (limit > 100) query.set('limit', limit)
        if (trending) query.set('trending', 'true')
        query.set('verified', verified)

        const abortController = new AbortController()
        const url = `collections/${search}?${query.toString()}`
        get(url, 'wax-api', { signal: abortController.signal }).then(
            parseResponse,
        )

        return () => abortController.abort()
    }, [load, search, tagId, limit, verified])

    return { loading, collections }
}

export const useGet = (path, endpoint) => {
    const [state, setState] = useState({
        data: undefined,
        loading: true,
        controller: undefined,
    })

    // need to use a ref here to prevent the fetch function to be re-build
    const stateRef = useRef(state)
    stateRef.current = state

    const request = useCallback(
        (force = false) => {
            // unpack the state here
            const state = stateRef.current

            if (!path)
                return setState(({ data }) => ({
                    data,
                    loading: false,
                    controller: undefined,
                }))
            // if there is a retry, abort the previous request when forced, else do nothing
            if (state.loading) {
                if (!force) return
                state.controller?.abort()
            }

            const controller = new AbortController()
            setState(({ data }) => ({ data, loading: true, controller }))

            get(path, endpoint, { signal: controller.signal }).then(
                (result) => {
                    setState({
                        data: result,
                        loading: false,
                        controller: undefined,
                    })
                },
            )
            return () => controller.abort()
        },
        [path],
    )

    useEffect(() => request(true), [request])

    return { data: state.data, loading: state.loading, fetch: request }
}

export const useEventListener = (eventName, handler, element = window) => {
    // Create a ref that stores handler
    const savedHandler = useRef()

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = element && element.addEventListener
            if (!isSupported) return

            // Create event listener that calls handler function stored in ref
            const eventListener = (event) => savedHandler.current(event)

            // Add event listener
            element.addEventListener(eventName, eventListener)

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener)
            }
        },
        [eventName, element], // Re-run if eventName or element changes
    )
}

export const useFetch = (input, init) => {
    const [state, setState] = useState({
        data: undefined,
        loading: true,
        controller: undefined,
    })

    // need to use a ref here to prevent the fetch function to be re-build
    const stateRef = useRef(state)
    stateRef.current = state

    const request = useCallback(
        (force = false) => {
            // unpack the state here
            const state = stateRef.current
            if (!input)
                return setState(({ data }) => ({
                    data,
                    loading: false,
                    controller: undefined,
                }))
            // if there is a retry, abort the previous request when forced, else do nothing
            if (state.loading) {
                if (!force) return
                state.controller?.abort()
            }

            const controller = new AbortController()

            setState(({ data }) => ({ data, loading: true, controller }))

            fetch(input, { signal: controller.signal, ...init })
                .then(async (result) => {
                    setState({
                        data: await result.json(),
                        loading: false,
                        controller: undefined,
                    })
                })
                .catch((error) => {
                    console.error(error)
                    setState({
                        data: null,
                        loading: false,
                        controller: undefined,
                    })
                })
            return () => controller.abort()
        },
        [input],
    )

    useEffect(() => request(true), [request])

    return { data: state.data, loading: state.loading, fetch: request }
}

export const useBlockchainState = () => {
    const [blockchainState, setBlockchainState] = useState(null)

    const parseAll = (res) => {
        if (res.length === 2) {
            const atomicHeadblock = res[0]['data']['chain']['head_block']
            const apiHeadblock = res[1]['block_num']
            setBlockchainState(atomicHeadblock - apiHeadblock)
        }
    }

    useEffect(() => {
        const controller = new AbortController()
        Promise.all([
            get_ext('https://atomic3.hivebp.io/health', controller.signal),
            get('health'),
        ]).then(parseAll)

        return () => controller.abort()
    }, [])

    return blockchainState
}

export const useAttributeNames = (
    author,
    schema,
    initialAttributeNames = getDefaultAttrNames(),
) => {
    const [attrNames, setAttrNames] = useState(initialAttributeNames)

    useEffect(() => {
        if (author) {
            const controller = new AbortController()
            getAttributeNames(author, schema, controller.signal).then(
                (result) => {
                    if (result && !('errors' in result)) setAttrNames(result)
                },
            )
            return () => controller.abort()
        }
    }, [author, schema])

    return [attrNames, setAttrNames]
}

export const useCraftingAuthors = () => {
    const [craftingAuthors, setCraftingAuthors] = useState([])
    if (process.env.NEXT_PUBLIC_TESTNET === 'TRUE') return [craftingAuthors]

    useEffect(() => {
        if (craftingAuthors.length === 0) {
            const controller = new AbortController()
            getCraftingAuthors(controller.signal).then((result) => {
                if (result && !('errors' in result)) setCraftingAuthors(result)
            })
            return () => controller.abort()
        }
    }, [])

    return [craftingAuthors]
}

export const usePFPAuthors = () => {
    const [pfpAuthors, setPfpAuthors] = useState([])
    if (process.env.NEXT_PUBLIC_TESTNET === 'TRUE') return [pfpAuthors]

    useEffect(() => {
        if (pfpAuthors.length === 0) {
            const controller = new AbortController()
            getPFPAuthors(controller.signal).then((result) => {
                if (result && !('errors' in result)) setPfpAuthors(result)
            })
            return () => controller.abort()
        }
    }, [])

    return [pfpAuthors]
}

const getSupportedTokens = async (state) => {
    const body = {
        json: true,
        code: 'nfthivedrops',
        scope: 'nfthivedrops',
        table: 'newconfig',
        table_key: '',
        lower_bound: '',
        upper_bound: '',
        index_position: 1,
        key_type: 'name',
        limit: 200,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : state.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    const tokens = []

    if (res && res.status === 200 && res.body.rows.length > 0) {
        for (let token of res.body.rows[0]['supported_tokens']) {
            tokens.push(token)
        }
    }

    return tokens
}

// export const useSupportedTokens = (state) => {
//     const [supportedTokens, setSupportedTokens] = useState([])

//     if (process.env.NEXT_PUBLIC_TESTNET === 'TRUE') return [supportedTokens]

//     useEffect(() => {
//         if (supportedTokens.length === 0) {
//             const controller = new AbortController()
//             getSupportedTokens(controller.signal, state).then((result) => {
//                 if (result && !('errors' in result)) setSupportedTokens(result)
//             })
//             return () => controller.abort()
//         }
//     }, [])

//     return [supportedTokens]
// }

export const useAetherAuthors = () => {
    const [aetherAuthors, setAetherAuthors] = useState([])

    if (process.env.NEXT_PUBLIC_TESTNET === 'TRUE') return [aetherAuthors]

    useEffect(() => {
        if (aetherAuthors.length === 0) {
            const controller = new AbortController()
            getAetherAuthors(controller.signal).then((result) => {
                if (result && !('errors' in result)) setAetherAuthors(result)
            })
            return () => controller.abort()
        }
    }, [])

    return [aetherAuthors]
}

export const useLanguage = (props = { lang: 'en' }, ns = 'common') => {
    const { t, i18n } = useTranslation(ns)

    const language = props['lang'] ? props['lang'] : 'en'

    if (typeof window === undefined && language) i18n.changeLanguage(language)

    return { t, language }
}

export const useWindow = () => (typeof window !== 'undefined' ? window : null)

/**
 * Returns the contents of the query string
 */
export const useQueryString = () => {
    const window = useWindow()

    let values = window
        ? qs.parse(
              window.location.search.substring(
                  1,
                  window.location.search.length,
              ),
          )
        : {}

    const getValue = (key, def = undefined, { expectArray = true } = {}) => {
        const value = values[key]
        if (!value) return def
        if (expectArray && !Array.isArray(value)) return [value]
        return Array.isArray(value) ? value[0] : value
    }

    return { values, getValue }
}
