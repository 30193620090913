import { Checkbox } from '@mui/material'
import React from 'react'
import cn from 'classnames'

function QuickSettingsItem({
    handleCheck,
    handleOnChange,
    title,
    description,
}) {
    return (
        <div
            className={
                'flex flex-col border-b border-zinc-200 dark:border-zinc-800'
            }
        >
            <div className="flex">
                <div
                    className={cn('flex mr-3 h-5 w-5 my-auto', {
                        'dark:!text-secondary text-white': true,
                    })}
                >
                    <Checkbox
                        checked={handleCheck}
                        sx={{
                            width: '10px',
                        }}
                        onChange={handleOnChange}
                    />
                </div>
                <div
                    className={cn(
                        'relative text-left text-[14px] md:text-md',
                        'dark:text-white text-black mr-auto',
                    )}
                >
                    {title}
                </div>
            </div>

            <span className="text-zinc-500 text-[12px] md:text-[14px] my-2">
                {description}
            </span>
        </div>
    )
}

export default QuickSettingsItem
