import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { Card } from '@nextui-org/react'
import ErrorNote from '../common/util/ErrorNote'
import MainButton from '../common/util/input/MainButton'
import { get, post } from '../helpers/Api'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import ResultWindow from '../packs/ResultWindow'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import Popup from './Popup'

function UnpackPopup(props) {
    const asset = props['asset']

    const { name, assetId } = asset

    const image = asset['image']

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']

    const { t } = useTranslation('common')

    const [isLoading, setIsLoading] = useState(true)
    const [loadingMessage, setLoadingMessage] = useState('Loading Transaction')
    const [error, setError] = useState()
    const claimOnly = props['claimOnly']
    const closeCallBack = props['closeCallBack']
    const [animation, setAnimation] = useState(null)
    const [showAnimation, setShowAnimation] = useState(true)
    const [showResults, setShowResults] = useState(false)
    const [pack, setPack] = useState(null)
    const [results, setResults] = useState(null)
    const [unpacked, setUnpacked] = useState(false)
    const [reloads, setReloads] = useState(0)
    const [transactionId, setTransactionId] = useState(null)

    const getPackInfo = async (asset) => {
        const body = {
            code: asset.contract,
            index_position: 1,
            json: 'true',
            key_type: 'i64',
            limit: 1,
            lower_bound: asset.packId,
            upper_bound: asset.packId,
            reverse: 'true',
            scope: asset.contract,
            show_payer: 'false',
            table: 'packs',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : state.api + '/v1/chain/get_table_rows'
        const res = await post(url, body)

        if (res && res.status === 200 && res.data && res.data.rows) {
            setPack(res.data.rows[0])
        }

        setIsLoading(false)
    }

    useEffect(() => {}, [unpacked])

    useEffect(() => {
        setReloads(reloads + 1)
        getPackInfo(asset)
    }, [assetId])

    const claimPack = async (pack, asset, activeUser) => {
        const userName = activeUser['accountName']

        if (asset.contract === 'atomicpacksx') {
            const origin_roll_ids = []
            const result_templates = []

            const body = {
                code: asset.contract,
                index_position: 'primary',
                json: 'true',
                key_type: 'i64',
                limit: 1000,
                lower_bound: '',
                upper_bound: '',
                reverse: 'false',
                scope: asset.assetId,
                show_payer: 'false',
                table: 'unboxassets',
                table_key: '',
            }

            const url =
                process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                    ? config.testapi + '/v1/chain/get_table_rows'
                    : state.api + '/v1/chain/get_table_rows'
            const res = await post(url, body)

            if (res && res.status === 200 && res.data && res.data.rows) {
                res.data.rows.map((item) => {
                    origin_roll_ids.push(parseInt(item.origin_roll_id))
                    result_templates.push(parseInt(item.template_id))
                    return null
                })

                if (origin_roll_ids.length === 0) {
                    setError(t('packs.result_not_ready_yet'))
                    return
                }

                try {
                    const result = await activeUser.session.transact(
                        {
                            actions: [
                                {
                                    account: asset.contract,
                                    name: 'claimunboxed',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        origin_roll_ids: origin_roll_ids,
                                        pack_asset_id: asset.assetId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )

                    setTransactionId(result?.response?.transaction_id)

                    return { templates: result_templates }
                } catch (e) {
                    callBack({ unpacked: false, error: e.message })
                    console.error(e)
                    setError(e.message)

                    return null
                }
            }
        } else if (asset.contract === 'nfthivepacks') {
            const result_assets = []
            const result_templates = []
            let result_tokens = []

            const body = {
                code: asset.contract,
                index_position: 'primary',
                json: 'true',
                key_type: 'i64',
                limit: 1,
                lower_bound: asset.assetId,
                upper_bound: asset.assetId,
                reverse: 'false',
                scope: asset.contract,
                show_payer: 'false',
                table: 'unpackresult',
                table_key: '',
            }

            const url =
                process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                    ? config.testapi + '/v1/chain/get_table_rows'
                    : state.api + '/v1/chain/get_table_rows'
            const res = await post(url, body)

            if (res && res.status === 200 && res.data && res.data.rows) {
                res.data.rows.map((item) => {
                    item['result_asset_ids'].map((assetId) =>
                        result_assets.push(parseInt(assetId)),
                    )
                    item['result_template_ids'].map((templateId) =>
                        result_templates.push(parseInt(templateId)),
                    )
                    return null
                })

                if (
                    result_assets.length === 0 &&
                    result_templates.length === 0
                ) {
                    setError(t('packs.result_not_ready_yet'))
                    return
                }
                const tBody = {
                    code: asset.contract,
                    index_position: 'primary',
                    json: 'true',
                    key_type: 'i64',
                    limit: 1,
                    lower_bound: asset.assetId,
                    upper_bound: asset.assetId,
                    reverse: 'false',
                    scope: asset.contract,
                    show_payer: 'false',
                    table: 'unpacktokens',
                    table_key: '',
                }

                const tUrl =
                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                        ? config.testapi + '/v1/chain/get_table_rows'
                        : state.api + '/v1/chain/get_table_rows'
                const tRes = await post(tUrl, tBody)

                if (
                    tRes &&
                    tRes.status === 200 &&
                    tRes.data &&
                    tRes.data.rows
                ) {
                    tRes.data.rows.map((item) => {
                        if (
                            item &&
                            item['pack_asset_id'] &&
                            item['pack_asset_id'].toString() ===
                                asset.assetId.toString()
                        ) {
                            result_tokens = item['tokens']
                        }
                        return null
                    })
                }

                try {
                    const result = await activeUser.session.transact(
                        {
                            actions: [
                                {
                                    account: asset.contract,
                                    name: 'claimunpack',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        pack_asset_id: asset.assetId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )

                    setTransactionId(result?.response?.transaction_id)

                    return {
                        assets: result_assets,
                        templates: result_templates,
                        tokens: result_tokens,
                    }
                } catch (e) {
                    callBack({ unpacked: false, error: e.message })
                    console.error(e)
                    setError(e.message)

                    return null
                }
            } else {
                setError(t('packs.result_not_ready_yet'))
                return null
            }
        } else {
            const roll_indexes = []
            const result_assets = []

            const body = {
                code: asset.contract,
                index_position: 'primary',
                json: 'true',
                key_type: 'i64',
                limit: 1000,
                lower_bound: asset.assetId,
                upper_bound: asset.assetId,
                reverse: 'false',
                scope: asset.contract,
                show_payer: 'false',
                table: 'claimassets',
                table_key: '',
            }

            const url =
                process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                    ? config.testapi + '/v1/chain/get_table_rows'
                    : state.api + '/v1/chain/get_table_rows'
            const res = await post(url, body)

            if (
                res &&
                res.status === 200 &&
                res.data &&
                res.data.rows &&
                res.data.rows.length > 0
            ) {
                res.data.rows[0].claims.map((item, index) => {
                    roll_indexes.push(index)
                    if (item && item['claim'])
                        result_assets.push(item['claim'][1]['asset_id'])
                })

                try {
                    const result = await activeUser.session.transact(
                        {
                            actions: [
                                {
                                    account: asset.contract,
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        claim_id: asset.assetId,
                                        roll_indexes: roll_indexes,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )

                    setTransactionId(result?.response?.transaction_id)
                } catch (e) {
                    callBack({ unpacked: false, error: e.message })
                    console.error(e)
                    setError(e.message)

                    return null
                }

                return { assets: result_assets }
            } else {
                setError(t('packs.result_not_ready_yet'))
                return null
            }
        }
    }

    const parseDisplayData = (packDisplayData) => {
        if (packDisplayData) {
            try {
                const displayData = JSON.parse(packDisplayData)

                if (
                    displayData &&
                    displayData.animation &&
                    displayData.animation.drawing
                ) {
                    const data = displayData.animation.drawing.data
                    const video = data && data.video ? data.video : null
                    const bgColor = displayData.animation.drawing.bg_color

                    setAnimation({ video: video, bgColor: bgColor })
                } else {
                    setAnimation('No Animation')
                }
            } catch (e) {
                setAnimation('No Animation')
                console.error(e)
            }
        }
    }

    const parseResults = (assetRes, templateIds, tempRes, tokenRes) => {
        const res = {}
        if (assetRes) {
            res['assets'] = assetRes
        }

        if (templateIds && tempRes) {
            const templates = []
            for (const templateId of templateIds) {
                for (const res of tempRes) {
                    if (res['templateId'] === templateId) {
                        templates.push(res)
                    }
                }
            }
            res['templates'] = templates
        }

        if (tokenRes) {
            res['tokens'] = tokenRes
        }

        setResults(res)

        parseDisplayData(pack?.display_data)

        setIsLoading(false)
    }

    const parseTokens = (res, tokens) => {
        const result = []

        for (const token of tokens) {
            let logo = ''
            let symbol = ''
            for (const item of res) {
                if (
                    item['account'] === token['contract'] &&
                    token['amount'].includes(' ' + item['symbol'])
                ) {
                    symbol = item['symbol']
                    logo = item['logo_lg']
                }
            }
            result.push({
                contract: token['contract'],
                amount: token['amount'],
                symbol: symbol,
                logo: logo,
            })
        }

        return result
    }

    const loadResults = async (res) => {
        let tempRes = null
        let assetRes = null
        let tokenRes = null
        let templateIds = null

        if (
            res &&
            Object.keys(res).includes('templates') &&
            res['templates'].length > 0
        ) {
            templateIds = res['templates']

            tempRes = await get(
                'templates?template_ids=' + templateIds.join(','),
            )
        }

        if (
            res &&
            Object.keys(res).includes('assets') &&
            res['assets'].length > 0
        ) {
            const assetIds = res['assets']

            assetRes = await get('assets?asset_ids=' + assetIds.join(','))
        }

        if (
            res &&
            Object.keys(res).includes('tokens') &&
            res['tokens'].length > 0
        ) {
            const tRes = await get('get-wax-tokens', 'api1')
            tokenRes = parseTokens(tRes, res['tokens'])
        }

        parseResults(assetRes, templateIds, tempRes, tokenRes)
    }

    const getPackResult = () => {
        try {
            claimPack(pack, asset, activeUser).then((res) => loadResults(res))
        } catch (e) {
            callBack({ unboxed: false, error: e.message })
            console.error(e)
            setError(e.message)
        }
    }

    const handleUnpacked = () => {
        setUnpacked(true)
        setIsLoading(false)
    }

    const unbox = async () => {
        setLoadingMessage('Unpacking ...')
        setIsLoading(true)

        try {
            if (!pack) {
                throw 'Unable to Load Pack'
            }

            await activeUser.session.transact(
                {
                    actions: [
                        {
                            account: 'atomicassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                memo:
                                    asset.contract === 'nfthivepacks'
                                        ? 'unpack'
                                        : 'unbox',
                                to: asset.contract,
                                asset_ids: [asset.assetId],
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            setTimeout(handleUnpacked, 3000)
        } catch (e) {
            callBack({ unpacked: false, error: e.message })
            console.error(e)
            setError(e.message)
        }
    }

    const dismissError = () => {
        setError(null)
    }

    const cancel = () => {
        callBack({ unpacked: false })
        closeCallBack()
    }

    const acknowledge = () => {
        callBack({ unpacked: true, transactionId: transactionId })
        closeCallBack()
    }

    const stopAnimation = () => {
        setShowAnimation(false)
        setShowResults(true)
    }

    return animation ? (
        <ResultWindow
            stopAnimation={stopAnimation}
            showResults={showResults}
            showAnimation={showAnimation}
            results={results}
            animation={animation}
            acknowledge={acknowledge}
        />
    ) : (
        <Popup title={name} cancel={cancel} image={image} asset={asset}>
            <div className="relative my-4 text-lg font-semibold text-left">
                {error ? (
                    <ErrorNote error={error} onClick={dismissError} />
                ) : (
                    ''
                )}
                {unpacked || claimOnly
                    ? t('asset.claim_assets_now')
                    : t('asset.do_you_want_to_unpack_x', {
                          x: name ? name : assetId,
                      })}
            </div>
            {isLoading || !pack ? (
                <div className="mb-2">
                    <LoadingIndicator text={loadingMessage} />
                </div>
            ) : (
                <div
                    className={cn(
                        'relative m-auto mt-5 h-20 lg:h-8',
                        'flex justify-start gap-4',
                    )}
                >
                    <MainButton onClick={cancel} className="bg-red-500 ">
                        {t('asset.cancel')}
                    </MainButton>
                    {claimOnly || unpacked ? (
                        <MainButton
                            className="w-[80px] max-w-32"
                            onClick={getPackResult}
                        >
                            {t('asset.claim')}
                        </MainButton>
                    ) : (
                        <MainButton
                            className="w-[80px] max-w-32"
                            onClick={unbox}
                        >
                            {t('asset.unpack')}
                        </MainButton>
                    )}
                </div>
            )}
        </Popup>
    )
}

export default UnpackPopup
