import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import config from '../../config.json'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import ResultList from './ResultList'

function ResultWindow(props) {
    const stopAnimation = props['stopAnimation']
    const showResults = props['showResults']
    const showAnimation = props['showAnimation']
    const results = props['results']
    const animation = props['animation']
    const acknowledge = props['acknowledge']
    const bgColor = animation ? animation['bgColor'] : null

    const isLoading = props['isLoading']
    const [fileType, setFileType] = useState(null)
    const [fileTypeLoading, setFileTypeLoading] = useState(false)

    const determineFileType = async () => {
        const file =
            animation.video && animation.video.includes('http')
                ? animation.video
                : config.ipfs + animation.video
        const req = await fetch(file, { method: 'HEAD' })
        setFileType(req.headers.get('content-type'))
    }

    useEffect(() => {
        if (!animation || animation === 'No Animation' || !animation.video) {
            stopAnimation()
        } else {
            determineFileType().then(setFileTypeLoading(false))
        }
    }, [animation])

    return (
        <div
            style={{ backgroundColor: bgColor }}
            className={cn(
                'fixed left-0 top-0 w-full h-full',
                'text-sm dark:text-white text-black font-light opacity-100',
                'bg-paper rounded-xl shadow-lg z-100',
                'backdrop-filter backdrop-blur-lg',
            )}
        >
            <div className={cn('flex justify-center h-full')}>
                {animation &&
                animation !== 'No Animation' &&
                showAnimation &&
                !fileTypeLoading &&
                !showResults ? (
                    fileType === 'image/gif' ? (
                        <img
                            src={
                                animation.video &&
                                animation.video.includes('http')
                                    ? animation.video
                                    : config.ipfs + animation.video
                            }
                        />
                    ) : (
                        <video
                            className={cn(
                                'flex object-fill align-middle m-auto',
                            )}
                            id={'unbox-animation'}
                            onEnded={stopAnimation}
                            autoPlay={true}
                            muted={false}
                        >
                            <source
                                src={
                                    animation.video &&
                                    animation.video.includes('http')
                                        ? animation.video
                                        : config.ipfs + animation.video
                                }
                            />
                            Your browser does not support the video tag.
                        </video>
                    )
                ) : (
                    <div id={'Results'}>
                        {isLoading ? (
                            <LoadingIndicator />
                        ) : (
                            <div>
                                <ResultList
                                    results={results}
                                    close={acknowledge}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <img
                className="absolute z-50 cursor-pointer top-4 right-4 w-4 h-4 "
                id={'stop-animation'}
                onClick={showAnimation ? stopAnimation : acknowledge}
                src="/close_btn.svg"
                alt="X"
            />
        </div>
    )
}

export default ResultWindow
