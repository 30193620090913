import { Alert, Button, Collapse, TextField } from '@mui/material'
import cn from 'classnames'
import ReactMde from 'react-mde'
import MarkDown from '../markdown/MarkDown'
import { DropPaper } from './DropPaper'
import { PreviewImageUpload } from './pfpeditor/PreviewImageUpload'
import { Input } from '@nextui-org/react'
import MainButton from '../common/util/input/MainButton'
import { useTranslation } from 'react-i18next'
export const DropDetailsEditor = ({
    dropName,
    setDropName,
    editorOpen,
    setEditorOpen,
    dropDescription,
    setDropDescription,
    previewImg,
    setPreviewImg,
    isPfp,
    title,
    nameTitle,
    descriptionTitle,
}) => {
    const { t } = useTranslation('common')

    return (
        <DropPaper
            expandable
            error={dropName.length === 0}
            title={title}
            id={'DropDetailsEditor'}
        >
            <DropPaper title={nameTitle}>
                <Input
                    variant="faded"
                    label={nameTitle}
                    value={dropName}
                    required={true}
                    error={dropName.length === 0}
                    onChange={(e) => setDropName(e.target.value)}
                />
            </DropPaper>
            {isPfp ? (
                <PreviewImageUpload
                    previewImage={previewImg}
                    setPreviewImage={setPreviewImg}
                />
            ) : null}

            <DropPaper title={descriptionTitle}>
                <div
                    className={cn(
                        'text-left',
                        editorOpen || dropDescription ? 'mb-5' : 'mb-10',
                    )}
                >
                    <div className="flex justify-center">
                        <MainButton
                            color={
                                !editorOpen && !dropDescription
                                    ? 'warning'
                                    : 'primary'
                            }
                            onClick={() => setEditorOpen(!editorOpen)}
                        >
                            {editorOpen
                                ? t('buttons.close')
                                : dropDescription
                                ? t('buttons.edit')
                                : t('buttons.add')}
                        </MainButton>
                    </div>
                </div>
                <Collapse in={editorOpen}>
                    <div className="mb-5 text-black border-8 rounded-md light border-graphbg">
                        <ReactMde
                            value={dropDescription}
                            onChange={setDropDescription}
                            disablePreview={true}
                        />
                    </div>
                </Collapse>
                <Collapse in={dropDescription}>
                    <div>
                        <div className="mb-2">Preview:</div>
                        <div className="p-5 mb-5 border rounded-md">
                            <MarkDown text={dropDescription} />
                        </div>
                    </div>
                </Collapse>
                <Collapse in={!editorOpen && !dropDescription}>
                    <div className="mb-5">
                        <Alert severity="warning">
                            {t(
                                'drops.we_strongly_recommend_adding_a_description',
                            )}
                        </Alert>
                    </div>
                </Collapse>
            </DropPaper>

            <Collapse in={dropName.length === 0}>
                <div className="mb-5">
                    <Alert severity="error">{t('drops.name_is_missing')}</Alert>
                </div>
            </Collapse>
        </DropPaper>
    )
}
