import { Table, TableBody, TableCell, TableRow, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Card } from '@nextui-org/react'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function TransferPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const {
        assetId,
        aAssetId,
        schema,
        number,
        rarity,
        variant,
        author,
        name,
        image,
        standard,
    } = asset

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [receiver, setReceiver] = useState(
        props['receiver'].trim().toLowerCase(),
    )
    const [memo, setMemo] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']

    const transfer = async () => {
        if (!receiver) return
        closeCallBack()
        setIsLoading(true)

        try {
            let result
            switch (standard) {
                case 'simpleassets':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'simpleassets',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        to: receiver,
                                        assetids: [assetId],
                                        memo: memo,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'atomicassets':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'atomicassets',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        memo: memo,
                                        asset_ids: [aAssetId],
                                        to: receiver,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'packs.ws':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'packs.ws',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        memo: memo,
                                        quantity: `1 ${name}`,
                                        to: receiver,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'packs.topps':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'packs.topps',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        memo: memo,
                                        quantity: `1 ${name}`,
                                        to: receiver,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'pack.worlds':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'pack.worlds',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        memo: memo,
                                        quantity: `1 ${name}`,
                                        to: receiver,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
            }
            callBack({
                transferred: true,
                receiver: receiver,
                transactionId: result?.response?.transaction_id,
            })
        } catch (e) {
            callBack({
                transferred: false,
                receiver: null,
                error: e.message,
            })
            console.error(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const cancel = () => {
        callBack({ transferred: false, receiver: null, offer: 0 })
        closeCallBack()
    }

    const changeReceiver = (e) => {
        const val = e.target.value
        setReceiver(val.trim().toLowerCase())
    }

    const changeMemo = (e) => {
        const val = e.target.value
        setMemo(val)
    }

    return (
        <Popup title={name} cancel={cancel} image={image}>
            <div className="mx-auto mb-4 text-xl font-bold">
                {t('popups.are_you_sure_you_want_to_transfer_asset_to', {
                    name: name,
                    account: receiver,
                })}
            </div>
            <Card className="p-4">
                <div className="px-2 my-4">
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <b>{t('asset.schema')}</b>
                                </TableCell>
                                <TableCell>{schema}</TableCell>
                            </TableRow>
                            {number && variant ? (
                                <TableRow>
                                    <TableCell>
                                        <b>{t('asset.card')}</b>
                                    </TableCell>
                                    <TableCell>
                                        {number}
                                        {variant}
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            {rarity ? (
                                <TableRow>
                                    <TableCell>
                                        <b>{t('asset.rarity')}</b>
                                    </TableCell>
                                    <TableCell>{rarity}</TableCell>
                                </TableRow>
                            ) : null}
                            <TableRow>
                                <TableCell>
                                    <b>{t('asset.author')}</b>
                                </TableCell>
                                <TableCell>{author}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </Card>
            {error ? <ErrorMessage error={error} /> : null}
            <div className="flex justify-center gap-2 mt-4 ">
                <div>
                    <Input
                        variant="faded"
                        type="text"
                        onChange={changeReceiver}
                        value={receiver}
                        label={t('asset.receiver')}
                    />
                </div>
                <div>
                    <Input
                        variant="faded"
                        type="text"
                        onChange={changeMemo}
                        value={memo}
                        label={t('asset.memo')}
                    />
                </div>
            </div>
            <div className="flex justify-center gap-2 mt-4">
                <MainButton onClick={cancel} className="bg-red-500 " fullWidth>
                    {t('asset.cancel')}
                </MainButton>

                <MainButton disabled={!receiver} onClick={transfer} fullWidth>
                    {t('asset.transfer')}
                </MainButton>
            </div>

            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default TransferPopup
