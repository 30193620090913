import { FormControl } from '@mui/material'
import { Select, SelectItem } from '@nextui-org/react'
import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MissingDropOwnershipTemplates } from '../helpers/DropProofs'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import CountrySelect from '../ui/countryselect/CountrySelect'
import MainButton from '../common/util/input/MainButton'
import { DropAlert } from './DropAlert'

export const BuyDropPopupBuyConfirmation = ({
    closeCallBack,
    purchase,
    drop,
    listingPrice,
    setListingPrice,
    dropPrices,
    free,
    amount,
    isLoadingValidation,
    missingProof,
    fulfilled,
    country,
    onSelectCountry,
    cancel,
    supportedTokens,
}) => {
    const { t } = useTranslation('common')
    return (
        <>
            <div
                className={cn(
                    'w-full grid justify-items-center grid-cols-1 md:grid-cols-2 gap-4 mt-8 mb-2',
                    dropPrices ? ' justify-between' : ' justify-center',
                )}
            >
                <div className="col-span-1 ">
                    <CountrySelect
                        onSelect={onSelectCountry}
                        country={country}
                    />
                </div>
                {dropPrices ? (
                    <div className="col-span-1">
                        <FormControl size="small" className="flex mx-auto">
                            <Select
                                label={t('drops.pay_with')}
                                defaultSelectedKeys={new Set([listingPrice])}
                                onChange={(e) => {
                                    setListingPrice(e.target.value)
                                }}
                                variant="bordered"
                                placeholder="Select"
                                className="font-semibold  min-w-[220px] max-w-[220px]"
                                classNames={{
                                    listbox: 'text-black dark:text-white',
                                }}
                            >
                                {dropPrices.map((price, index) => (
                                    <SelectItem key={price} value={price}>
                                        {price}
                                    </SelectItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                ) : (
                    ''
                )}
            </div>

            {drop.authRequired ? (
                <div className={'mb-5'}>
                    <div className={'my-2 text-center'}>
                        {t('drops.auth_required')}
                    </div>
                    {isLoadingValidation ? (
                        <LoadingIndicator />
                    ) : (
                        <div
                            className={cn(
                                'text-center',
                                { 'text-green-400': fulfilled },
                                { 'text-red-400': !fulfilled },
                            )}
                        >
                            {fulfilled ? 'Fulfilled' : 'Not Fulfilled'}
                        </div>
                    )}
                    {missingProof && (
                        <MissingDropOwnershipTemplates
                            t={t}
                            missingProof={missingProof}
                            closeCallBack={closeCallBack}
                        />
                    )}
                </div>
            ) : null}
            <div
                className={cn(
                    'relative mt-4',
                    'flex flex-col items-start justify-start',
                )}
            >
                <div className="w-full my-4 text-lg text-start">
                    {free
                        ? `Do you want to claim this Drop for free?`
                        : `Do you want to buy ${
                              amount > 1 ? amount + ' of' : ''
                          } this Drop for ${listingPrice}${
                              amount > 1 ? ' each' : ''
                          }?`}
                </div>
                <div className="flex flex-wrap justify-start gap-4">
                    <div className="mx-2 w-">
                        <MainButton color="danger" onClick={cancel}>
                            Cancel
                        </MainButton>
                    </div>
                    <div className="w-[80px] mx-2">
                        <MainButton
                            onClick={purchase}
                            disabled={
                                supportedTokens === null ||
                                !country ||
                                isLoadingValidation ||
                                (drop.authRequired && !fulfilled)
                            }
                            className="w-[80px] max-w-32"
                        >
                            {free ? 'Claim' : 'Purchase'}
                        </MainButton>
                    </div>
                </div>
            </div>
            <DropAlert severity="error" condition={!country}>
                Please select your country
            </DropAlert>
            <DropAlert
                severity="error"
                condition={drop.authRequired && !fulfilled}
            >
                You are not authorized to buy this Drop
            </DropAlert>
        </>
    )
}
