import { Tooltip } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'
import React from 'react'
import config from '../../config.json'
import Link from '../common/util/input/Link'
import { get } from './Api'

export const getAssetLink = (
    assetId,
    name,
    number,
    variant,
    offer,
    orderId,
    auctionId,
    type,
    globalSequence,
    dropId,
    contract,
    market,
) => {
    return dropId
        ? '/drop/' + contract + '/' + dropId
        : auctionId
        ? '/auction/' + market + '/' + auctionId
        : offer && type === 'buy'
        ? '/sale/' + globalSequence
        : offer && type === 'sale'
        ? '/listing/' + market + '/' + orderId
        : '/asset/' + assetId
}

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const tokenSortFunc = (a, b) => {
    if (a.label === 'WAX' && b.label !== 'WAX') {
        return -1
    } else if (b.label === 'WAX' && a.label !== 'WAX') {
        return 1
    } else if (a.label === 'USD' && b.label !== 'WAX' && b.label !== 'USD') {
        return -1
    } else if (b.label === 'USD' && a.label !== 'WAX' && a.label !== 'USD') {
        return 1
    } else if (
        a.label === 'HONEY' &&
        b.label !== 'WAX' &&
        b.label !== 'USD' &&
        b.label !== 'HONEY'
    ) {
        return -1
    } else if (
        b.label === 'HONEY' &&
        a.label !== 'WAX' &&
        a.label !== 'USD' &&
        a.label !== 'HONEY'
    ) {
        return 1
    } else if (
        a.label === 'WUF' &&
        b.label !== 'WAX' &&
        b.label !== 'USD' &&
        b.label !== 'HONEY' &&
        b.label !== 'WUF'
    ) {
        return -1
    } else if (
        b.label === 'WUF' &&
        a.label !== 'WAX' &&
        a.label !== 'USD' &&
        a.label !== 'HONEY' &&
        a.label !== 'WUF'
    ) {
        return 1
    } else if (a.label < b.label) {
        return -1
    } else if (a.label > b.label) {
        return 1
    }

    return 0
}

export const parseAtomicImmutableData = (atomicData) => {
    const idata = atomicData.immutable_data
    const template_idata = atomicData.template
        ? atomicData.template.immutable_data
        : {}

    const data = {}

    for (const format of atomicData.schema.format) {
        if (Object.keys(idata).includes(format['name'])) {
            data[format['name']] = idata[format['name']]
        } else if (Object.keys(template_idata).includes(format['name'])) {
            data[format['name']] = template_idata[format['name']]
        }
    }

    return data
}

export const parseAtomicMutuableData = (atomicData) => {
    return atomicData.mutable_data &&
        Object.keys(atomicData.mutable_data).length > 0
        ? atomicData.mutable_data
        : null
}

export const setQueryStringWithoutPageReload = (qsValue) => {
    const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        qsValue
    const newPath = window.location.pathname + '?' + qsValue

    const oldState = window.history.state
    oldState.path = newPath
    oldState.url = newurl

    window.history.replaceState(oldState, '', newPath)
}

export const setQueryStringWithoutPageReloadPush = (qsValue) => {
    const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        (qsValue && qsValue.length > 0 ? '?' : '') +
        qsValue
    const newPath =
        window.location.pathname +
        (qsValue && qsValue.length > 0 ? '?' : '') +
        qsValue

    const oldState = window.history.state

    oldState.path = newPath
    oldState.url = newurl
    oldState.as = newPath

    window.history.pushState(oldState, '', newPath)
}

export const setQueryStringWithoutPageReloadPushPath = (qsValue, path) => {
    const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        '/' +
        path +
        '?' +
        qsValue
    const newPath = window.location.host + '/' + path + '?' + qsValue

    const oldState = window.history.state

    oldState.path = newurl
    oldState.url = newurl
    oldState.as = newurl

    window.history.pushState(oldState, '', newPath)
}

export const getOwnerLink = (owner) => {
    return owner ? (
        <Link href={`/user/${owner}`}>
            <div className="cursor-pointer text-primary hover:underline">
                {owner}
            </div>
        </Link>
    ) : null
}

export const getAuthorLink = (author) => {
    return (
        <Link href={`/collection/${author}`}>
            <div className="cursor-pointer">{author}</div>
        </Link>
    )
}

export const getVariantLink = (author, schema, variant) => {
    return (
        <Link
            href={`/market?searchType=sales&order_by=offer_asc&collection=${author}&schema=${schema}&variant=${variant}`}
        >
            <div className="cursor-pointer">{variant}</div>
        </Link>
    )
}

export const getSearchName = (t, values, props) => {
    let search = t('search.sales')

    if (typeof window !== 'undefined') {
        if (values['search_type'] && 'cheapest' === values['search_type'])
            search = 'Cheapest Sales per Asset'
        else if (
            values['search_type'] &&
            'cheapest_missing' === values['search_type']
        )
            search = t('search.cheapest_missing_assets')
        else if (
            values['search_type'] &&
            ['sales', 'below_average'].includes(values['search_type'])
        )
            search = t('search.sales')
        else if (values['search_type'] && values['search_type'] === 'staked')
            search = t(`search.staked_assets`)
        else if (values['search_type'])
            search = t(`search.${values['search_type']}`)
        if (values['tab'] === 'packs') search = t('overview.packs')
        else if (values['tab'] === 'auctions') search = t('search.auctions')
    } else {
        if (props.search_type && 'cheapest' === props.search_type)
            search = 'Cheapest Sales per Asset'
        else if (props.search_type && 'cheapest_missing' === props.search_type)
            search = t('search.cheapest_missing_assets')
        else if (
            props.search_type &&
            ['sales', 'below_average'].includes(props.search_type)
        )
            search = t('search.sales')
        else if (props.search_type && props.search_type === 'staked')
            search = t(`search.staked_assets`)
        else if (props.search_type) search = t(`search.${props.search_type}`)
        if (props.tab === 'packs') search = t('overview.packs')
        else if (props.tab === 'auctions') search = t('search.auctions')
    }

    return search
}

export const formatAuthor = (author) => {
    return author ? author.charAt(0).toUpperCase() + author.slice(1) : author
}

export const createCollectionOption = (name) => {
    if (!name) return name

    return (
        <div
            className={cn(
                'flex justify-between font-bold w-32',
                { 'text-xs': name.length >= 15 },
                { 'text-sm': name.length < 15 },
            )}
        >
            {name}
        </div>
    )
}

export const createCollectionImageOption = (name, image) => {
    return (
        <div className={cn('flex justify-between w-32', 'text-sm font-bold')}>
            <div className="w-8 h-8 m-auto">
                <img src={image} className="w-8 h-8" />
            </div>
            <div className="w-full pl-1 pr-3 overflow-x-hidden whitespace-nowrap">
                {name}
            </div>
        </div>
    )
}

export const createAuthors = (data, search = '', def = '') => {
    const authors = []

    if (def) {
        ;(data['authors'] ? data['authors'] : data)
            .filter((author) => author === def)
            .forEach((element) => {
                if (!authors.find((a) => a.value === element))
                    if (
                        !search ||
                        element.toLowerCase().includes(search.toLowerCase()) ||
                        (data['names'][element] &&
                            data['names'][element]
                                .toLowerCase()
                                .includes(search.toLowerCase()))
                    )
                        authors.push({
                            value: element,
                            title: data['names'][element]
                                ? data['names'][element]
                                : element,
                            label: element,
                            image:
                                data['images'] && data['images'][element]
                                    ? data['images'][element]
                                    : '',
                        })
            })
    }

    ;(data['authors'] ? data['authors'] : data)
        .filter((author) => data['verified'][author])
        .forEach((element) => {
            if (!authors.find((a) => a.value === element))
                if (
                    !search ||
                    element.toLowerCase().includes(search.toLowerCase()) ||
                    (data['names'][element] &&
                        data['names'][element]
                            .toLowerCase()
                            .includes(search.toLowerCase()))
                )
                    authors.push({
                        value: element,
                        title: data['names'][element]
                            ? data['names'][element]
                            : element,
                        label: element,
                        image:
                            data['images'] && data['images'][element]
                                ? data['images'][element]
                                : '',
                    })
        })
    ;(data['authors'] ? data['authors'] : data)
        .filter((author) => !data['verified'][author])
        .forEach((element) => {
            if (!authors.find((a) => a.value === element))
                if (
                    !search ||
                    element.toLowerCase().includes(search.toLowerCase()) ||
                    (data['names'][element] &&
                        data['names'][element]
                            .toLowerCase()
                            .includes(search.toLowerCase()))
                )
                    authors.push({
                        value: element,
                        title: data['names'][element]
                            ? data['names'][element]
                            : element,
                        label: element,
                        image:
                            data['images'] && data['images'][element]
                                ? data['images'][element]
                                : '',
                    })
        })

    return authors
}

export const getImage = (author) => {
    if (author && author !== '*')
        return 'https://ipfs.hivebp.io/hive/author/' + author

    return 'https://nfthive.io/nfthive-logo.png'
}

export const getImageData = (images, imageFormats, assetData, format) => {
    if (format && assetData) {
        const formatDict = {}
        for (let a of format) {
            formatDict[a['name']] = a['type']
        }
        Object.keys(formatDict).map((key) => {
            if (
                formatDict[key] &&
                (formatDict[key] === 'image' || formatDict[key] === 'video') &&
                assetData[key]
            ) {
                const image = assetData[key].includes('http')
                    ? assetData[key]
                    : `${config.ipfs}${assetData[key]}`
                if (!images.includes(image) && !image.includes('video:')) {
                    images.push(image)
                    imageFormats.push(formatDict[key])
                }
            }
        })
    }
}

export const createSchemas = (
    categories,
    includeDefault = true,
    schemaOptions = null,
) => {
    const categoriesOptions = []
    const addedCategories = []

    if (!categories || !categories.length) return null

    if (includeDefault) categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (
            element['schema'] &&
            (!schemaOptions || schemaOptions.includes(element['schema']))
        ) {
            if (!addedCategories.includes(element['schema'])) {
                categoriesOptions.push({
                    value: element['schema'],
                    label: element['schema'],
                })
                addedCategories.push(element['schema'])
            }
        }
    })

    return categoriesOptions
}

export const createCategories = (t) => {
    return [
        {
            value: 64,
            label: t('collection_category.1'),
        },
        {
            value: 65,
            label: t('collection_category.2'),
        },
        {
            value: 69,
            label: t('collection_category.3'),
        },
        {
            value: 66,
            label: t('collection_category.4'),
        },
        {
            value: 67,
            label: t('collection_category.5'),
        },
        {
            value: 68,
            label: t('collection_category.6'),
        },
    ]
}

export const createTemplates = (categories, includeDefault = true) => {
    const categoriesOptions = []
    const addedTemplates = []

    if (!categories || !categories.length) return null

    if (includeDefault) categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['templateId']) {
            if (!addedTemplates.includes(element['templateId'])) {
                categoriesOptions.push({
                    value: element['templateId'],
                    label: element['templateId'],
                })
                addedTemplates.push(element['templateId'])
            }
        }
    })

    return categoriesOptions
}

export const createNewRarities = (categories, includeDefault = true) => {
    const categoriesOptions = []
    const addedRarities = []

    if (!categories || !categories.length) return null

    if (includeDefault) categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['rarities']) {
            for (const rarity of element['rarities']) {
                if (!addedRarities.includes(rarity['rarity'])) {
                    categoriesOptions.push({
                        value: rarity['rarity'],
                        label: rarity['rarity'],
                        numTemplates: rarity['numTemplates'],
                        numAssets: rarity['numAssets'],
                    })
                    addedRarities.push(rarity['rarity'])
                }
            }
        }
    })

    return categoriesOptions
}

export const createRarities = (categories, includeDefault = true) => {
    const categoriesOptions = []
    const addedRarities = []

    if (!categories || !categories.length) return null

    if (includeDefault) categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['rarity']) {
            if (!addedRarities.includes(element['rarity'])) {
                categoriesOptions.push({
                    value: element['rarity'],
                    label: element['rarity'],
                })
                addedRarities.push(element['rarity'])
            }
        }
    })

    return categoriesOptions
}

export const createVariants = (categories, variant = 'Variant') => {
    const categoriesOptions = []
    const addedVariants = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: null, label: 'All' })

    categories.forEach((element) => {
        if (element['variant']) {
            if (!addedVariants.includes(element['variant'])) {
                categoriesOptions.push({
                    value: element['variant'],
                    label: element['variant'],
                })
                addedVariants.push(element['variant'])
            }
        }
    })

    return categoriesOptions
}

export const createColors = (categories, color = 'Color') => {
    const categoriesOptions = []
    const addedColors = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['color']) {
            if (!addedColors.includes(element['color'])) {
                categoriesOptions.push({
                    value: element['color'],
                    label: element['color'],
                })
                addedColors.push(element['color'])
            }
        }
    })

    return categoriesOptions
}

export const createAttributes = (categories, key, labelName) => {
    const categoriesOptions = []
    const addedBorder = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element[key] === 0 || element[key]) {
            if (!addedBorder.includes(element[key])) {
                categoriesOptions.push({
                    value: '' + element[key],
                    label: '' + element[key],
                })
                addedBorder.push(element[key])
            }
        }
    })

    return categoriesOptions.sort(
        (a, b) => parseInt(a.value) - parseInt(b.value),
    )
}

export const createBorders = (categories, border = 'Border') => {
    const categoriesOptions = []
    const addedBorder = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['border'] === 0 || element['border']) {
            if (!addedBorder.includes(element['border'])) {
                categoriesOptions.push({
                    value: '' + element['border'],
                    label: '' + element['border'],
                })
                addedBorder.push(element['border'])
            }
        }
    })

    return categoriesOptions.sort(
        (a, b) => parseInt(a.value) - parseInt(b.value),
    )
}

export const createTypes = (categories, type = 'Type') => {
    const categoriesOptions = []
    const addedBorder = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['type']) {
            if (!addedBorder.includes(element['type'])) {
                categoriesOptions.push({
                    value: element['type'],
                    label: element['type'],
                })
                addedBorder.push(element['type'])
            }
        }
    })

    return categoriesOptions.sort(
        (a, b) => parseInt(a.value) - parseInt(b.value),
    )
}

export const createNames = (categories, name = 'Name') => {
    const categoriesOptions = []
    const addedBorder = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories
        .sort((a, b) => ('' + a.name).localeCompare(b.name))
        .forEach((element) => {
            if (element['name']) {
                if (!addedBorder.includes(element['name'])) {
                    categoriesOptions.push({
                        value: element['name'],
                        label: element['name'],
                    })
                    addedBorder.push(element['name'])
                }
            }
        })

    return categoriesOptions.sort(
        (a, b) => parseInt(a.value) - parseInt(b.value),
    )
}

export const getAttributeNames = async (author, schema, signal) => {
    return get(
        `attribute-names/${author}${schema ? `/${schema}` : ''}`,
        undefined,
        {
            signal,
        },
    )
}

export const getAetherAuthors = async (signal) =>
    get('aether-authors', undefined, {
        signal,
    })

export const getUsdWax = async () => get('usd-wax')

export const getCraftingAuthors = async (signal) =>
    get('crafting-authors', undefined, {
        signal,
    })

export const getPFPAuthors = async (signal) =>
    get('pfp-authors', undefined, {
        signal,
    })

export const getDefaultAttrNames = () => {
    return {
        variant: 'Variant',
        rarity: 'Rarity',
        number: 'Number',
        type: 'Type',
        color: 'Color',
        border: 'Border',
        attr7: '',
        attr8: '',
        attr9: '',
        attr10: '',
    }
}

export const getVariantName = (t, author, schema) => {
    if (author === 'stf.capcom' || author === 'robotech') return 'Power Score'

    if (author === 'girlpinupart') return t('search.artist')

    if (author === 'bitcoinorign') return 'Moment'

    if (author === 'alien.worlds') {
        if (schema === 'land.worlds' || schema === 'Alien Worlds Land')
            return 'Planet'
        else if (!schema) return 'Shine / Planet'
        else return 'Shine'
    }

    if (author === 'armiesxpower') {
        return 'Mission'
    }

    if (author === 'upliftworld') {
        return 'World'
    }

    if (author === 'kogsofficial') {
        return 'Series'
    }

    if (author === 'darkcountryh') {
        if (schema === 'heroes') {
            return t('search.bagspace')
        }
        if (schema === 'cards') {
            return t('search.type')
        }

        return `${t('search.type')}/${t('search.bagspace')}`
    }

    if (author === 'cardinalland') {
        return 'Continent'
    }

    if (author === 'greenrabbit') {
        return 'Class'
    }

    return t('asset.variant')
}

export const getHash = (s) => {
    return s.split('').reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
    }, 0)
}

export const openSearchPopup = (
    searchType,
    asset,
    dispatch,
    callback,
    includeTubes = true,
    includeDrops = false,
) => {
    dispatch({
        type: 'SET_ACTION_SEARCH',
        payload: {
            actionSearch: 'search',
            asset: asset,
            includeTubes: includeTubes,
            searchType: searchType,
            searchID: getHash(moment.now().toString()),
            includeDrops: includeDrops,
        },
    })
    dispatch({
        type: 'SET_CALLBACK',
        payload: callback ? callback : () => {},
    })
}

export const formatDiffFloor = (diff, market, target) => {
    return (
        <Tooltip
            title={
                'waxbuyoffers' !== market
                    ? diff > 0
                        ? `Price is ${formatWAX(diff)} above ${target}`
                        : `Price at ${target}`
                    : diff > 0
                    ? `Offer is ${formatWAX(diff)} above ${target}`
                    : `Offer is at ${target}`
            }
        >
            <div
                className={cn(
                    {
                        'text-negative':
                            'waxbuyoffers' !== market ? diff > 0 : diff < 0,
                    },
                    {
                        'text-positive':
                            'waxbuyoffers' !== market ? diff <= 0 : diff > 0,
                    },
                )}
            >
                &nbsp;
                {` (${diff > 0 ? '+' : ''}${formatWAX(diff)})`}
            </div>
        </Tooltip>
    )
}

export const formatDiff = (diff, market, target) => {
    return diff || diff === 0 ? (
        <Tooltip
            title={
                'waxbuyoffers' !== market
                    ? diff > 0
                        ? `Price is ${formatPercentageFull(
                              diff,
                          )} above ${target}`
                        : `Price is ${formatPercentageFull(
                              diff,
                          )} below ${target}`
                    : diff > 0
                    ? `Offer is ${formatPercentageFull(diff)} above ${target}`
                    : `Offer is ${formatPercentageFull(diff)} below ${target}`
            }
        >
            <div
                className={cn(
                    {
                        'text-negative':
                            'waxbuyoffers' !== market ? diff > 0 : diff < 0,
                    },
                    {
                        'text-positive':
                            'waxbuyoffers' !== market ? diff < 0 : diff > 0,
                    },
                )}
            >
                &nbsp;
                {diff || diff === 0
                    ? ` (${diff > 0 ? '+' : ''}${formatPercentage(diff)})`
                    : ''}
            </div>
        </Tooltip>
    ) : (
        ''
    )
}

export const getViewer = async (model, poster) => {
    let viewer = ''
    if (typeof window !== 'undefined')
        viewer = await import('@google/model-viewer').then(() => {
            const src = model.replace('ipfs.hivebp.io', 'ipfs.io')
            const element = (
                <div className={'w-100 h-100'}>
                    <model-viewer
                        className={cn(
                            'm-auto max-h-64 md:max-h-[70vh] md:py-[2.5vh] max-w-full',
                        )}
                        src={src}
                        camera-controls
                        poster={poster}
                        autoplay
                        shadow-intensity="1"
                        touch-action="pan-y"
                    />
                </div>
            )

            return element
        })

    return viewer
}

export const getSellingElement = (str) => {
    const parts = str.split('|')
    return (
        <div>
            {parts[0]}
            <b>{parts[1]}</b>
            {parts[2]}
            <b>{parts[3]}</b>
        </div>
    )
}

export const formatNumber = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1000000000)
        return `${parseInt(`${Math.round(number / 10000000.0)}`) / 10.0}B`
    else if (Math.abs(number) >= 1000000)
        return `${parseInt(`${Math.round(number / 100000.0)}`) / 10.0}M`
    else if (Math.abs(number) >= 10000)
        return `${parseInt(`${Math.round(number / 100.0)}`) / 10.0}K`
    else return Math.round(value * 100) / 100
}

export const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
        return
    }
    navigator.clipboard.writeText(text).then(
        function () {
            console.error('Async: Copying to clipboard was successful!')
        },
        function (err) {
            console.error('Async: Could not copy text: ', err)
        },
    )
}

export const formatRAM = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1024 * 1024 * 1024)
        return `${
            parseInt(
                `${Math.round((number / (1024.0 * 1024.0 * 1024.0)) * 10)}`,
            ) / 10.0
        }GB`
    else if (Math.abs(number) >= 1024 * 1024)
        return `${
            parseInt(`${Math.round((number / (1024.0 * 1024.0)) * 10)}`) / 10.0
        }MB`
    else if (Math.abs(number) >= 1024)
        return `${parseInt(`${Math.round((number / 1024.0) * 10)}`) / 10.0}KB`
    else return `${Math.round(number * 100) / 100} Byte`
}

export const formatRateNumber = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1000000000)
        return `${Math.round(
            parseInt(`${Math.round(number / 10000000.0)}`) / 10.0,
        )}B`
    else if (Math.abs(number) >= 1000000)
        return `${Math.round(
            parseInt(`${Math.round(number / 100000.0)}`) / 10.0,
        )}M`
    else if (Math.abs(number) >= 10000)
        return `${Math.round(
            parseInt(`${Math.round(number / 100.0)}`) / 10.0,
        )}K`
    else if (Math.abs(number) >= 100)
        return parseInt(`${Math.round(value * 100) / 100}`)
    else return Math.round(value * 100) / 100
}

export const validJson = (str) => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export const formatUSD = (usd) => {
    return usd || usd === 0
        ? `$${usd.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
          })}`
        : ''
}

export const rgbToHex = (r, g, b) => {
    return (
        '#' +
        [r, g, b, 128]
            .map((x) => {
                const hex = x.toString(16)
                return hex.length === 1 ? '0' + hex : hex
            })
            .join('')
            .toUpperCase()
    )
}

export const formatWAX = (wax) => {
    return wax || wax === 0
        ? `${wax.toLocaleString(undefined, {
              maximumFractionDigits: 8,
              minimumFractionDigits: 0,
          })} WAX`
        : ''
}

export const formatWAXS = (wax) => {
    return wax || wax === 0
        ? `${wax.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
          })} WAX`
        : ''
}

export const formatWAXInt = (wax) => {
    return wax || wax === 0
        ? `${wax.toLocaleString(undefined, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
          })} WAX`
        : ''
}

export const formatCurrency = (currency, decimals, useGrouping, symbol) => {
    return (
        currency.toLocaleString(undefined, {
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals,
            useGrouping: useGrouping,
        }) +
        ' ' +
        symbol
    )
}

export const formatHoney = (honey) => {
    return `${honey.toLocaleString(undefined, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
    })} HONEY`
}

export const formatToken = (amount, token, digits) => {
    return `${amount.toLocaleString(undefined, {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
    })} ${token}`
}

export const formatUSDM = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1000000000)
        return `$${parseInt(`${Math.round(number / 10000000.0)}`) / 10.0}B`
    else if (Math.abs(number) >= 1000000)
        return `$${parseInt(`${Math.round(number / 100000.0)}`) / 10.0}M`
    else if (Math.abs(number) >= 10000)
        return `$${parseInt(`${Math.round(number / 100.0)}`) / 10.0}K`
    else
        return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        })}`
}

export const formatDate = (timestamp) => {
    const utc = moment.utc(timestamp).toDate()
    return moment(utc).local().format('YYYY-MM-DD HH:mm:ss')
}

export const formatNumberS = (value) => {
    return Math.round(value * 10000) / 10000.0
}

export const formatNumber1K = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1000000000)
        return `${parseInt(`${Math.round(number / 10000000.0)}`) / 10.0}B`
    else if (Math.abs(number) >= 1000000)
        return `${parseInt(`${Math.round(number / 100000.0)}`) / 10.0}M`
    else if (Math.abs(number) >= 1000)
        return `${parseInt(`${Math.round(number / 100.0)}`) / 10.0}K`
    else if (Math.abs(number) >= 10) return Math.round(value)
    else return value
}

export const formatPercentage = (value) => {
    const number = Math.round(parseFloat(value) * 10000.0) / 100.0
    return `${formatNumber1K(number)}%`
}

export const formatPercentageFull = (value) => {
    const number = Math.round(parseFloat(value) * 10000.0) / 100.0
    return `${formatNumber(number)}%`
}

export const formatTransaction = (tx) => {
    return tx.substring(0, 20) + '…'
}

export const formatGrowth = (value, usd = 0, includeWAX = false) => {
    const number = parseInt(value)
    if (number > 0)
        return (
            <div className="flex items-center">
                <svg
                    className="fill-primary"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M9 7v6H7V7H4l4-4 4 4z" fillRule="evenodd"></path>
                </svg>
                <div>
                    {includeWAX
                        ? `${formatNumber(value)} WAX`
                        : formatNumber(value)}
                    {usd ? (
                        <span className="text-xs">/${formatNumber(usd)}</span>
                    ) : null}
                </div>
            </div>
        )
    if (number < 1000000)
        return (
            <div className="flex items-center">
                <svg
                    className="fill-neutral"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M9 9h3l-4 4-4-4h3V3h2z" fillRule="evenodd"></path>
                </svg>
                <div>
                    {includeWAX
                        ? `${formatNumber(value)} WAX`
                        : formatNumber(value)}
                    {usd ? (
                        <span className="text-xs">/${formatNumber(usd)}</span>
                    ) : null}
                </div>
            </div>
        )
    else return '-'
}

export const formatMarket = (market) => {
    return market
}

export const getWaxStashAuthor = (author) => {
    if (author === 'dopestickers') {
        return 'boxmaze'
    } else if (author === 'cryptotwerpz') {
        return 'twerpz'
    }

    return 'gpk'
}
