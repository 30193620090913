import { FormControlLabel, InputAdornment, Switch } from '@mui/material'
import { Input, Card } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'

import { AmountSlider } from '../amountslider/AmountSlider'
import MainButton from '../common/util/input/MainButton'
import { floatRegEx } from '../editor/EditorComponents'
import { get } from '../helpers/Api'
import { formatNumber, formatWAX, sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import cn from 'classnames'
import { useSharedState } from '../waxplorer/Waxplorer'

const loadPriceInfo = async (args) => await get('price-info/' + args.assetId)
const loadPriceInfoPacks = async (args) =>
    await get('price-info-fts/' + args.symbol)

function SellPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const {
        assetId,
        schema,
        number,
        rarity,
        variant,
        author,
        name,
        image,
        standard,
        symbol,
        amount,
    } = asset

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const [allAssets, setAllAssets] = useState(null)

    const callBack = props['callBack']

    const getMarket = (asset) => {
        let standard = asset['standard']

        if (!standard && asset['assets']) {
            standard = asset['assets'][0]['standard']
        }

        if (standard === 'atomicassets') return 'atomicmarket'

        if (standard === 'simpleassets' && !symbol) return 'waxplorercom'

        return 'nft.hive'
    }

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [collectionFee, setCollectionFee] = useState(null)
    const [sellPrice, setSellPrice] = useState(0)
    const [sellAmount, setSellAmount] = useState(1)
    const [amountToSell, setAmountToSell] = useState(0)
    const [sellMultiple, setSellMultiple] = useState(false)
    const [currentDisplayAction, setCurrentDisplayAction] = useState(0)

    const LoadPriceInfo = (assetId) => {
        const { data, error, isLoading } = useAsync({
            promiseFn: loadPriceInfo,
            assetId: assetId,
        })
        if (data) {
            data.isLoading = false
            return data
        }
        return { priceInfo: {}, isLoading: true }
    }

    const LoadPriceInfoPacks = (symbol) => {
        const { data, error, isLoading } = useAsync({
            promiseFn: loadPriceInfoPacks,
            symbol: symbol,
        })
        if (data) {
            data.isLoading = false
            return data
        }
        return { priceInfo: {}, isLoading: true }
    }

    const getCollectionFee = (author) => {
        get('collection-fee/' + author).then((result) =>
            setCollectionFee(result['fee']),
        )
    }

    const getAllOwned = (assetId) => {
        get('get-all-owned/' + assetId).then((result) => setAllAssets(result))
    }

    useEffect(() => {
        if (assetId && !symbol) {
            getAllOwned(assetId)
        }
    }, [assetId])

    useEffect(() => {
        getCollectionFee(author)
    }, [])

    const priceInfo = symbol
        ? LoadPriceInfoPacks(symbol)
        : LoadPriceInfo(assetId)

    const maxActions = 10

    const multiSell = async () => {
        if (!sellPrice) return

        const quantity = parseFloat(sellPrice)

        setIsLoading(true)

        let currentAction = 0

        const sold = {}
        const errors = {}

        const assetsToSell = {}
        assetsToSell[asset['assetId']] = asset
        let index = 0
        while (Object.keys(assetsToSell).length < amountToSell) {
            if (allAssets[index].assetId !== asset['assetId']) {
                assetsToSell[allAssets[index].assetId] = allAssets[index]
            }
            index++
        }

        let sellError = null

        let marketplace = getMarket(asset)

        while (currentAction < amountToSell / maxActions) {
            const start = currentAction * maxActions
            const end = start + maxActions
            const actions = [getBoostAction(activeUser)]

            Object.keys(assetsToSell)
                .sort((a, b) => parseFloat(a.assetId) - parseFloat(b.assetId))
                .slice(start, end)
                .map((assetId) => {
                    const asset = assetsToSell[assetId]

                    marketplace = getMarket(asset)

                    if (marketplace === 'atomicmarket') {
                        actions.push({
                            account: 'atomicmarket',
                            name: 'announcesale',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                seller: userName,
                                maker_marketplace:
                                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                        ? 'nfthiveboost'
                                        : 'nft.hive',
                                settlement_symbol: '8,WAX',
                                asset_ids: [
                                    asset.aAssetId
                                        ? asset.aAssetId
                                        : asset.assetId,
                                ],
                                listing_price: quantity.toFixed(8) + ' WAX',
                            },
                        })
                        actions.push({
                            account: 'atomicassets',
                            name: 'createoffer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                sender: userName,
                                recipient: 'atomicmarket',
                                sender_asset_ids: [
                                    asset.aAssetId
                                        ? asset.aAssetId
                                        : asset.assetId,
                                ],
                                recipient_asset_ids: [],
                                memo: 'sale',
                            },
                        })
                    } else {
                        actions.push({
                            account: 'waxplorercom',
                            name: 'announcesale',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                seller: userName,
                                asset_ids: [assetId],
                                listing_price: quantity.toFixed(8) + ' WAX',
                            },
                        })
                        actions.push({
                            account: 'simpleassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                to: 'waxplorercom',
                                assetids: [assetId],
                                memo: 'sale',
                            },
                        })
                    }
                })

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                Object.keys(assetsToSell)
                    .sort((a, b) => parseInt(a.assetId) - parseInt(b.assetId))
                    .slice(start, end)
                    .map((assetId) => {
                        marketplace = getMarket(assetsToSell[assetId])
                        sold[assetId] = assetsToSell[assetId]
                        sold[assetId]['price'] = quantity
                        sold[assetId]['market'] = marketplace
                        sold[assetId]['transactionId'] =
                            result?.response?.transaction_id
                    })
            } catch (e) {
                Object.keys(assetsToSell)
                    .sort((a, b) => parseInt(a.assetId) - parseInt(b.assetId))
                    .slice(start, end)
                    .map((assetId) => {
                        errors[assetId] = {
                            message: e.message,
                            summaryId: assetsToSell[assetId].summaryId,
                        }
                    })
                console.error(e)
                setError(e.message)
                sellError = e
            } finally {
                currentAction += 1
                setCurrentDisplayAction(currentAction)
                if (currentAction < amountToSell / maxActions) {
                    sleep(2000)
                }
            }
        }
        setIsLoading(false)
        if (!sellError) {
            callBack({ sold: sold, market: marketplace, offer: quantity })
            closeCallBack()
        }
    }

    const sell = async () => {
        if (!sellPrice) return
        const quantity = parseFloat(sellPrice)
        let { assetId, aAssetId } = asset
        if (!assetId && asset['assets']) {
            assetId = asset['assets'][0]['assetId']
            aAssetId = asset['assets'][0]['aAssetId']
        }

        closeCallBack()
        setIsLoading(true)

        const marketplace = getMarket(asset)

        try {
            let result
            switch (marketplace) {
                case 'waxplorercom':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'waxplorercom',
                                    name: 'announcesale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        seller: userName,
                                        asset_ids: [assetId],
                                        listing_price:
                                            quantity.toFixed(8) + ' WAX',
                                    },
                                },
                                {
                                    account: 'simpleassets',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        to: 'waxplorercom',
                                        assetids: [assetId],
                                        memo: 'sale',
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'nft.hive':
                    const actions = [getBoostAction(activeUser)]

                    for (let i = 0; i < sellAmount; ++i) {
                        actions.push({
                            account: standard,
                            name:
                                standard === 'simpleassets'
                                    ? 'transferf'
                                    : 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data:
                                standard === 'simpleassets'
                                    ? {
                                          from: userName,
                                          to: 'nft.hive',
                                          author: 'a.rplanet',
                                          quantity:
                                              (1.0).toFixed(4) + ' ' + symbol,
                                          memo: 'NFTHive List',
                                      }
                                    : {
                                          from: userName,
                                          to: 'nft.hive',
                                          symbol: symbol,
                                          quantity: 1 + ' ' + symbol,
                                          memo: 'NFTHive List',
                                      },
                        })
                        actions.push({
                            account: 'nft.hive',
                            name: 'createsale',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data:
                                standard === 'simpleassets'
                                    ? {
                                          seller: userName,
                                          quantity:
                                              (1.0).toFixed(4) + ' ' + symbol,
                                          listing_price:
                                              quantity.toFixed(8) + ' WAX',
                                      }
                                    : {
                                          seller: userName,
                                          quantity: 1 + ' ' + symbol,
                                          listing_price:
                                              quantity.toFixed(8) + ' WAX',
                                      },
                        })
                    }
                    result = await activeUser.session.transact(
                        {
                            actions: actions,
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'atomicmarket':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'atomicmarket',
                                    name: 'announcesale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        seller: userName,
                                        maker_marketplace:
                                            process.env.NEXT_PUBLIC_TESTNET ===
                                            'TRUE'
                                                ? 'nfthiveboost'
                                                : 'nft.hive',
                                        settlement_symbol: '8,WAX',
                                        asset_ids: [aAssetId],
                                        listing_price:
                                            quantity.toFixed(8) + ' WAX',
                                    },
                                },
                                {
                                    account: 'atomicassets',
                                    name: 'createoffer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        sender: userName,
                                        recipient: 'atomicmarket',
                                        sender_asset_ids: [aAssetId],
                                        recipient_asset_ids: [],
                                        memo: 'sale',
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
            }
            callBack({
                sold: true,
                market: marketplace,
                offer: quantity,
                transactionId: result?.response?.transaction_id,
            })
        } catch (e) {
            callBack({
                sold: false,
                market: null,
                offer: quantity,
                error: e.message ? e.message : e,
            })
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    const cancel = () => {
        callBack({ sold: false, market: null, offer: 0 })
        closeCallBack()
    }

    const changePrice = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) setSellPrice(val)
    }

    const changeAmount = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setSellAmount(Math.min(val, amount))
    }

    const changeAmountToSell = (e) => {
        setAmountToSell(e.target.value)
    }

    const increaseAmountToSell = () => {
        setAmountToSell(Math.min(amountToSell + 1, allAssets.length))
    }

    const decreaseAmountToSell = () => {
        setAmountToSell(Math.max(amountToSell - 1, 0))
    }

    let cut = sellPrice - 0.03 * sellPrice
    if (collectionFee) cut = cut - collectionFee * sellPrice

    const changeSellMultiple = (e) => {
        setSellMultiple(e.target.checked)
        setAmountToSell(0)
    }

    return (
        <Popup title={name} cancel={cancel} image={image} asset={asset}>
            <div>
                <Card className="p-4 mb-4 ">
                    <div className="grid grid-cols-2 ">
                        <div className="col-span-1 ">
                            <div className="flex flex-col gap-2 ">
                                <b>{t('asset.schema')}:</b>
                                {number && variant ? (
                                    <b>{t('asset.card')}:</b>
                                ) : null}
                                {rarity ? <b>{t('asset.rarity')}:</b> : null}
                                <b>{t('asset.author')}:</b>
                                <b>{t('asset.lowest_available_price')}:</b>
                                <b>{t('asset.average_bought')}:</b>
                                <b>{t('asset.last_sold')}:</b>
                            </div>
                        </div>
                        <div className="col-span-1 ">
                            <div className="flex flex-col gap-2 ">
                                <div>{schema}</div>
                                {number && variant ? (
                                    <div>
                                        {number}
                                        {variant}
                                    </div>
                                ) : null}
                                {rarity ? <div>div{rarity}</div> : null}
                                <div>{author}</div>
                                <div>
                                    {priceInfo['isLoading'] ? (
                                        'Loading...'
                                    ) : priceInfo['lowest'] ? (
                                        <span
                                            className="cursor-pointer text-primary hover:underline"
                                            onClick={() =>
                                                setSellPrice(
                                                    priceInfo['lowest'],
                                                )
                                            }
                                        >
                                            {formatNumber(priceInfo['lowest'])}{' '}
                                            WAX{' '}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                    {priceInfo['isLoading'] ? (
                                        ''
                                    ) : (
                                        <span className="usd">
                                            (
                                            {priceInfo['lowest_usd']
                                                ? `$${formatNumber(
                                                      priceInfo['lowest_usd'],
                                                  )}`
                                                : '-'}
                                            )
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {priceInfo['isLoading'] ? (
                                        'Loading...'
                                    ) : priceInfo['average'] ? (
                                        <span
                                            className="cursor-pointer text-primary hover:underline"
                                            onClick={() =>
                                                setSellPrice(
                                                    priceInfo['average'],
                                                )
                                            }
                                        >
                                            {Math.round(
                                                priceInfo['average'] * 100,
                                            ) / 100}{' '}
                                            WAX{' '}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                    {priceInfo['isLoading'] ? (
                                        ''
                                    ) : priceInfo['average_usd'] ? (
                                        <span className="usd">
                                            ($
                                            {formatNumber(
                                                priceInfo['average_usd'],
                                            )}
                                            )
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                                <div>
                                    {priceInfo['isLoading'] ? (
                                        'Loading...'
                                    ) : priceInfo['last_sold'] ? (
                                        <span
                                            className="cursor-pointer text-primary hover:underline"
                                            onClick={() =>
                                                setSellPrice(
                                                    priceInfo['last_sold'],
                                                )
                                            }
                                        >
                                            {Math.round(
                                                priceInfo['last_sold'] * 100,
                                            ) / 100}{' '}
                                            WAX{' '}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                    {priceInfo['isLoading'] ? (
                                        ''
                                    ) : priceInfo['last_sold_usd'] ? (
                                        <span className="usd">
                                            ($
                                            {formatNumber(
                                                priceInfo['last_sold_usd'],
                                            )}
                                            )
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <Card className="p-4 mb-4 ">
                {symbol ? (
                    <div className="mx-auto mb-4 text-sm font-bold 2xl:text-xl">
                        {t(
                            'popups.are_you_sure_you_want_to_sell_x_assets_for_amount',
                            {
                                x: sellAmount,
                                asset: name,
                                amount: `${formatNumber(
                                    sellAmount * sellPrice,
                                )} WAX / $${
                                    !priceInfo || priceInfo['isLoading']
                                        ? '...'
                                        : formatNumber(
                                              sellAmount *
                                                  sellPrice *
                                                  priceInfo['usd_rate'],
                                          )
                                }`,
                            },
                        )}
                    </div>
                ) : (
                    <div className="mx-auto mb-4 text-sm font-bold 2xl:text-xl">
                        {sellMultiple
                            ? t(
                                  'popups.are_you_sure_you_want_to_sell_x_assets_for_amount_y_each',
                                  {
                                      x: amountToSell,
                                      asset: name,
                                      amount: `${formatWAX(
                                          amountToSell * sellPrice,
                                      )} / $${
                                          !priceInfo || priceInfo['isLoading']
                                              ? '...'
                                              : formatNumber(
                                                    amountToSell *
                                                        sellPrice *
                                                        priceInfo['usd_rate'],
                                                )
                                      }`,
                                      y: `${formatWAX(sellPrice)} / $${
                                          !priceInfo || priceInfo['isLoading']
                                              ? '...'
                                              : formatNumber(
                                                    sellPrice *
                                                        priceInfo['usd_rate'],
                                                )
                                      }`,
                                  },
                              )
                            : t(
                                  'popups.are_you_sure_you_want_to_sell_asset_for_amount',
                                  {
                                      asset: name,
                                      amount: `${formatNumber(
                                          sellPrice,
                                      )} WAX / $${
                                          !priceInfo || priceInfo['isLoading']
                                              ? '...'
                                              : formatNumber(
                                                    sellPrice *
                                                        priceInfo['usd_rate'],
                                                )
                                      }`,
                                  },
                              )}
                    </div>
                )}
            </Card>
            <div className={'relative'}>
                {error ? (
                    <ErrorMessage error={error} onClick={() => setError('')} />
                ) : (
                    ''
                )}
                {allAssets && allAssets.length > 1 && (
                    <Card className="p-4 mb-4 ">
                        <div className="grid w-full grid-cols-2 mb-2">
                            <FormControlLabel
                                fullWidth={true}
                                size="large"
                                labelPlacement="top"
                                label={`You own multiple ${name}. Sell Multiple Assets?`}
                                control={
                                    <Switch
                                        checked={sellMultiple}
                                        onChange={changeSellMultiple}
                                        disabled={allAssets.length < 2}
                                    />
                                }
                            />
                            {sellMultiple && (
                                <AmountSlider
                                    title={t('popups.amount_to_sell')}
                                    disabled={!sellMultiple}
                                    currentAmount={amountToSell}
                                    changeAmount={changeAmountToSell}
                                    increaseAmount={increaseAmountToSell}
                                    decreaseAmount={decreaseAmountToSell}
                                    max={allAssets.length}
                                />
                            )}
                        </div>
                    </Card>
                )}
                <div className="grid grid-cols-2 gap-2 mb-4">
                    <Input
                        variant="faded"
                        label={
                            symbol || amountToSell
                                ? t('asset.price_for_one')
                                : t('asset.price')
                        }
                        onChange={changePrice}
                        value={sellPrice ? sellPrice : ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    WAX
                                </InputAdornment>
                            ),
                        }}
                        error={!floatRegEx.test(sellAmount)}
                    />
                    {symbol ? (
                        <div className={'ml-2'}>
                            <Input
                                variant="faded"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                label={t('asset.amount')}
                                onChange={changeAmount}
                                value={sellAmount ? sellAmount : ''}
                                error={!sellAmount}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                <div
                    className={
                        'w-full xl:w-1/2 flex mt-4 xl:mt-0 gap-4 justify-center lg:justify-start'
                    }
                >
                    <MainButton
                        size="medium"
                        onClick={cancel}
                        className={
                            'w-32 max-w-32 bg-red-500 lg:w-26 lg:max-w-26 2xl:w-32 2xl:max-w-32'
                        }
                        padding={'pl-0 '}
                    >
                        {t('asset.cancel')}
                    </MainButton>
                    <MainButton
                        size="medium"
                        onClick={sellMultiple ? multiSell : sell}
                        disabled={sellMultiple && amountToSell === 0}
                        className={
                            'w-32 max-w-32 lg:w-26 lg:max-w-26 2xl:w-32 2xl:max-w-32'
                        }
                        padding={'pl-0 xl:pl-4'}
                    >
                        {t('asset.sell')}
                    </MainButton>
                </div>
            </div>
            <div className={'h-20'}>
                {collectionFee || collectionFee === 0 ? (
                    <div className="grid grid-cols-2 gap-2 pt-4 mt-4 ml-auto text-sm leading-3 md:grid-cols-4 text-start">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold ">
                                {t('popups.market_fee')}:
                            </div>
                            <div>2%</div>
                        </div>
                        <div className="flex flex-col gap-2 ">
                            <div className="font-semibold ">
                                {t('popups.defi_fee')}:
                            </div>
                            <div>1%</div>
                        </div>
                        <div className="flex flex-col gap-2 ">
                            <div className="font-semibold ">
                                {t('popups.collection_fee')}:
                            </div>
                            <div>{formatNumber(collectionFee * 100)}%</div>
                            {/*  {`${t('popups.collection_fee')}: ${
                            collectionFee * 100
                        }%`} */}
                        </div>
                        <div className="flex flex-col gap-2 ">
                            <div className="font-semibold ">
                                {t('popups.your_cut')}:
                            </div>
                            <div>
                                {cut}{' '}
                                <span className=" text-1xs xs1:text-[14px]">
                                    WAX
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <LoadingIndicator />
                )}
            </div>

            {isLoading ? (
                sellMultiple ? (
                    <PopupLoadingIndicator
                        text={t('popups.signing_multiple_transactions', {
                            x: amountToSell,
                            y: Math.ceil(amountToSell / maxActions),
                        })}
                        isLoading={isLoading && sellMultiple}
                    />
                ) : (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                )
            ) : (
                ''
            )}
        </Popup>
    )
}

export default SellPopup
