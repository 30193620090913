import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CheckIndicator from '../checkindicator/CheckIndicator'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { Input } from '@nextui-org/react'
import { useSharedState } from '../waxplorer/Waxplorer'

function TradePopup(props) {
    const assets = props['assets']

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [memo, setMemo] = useState('')

    const myAssets = assets[userName]

    const [transferred, setTransferred] = useState(false)

    let partner = ''

    Object.keys(assets).map((trader) => {
        if (trader !== userName) {
            partner = trader
        }
    })

    const partnerAssets = assets[partner]

    const trade = async () => {
        setIsLoading(true)

        let transferError = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'atomicassets',
                            name: 'createoffer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                sender: userName,
                                recipient: partner,
                                sender_asset_ids: myAssets
                                    ? Object.keys(myAssets)
                                    : [],
                                recipient_asset_ids: partnerAssets
                                    ? Object.keys(partnerAssets)
                                    : [],
                                memo: memo,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            setTransferred(true)
        } catch (e) {
            console.error(e)
            setError(e.message)
            transferError = e
            callBack({
                transferred: false,
                error: transferError ? transferError.message : '',
            })
        }
        setIsLoading(false)
    }

    let transferInfo = t('trade.send_offer')

    const transferField = (
        <MainButton className="w-[80px] max-w-32" onClick={trade}>
            {transferInfo}
        </MainButton>
    )

    const cancel = () => {
        callBack({ transferred: transferred, error: error })
        closeCallBack()
    }

    const changeMemo = (e) => {
        const val = e.target.value
        setMemo(val)
    }

    return (
        <Popup title={t('trade.trade')} cancel={cancel}>
            <div className="p-2 ">
                {transferred ? <CheckIndicator /> : ''}
                {!transferred ? (
                    <div className="mx-auto mt-2 mb-4 text-lg font-bold text-center">
                        {t(
                            'popups.are_you_sure_you_want_to_trade_x_items_for_y_items_with_partner',
                            {
                                x: myAssets ? Object.keys(myAssets).length : 0,
                                y: partnerAssets
                                    ? Object.keys(partnerAssets).length
                                    : 0,
                                partner: partner,
                            },
                        )}
                    </div>
                ) : (
                    ''
                )}
                {error ? <ErrorMessage error={error} /> : ''}
                {!transferred ? (
                    <div className="relative flex flex-col items-center justify-center gap-2">
                        <div className="flex justify-center w-2/3">
                            <Input
                                variant="faded"
                                className={'SellInput Memo Large'}
                                type="text"
                                onChange={changeMemo}
                                value={memo ? memo : ''}
                                placeholder={t('asset.memo')}
                            />
                        </div>
                        <div className="flex justify-center gap-2 mt-4 ">
                            <MainButton
                                onClick={cancel}
                                className="bg-red-500 "
                            >
                                {t('asset.cancel')}
                            </MainButton>
                            {transferField}
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {isLoading ? (
                    <PopupLoadingIndicator
                        text={t('popups.loading_transaction')}
                        isLoading={isLoading}
                    />
                ) : (
                    ''
                )}
            </div>
        </Popup>
    )
}

export default TradePopup
