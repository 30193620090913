import {
    Alert,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import MainButton from '../common/util/input/MainButton'

// import { DatePicker } from '@nextui-org/react'
// import {
//     getLocalTimeZone,
//     today,
//     now,
//     parseAbsoluteToLocal,
//     toCalendarDateTime,
// } from '@internationalized/date'

function EditPackUnlockTimePopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const packId = props['packId']
    const collectionName = props['collectionName']

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const [unlockTime, setUnlockTime] = useState(
        props['packUnlockTime'] !== 0
            ? new Date(props['packUnlockTime'] * 1000)
            : new Date(),
    )
    const [use24hrFormat, setUse24hrFormat] = useState(false)
    const [dropStartDateEnabled, setDropStartDateEnabled] = useState(
        props['packUnlockTime'] !== 0,
    )

    const [error, setError] = useState(null)

    const update = async () => {
        setIsLoading(true)

        const updatePackUnlockTimeActions = [getBoostAction(activeUser)]

        const updatePackUnlockTimeData = {
            authorized_account: userName,
            collection_name: collectionName,
            pack_id: packId,
            unlock_time: !dropStartDateEnabled
                ? 0
                : unlockTime
                ? Math.floor(unlockTime.getTime() / 1000)
                : 0,
        }

        const updatePackUnlockTimeAction = {
            account: 'nfthivepacks',
            name: 'setpacktime',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: updatePackUnlockTimeData,
        }

        updatePackUnlockTimeActions.push(updatePackUnlockTimeAction)

        let updatePackUnlockTimeError = null
        let updatePackDisplayDataTransactionId = null

        try {
            const updatePackDisplayDataResult =
                await activeUser.session.transact(
                    {
                        actions: updatePackUnlockTimeActions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )

            updatePackDisplayDataTransactionId =
                updatePackDisplayDataResult?.response?.transaction_id
        } catch (e) {
            updatePackUnlockTimeError = e.message
            setError(e.message)
        }

        if (!updatePackUnlockTimeError) {
            closeCallBack()
            setIsLoading(false)
            callBack(
                !updatePackUnlockTimeError,
                updatePackUnlockTimeError ? [updatePackUnlockTimeError] : null,
                updatePackDisplayDataTransactionId,
            )
        }

        if (updatePackUnlockTimeError) {
            setIsLoading(false)
            callBack(
                false,
                [updatePackUnlockTimeError],
                updatePackDisplayDataTransactionId,
            )
        }
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    const isUnlockTimeInvalid = () => {
        if (dropStartDateEnabled && unlockTime < new Date())
            return 'Unlock time must be in the future'
    }

    const editUnlockTime = () => {
        return (
            <div
                className={cn(
                    'my-10 border rounded-2xl p-5',
                    isUnlockTimeInvalid() && 'border-red-600',
                )}
            >
                <div className="text-2xl mb-5">Set the Unlock Time</div>
                <div className="mb-3">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={dropStartDateEnabled}
                                    onChange={(e) =>
                                        setDropStartDateEnabled(
                                            e.target.checked,
                                        )
                                    }
                                />
                            }
                            label="Set Start Date and Time"
                        />
                    </FormGroup>
                </div>
                <Collapse in={dropStartDateEnabled}>
                    <div className="mb-5">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label="Start Date and Time (Your local timezone)"
                                value={unlockTime}
                                onChange={(e) => {
                                    setUnlockTime(new Date(e.setSeconds(0)))
                                }}
                                ampm={!use24hrFormat}
                                minDateTime={new Date()}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="my-5">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={use24hrFormat}
                                        onChange={() => {
                                            setUse24hrFormat(!use24hrFormat)
                                        }}
                                    />
                                }
                                label="Use 24-hour format"
                            />
                        </FormGroup>
                    </div>
                </Collapse>
                <Collapse in={isUnlockTimeInvalid()}>
                    <div className="mb-5">
                        <Alert severity="error">{isUnlockTimeInvalid()}</Alert>
                    </div>
                </Collapse>
            </div>
        )
    }

    return (
        <Popup title="Update Pack Unlock Time" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to update the Unlock Time of pack #{packId} of the{' '}
                {collectionName} collection?
            </div>
            <div>
                <div>{editUnlockTime()}</div>
            </div>
            {error ? (
                <div className="relative h-24" onClick={dismissError}>
                    <ErrorMessage error={error} />
                </div>
            ) : (
                <div className="flex flex-row justify-end">
                    <div className="mx-2">
                        <MainButton color="danger" onClick={cancel}>
                            Cancel
                        </MainButton>
                    </div>
                    <div className="mx-2">
                        <MainButton onClick={update}>Update Pack</MainButton>
                    </div>
                </div>
            )}

            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default EditPackUnlockTimePopup
