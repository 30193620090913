import TuneIcon from '@mui/icons-material/Tune'
import { FormControl, MenuItem, Select } from '@mui/material'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setCookie } from '../helpers/cookies'
import { Context } from '../waxplorer/Waxplorer'
import QuickSettingsItem from './QuickSettingsItem'
import { WharfKit } from '../waxplorer/WharfKit'

const apiMappings = {
    'https://api3.hivebp.io': 'NFTHive',
    'https://api2.hivebp.io': 'NFTHive 2',
    'https://api.hivebp.io': 'NFTHive 3',
    'https://api.wax.tgg.gg': 'TokenGamer',
    'https://api.waxsweden.org': 'WaxSweden',
    'https://wax.eosphere.io': 'EosSphere',
    'https://api.waxdaobp.io': 'WaxDAO',
    'https://api.waxeastern.cn': 'WaxEastern',
}

export default function QuickSettingsMobileDropDown({
    skipBuyConfirmation,
    changeSkipBuyConfirmation,
    playAnimations,
    changeAutoPlay,
    changeFilter,
    hasNotifications,
    handleChangeNotifications,
    userFilters,
}) {
    const { t } = useTranslation('common')
    const [isHovered, setIsHovered] = useState(false) // state to track hover
    const [state, dispatch] = useContext(Context)

    const fadeInOut = {
        hidden: { opacity: 0, y: 0 },
        visible: { opacity: 1, y: 0 },
    }

    const changeAPI = (e) => {
        dispatch({
            type: 'API',
            payload: e.target.value,
        })
        WharfKit.getInstance(e.target.value).setEndpoint(e.target.value)
        setCookie('nfthive-api', e.target.value)
    }

    return (
        <div className="relative block lg:hidden">
            <button
                onClick={() => setIsHovered(!isHovered)}
                className="lg:hidden  dark:!bg-zinc-900 bg-white hover:bg-zinc-200 hover:dark:!bg-zinc-800  h-full flex items-center justify-center rounded-md bg-blue-600 px-2 py-2 text-[16px] font-normal leading-6 text-[#FFC30B]"
            >
                <TuneIcon className="md:w-6 w-5 md:h-6 h-5" />
            </button>

            <button
                className={cn(
                    'font-semibold hidden lg:flex items-center px-3 py-3 rounded-md cursor-pointer',
                    {
                        'dark:!bg-zinc-900 bg-zinc-200': !isHovered,
                        'dark:!bg-zinc-800 bg-zinc-400': isHovered,
                    },
                )}
                aria-haspopup="true"
            >
                <TuneIcon className="h-6 w-6 text-[#FFC30B]" />
            </button>
            <AnimatePresence>
                {isHovered && (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={fadeInOut}
                        transition={{ duration: 0.1, ease: 'easeInOut' }}
                        className="absolute -right-12 lg:right-0 top-[48px] z-50 min-w-[300px]"
                    >
                        <div className="p-4 md:p-6 border dark:!border-zinc-800 border-zinc-200 dark:!bg-zinc-900 bg-white rounded-xl shadow-xl w-full">
                            <div className="relative z-10 space-y-2">
                                <QuickSettingsItem
                                    handleCheck={skipBuyConfirmation}
                                    handleOnChange={changeSkipBuyConfirmation}
                                    title={t('navigation.skip_confirmation')}
                                    description={t(
                                        'navigation.skip_confirmation_when_buy_nfts',
                                    )}
                                />

                                <QuickSettingsItem
                                    handleCheck={playAnimations}
                                    handleOnChange={changeAutoPlay}
                                    title={t('navigation.play_animations')}
                                    description={t(
                                        'navigation.auto_play_animations',
                                    )}
                                />

                                <QuickSettingsItem
                                    handleCheck={
                                        !userFilters ||
                                        !userFilters.includes(71)
                                    }
                                    handleOnChange={() => changeFilter(71)}
                                    title={t('navigation.show_nsfw_content')}
                                    description={t(
                                        'navigation.nsfw_filter_explanation',
                                    )}
                                />

                                <QuickSettingsItem
                                    handleCheck={
                                        !userFilters ||
                                        !userFilters.includes(72)
                                    }
                                    handleOnChange={() => changeFilter(72)}
                                    title={t('navigation.show_ai_content')}
                                    description={t(
                                        'navigation.ai_filter_explanation',
                                    )}
                                />

                                <QuickSettingsItem
                                    handleCheck={hasNotifications}
                                    handleOnChange={handleChangeNotifications}
                                    title={t('navigation.enable_notifications')}
                                    description={
                                        hasNotifications
                                            ? t(
                                                  'navigation.enable_notifications',
                                              )
                                            : t(
                                                  'navigation.disable_notifications',
                                              )
                                    }
                                />

                                <div className="pt-2">
                                    <div
                                        className={cn(
                                            'relative text-left text-[14px] md:text-[16px] mb-4',
                                            'dark:!text-neutral text-zinc-900 mr-auto',
                                        )}
                                    >
                                        {t('navigation.api_endpoint')}
                                    </div>

                                    <FormControl
                                        fullWidth
                                        size="small"
                                        id={'idk'}
                                    >
                                        <Select
                                            variant="outlined"
                                            // label="API Endpoint"
                                            value={state.api}
                                            onChange={changeAPI}
                                            id={'api_select'}
                                        >
                                            {Object.entries(apiMappings).map(
                                                ([key, value]) => (
                                                    <MenuItem
                                                        value={key}
                                                        id={'item_' + value}
                                                    >
                                                        {value}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}
