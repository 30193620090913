import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs) {
    return twMerge(clsx(inputs))
}

export function showSuccessToast(toast, description) {
    toast({ variant: 'success', title: 'Success', description })
}

export function showErrorToast(toast, description) {
    toast({ variant: 'destructive', title: 'Error', description })
}
