import cn from 'classnames'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const MarkDown = ({ text, className }) => {
    function LinkRenderer(props) {
        return (
            <a href={props.href} target="_blank" rel="noreferrer">
                {props.children}
            </a>
        )
    }

    function BreakRenderer(props) {
        return <div className={'break-all'}>{props.children}</div>
    }

    function LinkBreakRenderer(props) {
        return (
            <div className={'break-all'}>
                <a href={props.href} target="_blank" rel="noreferrer">
                    {props.children}
                </a>
            </div>
        )
    }

    return (
        <div
            className={cn(
                'block prose prose-base prose-primary',
                'prose-a:text-bluel prose-a:no-underline hover:prose-a:text-blue ',

                '-li:marker:font-bold',
                'prose-strong:font-bold',
                'prose-text:text-primary block',

                'prose-p:text-black prose-li:text-black prose-li:marker:text-black prose-strong:text-black  prose-headings:text-black',
                'dark:prose-p:!text-white dark:prose-li:!text-white dark:prose-li:marker:!text-white dark:prose-strong:!text-white dark:prose-headings:!text-white ',
                className,
            )}
        >
            <ReactMarkdown
                children={text ? text : ''}
                remarkPlugins={[remarkGfm]}
                components={{
                    a: LinkRenderer,
                    br: BreakRenderer,
                    'p.a': LinkBreakRenderer,
                }}
            />
        </div>
    )
}

export default MarkDown
