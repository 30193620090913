import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { DropDetailsEditor } from '../drops/DropDetailsEditor'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'
import MainButton from '../common/util/input/MainButton'
import { Input } from '@nextui-org/react'

function UpdateDropNameDescrPopup(props) {
    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const [editorOpen, setEditorOpen] = useState(false)

    const collectionName = props['collectionName']
    const drop = props['drop']
    const [dropName, setDropName] = useState(props['dropName'])
    const [dropDescription, setDropDescription] = useState(
        props['dropDescription'],
    )
    const [previewImg, setPreviewImg] = useState(props['previewImg'])

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            authorized_account: userName,
            drop_id: drop.dropId,
            display_data: JSON.stringify({
                name: dropName,
                description: dropDescription,
                preview_img: previewImg,
            }),
        }

        const action = {
            account: 'nfthivedrops',
            name: 'setdropdata',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser.requestPermission
                        ? activeUser.requestPermission
                        : 'active',
                },
            ],
            data: data,
        }

        actions.push(action)
        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result?.response?.transaction_id
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Change Drop Name and Description" cancel={cancel}>
            <div className="mb-4 text-xl font-bold">
                Do you want to update the name and description of Drop{' '}
                {drop.dropId} ({collectionName})?
            </div>
            <div>
                <DropDetailsEditor
                    dropName={dropName}
                    setDropName={setDropName}
                    editorOpen={editorOpen}
                    setEditorOpen={setEditorOpen}
                    dropDescription={dropDescription}
                    setDropDescription={setDropDescription}
                    previewImg={previewImg}
                    setPreviewImg={setPreviewImg}
                    isPfp={drop.isPFP}
                    title={t('drops.drop_details')}
                    nameTitle={t('drops.drop_name')}
                    descriptionTitle={t('drops.drop_description')}
                />
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-center gap-2">
                <div className="mx-2">
                    <MainButton className="bg-red-500 " onClick={cancel}>
                        Cancel
                    </MainButton>
                </div>
                <div className="mx-2">
                    <MainButton onClick={create}>
                        {t('drops.update_drop')}
                    </MainButton>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default UpdateDropNameDescrPopup
