import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton, Slider } from '@mui/material'
import React from 'react'

export const AmountSlider = ({
    title,
    max,
    currentAmount,
    disabled,
    changeAmount,
    decreaseAmount,
    increaseAmount,
}) => {
    return (
        <>
            <div>{title}</div>
            <div className={'flex w-full justify-evenly'}>
                <div className={'w-1/10 flex justify-center m-auto'}>
                    <IconButton
                        aria-label="previous"
                        size="small"
                        onClick={decreaseAmount}
                    >
                        <ArrowBackIosIcon fontSize="inherit" />
                    </IconButton>
                </div>
                <div className={'w-4/5 m-auto'}>
                    <Slider
                        valueLabelDisplay="auto"
                        min={0}
                        max={max}
                        value={currentAmount}
                        disabled={disabled}
                        onChange={changeAmount}
                    />
                </div>
                <div className={'w-1/10 flex justify-center m-auto'}>
                    <IconButton
                        aria-label="next"
                        size="small"
                        onClick={increaseAmount}
                    >
                        <ArrowForwardIosIcon fontSize="inherit" />
                    </IconButton>
                </div>
            </div>
        </>
    )
}
