import { Tooltip } from '@mui/material'
import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../helpers/FormatLinks'

export const RankInfo = ({ rarityScore, rank }) => {
    const { t } = useTranslation('common')

    return (
        <div
            className={
                'absolute block bg-mint rounded-lg p-1 right-2 -bottom-4'
            }
        >
            {rank ? (
                <Tooltip
                    title={`${t('ranking.rank')} #${rank}\t${t(
                        'ranking.rarity_score',
                    )}: ${formatNumber(rarityScore)}`}
                >
                    <div
                        className={cn(
                            'text-sm text-primary my-auto font-bold ml-auto',
                        )}
                    >
                        {` #${rank}`}
                    </div>
                </Tooltip>
            ) : (
                ''
            )}
        </div>
    )
}
export default RankInfo
