import { Tab } from '@mui/icons-material'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../common/util/input/Link'
import ContentTabs from '../contenttabs/ContentTabs'
import FixedSearchComponent from '../searchitems/FixedSearchComponent'
import TabItem from '../tabitem/TabItem'
import { useSharedState } from '../waxplorer/Waxplorer'
import { Button } from '@nextui-org/react'
import FixedDropsList from '../drops/FixedDropsList'
function SearchPopup(props) {
    const asset = props['asset']

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']
    const searchType = props['searchType']
    const includeTubes = props['includeTubes']
    const includeDrops = props['includeDrops']

    const searchId = props['searchId']

    const {
        schema,
        attr7,
        attr8,
        attr9,
        attr10,
        rarity,
        variant,
        author,
        name,
        color,
        type,
        border,
        owner,
        templateId,
        summaryId,
        attributesFloor,
        floorAttributeName,
        floorAttributeValue,
    } = asset

    const [tabKey, setTabKey] = useState('listings')

    const term = templateId ? templateId : name ? escape(name) : ''

    let link = ''
    let orderBy = 'offer_asc'
    let title = ''
    let search = searchType

    const searchAttributes = attributesFloor && floorAttributeName
    const attributesStr = searchAttributes
        ? `&attributes=${escape(floorAttributeName)}:${escape(
              floorAttributeValue,
          )}`
        : `${templateId ? `&template_id=${templateId}` : ''}${
              summaryId ? `&summary_id=${summaryId}` : ''
          }`
    const term2 = searchAttributes ? '' : term

    switch (searchType) {
        case 'cheapest':
            link = `/market/?term=${term ? term : ''}&collection=${
                author ? author : ''
            }&schema=${schema ? schema : ''}&order_by=offer_asc&limit=100${
                rarity ? `&rarity=${rarity}` : ''
            }${variant ? `&variant=${variant}` : ''}${
                border ? `&border=${border}` : ''
            }${color ? `&color=${color}` : ''}${type ? `&type=${type}` : ''}${
                attr7 ? `&attr7=${attr7}` : ''
            }${attr8 ? `&attr8=${attr8}` : ''}${
                attr9 ? `&attr9=${attr9}` : ''
            }${attr10 ? `&attr10=${attr10}` : ''}${
                templateId ? `&template_id=${templateId}` : ''
            }${
                summaryId ? `&summary_id=${summaryId}` : ''
            }&exact_search=true&search_type=cheapest`
            title = t('search.cheapest')
            break
        case 'sales':
            link = `/market/?term=${term2 ? term2 : ''}&collection=${
                author ? author : ''
            }&schema=${schema ? schema : ''}&order_by=offer_asc&limit=100${
                rarity ? `&rarity=${rarity}` : ''
            }${variant ? `&variant=${variant}` : ''}${
                border ? `&border=${border}` : ''
            }${color ? `&color=${color}` : ''}${type ? `&type=${type}` : ''}${
                attr7 ? `&attr7=${attr7}` : ''
            }${attr8 ? `&attr8=${attr8}` : ''}${
                attr9 ? `&attr9=${attr9}` : ''
            }${
                attr10 ? `&attr10=${attr10}` : ''
            }${attributesStr}&exact_search=true&search_type=sales`
            title = t('search.sales')
            break
        case 'active_sales':
            link = `/market/?term=${term ? term : ''}&collection=${
                author ? author : ''
            }&schema=${schema ? schema : ''}&order_by=offer_asc&limit=100${
                rarity ? `&rarity=${rarity}` : ''
            }${variant ? `&variant=${variant}` : ''}${
                border ? `&border=${border}` : ''
            }${color ? `&color=${color}` : ''}${type ? `&type=${type}` : ''}${
                attr7 ? `&attr7=${attr7}` : ''
            }${attr8 ? `&attr8=${attr8}` : ''}${
                attr9 ? `&attr9=${attr9}` : ''
            }${attr10 ? `&attr10=${attr10}` : ''}${
                templateId ? `&template_id=${templateId}` : ''
            }&owner=${owner ? owner : ''}${
                summaryId ? `&summary_id=${summaryId}` : ''
            }&exact_search=true&search_type=sales`
            title = t('profile.active_sales')
            search = 'sales'
            break
        case 'cheapest_missing':
            link = `/market/?term=${term ? term : ''}&collection=${
                author ? author : ''
            }&schema=${schema ? schema : ''}&order_by=offer_asc&limit=100${
                rarity ? `&rarity=${rarity}` : ''
            }${variant ? `&variant=${variant}` : ''}${
                border ? `&border=${border}` : ''
            }${color ? `&color=${color}` : ''}${type ? `&type=${type}` : ''}${
                attr7 ? `&attr7=${attr7}` : ''
            }${attr8 ? `&attr8=${attr8}` : ''}${
                attr9 ? `&attr9=${attr9}` : ''
            }${attr10 ? `&attr10=${attr10}` : ''}${
                templateId ? `&template_id=${templateId}` : ''
            }&owner=${owner ? owner : ''}${
                summaryId ? `&summary_id=${summaryId}` : ''
            }${
                includeTubes ? '' : '&include_tubes=false'
            }&exact_search=true&search_type=cheapest_missing`
            title = t('search.sales')
            break
        case 'trades':
            link = `/market/?term=${term ? term : ''}&collection=${
                author ? author : ''
            }&schema=${schema ? schema : ''}&order_by=date_desc&limit=100${
                rarity ? `&rarity=${rarity}` : ''
            }${variant ? `&variant=${variant}` : ''}${
                border ? `&border=${border}` : ''
            }${color ? `&color=${color}` : ''}${type ? `&type=${type}` : ''}${
                attr7 ? `&attr7=${attr7}` : ''
            }${attr8 ? `&attr8=${attr8}` : ''}${
                attr9 ? `&attr9=${attr9}` : ''
            }${attr10 ? `&attr10=${attr10}` : ''}${
                templateId ? `&template_id=${templateId}` : ''
            }${
                summaryId ? `&summary_id=${summaryId}` : ''
            }&exact_search=true&search_type=trades&tab=trades`
            orderBy = 'date_desc'
            title = t('profile.recent_sells')
            break
    }

    if (searchAttributes) {
        title = title + ' - ' + floorAttributeName + ': ' + floorAttributeValue
    }

    const cancel = () => {
        callBack(false)
        closeCallBack()
    }

    useEffect(() => {}, [searchId])

    return (
        <div
            className={cn(
                'relative z-40 w-11/12 justify-center h-9/10 shadow-xl',
                'text-sm dark:text-white text-black p-2 md:p-8',
                'dark:!bg-paper bg-zinc-200 rounded-2xl m-auto top-10',
            )}
        >
            <div className={'flex justify-between h-10 py-2'}>
                <div className={'text-xl font-bold'}>{title}</div>
                <img src="/close_btn.svg" alt="X" onClick={cancel} />
            </div>
            {includeDrops ? (
                <div className={'relative h-9/10 overflow-y-auto InsideScroll'}>
                    <ContentTabs
                        tabKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                        bg={'bg-transparent'}
                    >
                        <Tab
                            eventKey="listings"
                            title={
                                <TabItem
                                    target={'listings'}
                                    tabKey={tabKey}
                                    title={t('search.sales')}
                                />
                            }
                        >
                            {tabKey === 'listings' ? (
                                <>
                                    <div
                                        className={
                                            'flex justify-between h-10 py-2'
                                        }
                                    >
                                        <Link href={link}>
                                            <div
                                                onClick={cancel}
                                                className={
                                                    'flex cursor-pointer'
                                                }
                                            >
                                                {t('search.open_full_search')}{' '}
                                                <img
                                                    className={'ml-2 w-5 h-5'}
                                                    src="/search.svg"
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className={'relative h-9/10'}>
                                        <FixedSearchComponent
                                            {...props}
                                            id={'Fixed1'}
                                            searchSettings={{
                                                owner: {
                                                    value: [
                                                        'assets',
                                                        'active_sales',
                                                        'staked',
                                                        'choobed',
                                                        'cheapest_missing',
                                                    ].includes(searchType)
                                                        ? owner
                                                        : '',
                                                },
                                                includeTubes: {
                                                    value: includeTubes,
                                                },
                                                search_type: { value: search },
                                                collection: { value: author },
                                                schema: {
                                                    value: schema ? schema : '',
                                                },
                                                variant: { value: variant },
                                                rarity: { value: rarity },
                                                color: { value: color },
                                                type: { value: type },
                                                name: {
                                                    value: searchAttributes
                                                        ? ''
                                                        : name,
                                                },
                                                attr7: { value: attr7 },
                                                attr8: { value: attr8 },
                                                attr9: { value: attr9 },
                                                attr10: { value: attr10 },
                                                exact_search: { value: true },
                                                templateId: {
                                                    value: searchAttributes
                                                        ? ''
                                                        : templateId,
                                                },
                                                summaryId: {
                                                    value: searchAttributes
                                                        ? ''
                                                        : summaryId,
                                                },
                                                border: { value: border },
                                                order_by: { value: orderBy },
                                                attributes: {
                                                    value: searchAttributes
                                                        ? `${escape(
                                                              floorAttributeName,
                                                          )}:${escape(
                                                              floorAttributeValue,
                                                          )}`
                                                        : '',
                                                },
                                                limit: {
                                                    value:
                                                        searchType ===
                                                        'cheapest_missing'
                                                            ? 100
                                                            : 12,
                                                    fixed: true,
                                                },
                                            }}
                                            userName={userName}
                                        />
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                        </Tab>
                        <Tab
                            eventKey="drops"
                            title={
                                <TabItem
                                    target={'drops'}
                                    tabKey={tabKey}
                                    title={t('navigation.drops')}
                                />
                            }
                        >
                            {' '}
                            {tabKey === 'drops' ? (
                                <>
                                    <div className={'relative h-9/10'}>
                                        <FixedDropsList
                                            {...props}
                                            id={'Fixed1'}
                                            searchSettings={{
                                                owner: {
                                                    value: [
                                                        'assets',
                                                        'active_sales',
                                                        'staked',
                                                        'choobed',
                                                        'cheapest_missing',
                                                    ].includes(searchType)
                                                        ? owner
                                                        : '',
                                                },
                                                market: 'all',
                                                collection: { value: author },
                                                schema: {
                                                    value: schema ? schema : '',
                                                },
                                                variant: { value: variant },
                                                rarity: { value: rarity },
                                                color: { value: color },
                                                type: { value: type },
                                                name: {
                                                    value: searchAttributes
                                                        ? ''
                                                        : name,
                                                },
                                                exact_search: { value: true },
                                                templateId: {
                                                    value: searchAttributes
                                                        ? ''
                                                        : templateId,
                                                },
                                                order_by: { value: orderBy },
                                                attributes: {
                                                    value: searchAttributes
                                                        ? `${escape(
                                                              floorAttributeName,
                                                          )}:${escape(
                                                              floorAttributeValue,
                                                          )}`
                                                        : '',
                                                },
                                                limit: {
                                                    value:
                                                        searchType ===
                                                        'cheapest_missing'
                                                            ? 100
                                                            : 12,
                                                    fixed: true,
                                                },
                                            }}
                                            userName={userName}
                                        />
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                        </Tab>
                    </ContentTabs>
                </div>
            ) : (
                <>
                    <div className={'flex justify-between h-10 py-2 mb-2'}>
                        <Link href={link}>
                            <Button
                                radius="sm"
                                variant="bordered"
                                color="warning"
                                onClick={cancel}
                                className={'flex cursor-pointer'}
                                /*  endContent={
                                    <img
                                        className={'w-6 h-6'}
                                        src="/search.svg"
                                        alt="searchIcon"
                                    />
                                } */
                            >
                                SHOW FULL PAGE
                            </Button>
                        </Link>
                    </div>
                    <div className={'relative h-9/10'}>
                        <FixedSearchComponent
                            {...props}
                            id={'Fixed1'}
                            searchSettings={{
                                owner: {
                                    value: [
                                        'assets',
                                        'active_sales',
                                        'staked',
                                        'choobed',
                                        'cheapest_missing',
                                    ].includes(searchType)
                                        ? owner
                                        : '',
                                },
                                includeTubes: { value: includeTubes },
                                search_type: { value: search },
                                collection: { value: author },
                                schema: { value: schema ? schema : '' },
                                variant: { value: variant },
                                rarity: { value: rarity },
                                color: { value: color },
                                type: { value: type },
                                name: { value: searchAttributes ? '' : name },
                                attr7: { value: attr7 },
                                attr8: { value: attr8 },
                                attr9: { value: attr9 },
                                attr10: { value: attr10 },
                                exact_search: { value: true },
                                templateId: {
                                    value: searchAttributes ? '' : templateId,
                                },
                                summaryId: {
                                    value: searchAttributes ? '' : summaryId,
                                },
                                border: { value: border },
                                order_by: { value: orderBy },
                                attributes: {
                                    value: searchAttributes
                                        ? `${escape(
                                              floorAttributeName,
                                          )}:${escape(floorAttributeValue)}`
                                        : '',
                                },
                                limit: {
                                    value:
                                        searchType === 'cheapest_missing'
                                            ? 100
                                            : 12,
                                    fixed: true,
                                },
                            }}
                            userName={userName}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default SearchPopup
