import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@nextui-org/react'
import BurnIndicator from '../burnindicator/BurnIndicator'
import CheckIndicator from '../checkindicator/CheckIndicator'
import ErrorNote from '../common/util/ErrorNote'
import Link from '../common/util/input/Link'
import MainButton from '../common/util/input/MainButton'
import get from '../helpers/Api'
import {
    formatNumber,
    formatUSD,
    formatWAX,
    getUsdWax,
    openSearchPopup,
} from '../helpers/FormatLinks'
import {
    getBoostAction,
    performLogin,
    signAlcorNFTBuy,
    signAtomicMarketBuy,
    signGpkMythBuy,
    signMarketMythBuy,
    signMarketPlaceBuy,
    signMarketPlaceBuy1,
    signMarketPlaceBuy2,
    signMarketPlaceBuy3,
    signNFTHiveBuy,
    signSimpleMarketBuy,
    signWaxInventoryBuy,
    signWaxplorerBuy,
    signWsMythBuy,
} from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import { Context, useSharedState } from '../waxplorer/Waxplorer'

const threshold = 20

export const AssetButtons = ({
    asset,
    loadBack,
    bidPlaced,
    bought,
    burned,
    canceled,
    claimed,
    transferred,
    claimer,
    className = 'w-full h-8 mt-auto',
    created,
    edited,
    error,
    assetType,
    frontVisible = true,
    handleBidPlaced,
    handleBought,
    handleCancel,
    handleClaim,
    handleCreateOffer,
    handleEditOffer,
    handleSell,
    handleBurn,
    handleSellOffer,
    handleTopup,
    handleUnpack,
    handleUnstake,
    isClaimed,
    isLoading,
    newOwner,
    onAdd,
    page,
    setError,
    setIsLoading,
    setSold,
    sold,
    staked,
    swapped,
    toppedUp,
    unpacked,
    unstaked,
    showOwner,
    searchOwner,
    currentAsset,
    view,
    active,
    onOpen,
}) => {
    const [state, dispatch, { login }] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const { t } = useTranslation('common')
    const [currentAction, setCurrentAction] = useState(1)

    let {
        templateId,
        balance,
        assets,
        bundle,
        offer,
        usd_offer,
        isAuction,
        orderId,
        price,
        numBids,
        isBurned,
        market,
        buyer,
        seller,
        bidder,
        blacklisted,
        currency,
        author,
        rarity,
        schema,
        receiver,
        unpackUrl,
        forSale,
        sender,
    } = asset

    const { lowest, attributesFloor, floorChange } = assets
        ? assets[currentAsset]
        : asset

    let { owner, assetId, aAssetId, standard, contract } = assets
        ? assets[currentAsset]
        : asset

    const assetIds = []

    if (bundle) {
        assets.forEach((asset) => assetIds.push(asset.assetId))
    } else {
        assetIds.push(assetId)
    }

    const directBuy = async () => {
        const quantity = parseFloat(offer ? offer : price)
        setIsLoading(true)

        const usd_wax = await getUsdWax()

        try {
            let result
            switch (market) {
                case 'atomicmarket':
                    {
                        result = await signAtomicMarketBuy(
                            activeUser,
                            orderId,
                            quantity,
                            currency,
                            usd_wax['usd_wax'],
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'waxplorercom':
                    {
                        result = await signWaxplorerBuy(
                            activeUser,
                            quantity,
                            orderId,
                            asset['assetId'],
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'simplemarket':
                    {
                        result = await signSimpleMarketBuy(
                            activeUser,
                            quantity,
                            assetId,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'nft.hive':
                    {
                        result = await signNFTHiveBuy(
                            activeUser,
                            quantity,
                            orderId,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'waxinventory':
                    {
                        result = await signWaxInventoryBuy(
                            activeUser,
                            quantity,
                            assetId,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'market.myth':
                    {
                        result = await signMarketMythBuy(
                            activeUser,
                            quantity,
                            assetId,
                            owner,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'gpk.myth':
                    {
                        result = await signGpkMythBuy(
                            activeUser,
                            quantity,
                            name,
                            owner,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'ws.myth':
                    {
                        result = await signWsMythBuy(
                            activeUser,
                            quantity,
                            name,
                            owner,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'alcornftswap':
                    {
                        result = await signAlcorNFTBuy(
                            activeUser,
                            orderId,
                            quantity,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'market.place':
                    {
                        if (!orderId) {
                            throw Error('Invalid Listing Id')
                        }
                        if (assetIds.length === 1) {
                            result = await signMarketPlaceBuy(
                                activeUser,
                                quantity,
                                orderId,
                                standard,
                                assets,
                            )

                            handleBought({
                                bought: true,
                                error: null,
                                asset: asset,
                            })
                        } else {
                            if (currentAction === 1) {
                                result = await signMarketPlaceBuy1(
                                    activeUser,
                                    quantity,
                                    orderId,
                                )
                                setCurrentAction(2)
                            }

                            if (
                                assetIds.length > threshold &&
                                currentAction === 3
                            ) {
                                const transferIds = [...assetIds]
                                transferIds.splice(threshold)
                                result = await signMarketPlaceBuy3(
                                    activeUser,
                                    transferIds,
                                )

                                handleBought({
                                    bought: true,
                                    error: null,
                                    asset: asset,
                                })
                            }

                            if (currentAction === 2) {
                                const transferIds = [...assetIds]
                                transferIds.splice(0, threshold)
                                result = await signMarketPlaceBuy2(
                                    activeUser,
                                    transferIds,
                                )
                                if (assetIds.length > threshold) {
                                    setCurrentAction(3)
                                } else {
                                    handleBought({
                                        bought: true,
                                        error: null,
                                        asset: asset,
                                    })
                                }
                            }
                        }
                    }
                    break
            }
        } catch (e) {
            handleBought({
                bought: false,
                error: e.message,
                asset: asset,
            })
            setError(e.message)
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    const autoBuyEnabled =
        state.enableLiveAutoBuy && state.enableLiveAutoBuy.price

    useEffect(() => {
        if (
            state.enableLiveAutoBuy &&
            (!state.enableLiveAutoBuy.collection ||
                state.enableLiveAutoBuy.collection === author) &&
            (!state.enableLiveAutoBuy.schema ||
                state.enableLiveAutoBuy.schema === schema) &&
            (!state.enableLiveAutoBuy.rarity ||
                state.enableLiveAutoBuy.rarity === rarity) &&
            (!state.enableLiveAutoBuy.templateId ||
                state.enableLiveAutoBuy.templateId.toString() ===
                    templateId.toString()) &&
            page === 'live' &&
            offer <= state.enableLiveAutoBuy.price &&
            asset['new'] &&
            buyable
        ) {
            dispatch({
                type: 'SET_ENABLE_LIVE_AUTOBUY',
                payload: null,
            })
            state.skipBuyConfirmation ? directBuy() : buy()
        }
    }, [autoBuyEnabled])

    const popError = null

    const getOrderId = async (assetId, market, bundle) => {
        const orderId = await get(
            'get-order-id/' +
                (market ? market + '/' : '') +
                assetId +
                '?bundle=' +
                bundle,
        )
        return orderId ? orderId['orderId'] : null
    }

    const getPackOrderId = async (symbol, market, seller, offer) => {
        const orderId = await get(
            'get-pack-order-id/' +
                market +
                '/' +
                seller +
                '/' +
                symbol +
                '?offer=' +
                offer,
        )
        return orderId ? orderId['orderId'] : null
    }

    const cancel = async () => {
        let { assetId, author, isAuction, assets, orderId, symbol, owner } =
            asset

        let { market } = asset

        if (!market) {
            market = owner
        }

        setError(null)
        setIsLoading(true)

        let newOrderId = null

        if (assetId || (assetIds.length > 0 && assetIds[0]))
            newOrderId = await getOrderId(
                assetId ? assetId : assetIds[0],
                market ? market : owner,
                assets && assets.length > 1,
            )

        if (symbol)
            newOrderId = await getPackOrderId(
                symbol,
                market ? market : owner,
                userName,
                offer,
            )

        if (newOrderId) orderId = newOrderId

        try {
            let result
            switch (market) {
                case 'atomicmarket':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: isAuction
                                        ? 'cancelauct'
                                        : 'cancelsale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: isAuction
                                        ? {
                                              auction_id: orderId,
                                          }
                                        : {
                                              sale_id: orderId,
                                          },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxbuyoffers':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'atomicmarket',
                                    name: 'canceltbuyo',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        buyoffer_id: orderId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxarena3dk1':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'waxarena3dk1',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        asset_id: assetId,
                                        winner: userName,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'nft.hive':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'nft.hive',
                                    name: 'cancelsale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        sale_id: orderId,
                                    },
                                },
                                {
                                    account: 'nft.hive',
                                    name: 'withdraw',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        user: userName,
                                        quantity:
                                            (author === 'a.rplanet'
                                                ? (1.0).toFixed(4)
                                                : 1) +
                                            ' ' +
                                            symbol,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'alcornftswap':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancelsell',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        maker: userName,
                                        order_id: orderId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxinventory':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancel',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        owner: userName,
                                        assetids: [assetId],
                                    },
                                },
                                {
                                    account: 'simpleassets',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        claimer: userName,
                                        assetids: [assetId],
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'simplemarket':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancel',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        owner: userName,
                                        assetids: [assetId],
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxplorercom':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancelsale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        sale_id: orderId,
                                    },
                                },
                                {
                                    account: 'simpleassets',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        claimer: userName,
                                        assetids: assetIds,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'market.place':
                    if (!orderId) {
                        throw Error('Invalid Listing Id')
                    }
                    if (assetIds.length === 1) {
                        result = await activeUser.session.transact(
                            {
                                actions: [
                                    getBoostAction(activeUser),
                                    {
                                        account: 'market.place',
                                        name: 'unlist',
                                        authorization: [
                                            {
                                                actor: userName,
                                                permission:
                                                    activeUser[
                                                        'requestPermission'
                                                    ],
                                            },
                                        ],
                                        data: {
                                            account: userName,
                                            listingid: orderId,
                                        },
                                    },
                                    {
                                        account: 'market.place',
                                        name: 'withdraw',
                                        authorization: [
                                            {
                                                actor: userName,
                                                permission:
                                                    activeUser[
                                                        'requestPermission'
                                                    ],
                                            },
                                        ],
                                        data: {
                                            user: userName,
                                            assets: assetIds.map((id) => {
                                                return {
                                                    contract: 'simpleassets',
                                                    id: id,
                                                    quantity: '0.00000000 WAX',
                                                }
                                            }),
                                        },
                                    },
                                    {
                                        account: 'simpleassets',
                                        name: 'claim',
                                        authorization: [
                                            {
                                                actor: userName,
                                                permission:
                                                    activeUser[
                                                        'requestPermission'
                                                    ],
                                            },
                                        ],
                                        data: {
                                            claimer: userName,
                                            assetids: assetIds,
                                        },
                                    },
                                ],
                            },
                            {
                                expireSeconds: 300,
                                blocksBehind: 0,
                            },
                        )
                        handleCancel(true)
                    } else {
                        if (currentAction === 1) {
                            result = await activeUser.session.transact(
                                {
                                    actions: [
                                        getBoostAction(activeUser),
                                        {
                                            account: 'market.place',
                                            name: 'unlist',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                account: userName,
                                                listingid: orderId,
                                                referral: 'waxplorerref',
                                            },
                                        },
                                    ],
                                },
                                {
                                    expireSeconds: 300,
                                    blocksBehind: 0,
                                },
                            )
                            setCurrentAction(2)
                        }

                        if (
                            assetIds.length > threshold &&
                            currentAction === 3
                        ) {
                            const transferIds = [...assetIds]
                            transferIds.splice(threshold)
                            result = await activeUser.session.transact(
                                {
                                    actions: [
                                        getBoostAction(activeUser),
                                        {
                                            account: 'market.place',
                                            name: 'withdraw',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                user: userName,
                                                assets: transferIds.map(
                                                    (id) => {
                                                        return {
                                                            contract:
                                                                'simpleassets',
                                                            id: id,
                                                            quantity:
                                                                '0.00000000 WAX',
                                                        }
                                                    },
                                                ),
                                            },
                                        },
                                        {
                                            account: 'simpleassets',
                                            name: 'claim',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                claimer: userName,
                                                assetids: transferIds,
                                            },
                                        },
                                    ],
                                },
                                {
                                    expireSeconds: 300,
                                    blocksBehind: 0,
                                },
                            )
                            handleCancel(true)
                        }

                        if (currentAction === 2) {
                            const transferIds = [...assetIds]
                            transferIds.splice(0, threshold)
                            result = await activeUser.session.transact(
                                {
                                    actions: [
                                        getBoostAction(activeUser),
                                        {
                                            account: 'market.place',
                                            name: 'withdraw',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                user: userName,
                                                assets: transferIds.map(
                                                    (id) => {
                                                        return {
                                                            contract:
                                                                'simpleassets',
                                                            id: id,
                                                            quantity:
                                                                '0.00000000 WAX',
                                                        }
                                                    },
                                                ),
                                            },
                                        },
                                        {
                                            account: 'simpleassets',
                                            name: 'claim',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                claimer: userName,
                                                assetids: transferIds,
                                            },
                                        },
                                    ],
                                },
                                {
                                    expireSeconds: 300,
                                    blocksBehind: 0,
                                },
                            )
                            if (assetIds.length > threshold) {
                                setCurrentAction(3)
                            } else {
                                handleCancel(true)
                            }
                        }
                    }
                    break
            }
        } catch (e) {
            console.error(e)
            setSold(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const claim = async () => {
        let { assetId, orderId } = asset

        setError(null)
        setIsLoading(true)

        try {
            switch (owner) {
                case 'atomicmarket':
                    await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'atomicmarket',
                                    name:
                                        userName === buyer ||
                                        userName === bidder
                                            ? 'auctclaimbuy'
                                            : 'cancelauct',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        auction_id: orderId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleClaim(true)
                    break
                case 'waxarena3dk1':
                    await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'waxarena3dk1',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        asset_id: assetId,
                                        winner: userName,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleClaim(true)
                    break
            }
        } catch (e) {
            console.error(e)
            setSold(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const unstake = async () => {
        let { assetId, standard, owner, action } = asset

        const stakingContract = newOwner ? newOwner : owner
        if (!stakingContracts.includes(stakingContract)) return

        setError(null)
        setIsLoading(true)

        try {
            await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        stakingContract === 's.rplanet'
                            ? {
                                  account: stakingContract,
                                  name: 'unstake',
                                  authorization: [
                                      {
                                          actor: userName,
                                          permission:
                                              activeUser.requestPermission
                                                  ? activeUser.requestPermission
                                                  : 'active',
                                      },
                                  ],
                                  data: {
                                      asset_ids: [assetId],
                                      contract: standard,
                                      to: userName,
                                  },
                              }
                            : {
                                  account: stakingContract,
                                  name: 'unstake',
                                  authorization: [
                                      {
                                          actor: userName,
                                          permission:
                                              activeUser.requestPermission
                                                  ? activeUser.requestPermission
                                                  : 'active',
                                      },
                                  ],
                                  data: {
                                      asset_ids: [assetId],
                                      authorized_user: userName,
                                      memo: action,
                                  },
                              },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            handleUnstake(true)
        } catch (e) {
            console.error(e)
            setSold(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const buy = () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (bought) => handleBought(bought),
        })
        dispatch({ type: 'SET_ACTION', payload: 'buy' })
    }

    const sell = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (sellInfo) => handleSell(sellInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'sell' })
    }

    const burn = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (burnInfo) => handleBurn(burnInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'burn_asset' })
    }

    const topup = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (topupInfo) => handleTopup(topupInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'topup' })
    }

    const selloffer = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (sellInfo) => handleSellOffer(sellInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'selloffer' })
    }

    const unpack = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (unpacked) => handleUnpack(unpacked),
        })
        dispatch({ type: 'SET_ACTION', payload: 'unpack' })
    }

    const claimPack = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (unpacked) => handleUnpack(unpacked),
        })
        dispatch({ type: 'SET_ACTION', payload: 'claim_pack' })
    }

    const bid = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({ type: 'SET_SELLPRICE', payload: offer })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (bid) => handleBidPlaced(bid),
        })
        dispatch({ type: 'SET_ACTION', payload: 'bid' })
    }

    const edit = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (offerInfo) => handleEditOffer(offerInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'editoffer' })
    }

    const disMissError = () => {
        if (popError) dispatch({ type: 'SET_ERROR', payload: null })
        const errorAssets = state.errorAssets
        if (errorAssets) {
            if (assetId) delete errorAssets[assetId.toString()]
            if (aAssetId) delete errorAssets[aAssetId.toString()]
            dispatch({ type: 'SET_ERROR_ASSETS', payload: errorAssets })
        }
        setError(null)
    }
    const buyContainerClassName = cn(
        'relative grid grid-cols-2 font-bold text-black dark:!text-white block w-full mt-auto space-x-4',
        { 'text-center': view !== 'full' },
        { 'text-left mb-4': view === 'full' },
    )
    const singleContainerClassName = cn(
        'mt-auto relative grid font-bold text-black dark:!text-white block text-center grid-cols-1 w-full mt-auto gap-2',
    )
    const sellContainerClassName = cn(
        'mt-auto relative grid grid-cols-2 font-bold text-black dark:!text-white block w-full mt-auto',
        { 'text-center': view !== 'full' },
        { 'text-left right-auto': view === 'full' },
    )
    const sellContainer3ColsClassName = cn(
        'mt-auto relative grid grid-cols-3 font-bold text-black dark:!text-white gap-3 block w-full mt-auto',
        { 'text-center': view !== 'full' },
        { 'text-left right-auto': view === 'full' },
    )
    const lbuttonClassName = cn(
        'font-bold rounded-md text-paper',
        { 'm-auto': view !== 'full' },
        { 'mr-auto': view === 'full' },
    )
    const buttonClassName = cn(
        'font-bold rounded-md text-paper',
        { 'm-auto': view !== 'full' },
        { 'mr-auto': view === 'full' },
    )
    const button3ColsClassName = cn(
        'font-bold rounded-md text-paper w-1/3 min-w-16',
        { 'm-auto': view !== 'full' },
        { 'mr-auto': view === 'full' },
    )
    const cancelButtonClassName = cn(
        buttonClassName,
        'm-auto w-24 text-black dark:!text-white',
    )
    const topupButtonClassName = cn('m-auto w-24 p-1')
    const usdClassName = cn('my-auto text-xs')

    const handleButtonClick = () => {
        onOpen()
        loadBack()
    }

    const detailsButton =
        view === 'full' ? (
            ''
        ) : (
            <MainButton
                fullWidth={true}
                className={'font-bold rounded-md text-primary'}
                varient={'bordered'}
                // onClick={loadBack}
                onClick={handleButtonClick}
                // padding={'pr-2'}
            >
                {t('asset.details')}
            </MainButton>
        )

    const sellField = (
        <div className={sellContainerClassName}>
            {detailsButton}
            {[
                'atomicassets',
                'simpleassets',
                'packs.topps',
                'packs.ws',
                'pack.worlds',
            ].includes(standard) ? (
                <MainButton
                    fullWidth={true}
                    className={buttonClassName}
                    onClick={sell}
                    padding={'pl-2'}
                >
                    {t('asset.sell')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const detailsField = (
        <div className={singleContainerClassName}>
            {owner && !receiver && !buyer ? owner : ''}
            {detailsButton}
        </div>
    )

    const sellUnpackLinkField = (
        <div className={sellContainer3ColsClassName}>
            {view === 'full' ? (
                ''
            ) : (
                <MainButton
                    fullWidth={true}
                    className={button3ColsClassName}
                    onClick={loadBack}
                    padding={'px-0'}
                >
                    {t('asset.details')}
                </MainButton>
            )}
            <MainButton
                padding={'px-0'}
                className={button3ColsClassName}
                onClick={unpack}
            >
                {t('asset.unpack')}
            </MainButton>
            {[
                'atomicassets',
                'simpleassets',
                'packs.topps',
                'packs.ws',
                'pack.worlds',
            ].includes(standard) ? (
                <MainButton
                    className={button3ColsClassName}
                    padding={'px-0'}
                    onClick={sell}
                >
                    {t('asset.sell')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const burnField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            {['atomicassets', 'simpleassets'].includes(standard) ? (
                <MainButton
                    padding={'pl-2'}
                    className={cancelButtonClassName}
                    onClick={burn}
                >
                    {t('asset.burn')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const offerField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            <MainButton className={buttonClassName} onClick={selloffer}>
                {t('trade.accept')}
            </MainButton>
        </div>
    )

    const unpackField = (
        <div className={sellContainerClassName}>
            {detailsButton}
            {['atomicassets'].includes(standard) &&
            ['atomicpacksx', 'neftyblocksp', 'nfthivepacks'].includes(
                contract,
            ) ? (
                <MainButton
                    padding={'pl-2'}
                    className={buttonClassName}
                    onClick={unpack}
                >
                    {t('asset.unpack')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const claimField = (
        <div className={sellContainerClassName}>
            {detailsButton}
            {['atomicassets'].includes(standard) &&
            ['atomicpacksx', 'neftyblocksp', 'nfthivepacks'].includes(
                contract,
            ) ? (
                <MainButton
                    padding={'pl-2'}
                    className={buttonClassName}
                    onClick={claimPack}
                >
                    {t('asset.claim')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const bidField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            <MainButton
                className={'font-bold rounded-md text-dark'}
                varient={'solid'}
                onClick={bid}
                // padding={'pl-2'}
            >
                {t('asset.bid')}
            </MainButton>
        </div>
    )

    const cancelField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            <MainButton
                className={'font-bold rounded-md text-primary'}
                varient={'bordered'}
                onClick={cancel}
                padding={'pl-2'}
            >
                {t('asset.cancel')}
            </MainButton>
        </div>
    )

    const editField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            {edited ? (
                <CheckIndicator />
            ) : (
                <MainButton
                    className={buttonClassName}
                    fullWidth={true}
                    onClick={edit}
                    padding={'pl-2'}
                >
                    {t('asset.edit')}
                </MainButton>
            )}
        </div>
    )

    const unstakeField = (
        <div className={sellContainerClassName}>
            {detailsButton}
            <MainButton
                className={cancelButtonClassName}
                fullWidth={true}
                onClick={unstake}
                padding={'pl-2'}
            >
                {t('asset.unstake')}
            </MainButton>
        </div>
    )

    const createoffer = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (offerInfo) => handleCreateOffer(offerInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'createoffer' })
    }

    const buyofferField = (
        <div className={sellContainerClassName}>
            {detailsButton}
            <MainButton
                className={buttonClassName}
                fullWidth={true}
                onClick={createoffer}
                padding={'pl-2'}
            >
                Create...
            </MainButton>
        </div>
    )

    const ownerField = (
        <div className={singleContainerClassName}>
            <Link href={'/user/' + owner}>{owner}</Link>
        </div>
    )

    const topupField = (
        <div className={buyContainerClassName}>
            <div
                className={cn(
                    'dark:!bg-paper bg-zinc-200 backdrop-filter backdrop-blur-3xl max-w-60 ',
                    'max-h-24 flex justify-center text-primary text-xs ',
                    'mx-auto p-1 leading-5 border border-solid rounded-md ',
                    'border-negative z-30',
                )}
            >
                <div className="m-auto">
                    <img className="w-5 h-5" src="/Warning_icn.svg" alt="!" />
                </div>
                <div className="pl-4 m-auto text-center">
                    Overdrawn Balance: {`${formatNumber(offer - balance)} WAX`}
                </div>
            </div>
            <MainButton
                fullWidth={true}
                className={topupButtonClassName}
                onClick={topup}
            >
                Top Up / Cancel
            </MainButton>
        </div>
    )

    const numActions =
        assetIds.length > threshold ? 3 : assetIds.length > 1 ? 2 : 1
    const cancelCollectablesField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            <MainButton
                className={cancelButtonClassName}
                onClick={cancel}
                fullWidth={true}
                disabled={isLoading ? 'disabled' : ''}
            >
                {`${
                    currentAction === 1 ? t('asset.cancel') : t('asset.claim')
                }${numActions > 1 ? ` ${currentAction}/${numActions}` : ''}`}
            </MainButton>
        </div>
    )

    const buyField =
        page === 'live' ? (
            <div className={singleContainerClassName}>
                <MainButton
                    fullWidth={true}
                    className={lbuttonClassName}
                    onClick={state.skipBuyConfirmation ? directBuy : buy}
                >
                    {t('asset.buy')}
                </MainButton>
            </div>
        ) : (
            <div className={buyContainerClassName}>
                {detailsButton}
                <MainButton
                    fullWidth={true}
                    className={buttonClassName}
                    onClick={state.skipBuyConfirmation ? directBuy : buy}
                >
                    {t('asset.buy')}
                </MainButton>
            </div>
        )

    const loginField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            <MainButton
                fullWidth={true}
                className={buttonClassName}
                onClick={() => login()}
            >
                {t('navigation.login')}
            </MainButton>
        </div>
    )

    const addField = (
        <div className={singleContainerClassName}>
            <MainButton
                fullWidth={true}
                className={buttonClassName}
                onClick={() => onAdd(asset)}
            >
                {t('trade.add')}
            </MainButton>
        </div>
    )

    const claimAuctionField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            <MainButton
                fullWidth={true}
                className={buttonClassName}
                onClick={claim}
            >
                {t('asset.claim')}
            </MainButton>
        </div>
    )

    const claimedField = (
        <div className={buyContainerClassName}>
            {t('asset.claimed_by', { claimer: claimer })}
        </div>
    )

    const stakingContracts = ['s.rplanet', 'waxdaofarmer']

    let claimableAuction,
        buyable,
        offerable,
        unstakable,
        buyofferable,
        sellable,
        burnable,
        unpacksellable,
        topuppable,
        checked,
        biddable,
        editable,
        collticancable,
        cancable,
        unpackable,
        claimable,
        shownameable

    if (
        offer &&
        !isAuction &&
        seller &&
        (!userName || userName !== seller) &&
        !created &&
        !bought &&
        market
    ) {
        buyable = true
    } else if (
        ['atomicassets'].includes(standard) &&
        ['neftyblocksp', 'atomicpacksx', 'nfthivepacks'].includes(contract) &&
        !swapped &&
        userName &&
        (userName === owner || (newOwner && newOwner === userName)) &&
        !offer &&
        (!sold || bought || canceled) &&
        !isClaimed &&
        !staked &&
        !unstakable &&
        page &&
        page === 'packs' &&
        !unpacked
    ) {
        unpackable = true
    } else if (
        unpackUrl &&
        contract &&
        !claimed &&
        !sold &&
        !unpacked &&
        (userName === owner || (newOwner && newOwner === userName)) &&
        assetId &&
        !offer &&
        !burned
    ) {
        unpacksellable = true
    } else if (
        !swapped &&
        userName &&
        (userName === owner || (newOwner && newOwner === userName)) &&
        !offer &&
        (!sold || bought) &&
        !isClaimed &&
        !staked &&
        !edited &&
        !canceled &&
        !burned &&
        !unstakable &&
        !unpacked &&
        !created &&
        !forSale &&
        !isAuction &&
        (!page || page === 'inventory' || page === 'live')
    ) {
        sellable = true
    } else if (
        !isClaimed &&
        isAuction &&
        !active &&
        ((numBids && userName === bidder) || (!numBids && userName === seller))
    ) {
        claimableAuction = true
    } else if (
        offer &&
        !isAuction &&
        (!userName || userName !== buyer) &&
        !sold &&
        buyer &&
        'waxbuyoffers' === market
    ) {
        offerable = true
    } else if (
        !unstaked &&
        userName &&
        !burned &&
        userName === sender &&
        (stakingContracts.includes(owner) ||
            stakingContracts.includes(newOwner))
    ) {
        unstakable = true
    } else if (!created && userName && page && page === 'newbuyoffer') {
        buyofferable = true
    } else if (
        userName === buyer &&
        market === 'waxbuyoffers' &&
        !toppedUp &&
        !canceled &&
        balance < offer
    ) {
        topuppable = true
    } else if (
        !swapped &&
        userName &&
        (userName === owner ||
            (newOwner && newOwner === userName) ||
            transferred) &&
        !offer &&
        !sold &&
        !bought &&
        !unstakable &&
        (canceled ||
            isClaimed ||
            swapped ||
            unpacked ||
            (isClaimed && isAuction && !active) ||
            bidPlaced ||
            staked ||
            (userName === buyer &&
                market === 'waxbuyoffers' &&
                !toppedUp &&
                canceled &&
                balance < offer) ||
            created ||
            transferred ||
            burned)
    ) {
        checked = true
    } else if (
        offer &&
        !created &&
        isAuction &&
        userName &&
        userName !== seller &&
        !bidPlaced &&
        [
            'atomicmarket',
            'waxplorercom',
            'simplemarket',
            'waxinventory',
            'alcornftswap',
            'market.place',
            'gpk.market',
            'waxarena3dk1',
        ].includes(newOwner ? newOwner : market ? market : owner)
    ) {
        biddable = true
    } else if (
        userName &&
        !topuppable &&
        ((((userName === seller && active) || sold) &&
            market !== 'waxbuyoffers') ||
            (userName === buyer && market === 'waxbuyoffers' && !sold)) &&
        (offer || (isAuction && !numBids)) &&
        !canceled &&
        [
            'nft.hive',
            'waxplorercom',
            'simplemarket',
            'waxinventory',
            'market.myth',
            'alcornftswap',
            'waxarena3dk1',
            'waxbuyoffers',
            'atomicmarket',
        ].includes(newOwner ? newOwner : market ? market : owner)
    ) {
        cancable = true
    }

    if (
        userName &&
        (userName === seller || sold) &&
        !topuppable &&
        offer &&
        !isAuction &&
        !canceled &&
        ['atomicmarket'].includes(newOwner ? newOwner : market ? market : owner)
    ) {
        editable = true
    } else if (
        userName &&
        (userName === seller || sold) &&
        !canceled &&
        (owner === 'market.place' || (newOwner && newOwner === 'market.place'))
    ) {
        collticancable = true
    } else if (
        ['atomicassets'].includes(standard) &&
        ['neftyblocksp', 'atomicpacksx', 'nfthivepacks'].includes(contract) &&
        !swapped &&
        userName &&
        (userName === owner || (newOwner && newOwner === userName)) &&
        !offer &&
        (!sold || bought || canceled) &&
        !isClaimed &&
        !staked &&
        !unstakable &&
        page &&
        page === 'claims' &&
        !unpacked
    ) {
        claimable = true
    }

    if (
        !claimableAuction &&
        !buyable &&
        !offerable &&
        !unstakable &&
        !buyofferable &&
        !sellable &&
        !burnable &&
        !unpacksellable &&
        !topuppable &&
        !checked &&
        !biddable &&
        !editable &&
        !collticancable &&
        !cancable &&
        !unpackable &&
        !claimable
    ) {
        shownameable = true
    }

    return (
        <div
            className={cn(
                'relative',
                {
                    hidden: !frontVisible,
                },
                className,
            )}
        >
            <div
                className={cn(
                    'relative py-0 px-0 2xl:px-1 w-full text-base mb-4',
                    'md:text-xs lg:text-base leading-5 flex-wrap text-center min-h-[28px]',
                    { 'text-center': view !== 'full' },
                    { 'text-left': view === 'full' },
                )}
            >
                <div
                    className={cn(
                        'flex h-2/3 flex-col text-left',
                        { 'justify-start mx-auto': view !== 'full' },
                        { 'justify-start mr-auto': view === 'full' },
                    )}
                >
                    {!offer ? (
                        <div
                            className={
                                'text-xs text-left h-4 space-y-1 font-bold'
                            }
                        >
                            {searchOwner === seller && price && !offer
                                ? `${t('asset.sold_for_x_to', {
                                      x: formatWAX(price),
                                      y: buyer,
                                  })}`
                                : (searchOwner === buyer ||
                                      searchOwner === owner) &&
                                  price &&
                                  !offer
                                ? `${t('asset.bought_for')}: ${formatWAX(
                                      price,
                                  )}`
                                : !searchOwner && price && buyer && !offer
                                ? `${t('asset.bought_for_x_by_y', {
                                      x: formatWAX(price),
                                      y: buyer,
                                  })}`
                                : ''}
                        </div>
                    ) : (
                        ''
                    )}
                    {!price && receiver ? (
                        <div className={'text-sm text-left my-auto font-bold'}>
                            {`${t('asset.receiver')}: ${receiver}`}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="flex">
                        {offer ? (
                            <div
                                className={cn(
                                    {
                                        'text-xs md:text-xs lg:text-xs xl:text-xs my-auto font-bold pr-1 text-left':
                                            view !== 'full',
                                    },
                                    {
                                        'text-xs text-right my-auto font-bold px-1':
                                            view === 'full',
                                    },
                                )}
                            >
                                {`${
                                    isAuction && offer
                                        ? numBids && numBids > 0
                                            ? `${t('search.top_bid')}: `
                                            : `${t('search.start_bid')}: `
                                        : ''
                                } ${formatWAX(offer)}`}
                            </div>
                        ) : (
                            ''
                        )}
                        {usd_offer && offer ? (
                            <div
                                className={cn(
                                    {
                                        'text-xs md:text-xs lg:text-xs xl:text-xs text-right my-auto font-bold px-1 flex':
                                            view !== 'full',
                                    },
                                    {
                                        'text-xs text-right my-auto font-bold px-1':
                                            view === 'full',
                                    },
                                )}
                            >
                                {` / $${formatNumber(usd_offer)}`}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                {view !== 'full' &&
                (lowest || attributesFloor) &&
                assetType !== 'pfpranking' ? (
                    <div
                        className={cn(
                            'flex text-sm md:text-1xs opacity-100 md:opacity-80 h-1/3 hover:opacity-100',
                            'cursor-pointer my-auto leading-1 ',
                            { 'justify-start': view !== 'full' },
                            { 'justify-start': view === 'full' },
                        )}
                        onClick={() =>
                            openSearchPopup(
                                'sales',
                                assets ? assets[currentAsset] : asset,
                                dispatch,
                            )
                        }
                    >
                        {assetType === 'pack-content' ? (
                            <div className={'w-full font-bold text-base'}>
                                {`${t('drops.remaining')}: ${
                                    asset['numRemaining']
                                }`}
                            </div>
                        ) : (
                            ''
                        )}
                        <p className="text-[11px]">
                            {!(!searchOwner && price && buyer && !offer)
                                ? `${t('asset.floor')}: ${formatWAX(
                                      offer && market !== 'waxbuyoffers'
                                          ? Math.min(
                                                attributesFloor
                                                    ? attributesFloor
                                                    : lowest,
                                                offer,
                                            )
                                          : attributesFloor
                                          ? attributesFloor
                                          : lowest,
                                  )}${
                                      floorChange
                                          ? ' (+' + formatWAX(floorChange) + ')'
                                          : ''
                                  }`
                                : ''}
                        </p>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div
                className={
                    'flex h-1/2 my-auto mx-auto gap-2 w-full justify-center'
                }
            >
                {!isLoading && !blacklisted && !onAdd && checked ? (
                    <CheckIndicator />
                ) : (
                    ''
                )}
                {!isLoading && !blacklisted && onAdd ? addField : ''}
                {isLoading ? (
                    <LoadingIndicator
                        className={buttonClassName}
                        margin={'m-0'}
                    />
                ) : !blacklisted && !onAdd && claimableAuction ? (
                    claimAuctionField
                ) : !blacklisted && !onAdd && claimed && claimer ? (
                    claimedField
                ) : !blacklisted && !onAdd && !sold && offerable ? (
                    offerField
                ) : !onAdd &&
                  (((sold || canceled) && 'waxbuyoffers' === market) ||
                      created) &&
                  !checked ? (
                    <CheckIndicator />
                ) : burnable ? (
                    burnField
                ) : !onAdd && (burned || isBurned) ? (
                    <BurnIndicator />
                ) : !blacklisted && !onAdd && buyable ? (
                    userName ? (
                        buyField
                    ) : (
                        loginField
                    )
                ) : !blacklisted && !onAdd && unstakable ? (
                    unstakeField
                ) : !blacklisted && !onAdd && buyofferable ? (
                    buyofferField
                ) : !blacklisted && !onAdd && sellable ? (
                    sellField
                ) : !blacklisted && !onAdd && unpacksellable ? (
                    sellUnpackLinkField
                ) : !blacklisted && !onAdd && unpackable ? (
                    unpackField
                ) : !blacklisted && !onAdd && claimable ? (
                    claimField
                ) : !blacklisted && !onAdd && biddable ? (
                    bidField
                ) : !blacklisted && !onAdd && cancable && !editable ? (
                    cancelField
                ) : !blacklisted && !onAdd && editable ? (
                    editField
                ) : !blacklisted && !onAdd && topuppable ? (
                    topupField
                ) : !blacklisted &&
                  !onAdd &&
                  !sellable &&
                  showOwner &&
                  !cancable &&
                  !editable &&
                  !sold ? (
                    ownerField
                ) : !blacklisted && !onAdd && collticancable ? (
                    cancelCollectablesField
                ) : !collticancable &&
                  !sellable &&
                  !showOwner &&
                  !topuppable &&
                  !editable &&
                  !cancable &&
                  !biddable &&
                  !claimable &&
                  !unpackable &&
                  !unpacksellable &&
                  !buyofferable &&
                  !buyable &&
                  !checked &&
                  !sold &&
                  !canceled &&
                  !created &&
                  !offerable &&
                  !claimed &&
                  !claimableAuction ? (
                    detailsField
                ) : (
                    ''
                )}

                {!isLoading && !onAdd && (error || popError) ? (
                    <ErrorNote
                        onClick={disMissError}
                        error={error ? error : popError.message}
                        className={'-top-8 rounded-2xl'}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}
