import cn from 'classnames'
import NextLink from 'next/link'
import React from 'react'

export const Link2 = ({
    children,
    inactive,
    className,
    href,
    onClick,
    altOnClick = () => {},
    external,
    forcePointer,
}) => {
    const linkClassNames = cn(
        'block',
        'focus-visible:ring-1 focus-visible:ring-inset  w-full h-full',
        'focus-visible:ring-primary m-auto',
        { 'cursor-default': !forcePointer && inactive },
        { 'cursor-pointer': forcePointer || !inactive },
        className,
    )
    if (inactive || !href)
        return (
            <div onClick={altOnClick} className={'w-full h-full'}>
                {children}
            </div>
        )

    if (external)
        return (
            <NextLink
                href={href.toString()}
                target="_blank"
                rel="noopener noreferrer"
                className={'w-full h-full'}
                onClick={onClick}
            >
                {children}
            </NextLink>
        )
    return (
        <NextLink href={href} passHref className={'w-full h-full'}>
            <span className={'w-full h-full'} onClick={onClick}>
                {children}
            </span>
        </NextLink>
    )
}

Link2.displayName = 'Hive-Link'

export default Link2
