import { Checkbox, Tooltip } from '@mui/material'
import { Modal, ModalBody, ModalContent } from '@nextui-org/react'
import { useDisclosure } from '@nextui-org/react'
import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import LazyLoad from 'react-lazy-load'
import config from '../../config.json'
import { useTheme } from 'next-themes'
import AssetPreviewDisplay from '../assetpreviewdisplay/AssetPreviewDisplay'
import BackedInfo from '../backedinfo/BackedInfo'
import { MarketCard } from '../card/MarketCard'
import Link2 from '../common/util/input/Link2'
import { AssetButtons } from '../marketbuttons/AssetButtons'
import { MintInfo } from '../mintinfo/MintInfo'
import RankInfo from '../rankinfo/RankInfo'
import { AssetDetailsPopupContent } from './AssetDetailsPopupContent'
import { AssetPreviewInfo } from './AssetPreviewInfo'
import { CollectionAssetName } from './CollectionAssetName'
import MoreOptions from './MoreOptions'
import { useSharedState } from '../waxplorer/Waxplorer'
import { AuctionTimeleft } from './AuctionTimeleft'
import { useElementSize } from '../../hooks'
import BackAssetPopup from '../popups/BackAssetPopup'

// @ts-ignore
const AssetPreview = ({
    asset,
    selectedAsset,
    onAdd,
    bundleView,
    page,
    showOwner,
    selectable,
    searchOwner,
    clickAsset,
    index,
    assetType,
    previewDuration = 2,
    handleCheck,
    selectedAssets = [],
    errorAssets,
    transferredAssets,
    dispatch,
}) => {
    const [priceInfo, setPriceInfo] = useState(null)
    const [currentAsset, setCurrentAsset] = useState(0)
    const [newOwner, setNewOwner] = useState(null)
    const [frontVisible, setFrontVisible] = useState(true)
    const { theme } = useTheme()
    const [state] = useSharedState()
    const [backMenuOpen, setBackMenuOpen] = useState(null)

    const userName = state?.activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const [showMenu, setShowMenu] = useState(false)
    const [error, setError] = useState(null)
    const [swapped, setSwapped] = useState(false)
    const [unstaked, setUnstaked] = useState(false)
    const [staked, setStaked] = useState(false)
    const [bidPlaced, setBidPlaced] = useState(false)
    const [bought, setBought] = useState(false)
    const [burned, setBurned] = useState(false)
    const [unpacked, setUnpacked] = useState(false)
    const [canceled, setCanceled] = useState(false)
    const [edited, setEdited] = useState(false)
    const [toppedUp, setToppedUp] = useState(false)
    const [isClaimed, setIsClaimed] = useState(false)
    const [sold, setSold] = useState(false)
    const [created, setCreated] = useState(false)
    const [backed, setBacked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [transferred, setTransferred] = useState(false)

    const { isOpen, onOpen, onClose } = useDisclosure()
    useEffect(() => {
        if (errorAssets && Object.keys(errorAssets).length > 0) {
            for (let localId of Object.keys(errorAssets)) {
                if (
                    assetId.toString() === localId.toString() ||
                    (aAssetId && aAssetId.toString() === localId.toString())
                ) {
                    setError(errorAssets[localId])
                }
            }
        }
        if (Object.keys(transferredAssets).length > 0) {
            for (let localId of Object.keys(transferredAssets)) {
                if (
                    assetId.toString() === localId.toString() ||
                    (aAssetId && aAssetId.toString() === localId.toString())
                ) {
                    setNewOwner(transferredAssets[localId]['newOwner'])
                    asset['owner'] = transferredAssets[localId]['newOwner']
                    asset['newOwner'] = transferredAssets[localId]['newOwner']
                    asset['sender'] = userName

                    if (transferredAssets[localId]['sold']) {
                        setSold(true)
                        setNewOwner(transferredAssets[localId]['owner'])
                        asset['offer'] = transferredAssets[localId]['offer']
                        asset['market'] = transferredAssets[localId]['market']
                    } else if (transferredAssets[localId]['bought']) {
                        setBought(true)
                        asset['offer'] = null
                    } else if (transferredAssets[localId]['transferred']) {
                        setTransferred(true)
                    } else if (transferredAssets[localId]['transferred']) {
                        setCanceled(true)
                    }
                }
            }
        }
    }, [
        selectedAssets && selectedAssets.length,
        errorAssets && Object.keys(errorAssets).length,
        transferredAssets && Object.keys(transferredAssets).length,
    ])

    const { assets, bundle, orderName, offer, isAuction, saleId } = asset

    let {
        assetId,
        aAssetId,
        author,
        name,
        image,
        previmg,
        backimg,
        mint,
        rarityScore,
        rank,
        myMint,
        backedTokens,
        standard,
        claimer,
        claimed,
        orderId,
        verified,
        blacklisted,
        symbol,
        market,
        mdata,
        collectionImage,
        collectionThumbnail,
        thumbnail,
        universalPreview,
        templateId,
    } = asset

    if (!collectionImage && verified) {
        collectionImage =
            config.ipfs + 'QmVVE5aLtq4EtMe4Khev5CSic3AKqX2RuNTAB79thvpqQW'
    } else if (!verified && collectionImage) {
        collectionImage = null
    }

    if (standard && standard === 'atomicassets')
        assetId = aAssetId ? aAssetId : assetId

    const assetIds = []

    const handleBought = (buyInfo) => {
        if (buyInfo) {
            const { bought, error } = buyInfo

            if (bought) {
                setNewOwner(userName)
                asset['owner'] = newOwner
                asset['offer'] = null
            }

            setError(error)
            dispatch({ type: 'SET_BALANCE_CHANGED', payload: true })
            setBought(bought)
        }
        setIsLoading(false)
    }

    const handleSell = (sellInfo) => {
        if (sellInfo) {
            const wasSold = sellInfo['sold']
            const market = sellInfo['market']
            const offer = sellInfo['offer']

            if (wasSold) {
                if (wasSold && typeof wasSold === 'object') {
                    const transferredItems = {}
                    Object.keys(wasSold).map((assetId) => {
                        const asset = wasSold[assetId]
                        const localId =
                            asset['standard'] === 'atomicassets'
                                ? asset['aAssetId']
                                : asset['assetId']
                        transferredItems[localId] = asset
                        transferredItems[localId]['newOwner'] = asset['market']
                        transferredItems[localId]['owner'] = asset['market']
                        transferredItems[localId]['market'] = asset['market']
                        transferredItems[localId]['offer'] = offer
                        transferredItems[localId]['seller'] = userName
                        transferredItems[localId]['sold'] = true
                    })

                    dispatch({
                        type: 'SET_TRANSFERRED_ASSETS',
                        payload: transferredItems,
                    })
                }
                setNewOwner(market)
                asset['owner'] = market
                asset['market'] = market
                asset['offer'] = offer
                asset['seller'] = userName
                setCanceled(false)
            }

            if (sellInfo['error']) {
                setError(sellInfo['error'])
            }

            setSold(wasSold)
        }
        setIsLoading(false)
    }

    const handleTopup = (topupInfo) => {
        if (topupInfo) {
            const wasCanceled = topupInfo['canceled']
            const wasToppedUp = topupInfo['toppedUp']

            if (topupInfo['error']) {
                setError(topupInfo['error'])
                setIsLoading(false)
                return
            }

            setToppedUp(wasToppedUp)
            setCanceled(wasCanceled)
        }
        setIsLoading(false)
    }

    const handleCreateOffer = (offerInfo) => {
        if (offerInfo) {
            const wasCreated = offerInfo['created']

            if (offerInfo['error']) {
                setError(offerInfo['error'])
                setIsLoading(false)
                return
            }

            setCreated(wasCreated)
        }
        setIsLoading(false)
    }

    const handleBackAsset = (backInfo) => {
        if (backInfo) {
            const wasBacked = backInfo['backed']

            if (wasBacked) {
                asset['backedTokens'] = backInfo['backed'].map(
                    (token) => token['quantity'],
                )
            }

            if (backInfo['error']) {
                setError(backInfo['error'])
                setIsLoading(false)
                return
            }

            setBacked(wasBacked)
        }
        setIsLoading(false)
    }

    const handleBurn = (burnedInfo) => {
        if (burnedInfo) {
            const wasBurned = burnedInfo['burned']

            if (burnedInfo['error']) {
                setError(burnedInfo['error'])
                setIsLoading(false)
                return
            }

            setBurned(wasBurned)
        }
        setIsLoading(false)
    }

    const handleEditOffer = (offerInfo) => {
        if (offerInfo) {
            const edited = offerInfo['edited']
            const wasCanceled = offerInfo['canceled']

            if (offerInfo['error']) {
                setError(offerInfo['error'])
                setIsLoading(false)
                return
            }

            if (edited) {
                setEdited(true)
                asset['offer'] = edited
            }

            if (wasCanceled) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setCanceled(wasCanceled)
            }
        }
        setIsLoading(false)
    }

    const handleUnpack = (unpackInfo) => {
        if (unpackInfo) {
            if (unpackInfo['error']) {
                setError(unpackInfo['error'])
                setIsLoading(false)
                return
            }

            const wasUnpacked = unpackInfo['unpacked']

            if (wasUnpacked) {
                setNewOwner(null)
            }

            setUnpacked(wasUnpacked)
        }
        setIsLoading(false)
    }

    const handleCancel = (cancel) => {
        try {
            if (cancel) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setCanceled(cancel)
            }

            setIsLoading(false)
        } catch (e) {
            console.error(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleBidPlaced = (bidInfo) => {
        if (bidInfo?.[error]) setError(bidInfo?.[error])
        setBidPlaced(bidInfo?.['bidPlaced'])
        setIsLoading(false)
    }

    const handleClaim = (claim) => {
        try {
            if (claim) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setIsClaimed(true)
            }

            setIsLoading(false)
        } catch (e) {
            console.error(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleUnstake = (unstake) => {
        try {
            if (unstake) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setUnstaked(true)
                setStaked(false)
                setBought(false)
                setSold(false)
            }

            setIsLoading(false)
        } catch (e) {
            console.error(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleStake = (stake) => {
        try {
            if (stake) {
                setNewOwner('s.rplanet')
                asset['owner'] = userName
                asset['offer'] = null
                setStaked(true)
                setUnstaked(false)
                setBought(false)
            }

            setIsLoading(false)
        } catch (e) {
            console.error(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleSellOffer = (sellInfo) => {
        if (sellInfo) {
            if (sellInfo['error']) {
                setError(sellInfo['error'])
                setIsLoading(false)
                return
            }

            const wasSold = sellInfo['sold']

            setSold(wasSold)
        }
        setIsLoading(false)
    }

    const handleTransfer = (sellInfo) => {
        if (sellInfo) {
            const wasTransferred = sellInfo['transferred']
            const owner = sellInfo['receiver']

            if (wasTransferred) {
                setNewOwner(owner)
                asset['owner'] = owner
                asset['sender'] = userName
            }

            setTransferred(wasTransferred)
        }
        setIsLoading(false)
    }

    const handleSwap = (swap) => {
        try {
            setSwapped(swap)
            asset['standard'] =
                asset['standard'] === 'simpleassets'
                    ? 'atomicassets'
                    : 'simpleassets'

            setIsLoading(false)
            setCanceled(false)
        } catch (e) {
            console.error(e.message)
            setSwapped(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    if (assets && !bundleView) {
        const a = assets[currentAsset]
        assetId = a.assetId
        author = a.author
        name =
            assets.length > 1
                ? orderName
                    ? `${currentAsset + 1}/${assets.length} ${orderName.substr(
                          0,
                          60,
                      )}`
                    : `${assets[currentAsset].name} (Bundle ${
                          currentAsset + 1
                      }/${assets.length})`
                : a.name
        image = a.image
        thumbnail = a.thumbnail
        universalPreview = a.universalPreview
        backimg = a.backimg
        mint = a.mint
        mdata = a.mdata
        backedTokens = a.backedTokens
        assets.forEach((asset) => assetIds.push(asset.assetId))
    } else {
        assetIds.push(assetId)
    }

    const prevAsset = async () => {
        if (currentAsset > 0) setCurrentAsset(currentAsset - 1)
        else setCurrentAsset(assets.length - 1)
    }

    const nextAsset = async () => {
        if (currentAsset === assets.length - 1) setCurrentAsset(0)
        else setCurrentAsset(currentAsset + 1)
    }

    const assetData = mdata ? JSON.parse(mdata) : null

    let video = ''

    if (
        assetData &&
        Object.keys(assetData).includes('video') &&
        assetData['video']
    ) {
        video = assetData['video'].includes('http')
            ? assetData['video']
            : `${config.ipfs}${assetData['video']}`
    } else if (
        assetData &&
        Object.keys(assetData).includes('Video') &&
        assetData['Video']
    ) {
        video = assetData['Video'].includes('http')
            ? assetData['Video']
            : `${config.ipfs}${assetData['Video']}`
    } else if (image && image.includes('video:')) {
        video = image.replace('video:', '')
        video = video.includes('http') ? video : `${config.ipfs}${video}`
    }

    if (thumbnail && video) {
        const hashes = thumbnail.split('hash=')
        if (hashes.length > 1) {
            const hash = hashes[1]
            if (video.includes(hash)) {
                video = thumbnail
            } else {
                video = null
            }
        }
    }

    const toggleShowMenu = () => {
        setShowMenu(!showMenu)
    }

    const loadBack = (e) => {
        onOpen()
        setFrontVisible(false)
        if (e) e.preventDefault()
    }

    const assetIncluded = () => {
        return selectedAssets
            .map(
                (a) =>
                    Object.assign(
                        {},
                        a,
                        a.assets?.length > 0 ? a.assets[0] : null,
                    ).assetId,
            )
            .includes(
                Object.assign(
                    {},
                    asset,
                    asset.assets?.length > 0 ? asset.assets[0] : null,
                ).assetId,
            )
    }

    const checked = assetIncluded()

    const handleClose = () => {
        setFrontVisible(true)
        onClose()
    }

    const cardHeader = (
        <div className={'absolute w-full h-8 z-10'}>
            <div className={'absolute flex m-auto h-10 w-8'}>
                {selectable && selectable(asset) && (
                    <Checkbox
                        checked={checked}
                        onChange={(e) => handleCheck(e, asset)}
                    />
                )}
            </div>
            <div />
            <div />
            {page !== 'newbuyoffer' && page !== 'editor' && (
                <MoreOptions
                    setShowMenu={setShowMenu}
                    newOwner={newOwner}
                    showMenu={showMenu}
                    asset={asset}
                    handleSell={handleSell}
                    handleTransfer={handleTransfer}
                    handleSwap={handleSwap}
                    handleStake={handleStake}
                    handleCreateOffer={handleCreateOffer}
                    handleBackAsset={handleBackAsset}
                    handleBurn={handleBurn}
                    created={created}
                    backed={backed}
                    burned={burned}
                    staked={staked}
                    setError={setError}
                    sold={sold}
                    setSold={setSold}
                    setIsLoading={setIsLoading}
                    transferred={transferred}
                    toggleShowMenu={toggleShowMenu}
                    currentAsset={currentAsset}
                    setBackMenuOpen={setBackMenuOpen}
                />
            )}
        </div>
    )

    const href = symbol
        ? '/pack/' +
          symbol +
          (orderId ? `?listing_id=${orderId ? orderId : ''}` : '') +
          (assetId ? `${orderId ? '&' : '?'}asset_id=${assetId}` : '') +
          (market ? `${orderId || assetId ? '&' : '?'}market=${market}` : '')
        : market === 'waxbuyoffers'
        ? `/offer/${orderId}`
        : saleId
        ? `/sale/${saleId}`
        : offer
        ? isAuction
            ? '/auction/' + market + '/' + orderId
            : '/listing/' + market + '/' + (orderId ? orderId : assetId)
        : assetId
        ? '/asset/' + assetId
        : templateId
        ? '/template/' + templateId
        : ''

    const marketButtons = (view) => (
        <AssetButtons
            view={view}
            className={
                view === 'full'
                    ? 'mt-auto h-full w-full'
                    : 'mt-auto h-full w-full'
            }
            onAdd={onAdd}
            asset={asset}
            loadBack={loadBack}
            newOwner={newOwner}
            searchOwner={searchOwner}
            claimer={claimer}
            claimed={claimed}
            handleSell={handleSell}
            handleBurn={handleBurn}
            handleUnpack={handleUnpack}
            handleBought={handleBought}
            handleClaim={handleClaim}
            handleSellOffer={handleSellOffer}
            handleUnstake={handleUnstake}
            handleCancel={handleCancel}
            handleBidPlaced={handleBidPlaced}
            handleTopup={handleTopup}
            handleCreateOffer={handleCreateOffer}
            handleEditOffer={handleEditOffer}
            created={created}
            swapped={swapped}
            burned={burned}
            transferred={transferred}
            assetType={assetType}
            unstaked={unstaked}
            unpacked={unpacked}
            staked={staked}
            toppedUp={toppedUp}
            page={page}
            bidPlaced={bidPlaced}
            bought={bought}
            canceled={canceled}
            edited={edited}
            isClaimed={isClaimed}
            error={error}
            setError={setError}
            sold={sold}
            setSold={setSold}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            showOwner={showOwner}
            currentAsset={currentAsset}
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
        />
    )

    const [elementSize, elementRef] = useElementSize()
    let cardWidth = elementSize.width - 60
    return (
        <LazyLoad offset={200}>
            <>
                <Modal
                    isDismissable={false}
                    size={'3xl'}
                    isOpen={backMenuOpen}
                    onClose={() => {
                        setBackMenuOpen(false)
                        setIsLoading(false)
                    }}
                    scrollBehavior="inside"
                    classNames={{
                        wrapper: 'z-[1301]',
                        body: 'p-0',
                        closeButton:
                            'absolute top-2 right-2 z-50 bg-red-500 text-white hover:bg-red-600',
                    }}
                >
                    <ModalContent>
                        <>
                            <ModalBody className="px-0">
                                <BackAssetPopup
                                    asset={asset}
                                    open={backMenuOpen}
                                    closeCallBack={() => {
                                        setBackMenuOpen(false)
                                        setIsLoading(false)
                                    }}
                                    callBack={(item) => {
                                        handleBackAsset(item)
                                    }}
                                />
                            </ModalBody>
                        </>
                    </ModalContent>
                </Modal>
                <MarketCard
                    height={isAuction ? 'high' : 'normal'}
                    cardHeader={cardHeader}
                    onFlip={loadBack}
                    page={page}
                    new={asset['new']}
                >
                    <div
                        className={
                            ' relative flex  justify-center items-center  !aspect-1'
                        }
                    >
                        <div
                            className={cn(
                                'flex justify-center items-center w-full h-full',
                            )}
                        >
                            {bundle && !bundleView && assets.length > 1 ? (
                                <button
                                    className={cn(
                                        'absolute m-auto h-full border-0 text-primary cursor-pointer',
                                        'outline-none text-2xl left-0 opacity-80 hover:opacity-100',
                                    )}
                                    onClick={prevAsset}
                                >
                                    ‹
                                </button>
                            ) : null}
                            <Link2
                                inactive={
                                    page === 'newbuyoffer' ||
                                    page === 'editor' ||
                                    clickAsset
                                }
                                href={href}
                                onClick={(e) => {
                                    const elem = window.document.getElementById(
                                        'AssetDetailsPopupContent' +
                                            (assetId ? assetId : templateId),
                                    )
                                    if (!elem || !elem.contains(e.target)) {
                                        dispatch({
                                            type: 'SET_ACTION_SEARCH',
                                            payload: {
                                                actionSearch: '',
                                                asset: null,
                                                searchType: '',
                                                searchID: '',
                                                includeDrops: false,
                                            },
                                        })
                                    }
                                }}
                                altOnClick={
                                    clickAsset
                                        ? () => clickAsset(index)
                                        : () => {}
                                }
                                className={cn('h-full w-full')}
                            >
                                <div
                                    className={cn(
                                        'relative m-auto cursor-pointer h-full w-full',
                                    )}
                                >
                                    <Tooltip
                                        leaveDelay={100}
                                        title={
                                            <AssetDetailsPopupContent
                                                asset={
                                                    asset.assets
                                                        ? asset.assets[
                                                              currentAsset
                                                          ]
                                                        : asset
                                                }
                                                userName={userName}
                                                priceInfo={priceInfo}
                                                setPriceInfo={setPriceInfo}
                                            />
                                        }
                                        placement="right"
                                        arrow
                                        enterDelay={700}
                                        enterNextDelay={700}
                                        disableTouchListener
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    borderRadius: '1rem',
                                                    backgroundColor:
                                                        theme === 'light'
                                                            ? 'white'
                                                            : '#141414FA',
                                                    maxWidth: 'none',
                                                },
                                            },
                                        }}
                                    >
                                        <div className="relative flex w-full h-full overflow-hidden">
                                            <AssetPreviewDisplay
                                                image={image}
                                                prevImg={
                                                    universalPreview
                                                        ? universalPreview
                                                        : thumbnail
                                                        ? thumbnail
                                                        : previmg
                                                }
                                                video={video}
                                                backImg={backimg}
                                                previewDuration={
                                                    previewDuration
                                                }
                                                className={cn('h-full')}
                                                imgClassName={cn(
                                                    'object-contain transition transform max-h-full max-w-full',
                                                    'duration-500 md:hover:scale-100 hover:scale-105 cursor-pointer',
                                                )}
                                                mdata={mdata}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            </Link2>
                            {bundle && !bundleView && assets.length > 1 && (
                                <button
                                    className={cn(
                                        'absolute m-auto h-full border-0 text-primary cursor-pointer',
                                        'outline-none text-2xl right-0 opacity-80 hover:opacity-100',
                                    )}
                                    onClick={nextAsset}
                                >
                                    ›
                                </button>
                            )}
                        </div>
                        {mint && !rank && !rarityScore ? (
                            <MintInfo
                                top={'-bottom-4 right-1'}
                                mint={mint}
                                myMint={myMint}
                            />
                        ) : (
                            ''
                        )}
                        {rank && rarityScore ? (
                            <RankInfo rank={rank} rarityScore={rarityScore} />
                        ) : (
                            ''
                        )}
                        {backedTokens && (
                            <BackedInfo backedTokens={backedTokens} />
                        )}
                    </div>
                    <div
                        className={
                            'p-4 flex space-y-2 flex-wrap h-full justify-between'
                        }
                    >
                        <CollectionAssetName
                            linkInactive={
                                page === 'newbuyoffer' ||
                                page === 'editor' ||
                                clickAsset
                            }
                            collectionImage={
                                collectionThumbnail
                                    ? collectionThumbnail
                                    : collectionImage
                            }
                            onClick={() => {
                                dispatch({
                                    type: 'SET_ACTION_SEARCH',
                                    payload: {
                                        actionSearch: '',
                                        asset: null,
                                        searchType: '',
                                        searchID: '',
                                        includeDrops: false,
                                    },
                                })
                            }}
                            altOnClick={
                                clickAsset ? () => clickAsset(index) : () => {}
                            }
                            href={href}
                            name={name ? name : assetId}
                            collection={author}
                            verified={verified}
                            blacklisted={blacklisted}
                            cardWidth={cardWidth}
                            elementRef={elementRef}
                        />
                        {!selectedAsset &&
                            selectedAsset !== 0 &&
                            marketButtons('')}
                    </div>
                    {isAuction && <AuctionTimeleft asset={asset} />}
                </MarketCard>
                <Modal
                    isOpen={isOpen}
                    onClose={handleClose}
                    size="4xl"
                    scrollBehavior={'inside'}
                    classNames={{
                        body: 'p-0',
                        closeButton:
                            'absolute top-2 right-1 p-2 z-50 bg-red-500 text-white hover:bg-red-600',
                    }}
                    placement="top"
                >
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <AssetPreviewInfo
                                    cancel={handleClose}
                                    asset={
                                        assets ? assets[currentAsset] : asset
                                    }
                                    newOwner={newOwner}
                                    priceInfo={priceInfo}
                                    setPriceInfo={setPriceInfo}
                                    currentAsset={currentAsset}
                                    bundleView={bundleView}
                                    show={!frontVisible}
                                    buttons={marketButtons('full')}
                                />
                            </>
                        )}
                    </ModalContent>
                </Modal>
            </>
        </LazyLoad>
    )
}

function assetPropsAreEqual(prevAsset, nextAsset) {
    let item1 = 0
    let item2 = 0

    const id1 = (
        prevAsset.asset.assets?.length > 0
            ? prevAsset.asset.assets[0]
            : prevAsset.asset
    ).assetId

    if (
        prevAsset.selectedAssets &&
        prevAsset.selectedAssets
            .map((a) => (a.assets?.length > 0 ? a.assets[0] : a).assetId)
            .includes(id1)
    ) {
        item1 = id1
    }

    const id2 = (
        nextAsset.asset.assets?.length > 0
            ? nextAsset.asset.assets[0]
            : nextAsset.asset
    ).assetId

    if (
        nextAsset.selectedAssets &&
        nextAsset.selectedAssets
            .map((a) => (a.assets?.length > 0 ? a.assets[0] : a).assetId)
            .includes(id2)
    ) {
        item2 = id2
    }

    return (
        ((!prevAsset.selectedAssets && !nextAsset.selectedAssets) ||
            (prevAsset.selectedAssets &&
                nextAsset.selectedAssets &&
                prevAsset.selectedAssets.length ===
                    nextAsset.selectedAssets.length)) &&
        id1 === id2 &&
        prevAsset.index === nextAsset.index &&
        item1 === item2 &&
        prevAsset.isSelected === nextAsset.isSelected &&
        item1 === 0 &&
        item2 === 0
    )
}

export default React.memo(AssetPreview, assetPropsAreEqual)
