import { Button, TextField } from '@mui/material'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

function StakeCpuNetPopup(props) {
    const balance = props['balance']

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [stakeNet, setStakeNet] = useState(0)
    const [stakeCpu, setStakeCpu] = useState(0)
    const closeCallBack = props['closeCallBack']
    const [error, setError] = useState()

    const { t } = useTranslation('common')

    const stake = async () => {
        if ((!stakeNet && !stakeCpu) || stakeNet < 0 || stakeCpu < 0) {
            setError('Must at least stake a positive amount of CPU or NET')
            return false
        }
        const quantity_net = stakeNet ? stakeNet : 0
        const quantity_cpu = stakeCpu ? stakeCpu : 0
        setIsLoading(true)
        try {
            const result = await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'eosio',
                            name: 'delegatebw',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                receiver: userName,
                                stake_net_quantity: `${quantity_net.toFixed(
                                    8,
                                )} WAX`,
                                stake_cpu_quantity: `${quantity_cpu.toFixed(
                                    8,
                                )} WAX`,
                                transfer: false,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ wasStaked: true })
            closeCallBack()
        } catch (e) {
            console.error(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const changeNet = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (val === '') {
                setStakeNet(0)
            } else {
                const net = Math.min(parseFloat(val), balance)

                balanceCpu(net)

                setStakeNet(net)
            }
        }
    }

    const changeCpu = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (val === '') {
                setStakeCpu(0)
            } else {
                const cpu = Math.min(parseFloat(val), balance)

                balanceNet(cpu)

                setStakeCpu(cpu)
            }
        }
    }

    const dismissError = () => {
        setError(null)
    }

    const cancel = () => {
        callBack({ wasStaked: false })
        closeCallBack()
    }

    const balanceNet = (cpu) => {
        if (stakeNet + cpu > balance)
            setStakeNet(Math.floor((balance - cpu) * 100000000) / 100000000)
    }

    const balanceCpu = (net) => {
        if (stakeCpu + net > balance)
            setStakeCpu(Math.floor((balance - net) * 100000000) / 100000000)
    }

    const applyStakeCpu = (percentage) => {
        const cpu = Math.floor(balance * percentage * 100000000) / 100000000

        balanceNet(cpu)

        setStakeCpu(cpu)
    }

    const applyStakeNet = (percentage) => {
        const net = Math.floor(balance * percentage * 100000000) / 100000000

        balanceCpu(net)

        setStakeNet(net)
    }

    const btnClass = cn('my-1 w-24 mx-auto')

    return (
        <Popup title={'Stake CPU / NET'} cancel={cancel}>
            <div className="mx-auto text-xl font-bold mb-4">
                Staking {stakeCpu} WAX to CPU and {stakeNet} WAX to NET
            </div>
            <div className={'m-auto mb-4'}>
                Available: {balance ? balance : 0} WAX
            </div>
            <div className={cn('grid grid-cols-1 md:grid-cols-2')}>
                <div className={'mx-2'}>
                    <TextField
                        className={cn('w-full')}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={'CPU'}
                        onChange={changeCpu}
                        value={stakeCpu ? stakeCpu : ''}
                    />
                    <div className={'grid grid-cols-3'}>
                        <Button
                            variant={'outlined'}
                            className={btnClass}
                            onClick={() => applyStakeCpu(0.1)}
                        >
                            10%
                        </Button>
                        <Button
                            variant={'outlined'}
                            className={btnClass}
                            onClick={() => applyStakeCpu(0.5)}
                        >
                            50%
                        </Button>
                        <Button
                            variant={'outlined'}
                            className={btnClass}
                            onClick={() => applyStakeCpu(0.9)}
                        >
                            90%
                        </Button>
                    </div>
                </div>
                <div className={'mx-2'}>
                    <TextField
                        className={cn('w-full')}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={'NET'}
                        onChange={changeNet}
                        value={stakeNet ? stakeNet : ''}
                    />
                    <div className={'grid grid-cols-3'}>
                        <Button
                            variant={'outlined'}
                            className={btnClass}
                            onClick={() => applyStakeNet(0.1)}
                        >
                            10%
                        </Button>
                        <Button
                            variant={'outlined'}
                            className={btnClass}
                            onClick={() => applyStakeNet(0.5)}
                        >
                            50%
                        </Button>
                        <Button
                            variant={'outlined'}
                            className={btnClass}
                            onClick={() => applyStakeNet(0.9)}
                        >
                            90%
                        </Button>
                    </div>
                </div>
            </div>
            <div className={'flex justify-end mt-10'}>
                <MainButton
                    className={'mt-0 max-w-64'}
                    onClick={stake}
                    disabled={!stakeNet && !stakeCpu}
                >
                    stake
                </MainButton>
                <MainButton onClick={cancel}>Cancel</MainButton>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage error={error} />
                </div>
            ) : (
                ''
            )}
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default StakeCpuNetPopup
