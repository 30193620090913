import { Tooltip } from '@mui/material'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import Link from 'next/link'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '../helpers/FormatLinks'
import { Picture, UserPicture } from '../user/UserPicture'
import { ProfileItem } from './ProfileItem'
import { useSharedState } from '../waxplorer/Waxplorer'

export default function ProfileDropDown({
    userName,
    balance,
    claimableHoneyBalance,
    honeyBalance,
    showClaimableHoney,
    userPicture,
}) {
    const { t } = useTranslation('common')
    const [isHovered, setIsHovered] = useState(false) // state to track hover

    const shortcuts = [
        {
            name: t('navigation.account'),
            description: t('description.account'),
            href: '/account',
            icon: '/icons/user.svg',
        },
        {
            name: t('profile.inventory'),
            description: t('description.inventory'),
            href: '/user/' + userName + '?tab=inventory#Tabs',
            icon: '/icons/box-heart.svg',
        },
        {
            name: t('search.sales'),
            description: t('description.your_listings'),
            href: '/user/' + userName + '?tab=listings#Tabs',
            icon: '/icons/coins.svg',
        },
        {
            name: t('general.wallet'),
            description: t('description.wallet'),
            href: '/user/' + userName + '?view=wallet',
            icon: '/icons/wallet.svg',
        },
        {
            name: t('asset.unpack') + '/' + t('asset.claim'),
            description: t('description.unpack'),
            href: '/unpack',
            icon: '/icons/gift.svg',
        },
    ]

    const fadeInOut = {
        hidden: { opacity: 0, y: 0 },
        visible: { opacity: 1, y: 0 },
    }

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }
    const [, , { logout }] = useSharedState()

    const logoutItem = {
        name: t('profile.logout'),
        description: t('description.logout'),
        href: '',
        icon: '/icons/door-open.svg',
        onclick: logout,
    }

    const honeyItem = {
        name: `HONEY ${t('navigation.info')}`,
        description: t('description.honey_info'),
        href: '/honey',
        icon: '/tokens/honey_nfthivehoney.png',
        outline: true,
    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="px-3 py-2 dark:!bg-zinc-900 bg-white hover:bg-zinc-200 hover:dark:!bg-zinc-800 cursor-pointer rounded-md font-semibold flex items-center"
        >
            <Picture
                className="max-h-12 max-w-12 rounded-full p-1.5"
                image={userPicture}
            />

            <div className={'ml-2 text-left'}>
                <div className={cn('text-xs mb-1')}>{userName}</div>
                <div className={cn('text-1xs opacity-60')}>
                    {formatNumber(balance || balance === 0 ? balance : 0.0)} WAX
                </div>
                {claimableHoneyBalance ? (
                    <Link href={'/honey'}>
                        <Tooltip title={t('profile.you_have_honey_to_claim')}>
                            {showClaimableHoney ? (
                                <div
                                    className={cn(
                                        'text-1xs opacity-100 text-primary',
                                    )}
                                >
                                    {formatNumber(claimableHoneyBalance)} HONEY
                                </div>
                            ) : (
                                <div className={cn('text-1xs opacity-60')}>
                                    {formatNumber(
                                        honeyBalance || honeyBalance === 0
                                            ? honeyBalance
                                            : 0.0,
                                    )}{' '}
                                    HONEY
                                </div>
                            )}
                        </Tooltip>
                    </Link>
                ) : (
                    <div className={cn('text-1xs opacity-60')}>
                        {formatNumber(
                            honeyBalance || honeyBalance === 0
                                ? honeyBalance
                                : 0.0,
                        )}{' '}
                        HONEY
                    </div>
                )}
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-4 h-4 ml-2 dark:text-white text-black"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
            </svg>

            <AnimatePresence>
                {isHovered && (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={fadeInOut}
                        transition={{ duration: 0.1, ease: 'easeInOut' }}
                        className="absolute right-4 top-[76px] z-50 min-w-[300px] p-4 rounded-xl shadow-xl border border-zinc-200 bg-white dark:!border-zinc-800 dark:!bg-zinc-900"
                    >
                        <div className="">
                            <div className="z-10">
                                <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px] mb-2">
                                    {t('navigation.shortcuts')}
                                </p>
                                <ul className="grid grid-cols-1 space-y-2 text-[16px]">
                                    {shortcuts.map((item) => (
                                        <ProfileItem item={item} />
                                    ))}

                                    <hr className="border-zinc-200 dark:!border-zinc-800 " />

                                    <ProfileItem item={honeyItem} />
                                    <hr className="dark:!border-zinc-800 border-zinc-200" />
                                    <ProfileItem item={logoutItem} />
                                </ul>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}
