import { Input } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import { Card } from '@nextui-org/react'
import MainButton from '../common/util/input/MainButton'
import { get } from '../helpers/Api'
import { formatNumber } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'
import { useSharedState } from '../waxplorer/Waxplorer'

const loadPriceInfo = async (args) => await get('price-info/' + args.assetId)

function AuctionPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const {
        assetId,
        aAssetId,
        schema,
        number,
        rarity,
        variant,
        author,
        name,
        image,
        standard,
    } = asset

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const offer = props['offer']

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const marketplace =
        standard === 'atomicassets' ? 'atomicmarket' : 'waxplorercom'
    const closeCallBack = props['closeCallBack']
    const [collectionFee, setCollectionFee] = useState(null)
    const [sellPrice, setSellPrice] = useState(offer)
    const [days, setDays] = useState(1)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)

    const LoadPriceInfo = (assetId) => {
        const { data, error, isLoading } = useAsync({
            promiseFn: loadPriceInfo,
            assetId: assetId,
        })
        if (data) {
            data.isLoading = false
            return data
        }
        return { priceInfo: {}, isLoading: true }
    }

    const getCollectionFee = async (author) => {
        get('collection-fee/' + author).then((result) =>
            setCollectionFee(result['fee']),
        )
    }

    useEffect(() => {
        getCollectionFee(author)
    }, [])

    const priceInfo = LoadPriceInfo(assetId)

    const sell = async () => {
        if (!sellPrice) return
        const quantity = parseFloat(sellPrice)
        const { assetId } = asset
        closeCallBack()
        setIsLoading(true)
        try {
            const result = await activeUser.session.transact(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'atomicmarket',
                            name: 'announceauct',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                duration:
                                    (days ? parseInt(days) * 24 * 60 * 60 : 0) +
                                    (hours ? parseInt(hours) * 60 * 60 : 0) +
                                    (minutes ? parseInt(minutes) * 60 : 0),
                                starting_bid: quantity.toFixed(8) + ' WAX',
                                seller: userName,
                                maker_marketplace:
                                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                        ? 'nfthiveboost'
                                        : 'nft.hive',
                                asset_ids: [aAssetId],
                            },
                        },
                        {
                            account: 'atomicassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser.requestPermission
                                        ? activeUser.requestPermission
                                        : 'active',
                                },
                            ],
                            data: {
                                from: userName,
                                memo: 'auction',
                                asset_ids: [aAssetId],
                                to: 'atomicmarket',
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({
                sold: true,
                market: marketplace,
                offer: quantity,
                transactionId: result?.response?.transaction_id,
            })
        } catch (e) {
            callBack(false, e, assetId)
            console.error(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const cancel = () => {
        callBack({ sold: false, market: null, offer: 0 })
        closeCallBack()
    }

    let cut = sellPrice - 0.03 * sellPrice
    if (collectionFee) cut = cut - collectionFee * sellPrice

    const changePrice = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) setSellPrice(val)
    }

    const changeHours = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setHours(val)
    }

    const changeMinutes = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setMinutes(val)
    }

    const changeDays = (e) => {
        const val = e.target.value
        if (/^\d*$/.test(val)) setDays(val)
    }

    return (
        <Popup title={name} cancel={cancel} image={image}>
            <div className="mx-auto mb-4 text-xl font-bold">
                {t('popups.are_you_sure_you_want_to_auction_asset_for_amount', {
                    asset: name,
                    minutes: minutes,
                    hours: hours,
                    days: days,
                    amount: `${formatNumber(sellPrice)} WAX / $${
                        !priceInfo || priceInfo['isLoading']
                            ? '...'
                            : formatNumber(sellPrice * priceInfo['usd_rate'])
                    }`,
                })}
            </div>
            <Card className="p-4">
                <div className="grid grid-cols-2 ">
                    <div className="flex flex-col gap-2 ">
                        <b>{t('asset.schema')}:</b>
                        {number && variant ? <b>{t('asset.card')}:</b> : null}
                        {rarity ? <b>{t('asset.rarity')}:</b> : null}
                        <b>{t('asset.author')}:</b>
                        <b>{t('asset.lowest_available_price')}:</b>
                        <b>{t('asset.average_bought')}:</b>
                        <b>{t('asset.last_sold')}:</b>
                        <b>{t('asset.price')}:</b>
                    </div>
                    <div className="flex flex-col gap-2 t">
                        <div>{schema}</div>
                        {number && variant ? (
                            <div>
                                {number}
                                {variant}
                            </div>
                        ) : null}

                        {rarity ? <div>{rarity}</div> : null}
                        <div>{author}</div>
                        <div>
                            {priceInfo['isLoading']
                                ? 'Loading...'
                                : priceInfo['lowest']
                                ? `${formatNumber(priceInfo['lowest'])} WAX`
                                : ''}
                            {priceInfo['isLoading'] ? (
                                ''
                            ) : (
                                <span className="usd">
                                    (
                                    {priceInfo['lowest_usd']
                                        ? `$${formatNumber(
                                              priceInfo['lowest_usd'],
                                          )}`
                                        : '-'}
                                    )
                                </span>
                            )}
                        </div>
                        <div>
                            {priceInfo['isLoading']
                                ? 'Loading...'
                                : priceInfo['average']
                                ? `${
                                      Math.round(priceInfo['average'] * 100) /
                                      100
                                  } WAX`
                                : ''}
                            {priceInfo['isLoading'] ? (
                                ''
                            ) : priceInfo['average_usd'] ? (
                                <span className="usd">
                                    /$
                                    {formatNumber(priceInfo['average_usd'])}
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                        <div>
                            {priceInfo['isLoading']
                                ? 'Loading...'
                                : priceInfo['last_sold']
                                ? `${
                                      Math.round(priceInfo['last_sold'] * 100) /
                                      100
                                  } WAX`
                                : ''}
                            {priceInfo['isLoading'] ? (
                                ''
                            ) : priceInfo['last_sold_usd'] ? (
                                <span className="usd">
                                    /$
                                    {formatNumber(priceInfo['last_sold_usd'])}
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                        <div>
                            {sellPrice ? `${formatNumber(sellPrice)} WAX` : ''}
                            {priceInfo['isLoading'] ? (
                                ''
                            ) : priceInfo['usd_rate'] ? (
                                <span className="usd">
                                    /$
                                    {formatNumber(
                                        sellPrice * priceInfo['usd_rate'],
                                    )}
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                    </div>
                </div>
            </Card>
            {/*   <div className="relative flex justify-center w-full mb-4">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <b>{t('asset.schema')}:</b>
                            </td>
                            <td>{schema}</td>
                        </tr>
                        {number && variant ? (
                            <tr>
                                <td>
                                    <b>{t('asset.card')}:</b>
                                </td>
                                <td>
                                    {number}
                                    {variant}
                                </td>
                            </tr>
                        ) : (
                            ''
                        )}
                        {rarity ? (
                            <tr>
                                <td>
                                    <b>{t('asset.rarity')}:</b>
                                </td>
                                <td>{rarity}</td>
                            </tr>
                        ) : (
                            ''
                        )}
                        <tr>
                            <td>
                                <b>{t('asset.author')}:</b>
                            </td>
                            <td>{author}</td>
                        </tr>
                        {
                            <tr>
                                <td>
                                    <b>{t('asset.lowest_available_price')}:</b>
                                </td>
                                <td>
                                    {priceInfo['isLoading']
                                        ? 'Loading...'
                                        : priceInfo['lowest']
                                        ? `${formatNumber(
                                              priceInfo['lowest'],
                                          )} WAX`
                                        : ''}
                                    {priceInfo['isLoading'] ? (
                                        ''
                                    ) : (
                                        <span className="usd">
                                            (
                                            {priceInfo['lowest_usd']
                                                ? `$${formatNumber(
                                                      priceInfo['lowest_usd'],
                                                  )}`
                                                : '-'}
                                            )
                                        </span>
                                    )}
                                </td>
                            </tr>
                        }
                        {
                            <tr>
                                <td>
                                    <b>{t('asset.average_bought')}:</b>
                                </td>
                                <td>
                                    {priceInfo['isLoading']
                                        ? 'Loading...'
                                        : priceInfo['average']
                                        ? `${
                                              Math.round(
                                                  priceInfo['average'] * 100,
                                              ) / 100
                                          } WAX`
                                        : ''}
                                    {priceInfo['isLoading'] ? (
                                        ''
                                    ) : priceInfo['average_usd'] ? (
                                        <span className="usd">
                                            /$
                                            {formatNumber(
                                                priceInfo['average_usd'],
                                            )}
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                            </tr>
                        }
                        {
                            <tr>
                                <td>
                                    <b>{t('asset.last_sold')}:</b>
                                </td>
                                <td>
                                    {priceInfo['isLoading']
                                        ? 'Loading...'
                                        : priceInfo['last_sold']
                                        ? `${
                                              Math.round(
                                                  priceInfo['last_sold'] * 100,
                                              ) / 100
                                          } WAX`
                                        : ''}
                                    {priceInfo['isLoading'] ? (
                                        ''
                                    ) : priceInfo['last_sold_usd'] ? (
                                        <span className="usd">
                                            /$
                                            {formatNumber(
                                                priceInfo['last_sold_usd'],
                                            )}
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                            </tr>
                        }
                        {
                            <tr>
                                <td>
                                    <b>{t('asset.price')}:</b>
                                </td>
                                <td>
                                    {sellPrice
                                        ? `${formatNumber(sellPrice)} WAX`
                                        : ''}
                                    {priceInfo['isLoading'] ? (
                                        ''
                                    ) : priceInfo['usd_rate'] ? (
                                        <span className="usd">
                                            /$
                                            {formatNumber(
                                                sellPrice *
                                                    priceInfo['usd_rate'],
                                            )}
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div> */}
            {error ? <ErrorMessage error={error} /> : ''}
            <div className="grid grid-cols-2 gap-2 mt-4 md:grid-cols-4">
                <div>
                    <Input
                        variant="faded"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={t('search.start_bid')}
                        onChange={changePrice}
                        value={sellPrice ? sellPrice : ''}
                    />
                </div>
                <div>
                    <Input
                        variant="faded"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={t('popups.days')}
                        onChange={changeDays}
                        value={days ? days : ''}
                    />
                </div>
                <div>
                    <Input
                        variant="faded"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={t('popups.hours')}
                        onChange={changeHours}
                        value={hours ? hours : ''}
                    />
                </div>
                <div>
                    <Input
                        variant="faded"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        label={t('popups.minutes')}
                        onChange={changeMinutes}
                        value={minutes ? minutes : ''}
                    />
                </div>
            </div>
            <div className={'my-4 flex justify-start'}>
                <MainButton
                    onClick={cancel}
                    className={'w-32 bg-red-500 max-w-32'}
                >
                    {t('asset.cancel')}
                </MainButton>
                <MainButton
                    disabled={
                        !sellPrice ||
                        ((!days || days === '0') &&
                            (!hours || hours === '0') &&
                            (!minutes || minutes === '0'))
                            ? 'disabled'
                            : ''
                    }
                    className={'w-32 max-w-32'}
                    padding={'pl-4'}
                    onClick={sell}
                >
                    {t('popups.auction')}
                </MainButton>
            </div>
            {collectionFee || collectionFee === 0 ? (
                <div className="grid grid-cols-2 gap-2 mt-4 md:grid-cols-4 ">
                    <div className="flex flex-col ">
                        <div>{t('popups.market_fee')}:</div>
                        <div>2%</div>
                    </div>
                    <div className="flex flex-col ">
                        <div>{t('popups.defi_fee')}:</div>
                        <div>1%</div>
                    </div>
                    <div className="flex flex-col ">
                        <div>{t('popups.collection_fee')}:</div>
                        <div>{collectionFee * 100}%</div>
                    </div>
                    <div className="flex flex-col ">
                        <div>{t('popups.your_cut')}:</div>
                        <div>{cut} WAX</div>
                    </div>
                </div>
            ) : (
                <LoadingIndicator />
            )}

            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.loading_transaction')}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default AuctionPopup
