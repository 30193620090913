import { AlertTitle, Collapse, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import config from '../../../config.json'
import { ImageUploadButton } from '../../imageupload/ImageUploadButton'
import { DropAlert } from '../DropAlert'
import { DropPaper } from '../DropPaper'
import { Input } from '@nextui-org/react'

export const PreviewImageUpload = ({
    previewImage,
    setPreviewImage,
    explanation,
    enablePreviewVideo,
    inPopup = false,
}) => {
    const [error, setError] = useState(null)
    const [isUploading, setIsUploading] = useState(false)

    const [isVideo, setIsVideo] = useState(null)

    useEffect(() => {
        if (enablePreviewVideo && previewImage) {
            setIsVideo(null)
        }
    }, [previewImage])

    return (
        <DropPaper
            error={!previewImage}
            id={'PreviewImageUpload'}
            noMargin={inPopup}
            title={
                enablePreviewVideo ? 'Preview Image / Video' : 'Preview Image'
            }
        >
            <div className="mb-5">
                {explanation
                    ? explanation
                    : `This image will be displayed in the drop overview and on drop
                pages. We recommend adding a short gif of some possible pfp
                variations or similar.`}
            </div>
            <div className="flex flex-wrap justify-center md:justify-start mb-5 items-center">
                <div className="my-auto w-96 mx-2">
                    <Input
                        variant="faded"
                        label="IPFS Hash"
                        value={previewImage ? previewImage : ''}
                        error={!previewImage}
                        onChange={(e) => setPreviewImage(e.target.value.trim())}
                        disabled={isUploading}
                    />
                </div>
                <div className=" m-4 md:m-0 ">
                    <ImageUploadButton
                        setIpfsHash={setPreviewImage}
                        setError={setError}
                        setProgressPercentage={() => {}}
                        setIsUploading={setIsUploading}
                        allowVideo={enablePreviewVideo}
                    />
                </div>
            </div>
            <DropAlert severity="error" condition={!previewImage}>
                Valid IPFS Hash is required
            </DropAlert>
            <DropAlert severity="error" condition={error !== null}>
                <AlertTitle>An error occured during upload!</AlertTitle>
                {error}
            </DropAlert>
            <Collapse in={previewImage}>
                <div className="relative flex justify-center h-92 -z-1">
                    <div className="absolute bg-gray-200 rounded-md w-92 h-92" />
                    <div className="absolute h-92">
                        {isVideo ? (
                            <video
                                src={config.ipfs + previewImage}
                                className="h-92"
                                key={config.ipfs + previewImage}
                                loop
                                autoPlay={true}
                                muted={true}
                                onError={() => {
                                    if (previewImage) {
                                        setIsVideo(false)
                                    }
                                }}
                            />
                        ) : isVideo === null ? (
                            <img
                                src={config.ipfs + previewImage}
                                className="h-92"
                                key={config.ipfs + previewImage}
                                onError={() => {
                                    if (enablePreviewVideo && previewImage) {
                                        setPreviewImage(previewImage, true)
                                        setIsVideo(true)
                                    }
                                }}
                            />
                        ) : (
                            <div className="relative text-lg italic font-bold text-black -translate-y-1/2 top-1/2">
                                - Invalid Image -
                            </div>
                        )}
                    </div>
                </div>
            </Collapse>
            <DropAlert
                condition={enablePreviewVideo && isVideo}
                severity="info"
                className="flex justify-center"
            >
                This is video and will be stored on-chain as{' '}
                <span className="font-mono">preview_video</span> instead of{' '}
                <span className="font-mono">preview_img</span>
            </DropAlert>
        </DropPaper>
    )
}
