import { FormControlLabel, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@nextui-org/react'
import CheckIndicator from '../checkindicator/CheckIndicator'
import MainButton from '../common/util/input/MainButton'
import { get, getDelphiMedian } from '../helpers/Api'
import { sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function BulkBuyPopup(props) {
    const assets = props['assets']

    const { t } = useTranslation('common')

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [errors, setErrors] = useState({})
    const [bought, setBought] = useState([])
    const [transactions, setTransactions] = useState({})
    const [maxActions, setMaxActions] = useState(1)

    const addError = (assetId, e) => {
        errors[assetId] = e
        setErrors(errors)
    }

    const addTransaction = (assetId, tx) => {
        transactions[assetId] = tx
        setTransactions(transactions)
    }

    const getActiveSales = async (assets) =>
        await get(
            `active-sales?assets=${Object.keys(
                assets,
            ).toString()}&sales=${Object.keys(assets)
                .map((assetId) => assets[assetId].orderId)
                .filter((orderId) => orderId)}`,
        )

    const [currentDisplayAction, setCurrentDisplayAction] = useState(0)

    const buy = async () => {
        if (!maxActions) return

        setIsLoading(true)

        let currentAction = 0

        const stillActive = await getActiveSales(assets)

        Object.keys(assets).map((assetId) => {
            if (
                !stillActive.includes(assetId) &&
                !stillActive.includes(parseInt(assetId))
            ) {
                addError(assetId, 'Already sold')
            }
        })

        let usd_wax = await getDelphiMedian(state)
        usd_wax /= 10000

        while (currentAction < stillActive.length / maxActions) {
            const start = currentAction * maxActions
            const end = start + maxActions
            const actions = [getBoostAction(activeUser)]

            const simpleassetsClaims = []
            let sellError = null

            for (const assetId of stillActive
                .sort((a, b) => parseFloat(a.offer) - parseFloat(b.offer))
                .slice(start, end)) {
                const asset = assets[assetId]
                const orderId = asset.orderId
                const currency = asset.currency

                let quantity = parseFloat(asset.offer)

                if (currency === 'USD') {
                    quantity = parseFloat(asset.usd_offer) / usd_wax
                }

                const marketplace = asset.market

                switch (marketplace) {
                    case 'atomicmarket':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'atomicmarket',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo: 'deposit',
                                },
                            })

                            actions.push({
                                account: 'atomicmarket',
                                name: 'purchasesale',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    buyer: userName,
                                    sale_id: orderId,
                                    taker_marketplace:
                                        process.env.NEXT_PUBLIC_TESTNET ===
                                        'TRUE'
                                            ? 'nfthiveboost'
                                            : 'nft.hive',
                                    intended_delphi_median:
                                        currency === 'USD'
                                            ? Math.round(usd_wax * 10000)
                                            : 0,
                                },
                            })
                        }
                        break
                    case 'waxplorercom':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'waxplorercom',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo: `purchase:${orderId}`,
                                },
                            })
                            simpleassetsClaims.push(assetId)
                        }
                        break
                    case 'simplemarket':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'simplemarket',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo:
                                        '{"nftid": ' +
                                        assetId +
                                        ', "affiliate_id": 200001}',
                                },
                            })

                            simpleassetsClaims.push(assetId)
                        }
                        break
                    case 'waxinventory':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'waxinventory',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo:
                                        '{"id":"' +
                                        assetId +
                                        '","type":"market"}',
                                },
                            })
                            simpleassetsClaims.push(assetId)
                        }
                        break
                    case 'market.place':
                        {
                            actions.push({
                                account: 'eosio.token',
                                name: 'transfer',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    from: userName,
                                    to: 'market.place',
                                    quantity: `${quantity.toFixed(8)} WAX`,
                                    memo: 'Payment for NFTs | collectables.io',
                                },
                            })
                            actions.push({
                                account: 'market.place',
                                name: 'buy',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    account: userName,
                                    listingid: orderId,
                                    referral: 'waxplorerref',
                                },
                            })

                            actions.push({
                                account: 'market.place',
                                name: 'withdraw',
                                authorization: [
                                    {
                                        actor: userName,
                                        permission: activeUser.requestPermission
                                            ? activeUser.requestPermission
                                            : 'active',
                                    },
                                ],
                                data: {
                                    user: userName,
                                    assets: [asset.assetId].map((id) => {
                                        return {
                                            contract: asset.standard,
                                            id: id,
                                            quantity:
                                                asset.standard ===
                                                'simpleassets'
                                                    ? '0.00000000 WAX'
                                                    : `1 ${asset.name}`,
                                        }
                                    }),
                                },
                            })
                            if (asset.standard === 'simpleassets') {
                                simpleassetsClaims.push(assetId)
                            }
                        }
                        break
                }
            }

            if (simpleassetsClaims.length > 0) {
                actions.push({
                    account: 'simpleassets',
                    name: 'claim',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission
                                ? activeUser.requestPermission
                                : 'active',
                        },
                    ],
                    data: {
                        claimer: userName,
                        assetids: simpleassetsClaims,
                    },
                })
            }

            try {
                const result = await activeUser.session.transact(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )

                Object.keys(assets)
                    .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                    .slice(start, end)
                    .map((assetId) =>
                        addTransaction(
                            assetId,
                            result['response']['transaction_id'],
                        ),
                    )
            } catch (e) {
                Object.keys(assets)
                    .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                    .slice(start, end)
                    .map((assetId) => addError(assetId, e.message))
                console.error(e)
                setError(e.message)
                sellError = e
            } finally {
                currentAction += 1
                setCurrentDisplayAction(currentAction)
                if (!error && !sellError) {
                    Object.keys(assets)
                        .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                        .slice(start, end)
                        .map((assetId) => bought.push(assets[assetId]))
                    setBought(bought)
                }
                if (currentAction < Object.keys(assets).length / maxActions) {
                    sleep(500)
                }
            }
        }
        setIsLoading(false)
        callBack({
            bought: bought,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    let sellInfo = t('asset.buy')

    const buyField = (
        <MainButton
            className="w-[80px] max-w-32"
            onClick={buy}
            disabled={!maxActions}
        >
            {sellInfo}
        </MainButton>
    )

    const cancel = () => {
        callBack({
            bought: bought,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    const changeMaxActions = (e) => {
        const newValue = parseInt(e.target.value)
        setMaxActions(
            newValue > Object.keys(assets).length
                ? Object.keys(assets).length
                : newValue > 0
                ? newValue
                : '',
        )
    }

    return (
        <Popup title={t('asset.buy')} cancel={cancel}>
            {Object.keys(bought).length > 0 ? <CheckIndicator /> : ''}
            {Object.keys(bought).length === 0 ? (
                <div className="mx-auto mt-4 mb-4 text-xl font-bold text-center md:mt-0 ">
                    {t('popups.are_you_sure_you_want_to_buy_asset_for_amount', {
                        asset: `${Object.keys(assets).length} Assets`,
                        amount: `${Object.keys(assets)
                            .map((asset) => assets[asset].offer)
                            .reduce(
                                (prev, curr) =>
                                    parseFloat(prev) + parseFloat(curr),
                                0,
                            )} WAX`,
                    })}
                </div>
            ) : (
                ''
            )}
            <div className="w-full mt-9 md:my-auto">
                <FormControlLabel
                    fullWidth={true}
                    size="large"
                    labelPlacement="top"
                    label={t('popups.items_per_transaction_explained')}
                    className="flex items-center justify-center"
                    control={
                        <div className={'my-5'}>
                            <Input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                label={t('popups.items_per_transaction')}
                                onChange={changeMaxActions}
                                value={maxActions}
                                varient="faded"
                            />
                        </div>
                    }
                />
            </div>
            {error ? <ErrorMessage onClick={dismissError} error={error} /> : ''}
            {Object.keys(bought).length === 0 ? (
                <div className="relative flex justify-center gap-4 mt-4">
                    <MainButton onClick={cancel} className="bg-red-500">
                        {t('asset.cancel')}
                    </MainButton>
                    {buyField}
                </div>
            ) : (
                ''
            )}
            {isLoading ? (
                <PopupLoadingIndicator
                    text={t('popups.signing_multiple_transactions', {
                        x: currentDisplayAction,
                        y: Math.ceil(Object.keys(assets).length / maxActions),
                    })}
                    isLoading={isLoading}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BulkBuyPopup
