import AddCircleIcon from '@mui/icons-material/AddCircle'
//import { Chip } from '@mui/material'
import { Chip } from '@nextui-org/react'
import cn from 'classnames'
import React, { useEffect } from 'react'

const SelectionField = ({
    options,
    onChange,
    value,
    values,
    multiSelect,
    widthClassName = '',
    center = false,
    size = 'sm',
    padding: paddingClassName = 'p-0',
}) => {
    const numOptions = options ? options.length : 0

    useEffect(() => {}, [numOptions])

    return (
        <div
            className={cn('relative h-auto', widthClassName, paddingClassName)}
        >
            <div
                className={cn('flex flex-wrap', {
                    'justify-center': center,
                    'justify-left': !center,
                })}
            >
                {options &&
                    options.map((option) => (
                        <div className="m-1 font-light">
                            {multiSelect &&
                            values &&
                            values.includes(option.value) ? (
                                // Multi select, e.g. tag suggesting - item is selected

                                <Chip
                                    className="cursor-pointer"
                                    size={size}
                                    variant="solid"
                                    radius="sm"
                                    label={option.label}
                                    onDelete={() => onChange(option)}
                                    onClick={() => onChange(option)}
                                    color={
                                        values && values.includes(option.value)
                                            ? 'primary'
                                            : 'default'
                                    }
                                >
                                    {option.label}
                                </Chip>
                            ) : multiSelect &&
                              !(values && values.includes(option.value)) ? (
                                // Multi select, e.g. tag suggesting - item is not selected

                                <Chip
                                    className="cursor-pointer"
                                    size={size}
                                    radius="sm"
                                    variant="bordered"
                                    label={option.label}
                                    onClick={() => onChange(option)}
                                    color={
                                        values && values.includes(option.value)
                                            ? 'primary'
                                            : 'default'
                                    }
                                    icon={<AddCircleIcon />}
                                >
                                    {option.label}
                                </Chip>
                            ) : (
                                // Individual select, e.g. category selection
                                <Chip
                                    className="cursor-pointer"
                                    size={size}
                                    variant={
                                        value && value.value === option.value
                                            ? 'solid'
                                            : 'bordered'
                                    }
                                    label={
                                        option.label.length > 30
                                            ? `${option.label.substring(
                                                  0,
                                                  30,
                                              )}...`
                                            : option.label
                                    }
                                    onClick={() => onChange(option)}
                                    color={
                                        value &&
                                        value.value === option.value &&
                                        value.value !== 'blacklisted'
                                            ? 'primary'
                                            : value &&
                                              value.value === option.value &&
                                              value.value === 'blacklisted'
                                            ? 'error'
                                            : 'default'
                                    }
                                >
                                    {option.label}
                                </Chip>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default SelectionField
